import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
// import { standerdsBreadcumbs } from "./content";
import { useQuery } from "@tanstack/react-query";
import { useHistory, useLocation, useParams } from "react-router";
import { programsIcon } from "../../../../assets/icons/Index";
import { permissionsCheck, useQuery as useSearchQuery } from "../../../../helpers/utils";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import StanderdList from "./standerdsList";
import { toast } from "react-toastify";

const StanderdsPage = (props) => {
  const { indicatorId } = useLocation();

  const { surveyId, perspectiveId, interlocutorId } = useParams();
  const history = useHistory();
  const search = useSearchQuery();

  const { data: surveyStageData } = useQuery({
    queryKey: ["surveyStage", surveyId],
    queryFn: () => {
      return axiosGetRequest(
        environment.getSurveyStagesBySuveyId,{
          surveyId: surveyId,
        }
      );
    },
  });


  const { data: isPublished } = useQuery({
    queryKey: ["isPublished", surveyId],
    queryFn: () => {
      return axiosGetRequest(
        environment.getSurveyStagesBySuveyId,{
          surveyId: surveyId,
        }
      );
    },
    select: (data) => {
      return data?.result?.isPublished;
    }
  });


  const standerdsBreadcumbs = [
    {
      icon: programsIcon,
      title: "استبيان التقنيات الناشئة",
      url: `/indicators/${indicatorId || localStorage.getItem("NavIndicatorId") || search.get("indicatorId")}`,
    },
    {
      title: "القدرات",
      url: `/programs/${surveyId}/perspectives`,
    },
    {
      title: "العناصر",
      url: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: "المعايير",
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={standerdsBreadcumbs}
      displayBtn={true}
      btnLabel="إضافة معيار"
      handleBtnClick={() =>{
        if(isPublished){
          toast.error("لا يمكنك إضافة معيار لاستبيان منشور")
          return
        }

        history.push({
          pathname: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards/add`,
          indicatorId: indicatorId,
          search: `?indicatorId=${search.get("indicatorId")}`,
        })
      }}
      hideBtn={
        permissionsCheck("Pages.Standards.Create")
          ? // &&
            // localStorage.getItem("surveyStageId") ===
            // localStorage.getItem("stageForDashboard")
            !!surveyStageData?.isPublished === true
          : true
      }
    >
      <StanderdList />
    </HeaderWithBreadcrumbLayout>
  );
};

export default StanderdsPage;
