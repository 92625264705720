import { useState } from "react";
import { programsIcon } from "../../../../assets/icons/Index";
import NewHeader from "../../../../layouts/header/NewHeader";
import { isDgaUsers } from "../../../../helpers/isPermission";
import StandardsTab from "./StandardsTab";
import AgenciesTab from "./AgenciesTab";

const AllEntities = () => {

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      icon: programsIcon,
      title: "المعايير",
      table: "StandardsTab",
      content: (
        <StandardsTab hideTitle={true} />
      ),
    },
    {
      icon: programsIcon,
      title: "الجهات",
      table: "AgenciesTab",
      content: (
        <AgenciesTab />
      ),
    },
  ];


  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      
      {
        isDgaUsers() ? <>
          <div className="flex flex-col justify-start px-5 rounded-3xl">
            <div className="flex flex-row">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={
                    "mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] " +
                    (activeTab === index
                      ? "bg-emerald-500 text-white"
                      : "text-slate-400 bg-slate-50")
                  }
                  onClick={() => setActiveTab(index)}
                >
                  <img
                    src={tab.icon || programsIcon}
                    alt="icon"
                    width={"24px"}
                    height={"24px"}
                  />
                  <button className="text-lg mr-2 text-center">
                    {tab.title}
                  </button>
                </div>
              ))}
            </div>

            <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
              {tabs.map((tab, index) => (
                <div key={index}>{activeTab === index && tab.content}</div>
              ))}
            </div>
          </div>
        </>
          :
          <StandardsTab />
      }
    </div>
  );
};

export default AllEntities;
