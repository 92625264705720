import AxisCard from "./cards/axisCard";
import EndoscopesCard from "./cards/endoscopesCard";
import StanderedCard from "./cards/standeredsCard";
import UnstanderedCard from "./cards/unstanderedsCard";
import StanderedsCardPhaseThree from "./cards/standeredsCardPhaseThree";
import UnstanderedsCardPhaseThree from "./cards/unstanderedsCardPhaseThree";
import { StageContext } from "../../contexts/stageContext";
import { useContext, useMemo } from "react";

// Define the cards array
function getCards(currentStage) {
  return [
    { id: 1, fullWidth: true, component: <EndoscopesCard title="القدرات" /> },
    { id: 2, fullWidth: true, component: <AxisCard title="العناصر" /> },
    {
      id: 3,
      fullWidth: true,
      omit: currentStage === 3,
      component: <StanderedCard title="المعايير المجابة" />,
    },
    {
      id: 4,
      fullWidth: true,
      omit: currentStage === 3,
      component: <UnstanderedCard title="المعايير الغير المجابة" />,
    },
    {
      id: 5,
      fullWidth: true,
      omit: currentStage !== 3,
      component: (
        <StanderedsCardPhaseThree title="المعايير المعاد فتحها المجابة" />
      ),
    },
    {
      id: 6,
      fullWidth: true,
      omit: currentStage !== 3,
      component: (
        <UnstanderedsCardPhaseThree title="المعايير المعاد فتحها الغير مجابة" />
      ),
    },
  ];
}

function AgencyDashboardCards() {
  const { currentStage } = useContext(StageContext);
  // console.log(data, "data");

  const cards = useMemo(
    () => getCards(currentStage?.stageNumber),
    [currentStage?.stageNumber]
  );

  return (
    <div className="p-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
      {cards
        .filter((item) => !item.omit)
        .map((card) => (
          <div
            key={card.id}
            className={
              card.fullWidth ? "col-span-2" : "col-span-2 lg:col-span-1"
            }
          >
            {card.component}
          </div>
        ))}
    </div>
  );
}

export default AgencyDashboardCards;
