import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import usePrevReviewerAnswer from "../useGetPrevAnswer/usePrevReviewerAnswer";
import NotesForAgency from "./notesForAgency";
import ReviewerNoteTemplate from "./reviewerNoteTemplates";
import ReviewerProofFiles from "./reviewerProofFiles";
import ReviewerRequirementAnswerBasedOnSides from "./StandardRequirements/ReviewerRequirementAnswerBasedOnSides";
import { ReviewerRequirementAnswerBasedOnStandard } from "./StandardRequirements/ReviewerRequirementAnswerBasedOnStandard";

const ReviewerAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();
  const { loadPreReviewerAnswer } = useAnswerContext();
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const { newAgencyAnswer } = useAnswerContext();

  const { entityId, standardId } = useParams();

  useEffect(() => {
    getPrevReviewerAnswer(standardId, entityId, currentStage?.id);
  }, [currentStage]);

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreReviewerAnswer}
        />
      )}

      {loadPreReviewerAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 &&
            (newAgencyAnswer.surveyStructureSubStandardRequirementDirectly ? (
              <ReviewerRequirementAnswerBasedOnStandard setMenuElement={setMenuElement} />
            ) : (
              <ReviewerRequirementAnswerBasedOnSides setMenuElement={setMenuElement} />
            ))}

          {menuElement === 3 && (
            <>
              <ReviewerProofFiles setMenuElement={setMenuElement} />
              <NextBtn onClick={() => setMenuElement(6)} />
            </>
          )}

          {menuElement === 6 && (
            <NotesForAgency
              setMenuElement={setMenuElement}
            />
          )}

          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createReviewerAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <ReviewerNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewerAnswer;