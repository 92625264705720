import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AppDivider from "../../../../../../components/appDivider";
import { axiosGetRequest } from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";
import AddSurveySide from "../addSide";
import SideItem from "./sdieItem";
import SideItemSkeleton from "./sideItemSkeleton";

const SurveySidesList = () => {
  const {surveyId} = useParams();
  const [loading, setLoading] = useState(false);
  const [sideList, setSideList] = useState([]);


  useEffect(() => {
    getSidesList();
  }, []);

  const getSidesList = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getAllSides,
      {
        SurveyId: surveyId,
      }
    );
    if (res?.success) {
      setSideList(res?.result?.items);
    }
    setLoading(false);
  };

  console.log({sideList});
  return (
    <>
      <AddSurveySide degreeList={sideList} setDegreeList={setSideList}/>
      <AppDivider/>
      {loading ? (
        <>
          <SideItemSkeleton/>
          <SideItemSkeleton/>
        </>
      ) : (
        <>
        {
          sideList?.map((item) => (
            <SideItem
              {...item}
              key={item.id}
              surveyId={surveyId}
              setSidesList={setSideList}
              sidesList={sideList}
              subStandardRequirementSides={item.subStandardRequirementSides}
            />
          ))        
        }
        </>
      )}
    </>
  );
};

export default SurveySidesList;
