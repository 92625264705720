import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreatePocOfficerAnswer from "../useCreateAnswer/useCreatePocOfficerAnswer";
import usePrevPocOfficerAnswer from "../useGetPrevAnswer/usePrevPocOfficerAnswer";
import PocOfficerFinalAnswer from "./pocOfficerFinalAnswer";
import PocOfficerNoteTemplate from "./pocOfficerNoteTemplates";
import PocOfficerProofFiles from "./pocOfficerProofFiles";
import PocOfficerRequirementAnswer from "./pocOfficerRequirementAnswer";
import PocOfficerRequirementAnswerConfidential from "./pocOfficerRequirementAnswerConfidential";

import NotesForAgency from "./notesForAgency";
import PocOfficerVisit from "./pocOfficerVisit";
import Tabs from "../../../../../../../components/Tabs/tabs";

const PocOfficerAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevPocOfficerAnswer } = usePrevPocOfficerAnswer();
  const { loadPrePocOfficerAnswer } = useAnswerContext();
  const { createPocOfficerAnswer } = useCreatePocOfficerAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency
  const { programId, entityId, standardId } = useParams();

  useEffect(() => {
    if (currentStage) {
      getPrevPocOfficerAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);

  if (menuElement === 3) {
    if(loadPrePocOfficerAnswer && menuElement !== 0){
      return (
        <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <div className="min-h-[400px] flex justify-center items-center">
            <AppLoader />
          </div>
        </div>
      );
    }else{
      return (
        <PocOfficerRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPrePocOfficerAnswer}
        />
      )}

      {loadPrePocOfficerAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <Tabs.TabList defaultActiveTab={0}>
              <Tabs.Tab title="فتح النظام">
                <PocOfficerProofFiles setMenuElement={setMenuElement} />
              </Tabs.Tab>
              <Tabs.Tab title="test" omit={true}>
                <PocOfficerProofFiles setMenuElement={setMenuElement} />
              </Tabs.Tab>
            </Tabs.TabList>
          )}
          {menuElement === 3 && (
            <PocOfficerRequirementAnswer setMenuElement={setMenuElement} />
          )}
          {/* {menuElement === 4 && (
            <PocOfficerFinalAnswer setMenuElement={setMenuElement} />
          )} */}

          {/* {menuElement === 5 && (
                  <StandardAnswerTemplates
                    answers={answers}
                    setAnswers={setAnswers}
                    setMenuElement={setMenuElement}
                  />
                )} */}
          {menuElement === 5 && (
            <PocOfficerVisit
              //  loading={loadingData}
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 6 && (
            <NotesForAgency
              //  loading={loadingData}
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createPocOfficerAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <PocOfficerNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default PocOfficerAnswer;
