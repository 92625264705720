import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../components/UploadAdapter";
import environment from "../../../services/environment";
import { post } from "../../../services/Request";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { InputLabel, SModal } from "../../../components";
import { Formik } from "formik";
import Skeleton from "react-skeleton-loader";
import { toast } from "react-toastify";
import { permissionsCheck } from "../../../helpers/utils";

// {to, title, content, choose, send, draft, cancel, close}
const NewMessage = () => {
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeTo = (e) => {
    setTo(e.target.value);
  };
  const handleChangeCc = (e) => {
    setCc(e.target.value);
  };
  const handleChangeBcc = (e) => {
    setBcc(e.target.value);
  };
  const showHideCc = () => {
    setShowCc(!showCc);
  };
  const showHideBcc = () => {
    setShowBcc(!showBcc);
  };
  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
  };
  const onChangeFile = (event) => {
    let attachments = [];
    setFiles(attachments);
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let reader = new FileReader();
      reader.onload = (e) => {
        let newFile = e.target.result;
        const regex = /data:.*base64,/;
        let obj = { fileName: file.name, fileByte: newFile.replace(regex, "") };
        attachments.push(obj);
        setFiles(attachments);
      };
      reader.readAsDataURL(file);
    }
  };
  const sendNewMessage = (values) => {};
  const sendMessage = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      to: values.to.split(";"),
      cc: values.cc ? values.cc.split(";") : [],
      bcc: values.bcc ? values.bcc.split(";") : [],
      subject: values.subject,
      message: description,
      attachments: files,
    };
    post(environment.sendMail, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        toast.success(t("تم الإرسال بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          backToMailBox();
        }, 1000);
      }
    });
  };
  const backToMailBox = () => {
    history.push("/mailbox");
  };
  const saveDraft = (values) => {
    setLoading(true);
    if (!values.to) {
      setMessage({
        type: "error",
        message: t("modules.main.mailbox.toRequired"),
      });
      return;
    }
    if (!values.subject) {
      setMessage({
        type: "error",
        message: t("modules.main.mailbox.subjectRequired"),
      });
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      to: values.to.split(";"),
      cc: values.cc.split(";"),
      bcc: values.bcc.split(";"),
      subject: values.subject,
      message: description,
      attachments: files,
    };
    post(environment.saveDraft, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        toast.success(t("تم الإرسال بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          backToMailBox();
        }, 1000);
      }
    });
  };

  return (
    <div className="m-5 bg-slate-50 rounded-xl px-10 py-5">
      <div className="flex flex-row justify-between items-center mb-10">
        <label className="w-full mb-2 block text-lg font-semibold">
          رسالة جديدة
        </label>
        <FontAwesomeIcon
          className="ml-2 cursor-pointer"
          icon={faX}
          onClick={() => backToMailBox()}
        />
      </div>
      {loading ? (
        <div className="p-5 bg-transparent w-3/4">
          <Skeleton
            animated={true}
            color={"#2B296969"}
            width={"100%"}
            height={"500px"}
            count={1}
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            to: "",
            cc: "",
            bcc: "",
            subject: "",
            showCc: false,
            showBcc: false,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.to) {
              errors.to = t("modules.main.mailbox.toRequired");
            }
            if (!values.subject) {
              errors.subject = t("modules.main.mailbox.subjectRequired");
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            sendMessage(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="w-full">
              <div class="w-full flex flex-col gap-2">
                <div className="mb-2 w-full flex flex-col items-start justify-between">
                  <div className="flex flex-col gap-3  w-full">
                    <label className="mb-2 text-violet-950 text-lg font-medium">
                      إلى
                    </label>
                    <div className="flex flex-row w-full justify-start items-center">
                      <input
                        type="email"
                        className="w-[90%] py-4 rounded-[30px] text-violet-950 text-opacity-30 text-[16px]  border-none"
                        placeholder="أدخل وجهة الرساله هنا"
                        name="to"
                        style={{
                          borderColor: errors.to
                            ? "red"
                            : values.to != ""
                            ? "#22bb33"
                            : "",
                        }}
                        value={values.to}
                        onChange={handleChange}
                      />
                      <div className="flex flex-col mr-2">
                        <button
                          /*showHideCc()*/

                          onClick={() => (values.showCc = !values.showCc)}
                        >
                          {t("modules.main.mailbox.cc")}
                        </button>
                        <button
                          /*showHideBcc()*/
                          onClick={() => (values.showBcc = !values.showBcc)}
                        >
                          {t("modules.main.mailbox.bcc")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-red-600 my-3">
                  {errors.to && touched.to && errors.to}
                </div>
                <div
                  className={`mb-2 w-full flex flex-col items-start justify-between ${
                    values.showCc ? "" : "d-none"
                  }`}
                >
                  <label className="mb-2 text-violet-950 text-sm">
                    {t("modules.main.mailbox.cc")}
                  </label>
                  <input
                    type="email"
                    className="w-[90%] rounded-[30px] text-violet-950 text-opacity-30 text-[15px] border-none"
                    placeholder="email@email.com"
                    name="cc"
                    value={values.cc}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`mb-2 w-full flex flex-col items-start justify-between ${
                    values.showBcc ? "" : "d-none"
                  }`}
                >
                  <label className="mb-2 text-violet-950 text-sm">
                    {t("modules.main.mailbox.bcc")}
                  </label>
                  <input
                    type="email"
                    className="w-[90%] rounded-[30px] text-violet-950 text-opacity-30 text-[15px] border-none"
                    placeholder="email@email.com"
                    name="bcc"
                    value={values.bcc}
                    onChange={
                      handleChange
                    } /*onChange={(e)=>handleChangeBcc(e)}*/
                  />
                </div>
                <div className="mb-2 w-full flex flex-col items-start justify-between">
                  <div className="flex flex-col gap-3  w-full">
                    <label className="mb-2 text-violet-950 text-lg font-medium">
                      عنوان الرسالة
                    </label>
                    <input
                      type="text"
                      className="w-[90%] py-4 rounded-[30px] text-violet-950 text-opacity-30 text-[15px] border-none"
                      placeholder="أكتب عنوان الرسالة"
                      name="subject" /*value={subject} onChange={(e)=>handleChangeSubject(e)}*/
                      style={{
                        borderColor: errors.subject
                          ? "red"
                          : values.subject != ""
                          ? "#22bb33"
                          : "",
                      }}
                      value={values.subject}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="text-sm text-red-600 my-3">
                  {errors.subject && touched.subject && errors.subject}
                </div>

                <div className="flex flex-col gap-5 w-full">
                  <label className="text-violet-950 text-lg font-medium">
                    الرسالة
                  </label>
                  <div className="w-[90%]">
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        setDescription(editor.getData());
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function (loader) {
                          return new UploadAdapter(loader);
                        };
                      }}
                      config={{
                        language: "ar",

                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1' }, // Corrected to 'h1'
                            { model: 'heading2', view: 'h2', title: 'Heading 2' }, // Corrected to 'h2'
                            { model: 'heading3', view: 'h3', title: 'Heading 3' }  // Corrected to 'h3'
                          ]
                        },   

                        toolbar: [ 
                          'undo', 'redo', '|', 
                          'heading', '|', 
                          'bold', 'italic', 'Underline', '|',
                          'link', 'insertTable', 'blockQuote', '|',
                          'bulletedList', 'numberedList', '|',
                          // 'outdent', 'indent', 
                        ],
            
                        simpleUpload: {
                          uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                          withCredentials: true,
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function (loader) {
                          return new UploadAdapter(loader);
                        };
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </div>
                </div>

                <div className="my-5 w-full">
                  <InputLabel
                    style={{ display: "none" }}
                    type={"file"}
                    id={"fileUpload"}
                    isImage={false}
                    multiple={true}
                    onChange={onChangeFile}
                  />
                </div>

                <div className="flex justify-end my-5">
                  <div className="flex flex-row gap-4">
                    {permissionsCheck("Pages.MailBox.Create") ? (
                      <button
                        type={"submit"}
                        className="p-3 mt-3 text-white bg-[#1CC081] w-[160px] border-none rounded-[30px]"
                        onClick={() => sendMessage(values)}
                      >
                        ارسال
                      </button>
                    ) : null}
                    <button
                      type={"submit"}
                      className="p-3 mt-3 text-[#7D8BAB] bg-[#DBE0EF] w-[160px] border-none rounded-[30px]"
                      onClick={() => saveDraft(values)}
                    >
                      حفظ كمسودة
                    </button>
                    <button
                      type={"submit"}
                      className="p-3 mt-3 text-white bg-[#B40001] w-[99px] border-none rounded-[30px]"
                      onClick={() => backToMailBox()}
                    >
                      إلغاء
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      <SModal
        type={message.type}
        open={openModel}
        setOpen={() => setOpenModel(false)}
        subTitle={message.message}
      />
    </div>
  );
};

export default NewMessage;
