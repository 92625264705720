import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateExpertAnswer from "../useCreateAnswer/useCreateExpertAnswer";
import usePrevExpertAnswer from "../useGetPrevAnswer/usePrevExpertAnswer";
import ExpertNoteTemplate from "./expertNoteTemplates";
import ExpertProofFiles from "./expertProofFiles";
import NotesForAgency from "./notesForAgency";

import ExpertRequirementAnswerBasedOnSides from "./StandardRequirements/ExpertRequirementAnswerBasedOnSides";
import { ExpertRequirementAnswerBasedOnStandard } from "./StandardRequirements/ExpertRequirementAnswerBasedOnStandard";

const ExpertAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertAnswer } = useAnswerContext();
  const { createExpertAnswer } = useCreateExpertAnswer();
  const { entityId, standardId } = useParams();

  useEffect(() => {
    getPrevExpertAnswer(standardId, entityId, currentStage?.id);
  }, [currentStage]);

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertAnswer}
        />
      )}

      {loadPreExpertAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 &&
            (newAgencyAnswer.surveyStructureSubStandardRequirementDirectly ? (
              <>
                <ExpertRequirementAnswerBasedOnStandard setMenuElement={setMenuElement} />
              </>
            ) : (
              <>
                <ExpertRequirementAnswerBasedOnSides setMenuElement={setMenuElement} />
              </>
            ))}
          {menuElement === 3 && (
            <>
              <ExpertProofFiles setMenuElement={setMenuElement} />
              <NextBtn onClick={() => setMenuElement(6)} />
            </>
          )}

          {menuElement === 6 && (
            <NotesForAgency setMenuElement={setMenuElement} />
          )}

          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <ExpertNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertAnswer;