import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { surveyId } from "../../../../../helpers/isPermission";

export const useGetAnswersAnalysis = ({ currentPage, maxResult, agencyId }) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAnswersAnalysis } = useQuery({
    queryKey: ["GetAnswersAnalysis", currentPage, maxResult, agencyId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetAgencyAnswerByAgencyId,
        {
          AgencyId: agencyId,
          SkipCount: currentPage * maxResult,
          MaxResultCount: maxResult,
          surveyId: surveyId(),
        },
        true,
        signal
      ),
    enabled: agencyId != null,
  });

  const agencyDetails = data?.result;
  const agencyAnswers = data?.result?.agencyAnswer;
  const allStandards = data?.result?.standards;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / maxResult);

  return {
    agencyDetails,
    queryClient,
    agencyAnswers,
    allStandards,
    totalCount,
    totalPages,
    data,
    isGettingAnswersAnalysis,
  };
};
