import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import SearchIconPDF from "../assets/svgs/SearchIconPDF";
import CheckListIconPDF from "../assets/svgs/CheckListIconPDF";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";
import { Footer } from "../components/Footer";
export default function Page22({
  pageNumber,
  sectionNumber,
  pageTitle,
  styles,
  agencyTitle,
  sectorTitle,
  agencyLevel,
  agencyPercentage = "80%",
  level,
  percentage = 0,
  property,
  standardData,
  summary,
  elementsCode,
  elementsTitle,
  elementsSubject,
  interlocutorExpertNotes,
  reportType,
}) {
  const style = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "DiodrumArabic",
      direction: "rtl",
      position: "relative",
    },
    container: {
      marginTopTop: 80,
      marginRight: 10,
    },
    header: {
      textAlign: "right",
      flexDirection: "row-reverse",
      gap: 4,
      fontSize: 20,
      marginTop: 30,
    },
    headerTextNum: {
      color: "#7C32C9",
    },
    headerText: {
      color: "#3F3578",
    },
    main: {
      marginTop: 10,
    },
    reportInfo: {
      paddingVertical: 10,
      paddingHorizontal: 15,
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      gap: 15,
      //   height: 150,
      border: "2px solid #7C32C9",
      borderRadius: 12,
      lineHeight: 2.5,
    },
    reportText: {
      width: "80%",
      textAlign: "right",
      fontSize: 9,
      color: "#3F3578",
      flexWrap: "wrap",
    },
    reportData: {
      fontSize: 16,
      width: "15%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
    },
    reportLevelText: {
      textAlign: 'center',
      paddingBottom: 5,
      fontSize: 12,
      lineHeight: 1
    },

    percentageContainer: {
      width: 90,
      //   height: 30,
      textAlign: "center",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid #8341C9",
      borderRadius: 8,
      backgroundColor: "#8341C9",
      paddingVertical: 6,
    },
    reportPrecentageText: {
      lineHeight: 0.9,
    },
    elementsSection: {
      marginTop: 20,
      textAlign: "right",
      lineHeight: 2.1,
    },
    elementsTitle: {
      textAlign: "right",
      flexDirection: "row-reverse",
      gap: 4,
    },
    standardResult: { marginTop: 20 },
    standardResultTitle: {
      width: "100%",
      textAlign: "right",
      fontSize: 13,
    },
    standardCards: {
      textAlign: "right",
      width: "100%",
      flexDirection: "row-reverse",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 8,
      paddingTop: 10,
    },
    standardCard: {
      border: "2px solid #8341C9",
      borderRadius: 12,
      width: "34%",
      height: 60,
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backgroundColorConatiner: {
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      transform: "translateX(0.1px)",
      borderRadius: 10,
      height: "100%",
      width: "40%",
    },
    stdPercentage: {
      fontSize: 10,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      lineHeight: 1,
      gap: 1,
    },
    stdTitle: {
      width: "100%",
      textAlign: "center",
      fontSize: 11,
      // flexWrap: "wrap",
      paddingHorizontal: 4,
      flexDirection: "row-reverse",
      justifyContent: "center",
    },
    currentSituation: {
      width: "100%",
      textAlign: "right",
      color: "#3F3578",
      marginTop: 20,
      fontSize: 13,
      fontWeight: "medium",
      position: "relative",
    },
    currentSituationTitle: {
      flexDirection: "row-reverse",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 10,
    },
    innerFace: {
      position: "absolute",
      width: "99%",
      height: 120,
      overflow: 'hidden',
      borderRadius: "20%",
      backgroundColor: "#F1F1F1",
      padding: '15px',
      top: 30,
      left: 5.5,
      zIndex: 1,
    },
    outerFace: {
      width: "100%",
      minHeight: 130,
      paddingHorizontal: 50,
      border: "1px solid #fefcfc",
      borderRadius: "10%",
      borderTopColor: "#03ABAF",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  });
  return (
    <>
      <Page style={style?.page}>
        <View style={style?.container}>
          <View style={style?.header}>
            <Text style={style?.headerTextNum}>{sectionNumber}</Text>
            <Text style={style?.headerText}>{pageTitle}</Text>
          </View>
          <View style={style?.main}>
            <View style={style?.reportInfo}>
              <Text style={style?.reportText}>{summary}</Text>
              <View style={{ ...style?.reportData }}>
                <Text
                  style={{
                    ...style?.reportLevelText,
                    color: "#C00000",
                  }}
                >
                  {level}
                </Text>
                <View style={style?.percentageContainer}>
                  <Text
                    style={{
                      ...style?.reportPrecentageText,
                      color: "#fff",
                    }}
                  >
                    {percentage}%
                  </Text>
                </View>
              </View>
            </View>
            <View style={style?.elementsSection}>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    color: "#8341C9",
                  }}
                >
                  عناصر قدرة
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#8341C9",
                  }}
                >
                  "{property}"
                </Text>
              </View>
              <View style={style?.elementsTitle}>
                <Text style={{ fontSize: 14, color: "#3F3578", textAlign: "right" }}>{elementsCode}</Text>
                <Text style={{ fontSize: 14, color: "#3F3578", textAlign: "right" }}>{elementsTitle}</Text>
              </View>
              <Text style={{ fontSize: 8.5, color: "#2A1F6A" }}>
                {elementsSubject}
              </Text>
            </View>
            <View style={style?.standardResult}>
              <Text style={{ ...style?.standardResultTitle, color: "#1CC182" }}>
                نتائج المعاير الفرعية
              </Text>
              <View style={style?.standardCards}>
                {standardData?.map((data) => (
                  <View style={style?.standardCard}>
                    <View
                      style={{
                        ...style?.backgroundColorConatiner,
                        backgroundColor: '#2A206A',
                      }}
                    >
                      <View
                        style={{
                          ...style?.stdPercentage,
                          color: "#fff",
                        }}
                      >
                        <Text>{ifNotDefinedReturnZero(data.userPercentagesAvg)?.toFixed(2)}%</Text>
                        <Text>{data.commitmentLevelTitle}</Text>
                      </View>
                    </View>
                    <View style={style?.stdTitle}>
                      <Text>{data.standardCode}</Text>
                      <Text style={{ color: "#3F3578" }}>
                        {data.standardTitle}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={style?.currentSituation}>
              <View style={style?.currentSituationTitle}>
                <Text style={{ position: "relative", top: 3.3 }}>
                  الوضع الراهن
                </Text>
                <View style={{ position: "relative", bottom: 2 }}>
                  <SearchIconPDF height={16} width={16} />
                </View>
              </View>
              {/* الوضع الراهن و التوصيات  */}
              <View style={style?.innerFace}>
                {
                  interlocutorExpertNotes.map(item => {
                    return (
                      <Text> {item.note} -</Text>
                    )
                  })
                }
              </View>
              <View style={style?.outerFace}>
                <Text></Text>
              </View>
            </View>
            <View style={style?.currentSituation}>
              <View style={style?.currentSituationTitle}>
                <Text style={{ position: "relative", top: 3.3 }}>
                  أبرز التوصيات
                </Text>
                <View style={{ position: "relative", bottom: 2 }}>
                  <CheckListIconPDF height={20} width={20} />
                </View>
              </View>
              <View style={style?.innerFace}>
                {
                  interlocutorExpertNotes.map(item => {
                    return (<>
                      <Text> {item.recommendation} -</Text>
                    </>
                    )
                  })
                }
              </View>
              <View style={style?.outerFace}></View>
            </View>
          </View>
        </View>

        <Footer page={pageNumber} />
      </Page>
    </>
  );
}
