import { programsIcon } from "./../../../assets/icons/Index";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";

import AuditorExpertDashboard from "../../../dashboard/auditor/AuditorExpertDashboard";
import AdminDashboard from "../../../dashboard/admin/AdminDashboard";
import DashboardButtons from "../../../dashboard/admin/DashboardButtons";
import AgencyDashboard from "../../../dashboard/agency";
import AccountMangerDashboard from "../../../dashboard/accountManger";

import {
  isAccountManger,
  isAdminFunc,
  isAgency,
  isExpert,
  isExpertManager,
  isPOCOfficer,
  isReviewer,
  isViewer,
} from "../../../helpers/isPermission";
import { DashboardContextProvider } from "../../../contexts/DashboardContext";
import { permissionsCheck } from "../../../helpers/utils";

const dashboardBreadcrumbArr = [{ title: "الرئيسية", icon: programsIcon }];

const NewDashboard = () => {
  const isAdmin = permissionsCheck("Pages.UserType.Admin")
  const isViewer = permissionsCheck("Pages.UserType.Viewer");

  return (
    <DashboardContextProvider>
      <HeaderWithBreadcrumbLayout breadcrumbArr={dashboardBreadcrumbArr}>
        <DashboardButtons />
        {(isAdmin || isViewer) && <AdminDashboard />}

        {(isReviewer() || isExpert() || isPOCOfficer() || isExpertManager()) && (
          <AuditorExpertDashboard />
        )}

        {isAgency() && <AgencyDashboard />}
        {isAccountManger() && <AccountMangerDashboard />}
      </HeaderWithBreadcrumbLayout>
    </DashboardContextProvider>
  );
};

export default NewDashboard;
