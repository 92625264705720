import React, { useContext, useState } from "react";
import { DropdownMultiSelect } from "../../../../../../components";
import { StandardProgramContext } from "../../../../../../contexts/StandardProgramContext";

import strategicPlanning from "./../../../../../../assets/icons/strategicPlanning.svg";
import lamp from "./../../../../../../assets/icons/lamp.svg";
import AppTextarea from "../../../../../../components/form/appTextarea";
import NextBtn from "../components/buttons/nextBtn";
import NewFileUpload from "../../../../../../components/upload/NewFileUpload";
import { useParams } from "react-router";
import FilePreview from "../../../../../../components/upload/filePreview";
import {
  getUniqueValues,
  permissionsCheck,
} from "../../../../../../helpers/utils";
import PrevFilesDownload from "./prevFilesDownload";
import AppModal from "../../../../../../components/Models/appModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const StandardAnswerProofFiles = ({
  previousSurveyFiles,
  selectedPrevfilesArray,
  setSelectedPrevfilesArray,
  setMenuElement,
  pocAnswer,
}) => {
  const { entityId, standardId } = useParams();
  const standardProgramContext = useContext(StandardProgramContext);

  // ******************************************************************

  // let uniqueIds = [];
  console.log(
    standardProgramContext?.files,
    "STANDARD PROOF 😁😁😁😁😁😁 - FILES"
  );

  // standardProgramContext?.files?.map((item , inde) => {
  //   item?.attachments?.forEach((file) => {
  //     uniqueIds.push(file?.id);
  //   });
  // });
  // uniqueIds = Array?.from(new Set(uniqueIds));

  // // );
  // console.log(uniqueIds);

  // const files =

  // useEffect(() => {
  //   console.log(
  //     new Set(
  //       standardProgramContext?.files?.attachments?.map((item) => item["id"])
  //     )
  //   );
  // }, []);
  const prevFilesOptions =
    previousSurveyFiles?.length > 0 &&
    previousSurveyFiles?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const [openModal, setOpenModal] = useState(false);

  // Function to handle change for a specific dropdown
  const handleChangeSelectedPrevfiles = (index, selectedOptions) => {
    const updatedSelectedPrevfilesArray = [...selectedPrevfilesArray];
    updatedSelectedPrevfilesArray[index] = selectedOptions;
    setSelectedPrevfilesArray(updatedSelectedPrevfilesArray);

    standardProgramContext.files.forEach((element, fileIndex) => {
      if (element && fileIndex == index) {
        const prev =
          selectedOptions?.map((item) => ({
            attachmentId: item.value,
            fromPastYear: true,
          })) || [];
        let prevItem = element.attachments?.filter((item) => item.prevFiles);

        if (prevItem?.length == 0) {
          element.attachments = [
            ...element.attachments,
            {
              prevFiles: prev,
            },
          ];
        }

        element.attachments = element.attachments?.map((ele) => {
          if (ele.prevFiles) {
            return { prevFiles: prev };
          }
          return ele;
        });
      }
    });
  };

  // ******************************************************************
  const handleChangeNotes = (e, item, index) => {
    item.note = e.target.value;
    const newStndrd = standardProgramContext?.files?.map((ele) => {
      if (ele == item) return { ...ele, note: e.target.value };
      return ele;
    });
    console.log(newStndrd, "handleChangeNotes🤣🤣🤣🤣🤣🤣🤣");
    standardProgramContext?.setFiles(newStndrd);
  };

  const handleRemoveFile = (document, file) => {
    let filteredAttachment = getUniqueValues(document?.attachments);
    console.log(filteredAttachment, "filteredAttachment - filteredAttachment");

    filteredAttachment = filteredAttachment?.filter((item) => {
      console.log(item?.id);
      console.log(file?.id);

      return item?.id !== file?.id;
    });

    const newFiles = standardProgramContext?.files?.map((item) => {
      if (item?.id == document?.id)
        return {
          ...item,
          attachments: item?.attachments?.filter((item) => {
            return item?.id !== file?.id;
          }),
        };
      return item;
    });
    // const filteredFiles = standardProgramContext?.files?.filter(
    //   (item) => item?.id != id
    // );
    standardProgramContext?.setFiles(newFiles);
  };

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={strategicPlanning}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              مستندات الإثبات
            </h1>
          </div>

          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ""
                    ? "لا يوجد"
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <FileUpload from="files" multiple={true} update={refreshStandardProgramContext} folder={"standardProof"} /> */}
        {standardProgramContext?.files?.map((document, index) => (
          <div>
            <div className="max-w-[750px] pl-8 mb-14 pr-[80px] flex flex-col justify-beween items-start ">
              <div className="w-[100%]">
                <div className="relative mt-4 flex flex-row items-start justify-between">
                  <div className="flex items-start">
                    <span className="bg-[#D2F2E6] w-4 h-4 rounded-full absolute flex justify-center items-center mt-2">
                      <span className="bg-[#1CC081] w-[6px] h-[6px] rounded-full absolute"></span>
                    </span>
                    <h4 className="mr-6 ml-6 text-[#292069]">
                      {document?.title}
                    </h4>
                  </div>
                  <p className="text-[#7D8BAB] mb-2">
                    {document?.fileSize + "MB"}
                  </p>
                </div>
                <div className="flex flex-col justify-start">
                  <div className="flex flex-col mt-3">
                    {permissionsCheck("Pages.UserType.Reviewer") ||
                    permissionsCheck("Pages.UserType.Expert") ||
                    permissionsCheck("Pages.UserType.ExpertManager") ||
                    permissionsCheck("Pages.UserType.Admin")
                      ? getUniqueValues(document?.attachments, "id")
                          ?.filter((file, indx) => file?.prevFiles == undefined)
                          // ?.slice(0, document?.attachments?.length / 3)
                          .map((file, i) => (
                            <div className="bg-slate-100 flex justify-between items-center px-2 py-2 mt-2 mb-2 rounded-md">
                              {/* <img
                          className="bg-transparent ml-6"
                          src={pdf}
                          alt="pdf"
                        /> */}
                              <p className="text-[#161318] text-[14px] ml-5">
                                {file?.attachment?.name}
                              </p>
                              <div className="flex items-center">
                                {file?.fromPastYear == true ? (
                                  <>
                                    <p className="bg-red-50 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-red-800">
                                      من المعيار السابق
                                    </p>
                                    <FilePreview
                                      id={file.attachment?.id}
                                      fileName={file.attachment?.name}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                                      onClick={() =>
                                        handleRemoveFile(document, file)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <p className="bg-emerald-100 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-emerald-700">
                                      ملف جديد
                                    </p>
                                    <FilePreview
                                      id={file.attachment?.id}
                                      fileName={file.attachment?.name}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                                      onClick={() =>
                                        handleRemoveFile(document, file)
                                      }
                                    />
                                  </>
                                )}
                                {/* <img
                              onClick={() =>
                                downloadFile(
                                  file.attachment?.name,
                                  file.attachment?.id
                                )
                              }
                              className="bg-transparent mr-2 ml-1 cursor-pointer"
                              src={download}
                              alt="download"
                            /> */}
                              </div>
                            </div>
                          ))
                      : getUniqueValues(document?.attachments, "id")
                          ?.filter((file, indx) => file?.prevFiles == undefined)
                          // ?.slice(0, document?.attachments?.length / 2)
                          .map((file, i) => (
                            <div className="bg-slate-100 flex-shrink-0 flex justify-between items-center px-2 py-2 mt-2 mb-2 rounded-full">
                              {/* <img
                          className="bg-transparent ml-6"
                          src={pdf}
                          alt="pdf"
                        /> */}
                              <p className="text-[#161318] break-words text-[14px] ml-5">
                                {file?.attachment?.name}
                              </p>
                              <div className="flex items-center">
                                {file?.fromPastYear == true ? (
                                  <>
                                    <p className="bg-red-50 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-red-800">
                                      من المعيار السابق
                                    </p>
                                    <FilePreview
                                      id={file.attachment?.id}
                                      fileName={file.attachment?.name}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                                      onClick={() =>
                                        handleRemoveFile(document, file)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <p className="bg-emerald-100 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-emerald-700">
                                      ملف جديد
                                    </p>
                                    <FilePreview
                                      id={file.attachment?.id}
                                      fileName={file.attachment?.name}
                                    />{" "}
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                                      onClick={() =>
                                        handleRemoveFile(document, file)
                                      }
                                    />
                                  </>
                                )}
                                {/* <img
                              onClick={() =>
                                downloadFile(
                                  file.attachment?.name,
                                  file.attachment?.id
                                )
                              }
                              className="bg-transparent mr-2 ml-1 cursor-pointer"
                              src={download}
                              alt="download"
                            /> */}
                              </div>
                            </div>
                          ))}
                    {permissionsCheck("Pages.UserType.Reviewer") ||
                    permissionsCheck("Pages.UserType.Expert") ||
                    permissionsCheck("Pages.UserType.ExpertManager") ||
                    permissionsCheck("Pages.UserType.Admin") ? (
                      <div className="mb-2 pt-5">
                        <h2 className="text-[#292069] text-[16px] mb-2">
                          تعليق الجهة:
                        </h2>
                        <div className="text=[#7D8BAB] text-[14px]">
                          {document.note == ""
                            ? "لا يوجد تعليق للجهة"
                            : document.note}
                        </div>
                        {/* <div className="text-sm text-[#7e7e7e] mt-1">{standardProgramContext?.standard?.programExpertAnswer == null ? null : standardProgramContext?.standard?.agencyNoteDate}</div>  */}
                      </div>
                    ) : null}
                  </div>
                  {/* standardProgramContext?.agency.isConfidentialAgency == true || */}
                </div>
                {
                  // standardProgramContext?.agency?.isConfidentialAgency == true ||
                  permissionsCheck("Pages.UserType.Reviewer") ||
                  permissionsCheck("Pages.UserType.Expert") ||
                  permissionsCheck("Pages.UserType.ExpertManager") ||
                  permissionsCheck("Pages.UserType.Admin") ? null : (
                    <>
                      {/* <FileUpload
                      from="files"
                      document={document}
                      update={refreshStandardProgramContext}
                      multiple={true}
                      label={document?.title}
                      withoutLabel={true}
                    /> */}

                      <NewFileUpload
                        label={document?.title}
                        attachmentIndx={index}
                        agencyId={entityId}
                        from="Standards_Proofs"
                        proofId={document.id}
                        standardId={standardId}
                        document={document}
                      />
                    </>
                    // <FilesUpload
                    //   agencyId={standardProgramContext?.agency?.id}
                    //   from="files"
                    // />
                  )
                }
                {/* {prevFilesOptions?.length > 0 ? (
                  (permissionsCheck("Pages.UserType.POC") ||
                    permissionsCheck("Pages.UserType.CIO") ||
                    permissionsCheck("Pages.UserType.Delegate")) && (
                    <>
                      <div className="pt-1">
                        <DropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          hasSelectAll={true}
                          value={selectedPrevfilesArray[index]}
                          handleChange={(selectedFiles) =>
                            handleChangeSelectedPrevfiles(index, selectedFiles)
                          }
                          placeholder="اختيار من ملفات سابقة"
                          label={"اختيار من ملفات سابقة"}
                          options={prevFilesOptions || []}
                          isMultiple={true}
                          handleDownloadChange={() => setOpenModal(true)}
                          // errorMessage={errors.standardRelations}
                        />
                      </div>
                    </>
                  )
                ) : (
                  <p> لا يوجد ملفات سابقة</p>
                )} */}

                {prevFilesOptions?.length > 0 && <></>}

                {
                  // standardProgramContext?.agency.isConfidentialAgency == true ||
                  permissionsCheck("Pages.UserType.Reviewer") ||
                  permissionsCheck("Pages.UserType.Expert") ||
                  permissionsCheck("Pages.UserType.ExpertManager") ||
                  permissionsCheck("Pages.UserType.Admin") ? null : (
                    <>
                      {/* <div className="">
                      <h2>الصفحات التى تثبت السؤال</h2>
                      <div className="mt-2 flex w-full gap-3">
                        <div className="mt-1">
                          <AppInput
                            label="الصفحة من"
                            id="pageFrom"
                            name="pageFrom"
                            onChange={() => {}}
                            placeholder="0"
                            value={"55"}
                            variant="gray"
                            type="number"
                          />
                        </div>
                        <div className="mt-1">
                          <AppInput
                            label="الصفحة إلى"
                            id="pageFrom"
                            name="pageFrom"
                            onChange={() => {}}
                            placeholder="0"
                            value={"55"}
                            variant="gray"
                            type="number"
                          />
                        </div>
                      </div>
                    </div> */}
                      <div className="bg-[#F9F9FF] mt-5 rounded-lg">
                        <AppTextarea
                          rows={4}
                          placeholder="اكتب تعليق"
                          defaultValue={document.note}
                          style={{ resize: "none" }}
                          onChange={(e) =>
                            handleChangeNotes(e, document, index)
                          }
                        />
                      </div>
                    </>
                  )
                }
              </div>

              {/* standardProgramContext?.agency?.isConfidentialAgency == true ||  */}
            </div>
          </div>
        ))}
        <AppModal
          headerTitle="تحميل الملفات السابقة"
          open={openModal}
          setOpen={setOpenModal}
          submitLabel="اغلاق"
          handleSubmit={() => {
            setOpenModal(false);
          }}
        >
          <div className="">
            <PrevFilesDownload prevFiles={previousSurveyFiles} />
          </div>
        </AppModal>
      </div>
      <NextBtn onClick={() => setMenuElement(3)} pocAnswer={pocAnswer} />
    </>
  );
};

export default StandardAnswerProofFiles;
