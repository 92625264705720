import { ifNotDefinedReturnZero, permissionsCheck } from "./utils";

// TODO: all of this variables are source of a bugs
export const isAdminFunc = () => permissionsCheck("Pages.UserType.Admin");
export const isReviewer = () => permissionsCheck("Pages.UserType.Reviewer");
export const isExpert = () => permissionsCheck("Pages.UserType.Expert");
export const isExpertManager = () =>  permissionsCheck("Pages.UserType.ExpertManager");
export const isPocOfficer = () => permissionsCheck("Pages.UserType.POCOfficer") && false;
export const isPOC = () => permissionsCheck("Pages.UserType.POC");
export const isCIO = () => permissionsCheck("Pages.UserType.CIO");
export const isDelegate = () => permissionsCheck("Pages.UserType.Delegate");
export const isViewer = () => permissionsCheck("Pages.UserType.Viewer");
export const isPOCOfficer = () => permissionsCheck("Pages.UserType.POCOfficer");
export const isAccountManger = () => localStorage.getItem("userType") === "AccountManager";
export const isInquiryManager = () => permissionsCheck(
  "Pages.UserType.InquiryManager"
);

export const isAgency = () =>
  permissionsCheck("Pages.UserType.POC") ||
  permissionsCheck("Pages.UserType.CIO") ||
  permissionsCheck("Pages.UserType.Delegate");

export const isDgaUsers = () =>
  permissionsCheck("Pages.UserType.Reviewer") ||
  permissionsCheck("Pages.UserType.Expert") ||
  permissionsCheck("Pages.UserType.ExpertManager");

export const surveyId = () => ifNotDefinedReturnZero(localStorage.getItem("surveyId"));

// TODO: search for the different between surveyStageId and stageForDashboard
export const surveyStageId = () => localStorage.getItem("surveyStageId");
export const stageForDashboard = () => localStorage.getItem("stageForDashboard");

export const isPublishReportToAgency = () => JSON.parse(localStorage.getItem("isPublishReportToAgency"));
export const isAutomaticReport = () => JSON.parse(localStorage.getItem("isAutomaticReport"));

export const userId = () => localStorage.getItem("userId") || 0;
export const agencyId = () => isAgency() ? localStorage.getItem("agencyId") : null;
export const reviewerId = () => isReviewer() ? localStorage.getItem("userId") : null;
export const expertId = () => isExpert() ? localStorage.getItem("userId") : null;
export const expertManagerId = () => isExpertManager() ? localStorage.getItem("userId") : null;
export const pocOfficerId = () => isPocOfficer()
  ? localStorage.getItem("userId")
  : null;
