import React, { useContext, useState } from "react";
import AppButton from "../../../../../../../components/buttons/appButton";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { isDefined, permissionsCheck } from "../../../../../../../helpers/utils";
import { useAnswerContext } from "../../answerContext";
import { axiosGetRequest, axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { toast } from "react-toastify";
import { useAtomValue } from "jotai";
import { uploadingFileAtom } from "../../../../../../../components/upload/uploadFilesContext";
import { useQuery } from "@tanstack/react-query";
import { surveyId } from "../../../../../../../helpers/isPermission";
import usePrevExpertManagerAnswer from "../../answers/useGetPrevAnswer/usePrevExpertManagerAnswer";
import { useParams } from "react-router";

const SaveBtn = ({ onClick, loading, draftLoading = false }) => {
  const uploading = useAtomValue(uploadingFileAtom);
  const { stageRoles, currentStage } = useContext(StageContext);
  const { newAgencyAnswer, newExpertManagerAnswer } = useAnswerContext();
  const [isSetAnswerApprovedLoading, setIsSetAnswerApprovedLoading] = useState(false);

  const { standardId, entityId } = useParams();

  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();

  const StageId = isDefined(localStorage.getItem("surveyStageId"))
    ? localStorage.getItem("surveyStageId")
    : 0


  const agencyId = localStorage.getItem("agencyId");
  const isAgency =
    permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.Delegate");

  const { data: checkAnswerdStandard = false, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ["getCheckAnswerdStandard", StageId, agencyId],
    queryFn: () =>
      axiosGetRequest(environment.getCheckAnswerdStandard, {
        agencyId: agencyId,
        stageId: StageId,
        surveyId: surveyId(),
      }),
    select: (data) => data.result,
    enabled: isAgency,
  });

  const isThirdStage = currentStage?.stageIds?.[2] === currentStage?.id;
  const isExpert =
    permissionsCheck("Pages.UserType.ExpertManager") ||
    permissionsCheck("Pages.UserType.Expert") ||
    permissionsCheck("Pages.UserType.Reviewer") ||
    permissionsCheck("Pages.UserType.POCOfficer");



  const isExpertManager = permissionsCheck("Pages.UserType.ExpertManager");

  const isExpertCanAnswer =
  isExpert && isThirdStage && newAgencyAnswer.isConfidentialAgency;

  const isAgencyCanNotAnswer =
    isAgency &&
    ((isThirdStage && newAgencyAnswer.isConfidentialAgency) ||
      checkAnswerdStandard);
  
  const isSaveButtonDisabled = isExpertCanAnswer
    ? false
    : isAgencyCanNotAnswer
    ? true
    : !stageRoles.includes(localStorage.getItem("userType"));
  
  console.log({
    newExpertManagerAnswer,
  })

  async function setAnswerApprovedById(answerId){
    if(!answerId) return
    setIsSetAnswerApprovedLoading(true)
    const res = await axiosPostRequest(
      environment.setAnswerApprovedById,
      undefined,
      {
        surveyAnswerId: answerId,
        expertManagerId: parseFloat(localStorage.getItem("userId")),
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      () =>{
        setIsSetAnswerApprovedLoading(false)
      },
    )

    setIsSetAnswerApprovedLoading(false)
    if(res?.success){
      getPrevExpertManagerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage?.id)
      );
      // success toaster 
      toast.success("تم اعتماد الإجابة بنجاح")
    }
  }

  return (
    <div className=" sticky bottom-0 left-0 w-full h-16 ">
      {!permissionsCheck("Pages.UserType.Admin") && (
        <div className="bg-white_gred h-full flex justify-between  items-start pl-8">
          {isSaveButtonDisabled && (
            <p className="text-danger text-lg mt-3 pr-5">
              {
                checkAnswerdStandard ? "لقد تم إرسال الإجابات ، لا يمكنك الإجابة الآن" :
                  "لا يمكنك الإجابة فى هذه المرحلة"
              }
            </p>
          )}
          <div className="mr-auto flex items-center gap-5">
            {isExpertManager && (
              <>
                {newExpertManagerAnswer.reviewerLastAnsweredId && <AppButton
                  disabled={isSaveButtonDisabled}
                  // className="text-sm px-12 bg-transparent border border-primary text-blue"
                  variant="secondary"
                  onClick={() =>
                    setAnswerApprovedById(
                      newExpertManagerAnswer.reviewerLastAnsweredId
                    )
                  }
                  loading={isSetAnswerApprovedLoading || uploading}
                >
                  اعتماد اجابة المدقق
                </AppButton>}

                {newExpertManagerAnswer.expertLastAnsweredId && <AppButton
                  disabled={isSaveButtonDisabled}
                  // className="text-sm px-12 bg-transparent border border-primary text-blue"
                  variant="secondary"
                  onClick={() =>
                    setAnswerApprovedById(
                      newExpertManagerAnswer.expertLastAnsweredId
                    )
                  }
                  loading={isSetAnswerApprovedLoading || uploading}
                >
                  اعتماد اجابة الخبير
                </AppButton>}
              </>
            )}
            {(!isExpert) && (
              <AppButton
                disabled={isSaveButtonDisabled}
                className="text-xl font-semibold px-12"
                onClick={() => onClick(true)}
                loading={draftLoading || uploading}
                loadingLabel={ !uploading ? "جارى الحفظ" : "جارى الرفع" }
              >
                حــفــظ كمسودة
              </AppButton>
            )}

            <AppButton
              disabled={isSaveButtonDisabled}
              className="text-xl font-semibold px-12"
              onClick={() => onClick(false)}
              loading={loading || uploading}
              loadingLabel={ !uploading ? "جارى الحفظ" : "جارى الرفع" }
            >
              حــفــظ
            </AppButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaveBtn;
