import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Input } from "../../../../../../../../components";
import { axiosGetRequest, post } from "../../../../../../../../services/Request";
import { useAnswerContext } from "../../../answerContext";
import environment from "../../../../../../../../services/environment";
import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import { getCommitmentLevelsBasedOnDegree, showRequirementDegreeErrorMessage } from "../../utils";
import { TextInput } from "flowbite-react";
import UploadFile from "../../UploadFile";

export const AddStandardRequirementsBasedOnStandard = () => {

    const { standardId, entityId } = useParams();
    const { getSelfEvaluation, newExpertAnswer, setNewExpertAnswer } = useAnswerContext();

    const { data: commitmentLevels = [] } = useQuery({
        queryKey: ["commitmentLevels", { standardId }],
        queryFn: async ({ queryKey }) => {
            const [, { standardId }] = queryKey;

            const res = await axiosGetRequest(environment.getCommitmentLevels, {
                standardId,
            });

            return res?.result;
        },
    });

    const handleRequirementChange = (requirement, property = "decision") => {
        const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const subStandardRequirements =
                        requirement?.subStandardRequirements?.map((subReq) => {
                            return property === "decision"
                                ? {
                                    ...subReq,
                                    decision: requirement?.decision ? 0 : 1,
                                    donotApply: 0,
                                }
                                : {
                                    ...subReq,
                                    donotApply: !requirement?.donotApply,
                                    decision: !requirement?.donotApply
                                        ? 0
                                        : requirement?.decision,
                                };
                        });

                    return property === "decision"
                        ? {
                            ...requirement,
                            subStandardRequirements,
                            decision: requirement?.decision ? 0 : 1,
                            donotApply: 0,
                        }
                        : {
                            ...requirement,
                            subStandardRequirements,
                            donotApply: !requirement?.donotApply,
                            decision: !requirement?.donotApply ? 0 : requirement?.decision,
                        };
                }

                return item;
            }
        );

        getSelfEvaluation(standardId, updatedRequirements, entityId);

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedRequirements,
        });
    };

    const handleSubRequirementPercentageChange = (
        requirement,
        subReqId,
        property = "note",
        sideId,
        value,
        isUnAnswered
    ) => {
        const isNotValid = showRequirementDegreeErrorMessage(isUnAnswered, value);
        if (isNotValid) return;

        const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    sides: subRequire?.sides?.map((side) => {
                                        if (sideId === side?.id) {
                                            return {
                                                ...side,
                                                evaluationDegree: value,
                                            };
                                        }

                                        return side;
                                    }),
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedSubRequirements,
        });
    };

    const handleSubRequirementEvaluationNoteChange = (
        requirement,
        subReqId,
        property = "note",
        sideId,
        value
    ) => {
        const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    sides: subRequire?.sides?.map((side) => {
                                        if (sideId === side?.id) {
                                            return {
                                                ...side,
                                                evaluationNote: value,
                                            };
                                        }

                                        return side;
                                    }),
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedSubRequirements,
        });
    };

    const handleSubRequirementAttachmentIdChange = (
        requirement,
        subReqId,
        property = "note",
        sideId,
        value
    ) => {
        const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    sides: subRequire?.sides?.map((side) => {
                                        if (sideId === side?.id) {
                                            return {
                                                ...side,
                                                attachmentId: value,
                                            };
                                        }

                                        return side;
                                    }),
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedSubRequirements,
        });
    };

    const handleSubRequirementRecommendationChange = (
        requirement,
        subReqId,
        property = "note",
        sideId,
        value
    ) => {
        const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    sides: subRequire?.sides?.map((side) => {
                                        if (sideId === side?.id) {
                                            return {
                                                ...side,
                                                recommendation: value,
                                            };
                                        }

                                        return side;
                                    }),
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedSubRequirements,
        });
    };

    const handleChange = (
        requirement,
        subReqId,
        property,
        sideId,
        value
    ) => {
        const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    [property]: value
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertAnswer({
            ...newExpertAnswer,
            standardRequirements: updatedSubRequirements,
        });
    };


    return (
        newExpertAnswer?.standardRequirements?.length > 0 &&
        newExpertAnswer?.standardRequirements?.map((requirement) => {
            const hasSubRequirements =
                requirement?.subStandardRequirements?.length > 0;

            return (
                <>
                    <MainRequirement
                        key={requirement?.id}
                        title={requirement?.title}
                        hasSubRequirements={hasSubRequirements}
                        checked={requirement?.decision}
                        requirementDonotApplyChecked={requirement?.donotApply}
                        onChange={() => {
                            handleRequirementChange(requirement);
                        }}
                        handleDonotApplyRequirementChange={() =>
                            handleRequirementChange(requirement, "donotApply")
                        }
                    >
                        {hasSubRequirements &&
                            requirement?.subStandardRequirements?.map((subRequirement) => {
                                return (
                                    <>
                                        <div className="px-5 flex flex-col mb-3 py-2">
                                            <div className="flex flex-row gap-2 mb-1 py-1">
                                                <h2 className="text-xl pb-2">{subRequirement.title}</h2>
                                            </div>
                                            <div className="flex flex-row gap-2 mb-3 py-2">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                                                    id={subRequirement.value}
                                                    name={"ارجاع الى الجهة"}
                                                    label={""}
                                                    value={subRequirement.isReturnable}
                                                    checked={subRequirement.isReturnable}
                                                    onChange={(e) => {
                                                        handleChange(
                                                            requirement,
                                                            subRequirement?.id,
                                                            "isReturnable",
                                                            subRequirement.id,
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                                <span>معاد للجهة</span>
                                            </div>

                                            {subRequirement?.isReturnable && (
                                                <div className="flex gap-2 w-full items-center mb-4">
                                                    <span className="w-32">ملاحظة الى الجهة</span>
                                                    <TextInput
                                                        value={subRequirement?.reason}
                                                        className="grow"
                                                        placeholder="ملاحظة الى الجهة"
                                                        onChange={(e) => {
                                                            handleChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "reason",
                                                                subRequirement.id,
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            <div className="flex flex-col gap-4">
                                                <>
                                                    {subRequirement?.sides?.map((side, index) => {
                                                        const isUnAnswered =
                                                            subRequirement?.isUnAnswered ||
                                                            subRequirement?.isUnAnswered === null;
                                                        return (
                                                            <div>
                                                                <div className="border-b pb-2">
                                                                    <h3 className="text-xl pb-3">{side.title}</h3>
                                                                    <div className="flex items-center gap-2 mb-4">
                                                                        <span className="w-24">تقييم الخبير</span>
                                                                        <TextInput
                                                                            defaultValue={isUnAnswered ? 1 : ""}
                                                                            value={side?.evaluationDegree}
                                                                            type="number"
                                                                            placeholder=""
                                                                            min={isUnAnswered ? 1 : 0}
                                                                            max={isUnAnswered ? 20 : 100}
                                                                            onChange={(e) => {
                                                                                handleSubRequirementPercentageChange(
                                                                                    requirement,
                                                                                    subRequirement?.id,
                                                                                    "evaluationDegree",
                                                                                    side.id,
                                                                                    e.target.value,
                                                                                    isUnAnswered
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label>
                                                                            {getCommitmentLevelsBasedOnDegree(
                                                                                Number(side?.evaluationDegree),
                                                                                commitmentLevels
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                    <div className="flex gap-2 w-full items-center mb-4">
                                                                        <span className="w-24"> الملاحظة</span>
                                                                        <TextInput
                                                                            value={side?.evaluationNote}
                                                                            className="grow"
                                                                            placeholder="ملاحظة الخبير للجهة"
                                                                            onChange={(e) => {
                                                                                handleSubRequirementEvaluationNoteChange(
                                                                                    requirement,
                                                                                    subRequirement?.id,
                                                                                    "evaluationNote",
                                                                                    side.id,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="flex gap-2 w-full items-center">
                                                                        <span className="w-24">التوصية</span>
                                                                        <TextInput
                                                                            value={side?.recommendation}
                                                                            className="grow"
                                                                            placeholder="توصية الخبير للجهة"
                                                                            onChange={(e) => {
                                                                                handleSubRequirementRecommendationChange(
                                                                                    requirement,
                                                                                    subRequirement?.id,
                                                                                    "recommendation",
                                                                                    side.id,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <UploadFile
                                                                        value={side.attachmentId}
                                                                        onChange={(id) => {
                                                                            handleSubRequirementAttachmentIdChange(
                                                                                requirement,
                                                                                subRequirement?.id,
                                                                                "attachmentId",
                                                                                side.id,
                                                                                id
                                                                            );
                                                                        }}
                                                                        withOutToolTip
                                                                        label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </MainRequirement>
                </>
            );
        })
    )
}
