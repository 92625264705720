import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { DropdownMultiSelect } from "../../../../../components";
import AppButton from "../../../../../components/buttons/appButton";
import AppModal from "../../../../../components/Models/appModal";
import NewTable from "../../../../../components/NewTable";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import {
  agencyId,
  expertId,
  isAdminFunc,
  isAgency,
  reviewerId,
  surveyId,
} from "../../../../../helpers/isPermission";
import { errorToast } from "../../../../../helpers/toast";
import environment from "../../../../../services/environment";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../services/Request";
import FinalReportFiles from "./FinalReportFiles";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DigitalTransformationReport } from "../../../../../features/agencyAnswerReport/DigitalTransformationReport";
import { useGetAgencyAnswerReportData } from "../../../../../features/agencyAnswerReport/useGetAgencyAnswerReportData";
import DownloadButton from "../../../../../components/DownloadButton";
import { useQuery } from "@tanstack/react-query";
import { ifNotDefinedReturnZero } from "../../../../../helpers/utils";

const FinalReportPage = () => {
  const downloadExcel = useExcelExport();

  const [selectedAgency, setSelectedAgency] = useState({});
  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const agencyAnswerReportData = useGetAgencyAnswerReportData(selectedAgency?.value);

  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  const initialInputsState = {
    agency: null,
    attachmentId: "",
    reportTypeValue: '1'
  };
  const [inputs, setInputs] = useState(initialInputsState);
  const [descriptionFile, setDescriptionFile] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");
  const [reportType, setReportType] = useState('1');
  const [isReportAtomatic, setIsReportAtomatic] = useState(true);

  const handleChangeAgency = (selectedAgencyObj) => {
    setSelectedAgency(selectedAgencyObj || "");
  };

  const {
    data: manualReportByAgency,
    isLoading: isGetttingManualReportByAgency,
    refetch: refetchGetManualReportByAgency,
  } = useQuery({
    queryKey: [
      "GetManualReportByAgency",
      selectedAgency?.value
    ],
    queryFn: () =>
      axiosGetRequest(
        environment.GetManualReportByAgency,
        {
          agencyId: agencyId() ?? (selectedAgency?.value || 0),
          surveyId: surveyId(),
        }
      ),
    select: (data) => data?.result,
    enabled: ((agencyId() ?? selectedAgency?.value) && !isReportAtomatic),
  });
  const attachmentId = manualReportByAgency?.attachmentId;

  
  const handleGetReportStatusByAgency = async () => {
    const data = await axiosGetRequest(
      environment.GetReportStatusByAgency,
      {
        agencyId: agencyId() ?? selectedAgency?.value,
        surveyId: surveyId(),
      }
    );
    const isAutomatic = data?.result?.isAutomaticReport;
    setIsReportAtomatic(isAutomatic);
  };


  const handleGetAllAgencies = async () => {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination,
      {
        surveyId: surveyId(),
        expertId: expertId() || reviewerId(),
      }
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || "",
      value: item.id,
      name: item.name || "",
    }));

    setAllAgenciesOptions(agenciesOptions);
    setSelectedAgency(agenciesOptions[0]);
  };

  
  useEffect(() => {
    (agencyId() ?? selectedAgency?.value) && handleGetReportStatusByAgency();
  }, [selectedAgency?.value, agencyId()]);

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  useEffect(() => {
    setFilteredData(agencyAnswerReportData?.answersAverageStatisics);
    const reportTypeValue = agencyAnswerReportData?.answersAverageStatisics?.reportType;
    setReportType(reportTypeValue)
    setInputs({
      ...inputs,
      reportTypeValue
    }
    )
  }, [agencyAnswerReportData?.answersAverageStatisics]);

  const perspectiveColumns = [
    {
      name: "القدرة",
      selector: (row) => row.perspectiveTitle,
      header: "القدرة",
      accessorKey: "perspectiveTitle",
      sortable: true,
      center: true,
    },
    {
      name: "مستوى جاهزية التبني",
      selector: (row) => row.col2,
      header: "مستوى جاهزية التبني",
      accessorKey: "percentageCommitmentLevelTitle",
      sortable: true,
      center: true,
      cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
      name: "النتيجة",
      selector: (row) => Number(ifNotDefinedReturnZero(row.perspectiveUserPercentagesAvg))?.toFixed(2),
      header: "النتيجة",
      accessorKey: "perspectiveUserPercentagesAvg",
      sortable: true,
      center: true,
    },
  ];

  const interlocutorColumns = [
    {
      name: "العنصر",
      selector: (row) => row.interlocutorTitle,
      header: "العنصر",
      accessorKey: "interlocutorTitle",
      sortable: true,
      center: true,
    },
    {
      name: "مستوى جاهزية التبني",
      selector: (row) => row.col2,
      header: "مستوى جاهزية التبني",
      accessorKey: "percentageCommitmentLevelTitle",
      sortable: true,
      center: true,
      cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
      name: "النتيجة",
      selector: (row) => Number(ifNotDefinedReturnZero(row.interlocutorUserPercentagesAvg))?.toFixed(2),
      header: "النتيجة",
      accessorKey: "interlocutorUserPercentagesAvg",
      sortable: true,
      center: true,
    },
  ];

  const standardColumns = [
    {
      name: "المعيار",
      selector: (row) => row.standardTitle,
      header: "المعيار",
      accessorKey: "standardTitle",
      sortable: true,
      center: true,
    },
    {
      name: "مستوى جاهزية التبني",
      selector: (row) => row.col2,
      header: "مستوى جاهزية التبني",
      accessorKey: "percentageCommitmentLevelTitle",
      sortable: true,
      center: true,
      cell: (row) => row.percentageCommitmentLevelTitle,
    },
    {
      name: "النتيجة",
      selector: (row) => Number(ifNotDefinedReturnZero(row.standardUserPercentagesAvg))?.toFixed(2),
      header: "النتيجة",
      accessorKey: "standardUserPercentagesAvg",
      sortable: true,
      center: true,
    },
  ];

  // search
  const handleFilterData = (value, filterFrom) => {
    if (
      filterFrom === "1" &&
      agencyAnswerReportData?.answersAverageStatisics?.perspectiveLsts?.length
    ) {
      const filtered = agencyAnswerReportData?.answersAverageStatisics?.perspectiveLsts?.filter(
        (item) => {
          const { perspectiveTitle, perspectiveUserPercentagesAvg } = item;
          return (
            perspectiveTitle.includes(value) ||
            perspectiveUserPercentagesAvg.toString().includes(value)
          );
        }
      );

      setFilteredData((prev) => ({
        ...prev,
        perspectiveLsts: filtered,
      }));
    } else if (
      filterFrom === "2" &&
      agencyAnswerReportData?.answersAverageStatisics?.interlocutorLsts?.length
    ) {
      const filtered = agencyAnswerReportData?.answersAverageStatisics?.interlocutorLsts?.filter(
        (item) => {
          const { interlocutorTitle, interlocutorUserPercentagesAvg } = item;
          return (
            interlocutorTitle.includes(value) ||
            interlocutorUserPercentagesAvg.toString().includes(value)
          );
        }
      );

      setFilteredData((prev) => ({
        ...prev,
        interlocutorLsts: filtered,
      }));
    } else if (
      filterFrom === "3" &&
      agencyAnswerReportData?.answersAverageStatisics?.standardLsts?.length
    ) {
      const filtered = agencyAnswerReportData?.answersAverageStatisics?.standardLsts?.filter((item) => {
        const { standardTitle, standardUserPercentagesAvg } = item;
        return (
          standardTitle.includes(value) ||
          standardUserPercentagesAvg.toString().includes(value)
        );
      });

      setFilteredData((prev) => ({
        ...prev,
        standardLsts: filtered,
      }));
    }
  };

  const resetInputsValues = () => {
    setDescriptionFile("");
    setDescriptionFileId("");
    setInputs({ ...initialInputsState });
  };

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.agency) {
      errors.agency = "هذا الحقل مطلوب";
    }
    if (!descriptionFileId) {
      errors.attachmentId = "هذا الحقل مطلوب";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const res = await axiosPostRequest(environment.createAdminFinalReport, {
      agencyId: inputs.agency?.value,
      attachmentId: descriptionFileId,
      surveyId: surveyId(),
    });
    if (res?.success) {
      toast.success("تم إضافة الملف بنجاح");
      setOpen(false);
      resetInputsValues();
      refetchGetManualReportByAgency();
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
  };

  const handleDownloadManualPdf = async () => {
    if (attachmentId) {
      const queryParams = new URLSearchParams();

      queryParams.append("id", attachmentId);

      const resp = await axiosPostRequest(
        environment.downloadFile,
        null,
        queryParams,
        true,
        true,
        true
      );

      if (resp) {
        const blob = new Blob([resp], { type: "application/octet-stream" });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = `التقرير النهائي لجهة ${isAgency() ?
          agencyAnswerReportData?.answersAverageStatisics?.agencyName
          :
          selectedAgency?.label
          } 2024.pdf`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }
  }

  console.log('✌️isReportAtomatic asmaa--->', {isReportAtomatic, attachmentId});

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <div className="flex-1 max-w-[500px]">
            {
              !isAgency() &&
              <DropdownMultiSelect
                placeholder={"اختر جهة حكومية"}
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgency}
                handleChange={handleChangeAgency}
                options={allAgenciesOptions || []}
                isMultiple={false}
                isRequired={false}
                label={"اختر جهة حكومية"}
              />
            }
          </div>

          <div className="self-end">
            <div className=" flex gap-2">
              {
                isAdminFunc() &&
                <AppButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="purple"
                >
                  إضافة التقرير النهائي
                </AppButton>
              }

              {
                (!isReportAtomatic && attachmentId) ?
                  <DownloadButton
                    loading={
                      isGetttingManualReportByAgency
                    }
                    onClick={handleDownloadManualPdf}
                  >
                    تحميل التقرير النهائي (ملف PDf)
                  </DownloadButton>
                  :
                  <PDFDownloadLink
                    document={
                      <DigitalTransformationReport
                        {...agencyAnswerReportData}
                      />
                    }
                    fileName={`التقرير النهائي لجهة ${isAgency() ?
                      agencyAnswerReportData?.answersAverageStatisics?.agencyName
                      :
                      selectedAgency?.label} 2024.pdf`}
                  >
                    {({ loading }) => (
                      <DownloadButton
                        loading={
                          agencyAnswerReportData?.isGettingAnswersAverageStatisics
                        }
                      >
                        تحميل التقرير النهائي (ملف PDf)
                      </DownloadButton>
                    )}
                  </PDFDownloadLink>

              }

            </div>
          </div>
        </div>

        {
          [1, 2, 3].includes(reportType) &&
          <div className="px-5 mb-5 ">
            <h2> القدرات </h2>
            <NewTable
              withSearch
              withExcel
              onExportExcel={() => {
                const dataForExcel = filteredData?.perspectiveLsts?.map((ele) => {
                  return {
                    القدرة: ele.perspectiveTitle,
                    النتيجة: Number(ifNotDefinedReturnZero(ele.perspectiveUserPercentagesAvg))?.toFixed(2),
                  };
                });

                downloadExcel(
                  `تقرير التقنيات الناشئة السابقة لجهة ${(isAgency() ?
                    agencyAnswerReportData?.answersAverageStatisics?.agencyName
                    :
                    selectedAgency?.label) || ""
                  }`,
                  dataForExcel
                );
              }}
              onSearch={(val) => handleFilterData(val, "1")}
              loading={agencyAnswerReportData?.isGettingAnswersAverageStatisics}
              columns={perspectiveColumns}
              data={filteredData?.perspectiveLsts || []}
              pagination={false}
            />
          </div>
        }

        {
          [2, 3].includes(reportType) &&
          <div className="px-5 mb-5 ">
            <h2> العناصر </h2>
            <NewTable
              withSearch
              withExcel
              onExportExcel={() => {
                const dataForExcel = filteredData?.interlocutorLsts?.map(
                  (ele) => {
                    return {
                      العنصر: ele.interlocutorTitle,
                      النتيجة: Number(ifNotDefinedReturnZero(ele.interlocutorUserPercentagesAvg))?.toFixed(2),
                    };
                  }
                );

                downloadExcel(
                  `تقرير التقنيات الناشئة السابقة لجهة ${(isAgency() ?
                    agencyAnswerReportData?.answersAverageStatisics?.agencyName
                    :
                    selectedAgency?.label) || ""
                  }`,
                  dataForExcel
                );
              }}
              onSearch={(val) => handleFilterData(val, "2")}
              loading={agencyAnswerReportData?.isGettingAnswersAverageStatisics}
              columns={interlocutorColumns}
              data={filteredData?.interlocutorLsts || []}
              pagination={false}
            />
          </div>
        }

        {
          reportType === 3 &&
          <div className="px-5 mb-5 ">
            <h2> المعايير </h2>
            <NewTable
              withSearch
              withExcel
              onExportExcel={() => {
                const dataForExcel = filteredData?.standardLsts?.map((ele) => {
                  return {
                    المعيار: ele.standardTitle,
                    النتيجة: Number(ifNotDefinedReturnZero(ele.standardUserPercentagesAvg))?.toFixed(2),
                  };
                });

                downloadExcel(
                  `تقرير التقنيات الناشئة السابقة لجهة ${(isAgency() ?
                    agencyAnswerReportData?.answersAverageStatisics?.agencyName
                    :
                    selectedAgency?.label) || ""
                  }`,
                  dataForExcel
                );
              }}
              onSearch={(val) => handleFilterData(val, "3")}
              loading={agencyAnswerReportData?.isGettingAnswersAverageStatisics}
              columns={standardColumns}
              data={filteredData?.standardLsts || []}
              pagination={false}
            />
          </div>
        }
      </div>

      <AppModal
        loading={false}
        handleSubmit={handleSubmitAdd}
        type={"submit"}
        headerTitle={"إضافة التقرير النهائي"}
        open={open}
        setOpen={(value) => {
          setOpen(value);
        }}
        onClose={() => {
          setOpen(false);
        }}
        submitLabel={"إضافة"}
      >
        <FinalReportFiles
          formRef={formRef}
          isAddMode={false}
          surveyId={surveyId()}
          initialInputsState={initialInputsState}
          inputs={inputs}
          setInputs={setInputs}
          setDescriptionFileId={setDescriptionFileId}
          setDescriptionFile={setDescriptionFile}
          descriptionFile={descriptionFile}
          handleSubmit={handleSubmit}
          validate={validate}
        />
      </AppModal>
    </>
  );
};

export default FinalReportPage;
