import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";

export default function Page18({ styles, agencyTitle, agencySector, page = '18' }) {
  const pageStyles = StyleSheet.create({
    cardContainer: {
      borderWidth: 1,
      borderStyle: "dotted",
      borderColor: "#7C32C9",
      borderRadius: 8,
      padding: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: 10,
    },
    cardHeader: {
      backgroundColor: "#7C32C9",
      fontSize: 14,
      paddingTop: 7,
      color: "#fff",
      marginBottom: 2,
      // height: 28,
    },
    cardItemContainer: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      gap: 2,
    },
    cardItemWrapper: {
      flexGrow: 1,
      fontSize: 14,
      textAlign: "center",
    },
    cardItemHeader: {
      backgroundColor: "#7C32C9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      paddingTop: 7,

    },
    cardItemBody: { minHeight: 30, backgroundColor: "#F5F5F5" },
  });

  const StatusCard = (data) => {
    return (
      <View style={pageStyles.cardContainer}>
        <View style={pageStyles.cardHeader}>
          <Text>(اسم حالة الاستخدام)</Text>
        </View>

        <View style={pageStyles.cardItemContainer}>
          <View style={pageStyles.cardItemWrapper}>
            <View style={pageStyles.cardItemHeader}>
              <Text>التقنية</Text>
            </View>
            <View style={pageStyles.cardItemBody}></View>
          </View>{" "}
          <View style={pageStyles.cardItemWrapper}>
            <View style={pageStyles.cardItemHeader}>
              <Text>الجهة</Text>
            </View>
            <View style={pageStyles.cardItemBody}></View>
          </View>{" "}
        </View>
        <View style={pageStyles.cardItemContainer}>
          <View style={pageStyles.cardItemWrapper}>
            <View style={pageStyles.cardItemHeader}>
              <Text> التطبيق </Text>
            </View>
            <View style={pageStyles.cardItemBody}></View>
          </View>{" "}
          <View style={pageStyles.cardItemWrapper}>
            <View style={pageStyles.cardItemHeader}>
              <Text> الأثر </Text>
            </View>
            <View style={pageStyles.cardItemBody}></View>
          </View>{" "}
        </View>
      </View>
    );
  };

  const DATA = [{}, {}, {}];
  return (
    <Page style={styles.page}>
      <View style={styles.sectionTitle}>
        <Text style={styles.sectionTitleNumber}> 4.1</Text>
        <View style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Text>
            تطبيقات التقنيات الناشئة عالميا في قطاع {agencySector}
          </Text>
        </View>
      </View>
      <View style={styles.subtitle}>
        <Text>تطبيقات التقنيات الناشئة عالميا في </Text>
        <Text style={styles.agencyTitle}>{agencySector}</Text>
      </View>
      {DATA.map((data) => (
        <StatusCard data={data} />
      ))}
      <Footer page={page} />

    </Page>
  );
}
