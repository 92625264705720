import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";

export default function Page20({ styles, agencyTitle, agencySector, page = 20 }) {
  const pageStyles = StyleSheet.create({
    sectionSecondaryTitle: {
      color: "#7C32C9",
      marginBottom: 5,
      textAlign: "right",
    },
    sectionTitleNumber: {
      color: "#1DC183",
    },
    // cardContainer: {
    //   borderWidth: 1,
    //   borderStyle: "dotted",
    //   borderColor: "#7C32C9",
    //   borderRadius: 8,
    //   padding: 8,
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "stretch",
    //   justifyContent: "center",
    //   textAlign: "center",
    //   marginBottom: 10,
    // },
    cardHeader: {
      backgroundColor: "#7C32C9",
      fontSize: 14,
      paddingTop: 7,
      color: "#fff",
      marginBottom: 2,
      // height: 28,
    },
    cardItemContainer: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      gap: 2,
    },
    cardItemWrapper: {
      flexGrow: 1,
      fontSize: 14,
      textAlign: "center",
    },
    cardItemHeader: {
      backgroundColor: "#7C32C9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    },
    cardItemBody: { minHeight: 70, backgroundColor: "#F5F5F5" },

    tabelContainer: {
      fontSize: 14,
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
      color: "#1DC183",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1",
      textAlign: "center",
    },
    tableHeaderCell: {
      paddingTop: 10,
      paddingHorizontal: 5,
      width: "20%",
      fontWeight: 500,
    },
    tableBodyCell: {
      paddingVertical: 18,
      width: "20%",
      lineHeight: 1.2,
      padding: 30,
    },
    tableBody: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#2A2069",
      borderBottomStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: "1",
    },
    tableLastCell: {
      color: "#1DC183",
      paddingTop: 10,
      width: "20%",
      backgroundColor: "rgb(28 192 129 / 20%)",
    },
  });

  const tableData = [
    <Text style={{ color: "#1DC183" }}>البحث</Text>,
    <Text style={{ color: "#1DC183" }}>التواصل</Text>,
    <Text style={{ color: "#1DC183" }}>الثبات</Text>,
    <Text style={{ color: "#1DC183" }}>التكامل</Text>,
  ];

  interface ITableData { }
  const EvaluationTable = ({ data }: { data: ITableData[] }) => {
    console.log("🚀 ~ EvaluationTable ~ data:", data);

    return (
      <View style={pageStyles.tabelContainer}>
        {/* ---------------- table header ---------------- */}
        <View style={pageStyles.tableHeader}>
          <View style={pageStyles.tableHeaderCell}>
            <Text>القدرات</Text>
          </View>
          <View style={pageStyles.tableHeaderCell}>
            <Text>الدورة الاولي 2023م</Text>
          </View>
          <View style={pageStyles.tableHeaderCell}>
            <Text>الدورة الثانية 2024م</Text>{" "}
          </View>
          <View style={pageStyles.tableHeaderCell}>
            <Text>الدورة الثالثة 2025</Text>{" "}
          </View>
          <View style={pageStyles.tableLastCell}>
            <Text>نسبة التغير علي القدرة</Text>
          </View>
        </View>
        {/* ---------------- table body ---------------- */}
        {data.map((item, index) => (
          <View style={pageStyles.tableBody}>
            <View style={pageStyles.tableBodyCell}>{tableData[index]}</View>
            <View style={pageStyles.tableBodyCell}>
              <Text>XX</Text>{" "}
            </View>
            <View style={pageStyles.tableBodyCell}>
              <Text>XX</Text>{" "}
            </View>
            <View style={pageStyles.tableBodyCell}>
              <Text>XX</Text>{" "}
            </View>
            <View style={{ ...pageStyles.tableLastCell, padding: 30 }}>
              <Text>XX</Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const DATA = [{}, {}, {}, {}];
  return (
    <Page style={styles.page}>
      <View style={styles.mainSectionTitle}>
        <Text style={pageStyles.sectionTitleNumber}> .5</Text>
        <Text> {`نتائج تقييم ${agencyTitle}`} </Text>
      </View>
      <View style={{ ...styles.secondarySectionTitle, fontSize: 16 }}>
        <Text>تطبيقات التقنيات الناشئة عالميا في </Text>
        <Text style={styles.agencyTitle}>{agencySector}</Text>
      </View>

      <EvaluationTable data={DATA} />

      <Footer page={page} />
    </Page>
  );
}
