import React from "react";
import SubRequirement from "../standardAnswerRequirements/ui/subRequirement";
import MainRequirement from "../standardAnswerRequirements/ui/mainRequirement";
import { InputLabel } from "../../../../../../components";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { axiosGetRequest } from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";

const ReadOnlyRequirements = ({ requirements, label }) => {

  console.log({ requirements})

  const { standardId } = useParams();

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
    staleTime: 20000,
  })

  const renderedAgencyRequirements =
    requirements?.length > 0 &&
    requirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <MainRequirement
          key={requirement?.id}
          title={requirement?.title}
          hasSubRequirements={hasSubRequirements}
          checked={requirement?.decision}
          requirementDonotApplyChecked={requirement?.donotApply}
          readOnly={true}
        >
          {hasSubRequirements &&
            requirement?.subStandardRequirements?.map((subRequirement) => {

              const hasRqAnswerSides = subRequirement?.subRequirementAnswerSides?.length;
              return (
                <div className="px-5 flex flex-col mb-3 py-2">
                  <h2>{subRequirement.title}</h2>
                  {hasRqAnswerSides ? (
                    subRequirement?.subRequirementAnswerSides?.map(
                      (rqAnsSide) => {
                        return (
                          <>
                            <h2>{rqAnsSide?.title}</h2>
                            <div className="flex items-end gap-4">
                              <div className="w-64">
                                <InputLabel
                                  value={rqAnsSide?.evaluationDegree || ""}
                                  max={100}
                                  min={0}
                                  name={"evaluationDegree"}
                                  type="number"
                                  label={label ?? "تقييم الخبير"}
                                  isRequired={false}
                                  // placeholder={"تقييم الخبير"}
                                />
                              </div>
                            </div>
                            <div className="w-64 p-3">
                              <p>
                                {getCommitmentLevelsBasedOnDegree(Number(subRequirement?.evaluationDegree), commitmentLevels)}
                              </p>
                            </div>
                          </>
                        );
                      }
                    )
                  ) : (
                    <div className="flex items-end gap-4">
                      <div className="w-64">
                        <InputLabel
                          value={subRequirement?.percentage || ""}
                          max={100}
                          min={0}
                          name={"note"}
                          type="number"
                          label={label ?? "تقييم الخبير"}
                          isRequired={false}
                          // placeholder={"تقييم الخبير"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-64 p-3">
                    <p>
                      {getCommitmentLevelsBasedOnDegree(Number(subRequirement?.percentage), commitmentLevels)}
                    </p>
                  </div>
                </div>

                // <SubRequirement
                //   key={subRequirement?.id}
                //   title={subRequirement?.title}
                //   checked={subRequirement?.decision}
                //   subRequirementDonotApplyChecked={subRequirement?.donotApply}
                //   readOnly={true}
                // />
              );
            })}
        </MainRequirement>
      );
    });

  return renderedAgencyRequirements;
};

export default ReadOnlyRequirements;

function getCommitmentLevelsBasedOnDegree( degree, commitmentLevels ){
  const commitmentLevel = commitmentLevels.find((level) => level.rangeFrom <= degree && level.rangeTo >= degree);
  return commitmentLevel?.title;
}
