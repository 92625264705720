import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { useHistory } from "react-router";
import usePrevReviewerAnswer from "../useGetPrevAnswer/usePrevReviewerAnswer";

const useCreateReviewerAnswer = () => {
  const history = useHistory();

  const {
    newReviewerAnswer,
    newAgencyAnswer,
    reviewerDegree,
    setCreateAnswerLoading,
  } = useAnswerContext();
  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();

  const createReviewerAnswer = async (standardId, entityId, currentStage) => {
    const reviewerRequirementAnswers =
      newReviewerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            donotApply: subReq?.donotApply,

            note: subReq.note || "",
            recommendation: subReq.recommendation || "",
            percentage: subReq?.percentage,
            attachmentId: subReq?.attachmentId,
            reason: subReq?.reason || "",
            isReturnable: subReq?.isReturnable ?? false,
            
            reviewerSubRequirementAnswerSides: subReq?.sides.map( item => {
              return {
                subStandardRequirementSideId: item?.id,
                evaluationDegree: parseFloat(item?.evaluationDegree),
                answer: item?.answer || "",
                attachmentId: item?.attachmentId || null,
                evaluationNote: item?.evaluationNote || "",
                recommendation: item?.recommendation || "",
              }
            }),

            // reviewerSubRequirementAnswerSides: subReq?.sides.map( item => {
            //   return {
            //     "subStandardRequirementSideId": item?.id,
            //     "answer": "string",
            //     "evaluationDegree": +item?.evaluationDegree,
            //   }
            // }),
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          donotApply: req?.donotApply,
          reviewerSubRequirementAnswers: subStandardRequirements,
          percentage: req?.percentage,
        };
      });

      const allSubRequirmenets = reviewerRequirementAnswers.map(item => item.reviewerSubRequirementAnswers).flat()
      const subRequirementNotes = allSubRequirmenets.map(item => item.percentage)
      let isValidDegree = subRequirementNotes.every(degree =>  (degree || degree == 0) && degree !== "" && Number.isFinite(Number(degree))) 
      const isValidPercentage = subRequirementNotes.every(percentage => !percentage || (+percentage >= 0 && +percentage <= 100)) 

      if (!isValidPercentage) {
        // the evaluation of the expert  for the questions should be within 0 and 100
        errorToast("التقييم الخاص بالخبير للأسئلة يجب أن يكون بين 0 و 100")
        return
      }

      if (newAgencyAnswer?.surveyStructureSubStandardRequirementDirectly) { // على مستوى النعيار
        const subRequirementDegree = allSubRequirmenets.map(i => i?.reviewerSubRequirementAnswerSides)?.flat().map(item => item.evaluationDegree)
        isValidDegree = subRequirementDegree.every(degree =>  (degree || degree == 0) && degree !== "" && Number.isFinite(Number(degree))) 
      }

      if (!isValidDegree) {
        // the evaluation of the reviewer for the questions should have value
        errorToast("يجب إدخال جميع الحقول المطلوبة")
        return
      }
  

    // * Proof Files
    // const agencyProofAnswers = newReviewerAnswer?.standardProofs?.map(
    //   (file) => {
    //     return {
    //       standardProofId: file?.standardProofId,
    //       note: file?.note,
    //     };
    //   }
    // );

    // const data = {
    //   surveyStageId: Number(currentStage),
    //   standardId: Number(standardId),
    //   agencyId: Number(entityId),
    //   reviewerId: Number(localStorage.getItem("userId")),
    //   reviewerRequirementAnswers,
    //   // agencyProofAnswers,
    //   noteForAgency: {
    //     content: "",
    //   },
    //   selfEvaluationCommitmentLevelId:
    //     reviewerDegree?.selfEvaluationCommitmentLevel?.id,
    //   finalCommitmentLevelId: newReviewerAnswer?.checkedreviewerDegree?.id,
    //   selfEvaluationDegree: reviewerDegree?.selfEvaluationDegree,
    //   finalDegree: newReviewerAnswer?.checkedreviewerDegree?.fixedDegree,
    //   donotApply: false,
    //   visitId: 0,
    // };

    const surveyReviewerAnswerStandardProofs =
      newReviewerAnswer?.standardProofs
        ?.filter((proof) => proof?.reviewerProofNotes)
        .map((proof) => ({
          standardProofId: proof?.standardProofId || null,
          reviewerProofNotes: proof?.reviewerProofNotes || "",
        })) || [];

    // validation
    // if (newReviewerAnswer?.checkedReviewerDegree?.value == null) {
    //   return errorToast("لابد من وجود اجابة للتدقيق");
    // }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number((currentStage || 0)),
      reviewerRequirementAnswers,
      surveyReviewerAnswerStandardProofs,
      noteForAgency: {
        unCheckRequirement: !newReviewerAnswer?.newRequirementsAgency
          ? ""
          : `<ul style="padding : 6px 0 ">` +
            newReviewerAnswer?.newRequirementsAgency +
            "</ul>",
        template: "",
        content: newReviewerAnswer?.reviewerSelectedTemplate,
        standardNote: newReviewerAnswer?.reviewerSelectedNoteTemplateForAgency || "",
        standardRecommendation: newReviewerAnswer?.reviewerSelectedRecommTemplateForAgency || "",
        noteTemplateId: newReviewerAnswer?.selectedTemplatesForAgency,
      },
      reviewerId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree: newReviewerAnswer?.checkedReviewerDegree?.fixedDegree,
      finalCommitmentLevelId: newReviewerAnswer?.checkedReviewerDegree?.value,
      selfEvaluationCommitmentLevelId:
        reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree: reviewerDegree?.reviewerSelfEvaluationDegree || 0,

      reviewerAnswerNote:
        reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title !==
        newReviewerAnswer?.checkedReviewerDegree?.title
          ? newReviewerAnswer?.reviewerAnswerNote
          : null,

      needVisit: newReviewerAnswer?.needVisit,
      visitNote: newReviewerAnswer?.visitNote,
    };

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addReviewerAnswer, data);
    if (res?.success) {
      successToast("تم إضافة الإجابة بنجاح");

      getPrevReviewerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createReviewerAnswer };
};

export default useCreateReviewerAnswer;
