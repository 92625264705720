import { useTranslation } from "react-i18next";
import NewHeader from "../../../layouts/header/NewHeader";
import { FModal, InputLabel, SModal, Title } from "../../../components";
import { useEffect, useRef, useState } from "react";
import environment from "../../../services/environment";
import {
  axiosDeleteRequest,
  axiosGetRequest,
  axiosPostRequest,
  axiosPutRequest,
  get,
} from "../../../services/Request";
import { useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import { programsIcon, settingsIcon } from "../../../assets/icons/Index";
import { toast } from "react-toastify";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import AppButton from "../../../components/buttons/appButton";
import EditIcon from "../../../components/EditIcon";
import DeleteIcon from "../../../components/DeleteIcon";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import { permissionsCheck } from "../../../helpers/utils";
import TypeOfVisitTable from "./TypeOfVisitTable";
import AddVisitTargetModal from "./AddVisitTargetModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../components/UploadAdapter";
import { errorToast } from "../../../helpers/toast";

const VisitSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [isSupported, setIsSupported] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  // -------------------------- //
  const [selectedMemberTeam, setSelectedMemberTeam] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);

  const handleChangeMemberTeam = (value) => {
    setSelectedMemberTeam(value);
  };
  const handleChangeAgencies = (value) => {
    setSelectedAgencies(value);
  };

  const getAllAgency = async () => {
    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    if (res.success) {
      setAgencyList(res?.result);
    }
  };

  const allAgenciesOptions = agencyList.map((ele) => {
    return {
      label: ele.nameInArabic,
      value: ele.id,
    };
  });

  useEffect(() => {
    getAllAgency();
  }, []);

  // ------------------------------------------------------//
  // for allSectors multiSelect
  const [getAllAgencySectors, allAgencySectors] = useAxiosGetRequest();
  const [selectedClassification, setSelectedClassification] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [visitAgenda, setVisitAgenda] = useState("");
  const [standards, setStandards] = useState([]);
  const [selectedVisitTeamStandards, setSelectedVisitTeamStandards] = useState(
    []
  );
  const [selectedMemberTeamExternal, setSelectedMemberTeamExternal] = useState(
    []
  );

  const getUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllUsers + "?MaxResultCount=" + 50000, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let newUsers = [];
        let newArr = []
        res.data.result.items.forEach((user, index) => {
          let obj = { label: user.fullName, id: user.id, value: user.id ,showRoleId:user.showRole.id};
          newUsers.push(obj);
        });
        newArr = newUsers.filter(
          (item, index) => item.showRoleId != 40 && item.showRoleId != 39 && item.showRoleId != 41
        );
        console.log(newArr)
        setUsers(newArr);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getAllStandard = async () => {
    const res = await axiosPostRequest(
      "services/app/GetForSelection/Standards" +
        "?surveyId=" +
        localStorage.getItem("surveyId") || null
    );
    if (res.success) {
      setStandards(res?.result);
    }
  };

  const allStandardsOptions = standards.map((ele) => {
    return {
      label: ele.code,
      value: ele.id,
    };
  });

  useEffect(() => {
    getAllStandard();
  }, []);

  const handleChangeStandards = (value) => {
    setSelectedVisitTeamStandards(value);
  };

  useEffect(() => {
    getAllAgencySectors(environment.getAllAgencySectors);
  }, []);

  const allSectorsOptions =
    allAgencySectors?.result?.items &&
    allAgencySectors?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || "", value: item?.id };
    });

  const handleSelectedSector = (value) => {
    setSelectedSector(value || "");
  };

  // for multiSelect
  const [getAllAgencyClassification, allAgencyClassification] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyClassification(environment.getAllAgencyClassification);
  }, []);

  const allClassificationsOptions =
    allAgencyClassification?.result?.items &&
    allAgencyClassification?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || "", value: item?.id };
    });

  const handleSelectedClassification = (value) => {
    setSelectedClassification(value || "");
  };

  // ---------------------------------------------------- //

  // -------------------------- //

  const columns = [
    {
      name: t("اسم الفريق"),
      selector: (row) => row.teamName,
      header: "teamName",
      accessorKey: "teamName",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {row.teamName}
        </div>
      ),
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck("Pages.VisitTeam.Edit") && (
            <div className="flex gap-2">
              <EditIcon
                initialValuesFunction={() => {
                  setTitle(row?.teamName);
                  setSelectedMemberTeam(
                    row?.visitTeamMembers?.map((ele) => {
                      return {
                        label: ele.teamMember.userName,
                        value: ele.teamMember.id,
                      };
                    })
                  );
                  setVisitAgenda(row.visitAgenda);
                  setSelectedAgencies(
                    row.visitTeamAgenceis.map((ele) => {
                      return {
                        label: ele.teamAgency.nameInArabic,
                        value: ele.teamAgency.id,
                      };
                    })
                  );
                  setSelectedVisitTeamStandards(
                    row.visitTeamStandards.map((ele) => {
                      return {
                        label: ele.teamStandard.code,
                        value: ele.teamStandard.id,
                      };
                    })
                  );

                  // LATER
                  // setSelectedMemberTeamExternal(row.visitTeamExternalMembers);
                }}
                loading={editLoading}
                editFunction={(e) => handleUpdateTeam(row)}
                editedName={row.teamName}
                editModalContent={
                  <div className="flex flex-col gap-3">
                    <InputLabel
                      label={"اسم الفريق"}
                      name={"title"}
                      type={"text"}
                      value={title}
                      onChange={handleChangeTitle}
                    />

                    <section className="w-full flex flex-col">
                      <NewDropdownMultiSelect
                        isClearable={true}
                        withoutLabel={false}
                        isSearchable={true}
                        value={selectedMemberTeam}
                        handleChange={handleChangeMemberTeam}
                        placeholder="ابحث باسم العضو"
                        label="أعضاء الفريق"
                        options={users}
                        isMultiple={true}
                      />
                      <div className="text-sm text-red-600 mt-1">
                        {/* {errors.visitTeamMembers &&
                          touched.visitTeamMembers &&
                          errors.visitTeamMembers} */}
                      </div>
                    </section>

                    <div className="flex flex-col gap-5">
                      {/* <div className="">
                        <DropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          value={selectedClassification}
                          handleChange={handleSelectedClassification}
                          label="تصنيف الجهات"
                          options={allClassificationsOptions}
                          isMultiple={false}
                          isRequired={true}
                        />
                        <div className="text-red-700 mt-1">

                        </div>
                      </div> */}

                      {/* <div className="">
                        <DropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          value={selectedSector}
                          handleChange={handleSelectedSector}
                          label="قطاعات الجهات"
                          options={allSectorsOptions}
                          isMultiple={false}
                          isRequired={true}
                        />
                        <div className="text-red-700 mt-1">

                        </div>
                      </div> */}

                      {/* //LATER */}
                      {/* <div>
                        {selectedMemberTeamExternal.map((item) => (
                          <div className="grid mt-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 xl:grid-cols-4">
                            <div className="flex flex-row items-center">
                              <img
                                src={userImage}
                                className="w-[44px]"
                                alt="icon"
                              />
                              <span className="text-[13px] text-[#000000] mr-3 ml-3">
                                {item.name}
                              </span>
                            </div>
                            <div className="flex flex-row items-center">
                              <img src={call} alt="icon" />
                              <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                                {item.mobile}
                              </span>
                            </div>
                            <div className="flex flex-row items-center">
                              <img src={sms} alt="icon" />
                              <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                                {item.email}
                              </span>
                            </div>
                            <div className="flex flex-row items-center">
                              <img src={sms} alt="icon" />
                              <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                                {item.nationalityId}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div> */}

                      <div>
                        <div className="w-full flex flex-col">
                          <NewDropdownMultiSelect
                            isClearable={true}
                            withoutLabel={false}
                            isSearchable={true}
                            value={selectedAgencies}
                            handleChange={handleChangeAgencies}
                            placeholder="اختر الجهات الحكومية"
                            label="الجهات داخل النظام"
                            options={allAgenciesOptions}
                            isMultiple={true}
                          />

                          <div className="text-red-700 mt-1"></div>
                        </div>
                      </div>

                      <div>
                        <NewDropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          value={selectedVisitTeamStandards}
                          handleChange={handleChangeStandards}
                          label="المعايير"
                          options={allStandardsOptions}
                          isMultiple={true}
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {/* {errors.visitTeamStandards &&
                            touched.visitTeamStandards &&
                            errors.visitTeamStandards} */}
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="text-[16px] text-[#292069]">
                          أجندة الإجتماع
                        </label>
                        <div className="mt-3">
                          <CKEditor
                            editor={ClassicEditor}
                            data={visitAgenda}
                            onReady={(editor) => {
                              setVisitAgenda(editor.getData());
                              editor.plugins.get(
                                "FileRepository"
                              ).createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                            config={{
                              language: "ar",

                              heading: {
                                options: [
                                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                  { model: 'heading1', view: 'h1', title: 'Heading 1' }, // Corrected to 'h1'
                                  { model: 'heading2', view: 'h2', title: 'Heading 2' }, // Corrected to 'h2'
                                  { model: 'heading3', view: 'h3', title: 'Heading 3' }  // Corrected to 'h3'
                                ]
                              },   
  
                              toolbar: [ 
                                'undo', 'redo', '|', 
                                'heading', '|', 
                                'bold', 'italic', 'Underline', '|',
                                'link', 'insertTable', 'blockQuote', '|',
                                'bulletedList', 'numberedList', '|',
                                // 'outdent', 'indent', 
                              ],
                  
                              simpleUpload: {
                                uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                                withCredentials: true,
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "token"
                                  )}`,
                                },
                              },
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setVisitAgenda(data);
                              editor.plugins.get(
                                "FileRepository"
                              ).createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <DeleteIcon
                loading={deleteLoading}
                id={row.id}
                deleteFunction={() => handleDeleteTeam(row.id)}
                deletedName={row.teamName}
              />
            </div>
          )
        );
      },
    },
  ];

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeColor = (e) => {
    setColor(e.target.value);
  };

  const handleChangeIsSupported = (e) => {
    setIsSupported(e.target.checked);
  };

  const handleChangeSelectedUsers = (value) => {
    setSelectedUsers(value);
  };

  useEffect(() => {
    getAllUsers();
    getAllList(0);
  }, []);

  const getAllList = (page) => {
    setCurrentPage(page);
    getTeams(page);
  };

  const getTeams = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getVisitTeam + "?MaxResultCount=6&SkipCount=" + page * 6,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t("general.authError"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(t("general.serverError"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          setTeams(res.data.result.items);
          setTotalPages(Math.ceil(res.data.result.totalCount / 6));
          setLoading(false);
        }
      }
    );
  };

  const getAllUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllUsers, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              userId: res.data.result.items[i].id,
              label:
                res.data.result.items[i].name +
                " " +
                res.data.result.items[i].surname,
              isSelected: false,
            });
          }
          setUsers(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  // LATER
  // getAllVisitsTeams
  const [getAllVisitsTeams, allVisitsTeams, getAllVisitsTeamsLoading] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllVisitsTeams("services/app/VisitTeams/GetAll");
  }, []);

  // handleUpdateTeam
  const handleUpdateTeam = async (row) => {
    if (!title) {
      errorToast("لابد من إدخال اسم للفريق");
      return;
    } else if (!selectedMemberTeam.length) {
      errorToast("لابد من إدخال الخبراء ");
      return;
    } else if (!selectedAgencies.length) {
      errorToast("لابد من إدخال جهات ");
      return;
    } else if (!visitAgenda) {
      errorToast("لابد من إدخال اجندة ");
      return;
    }

    // else if (!selectedVisitTeamStandards.length) {
    //   errorToast("لابد من إدخال معايير ");
    //   return;
    // }
    const body = {
      id: row.id,
      teamName: title,
      visitTeamMembers: selectedMemberTeam.map((ele) => {
        return {
          teamMemberId: ele.value,
        };
      }),
      visitTeamExternalMembers: allVisitsTeams?.visitTeamExternalMembers || [],
      visitTeamAgenceis: selectedAgencies.map((ele) => {
        return {
          teamAgencyId: ele.value,
        };
      }),
      visitTeamStandards: selectedVisitTeamStandards.map((ele) => {
        return {
          teamStandardId: ele.value,
        };
      }),
      visitAgenda: visitAgenda,
    };

    setEditLoading(true);
    const res = await axiosPutRequest("services/app/VisitTeams/Update", body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث الفريق بنجاح`);
      getTeams(0);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  // handleDeleteTeam
  const handleDeleteTeam = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest("services/app/VisitTeams/DeleteById", {
      id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getTeams(0);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset);
  };

  // ------------------targetOfVisit------------------------ //
  const [addTargetLoading, setAddTargetLoading] = useState(false);
  const [addTargetOfVisitInputs, setAddTargetOfVisitInputs] = useState({
    title: "",
    nameInArabic: "",
  });

  // LATER
  // getAllVisitTargets
  const [getAllVisitTargets, allVisitTargets, getAllVisitTargetsLoading] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
  }, []);

  // handleAddVisitTarget
  const handleAddVisitTarget = async () => {
    const body = {
      name: addTargetOfVisitInputs.nameInArabic,
      nameInArabic: addTargetOfVisitInputs.nameInArabic,
    };

    setAddTargetLoading(true);
    const res = await axiosPostRequest(
      environment.createMeetingAndVisitGoal,
      body
    );
    setAddTargetLoading(false);

    if (res?.success) {
      toast.success(`تم إضافة نوع زيارة جديد بنجاح`);
      getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
      setOpenAddModal(false);
      setAddTargetOfVisitInputs({
        title: "",
        nameInArabic: "",
      });
    } else {
      toast.error("حدث خطأ");
    }
  };

  const handleAddTargetOfVisitInputs = (e) => {
    setAddTargetOfVisitInputs({
      ...addTargetOfVisitInputs,
      [e.target.name]: e.target.value,
    });
  };
  // ------------------targetOfVisit------------------------ //

  const tabs = [
    {
      // icon: categoryIcon,
      title: "الفرق",
      table: "teams",
      content: (
        <div>
          {/* MeetingAndVisitGoal */}
          {permissionsCheck("Pages.VisitTeam.GetAll") ? (
            <NewTable
              loading={loading}
              columns={columns}
              data={teams}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {"ليس لديك صلاحية لهذا"}
            </div>
          )}
        </div>
      ),
    },
    {
      // icon: priorityIcon,
      title: "الهدف من الزيارة",
      table: "targetOfVisit",
      content: (
        <div>
          {permissionsCheck("Pages.MeetingAndVisitGoal.GetAll") ? (
            <TypeOfVisitTable
              loading={getAllVisitTargetsLoading}
              allVisitTargets={allVisitTargets}
              getAllVisitTargets={getAllVisitTargets}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {"ليس لديك صلاحية لهذا"}
            </div>
          )}
        </div>
      ),
    },
  ];

  const formTarget = useRef(null);

  const handleSubmitAddTarget = () => {
    if (formTarget.current) {
      formTarget.current?.handleSubmit();
    }
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={settingsIcon}
        style={{ backgroundColor: "#2B2969" }}
        single={true}
        title={"اٍعدادات الزيارات"}
        withIcon={true}
        withoutButton={true}
      />
      <div className="w-full bg-white pb-5 my-5 flex flex-col">
        <div className="w-full flex justify-end h-[50px] px-5">
          <AppButton
            onClick={
              activeTab === 0
                ? () =>
                    permissionsCheck("Pages.VisitTeam.Create")
                      ? history.push("/visit/addTeam")
                      : () => {}
                : () => setOpenAddModal(true)
            }
          >
            {activeTab === 0
              ? "إضافة فريق"
              : activeTab === 1
              ? "إضافة هدف الزيارة"
              : ""}
          </AppButton>
        </div>
        <div className="w-full flex justify-end h-[50px] px-5"></div>

        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  "mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] " +
                  (activeTab === index
                    ? "bg-emerald-500 text-white"
                    : "text-slate-400 bg-slate-50")
                }
                onClick={() => setActiveTab(index)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={"24px"}
                  height={"24px"}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>
                {
                  activeTab === index &&
                    tab.content /*<NewTable columns={columns} data={categories}/>*/
                }
              </div>
            ))}
          </div>
        </div>

        {/* <div className="p-5 bg-slate-50 rounded-xl w-full">
          <NewTable
            loading={loading}
            columns={columns}
            data={teams}
            pagination={false}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
          />
        </div> */}
      </div>

      <FModal
        loading={activeTab === 0 ? "" : activeTab === 1 ? addTargetLoading : ""}
        submitLabel={"إضافة"}
        headerTitle={
          activeTab === 0 ? "" : activeTab === 1 ? "إضافة نوع زيارة" : ""
        }
        content={
          activeTab === 0 ? (
            ""
          ) : activeTab === 1 ? (
            <AddVisitTargetModal
              innerRef={formTarget}
              onSubmit={handleAddVisitTarget}
              inputs={addTargetOfVisitInputs}
              onChange={handleAddTargetOfVisitInputs}
            />
          ) : (
            ""
          )
        }
        open={openAddModal}
        setOpen={() => setOpenAddModal(false)}
        type={"submit"}
        action={
          activeTab === 0 ? "" : activeTab === 1 ? handleSubmitAddTarget : ""
        }
      />

      <SModal
        type={message.type}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={message.message}
      />
    </div>
  );
};

export default VisitSettings;
