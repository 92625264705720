import React from "react";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import ShowUploadedFiles from "../../standardAnswerProofFiles/ui/showUploadedFiles";
import StandardAnswerProofFiles from "../../standardAnswerProofFiles";
import { useAnswerContext } from "../../answerContext";

const ExpertManagerProofFiles = ({setMenuElement}) => {
  const { newExpertManagerAnswer, setNewExpertManagerAnswer } =
    useAnswerContext();

  const handleChangeNotes = (file, note) => {
    const updatedNotes = newExpertManagerAnswer?.standardProofs?.map(
      (document) => {
        if (document?.id == file?.id) {
          return {
            ...document,
            standardProofId: document?.standardProofId || document?.id,
            expertManagerProofNotes: note,
          };
        }
        return document;
      }
    );

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      standardProofs: updatedNotes,
    });
  };


  const allSubStandardRequirements = newExpertManagerAnswer?.standardRequirements?.map((req) => req.subStandardRequirements ?? []).flat() ?? [];

  const renderedFiles = newExpertManagerAnswer?.standardProofs?.length ? newExpertManagerAnswer?.standardProofs?.map(
    (file, index) => (
      <div
        key={file?.id}
        className="max-w-[750px] pl-8 mb-8 pr-[80px] flex flex-col justify-beween items-start "
      >
        <div className="w-full bg-slate-50 rounded-md px-5 ">
          <div className="relative my-4 flex flex-row items-start justify-between">
            <div className="flex items-start">
              <span className="bg-[#D2F2E6] w-4 h-4 rounded-full absolute flex justify-center items-center mt-2">
                <span className="bg-[#1CC081] w-[6px] h-[6px] rounded-full absolute"></span>
              </span>
              <h4 className="mr-6 ml-6 text-[#292069]">{file?.title}</h4>
            </div>
            <p className="text-[#7D8BAB] mb-2">{file?.fileSize + "MB"}</p>
          </div>

          <div className="py-2">
            <h3>الأسئلة المرتبطة</h3>
            <div>
              {file?.subStandardProofRequirements.map((req) => {
                return (
                  <p className="text-base">
                    {
                      allSubStandardRequirements.find(
                        (subReq) => subReq.id === req.subStandardRequirementId
                      )?.title
                    }
                  </p>
                );
              })}
            </div>
          </div>

          <ShowUploadedFiles uploadedFiles={file?.standardProofAttachments} />

          {file.note && (
            <div className="my-5">
              <h3 className="font-semibold">ملاحظة الجهة: </h3>
              <p className="my-1">{file.note || "لا يوجد"}</p>
            </div>
          )}
        </div>
      </div>
    )
  ) : (
    <div className="max-w-[750px] pl-8 mb-8 pr-[80px] flex flex-col items-start ">
      لا يوجد ملفات مطلوبة
    </div>
  );

  return <StandardAnswerProofFiles setMenuElement={setMenuElement} hideNext>{renderedFiles}</StandardAnswerProofFiles>;
};

export default ExpertManagerProofFiles;
