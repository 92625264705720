import {createContext, useState} from "react";
import environment from "../services/environment";
import {axiosGetRequest} from "../services/Request";

const IndicatorsContext = createContext();

function IndicatorsContextProvider(props) {
  // * Indicators
  const [loading, setLoading] = useState(false);
  const [indicators, setIndicators] = useState([]);

  const getIndicatorList = async (keyword, description) => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getAllIndicators, {
      Keyword: keyword,
      Description: description,
      MaxResultCount: 1000,
    });
    if (res?.success) {
      setIndicators(res?.result?.items);
    }
    setLoading(false);
  };

  const addnewIndicator = (indicator) => {
    setIndicators([{ ...indicator }, ...indicators]);
  };

  const updateEditedIndicator = (id, indicator) => {
    const indicatorsObj = indicators?.map((item) => {
      if (item.id === id) return { ...item, ...indicator };
      return item;
    });

    setIndicators(indicatorsObj);
  };

  const removeDeletedIndicator = (id) => {
    const indicatorsObj = indicators?.filter((item) => item.id != id);
    setIndicators(indicatorsObj);
  };

  // * Indicator Surveys
  const [loadingIndicatorSurveys, setLoadingIndicatorSurveys] = useState(false);
  const [indicatorSurveys, setIndicatorSurveys] = useState([]);

  const getIndicatorSurveysList = async (indicatorId) => {
    setLoadingIndicatorSurveys(true);
    const res = await axiosGetRequest(environment.getSurveys, {
      IndicatorId: indicatorId,
    });
    if (res?.success) {
      setIndicatorSurveys(res?.result?.items);
    }
    setLoadingIndicatorSurveys(false);
  };

  const addnewIndicatorSurveys = (indicator) => {
    setIndicatorSurveys([{ ...indicator }, ...indicatorSurveys]);
  };

  const updateEditedIndicatorSurveys = (id, indicator) => {
    const indicatorsObj = indicatorSurveys?.map((item) => {
      if (item.id === id) return { ...item, ...indicator };
      return item;
    });

    setIndicatorSurveys(indicatorsObj);
  };

  const removeDeletedIndicatorSurveys = (id) => {
    const indicatorsObj = indicatorSurveys?.filter((item) => item.id != id);
    setIndicatorSurveys(indicatorsObj);
  };

  return (
    <IndicatorsContext.Provider
      value={{
        loading,
        indicators,
        getIndicatorList,
        addnewIndicator,
        updateEditedIndicator,
        removeDeletedIndicator,

        loadingIndicatorSurveys,
        indicatorSurveys,
        getIndicatorSurveysList,
        addnewIndicatorSurveys,
        updateEditedIndicatorSurveys,
        removeDeletedIndicatorSurveys,
      }}
    >
      {props.children}
    </IndicatorsContext.Provider>
  );
}

export { IndicatorsContext, IndicatorsContextProvider };
