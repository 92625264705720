import {
  isExpert,
  isExpertManager,
  isPocOfficer,
} from "../../helpers/isPermission";
import cardConfigurations from "./cardConfigurations";

function getUserRole() {
  if (isExpertManager()) return "expertManager";
  if (isExpert()) return "expert";
  if (isPocOfficer()) return "pocOfficer";
  return "auditor"; // default to auditor if no other role matches
}

function AuditorExpertDashboardCards() {
  const userRole = getUserRole();
  const cards = cardConfigurations[userRole];

  return (
    <div className="p-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
      {cards.map((card) => (
        <div
          key={card.id}
          className={card.fullWidth ? "col-span-2" : "col-span-2 lg:col-span-1"}
        >
          {card.component}
        </div>
      ))}
    </div>
  );
}

export default AuditorExpertDashboardCards;
