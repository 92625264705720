import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import UploadAdapter from "../../../../../../../components/UploadAdapter";
import { DropdownMultiSelect } from "../../../../../../../components";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { showRequirement } from "../utils";
import { AddNotesAndRecomendation } from "../AddNotesAndRecomendation";

const ExpertManagerNoteTemplate = () => {
  const { newExpertManagerAnswer, expertManagerAnswer, setNewExpertManagerAnswer, newAgencyAnswer } =
    useAnswerContext();
  const [selectedTemplatesForAgency, setSelectedTemplatesForAgency] =
    useState("");
  const [selectedTemplatesForReviewer, setSelectedTemplatesForReviewer] =
    useState("");

  const [templateListOptions, setTemplateListOptions] = useState([]);
  const [selectedRequirementsTab, setSelectedRequirementsTab] = useState("note");

  useEffect(() => {
    const prevTemplateNote = templateListOptions
      ?.filter(
        (item) => item.value === newExpertManagerAnswer?.templateIdForAgency
      )
      .at(0);

    newExpertManagerAnswer.selectedTemplatesForAgency =
      prevTemplateNote?.value || null;
    setSelectedTemplatesForAgency(prevTemplateNote);
  }, [newExpertManagerAnswer?.templateIdForAgency, templateListOptions.length]);

  function extractTextFromHtml(htmlString) {
    // Remove HTML tags
    var text = htmlString.replace(/<[^>]*>/g, "");
    // Remove backslashes
    text = text.replace(/\\/g, "");
    // Return the extracted text
    return text;
  }

  useEffect(() => {
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    const res = await axiosGetRequest(environment.getAllNoteTemplate, {
      AgencyCommitmentLevelsIds:
        newAgencyAnswer?.checkedAgencyDegree?.id == null
          ? noAnswerDegreeId
          : newAgencyAnswer?.checkedAgencyDegree?.id,

      CorrectorCommitmentLevelsIds:
        newExpertManagerAnswer?.checkedExpertManagerDegree?.id == null
          ? noAnswerDegreeId
          : newExpertManagerAnswer?.checkedExpertManagerDegree?.id,
      MaxResultCount: 1000000,
      SkipCount: 0,
    });

    if (res.success) {
      let newTemplates = [];
      res?.result?.items.forEach((item) => {
        newTemplates.push({
          label: extractTextFromHtml(item.templateContent) || "",
          value: item.id,
        });
      });
      setTemplateListOptions(newTemplates);
    }
  };

  const handleChangeTemplatesForAgency = (value) => {
    newExpertManagerAnswer.selectedTemplatesForAgency = value.value;
    setSelectedTemplatesForAgency(value || "");
  };

  const handleChangeTemplatesForReviewer = (value) => {
    newExpertManagerAnswer.selectedTemplatesForReviewer = value.value;
    setSelectedTemplatesForReviewer(value || "");

    console.log(value);
  };

  return (
    <>
      <div className="w-[87%] mb-2 ">
        <div className="flex pb-5 w-full">
          <p
            className={
              selectedRequirementsTab == "note"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("note")}
          >
            ملاحظات المعيار
          </p>
          <p
            className={
              selectedRequirementsTab == "recommendation"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("recommendation")}
          >
            توصيات المعيار
          </p>
        </div>

        {selectedRequirementsTab === "note" && (
          <AddNotesAndRecomendation
            selectedRequirementsTab={selectedRequirementsTab}
            newAnswer={newExpertManagerAnswer}
            setNewAnswer={setNewExpertManagerAnswer}
            value={newExpertManagerAnswer?.expertManagerSelectedNoteTemplateForAgency}
            propName={'expertManagerSelectedNoteTemplateForAgency'}
          />
        )}

        {selectedRequirementsTab === "recommendation" && (
          <AddNotesAndRecomendation
            selectedRequirementsTab={selectedRequirementsTab}
            newAnswer={newExpertManagerAnswer}
            setNewAnswer={setNewExpertManagerAnswer}
            value={newExpertManagerAnswer?.expertManagerSelectedRecommTemplateForAgency}
            propName={'expertManagerSelectedRecommTemplateForAgency'}
          />
        )}

        {/* <div className="w-[87%] mb-6"> */}
          {/* <h2 className="text-[#292069] text-lg font-bold mb-1">
            ملاحظات الى الجهة:
          </h2> */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html:
                newExpertManagerAnswer?.expertManagerNoteForAgency ||
                "لا يوجد ملاحظات",
            }}
          ></div> */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: expertManagerAnswer?.expertManagerNoteTemplate,
            }}
          ></div> */}
          {/* <ul className="px-2">
            {expertManagerAnswer?.standardRequirements.map((requirement, index) => (
              <li>
                {showRequirement(requirement) ? requirement.title : ""}
                <ul>
                  {requirement.subStandardRequirements.map(
                    (subRequirement, i) => (
                      <>
                        {(subRequirement.decision == 0 ||
                          !subRequirement.decision) &&
                        subRequirement?.donotApply == false ? (
                          <li className="mx-10">{subRequirement.title}</li>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul> */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: expertManagerAnswer?.expertManagerNotContent || "",
            }}
          ></div>
          {!expertManagerAnswer?.standardRequirements.length &&
            !expertManagerAnswer?.expertNoteTemplate &&
            !expertManagerAnswer?.expertManagerNoteTemplate && (
              <div>لا يوجد ملاحظات</div>
            )} */}
        {/* </div> */}
        {/* {newExpertManagerAnswer?.noteForReviewer && (
          <div className="w-[87%] mb-14">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الى المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertManagerAnswer?.noteForReviewer || "لا يوجد ملاحظات",
              }}
            ></div>
           <div className="mt-3"
            dangerouslySetInnerHTML={{
              __html: newExpertManagerAnswer?.expertManagerSelectedTemplateForReviewer || "لا يوجد ملاحظات",
            }}
          ></div>
          </div>
        )} */}

        {/* * Agency note templates */}
        {/* <div className="my-6"> */}
          {/* <div className="pb-5">
            <DropdownMultiSelect
              isSearchable={true}
              value={selectedTemplatesForAgency}
              handleChange={handleChangeTemplatesForAgency}
              placeholder={t("اختر من القوالب")}
              label={t("قوالب الملاحظات للجهة")}
              options={templateListOptions}
              isMultiple={false}
            />
          </div> */}
          {/* {!selectedTemplatesForAgency ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                القالب الذي تم اختياره{" "}
              </div>
              <div className="bg-[#DBE0EF] p-2 rounded-xl mt-2 mb-3">
                {selectedTemplatesForAgency.label}
              </div>
            </div>
          )}
          {!newExpertManagerAnswer?.standardRequirements?.length ? null : (
            <div className="mt-2 mb-2">
              {/* <div className="text-[#202969] font-bold text-[15px]">
                الأسئلة التي لم يتم اختيارها{" "}
              </div> 
              <ol className="p-2">
                {newExpertManagerAnswer?.standardRequirements.map(
                  (requirement, index) => (
                    <li>
                      {showRequirement(requirement) ? requirement.title : ""}
                      <ul>
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <>
                              {(subRequirement.decision == 0 ||
                                !subRequirement.decision) &&
                              subRequirement?.donotApply == false ? (
                                <li className="mx-10">
                                  {subRequirement.title}
                                </li>
                              ) : (
                                ""
                              )}
                            </>
                          )
                        )}
                      </ul>
                    </li>
                  )
                )}
              </ol>
            </div>
          )} */}
          {/* <CKEditor
            editor={ClassicEditor}
            data={
              // selectedTemplatesForAgency?.label ||
              newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency
            }
            onReady={(editor) => {
              setNewExpertManagerAnswer({
                ...newExpertManagerAnswer,
                expertManagerSelectedTemplateForAgency: editor.getData(),
              });
              // setContent(editor.getData());
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
            config={{
              language: "ar",

              heading: {
                options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1' }, // Corrected to 'h1'
                  { model: 'heading2', view: 'h2', title: 'Heading 2' }, // Corrected to 'h2'
                  { model: 'heading3', view: 'h3', title: 'Heading 3' }  // Corrected to 'h3'
                ]
              },   

              toolbar: [ 
                'undo', 'redo', '|', 
                'heading', '|', 
                'bold', 'italic', 'Underline', '|',
                'link', 'insertTable', 'blockQuote', '|',
                'bulletedList', 'numberedList', '|',
                // 'outdent', 'indent', 
              ],

              simpleUpload: {
                uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                withCredentials: true,

                headers: {
                  // 'X-CSRF-TOKEN': 'CSRF-Token',
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setNewExpertManagerAnswer({
                ...newExpertManagerAnswer,
                expertManagerSelectedTemplateForAgency: data,
              });
              console.log(data);
              // setContent(data);
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
          /> */}
        {/* </div> */}

        {/* * Reviewer note templates */}
        {/* <div className="my-6">
          <div className="text-lg font-bold text-[#202969] pb-5">
            ملاحظة للمدقق
          </div>
          <div className="pb-5">
            <DropdownMultiSelect
              isSearchable={true}
              value={selectedTemplatesForReviewer}
              handleChange={handleChangeTemplatesForReviewer}
              placeholder={t("اختر من القوالب")}
              label={t("قوالب الملاحظات للمدقق")}
              options={templateListOptions}
              isMultiple={false}
            />
          </div>
          {!selectedTemplatesForReviewer ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                القالب الذي تم اختياره{" "}
              </div>
              
              <div className="bg-[#DBE0EF] p-2 rounded-xl mt-2 mb-3">
                {selectedTemplatesForReviewer.label}
              </div>
            </div>
          )}
          {newExpertManagerAnswer?.standardRequirements.length == 0 ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                الأسئلة التي لم يتم اختيارها{" "}
              </div>
              <ol className="p-2">
                {newExpertManagerAnswer?.standardRequirements.map(
                  (requirement, index) => (
                    <li>
                      {requirement.decision == 0 || !requirement.decision
                        ? requirement.title
                        : ""}
                      <ul>
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <>
                              {subRequirement.decision == 0 ||
                              !subRequirement.decision ? (
                                <li className="mx-10">
                                  {subRequirement.title}
                                </li>
                              ) : (
                                ""
                              )}
                            </>
                          )
                        )}
                      </ul>
                    </li>
                  )
                )}
              </ol>
            </div>
          )}
          <CKEditor
            editor={ClassicEditor}
            data={
              // selectedTemplatesForReviewer?.label ||
              newExpertManagerAnswer?.expertManagerSelectedTemplateForReviewer
            }
            onReady={(editor) => {
              setNewExpertManagerAnswer({
                ...newExpertManagerAnswer,
                expertManagerSelectedTemplateForReviewer: editor.getData(),
              });
              // setContent(editor.getData());
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
            config={{
              language: "ar",
              simpleUpload: {
                uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                withCredentials: true,

                headers: {
                  // 'X-CSRF-TOKEN': 'CSRF-Token',
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setNewExpertManagerAnswer({
                ...newExpertManagerAnswer,
                expertManagerSelectedTemplateForReviewer: data,
              });
              console.log(data);
              // setContent(data);
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
          />
        </div> */}
        {/* <div className="text=[#7D8BAB] text-[14px]">
          {standardProgramContext?.standard.programExpertManagerAnswer == null
            ? null
            : standardProgramContext?.standard.expertManagerToReviewerNoteDate}
        </div> */}
      </div>
    </>
  );
};

export default ExpertManagerNoteTemplate;
