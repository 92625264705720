import React, { useState } from "react";
import answer from "./../../../../../../../assets/icons/answer.svg";
import NextBtn from "../../components/buttons/nextBtn";
import { useAnswerContext } from "../../answerContext";
import {
  isAdminFunc,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { showRequirement } from "../utils";
import { NotesAndRecomendation } from "../NotesAndRecomendation";

const NotesForAgency = ({ setMenuElement }) => {
  const { newExpertManagerAnswer, expertManagerAnswer } = useAnswerContext();
  const [selectedRequirementsTab, setSelectedRequirementsTab] = useState("note");

  return (
    <>
      <div className="min-h-[400px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
            ملاحظات و توصيات المعيار
            </h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto scrollbar-track-gray-100">
        <div className="flex  py-5 w-full">
            <p
              className={
                selectedRequirementsTab == "note"
                  ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("note")}
            >
              ملاحظات المعيار
            </p>
            <p
              className={
                selectedRequirementsTab == "recommendation"
                  ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("recommendation")}
            >
              توصيات المعيار
            </p>
          </div>

          {selectedRequirementsTab === "note" && (
            <NotesAndRecomendation
              selectedRequirementsTab={selectedRequirementsTab}
              expertValue={newExpertManagerAnswer?.expertStandardNote}
              experManagertValue={newExpertManagerAnswer?.expertManagerStandardNote}
              reviewerValue={newExpertManagerAnswer?.reviewerStandardNote}
            />
          )}
          {selectedRequirementsTab === "recommendation" && (
            <NotesAndRecomendation
              selectedRequirementsTab={selectedRequirementsTab}
              expertValue={newExpertManagerAnswer?.expertStandardRecommendation}
              experManagertValue={newExpertManagerAnswer?.expertManagerStandardRecommendation}
              reviewerValue={newExpertManagerAnswer?.reviewerStandardRecommendation}
            />
          )}

          {/* <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {expertManagerAnswer?.pocOfficerRequirementAnswers.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.pocOfficerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertManagerAnswer?.pocOfficerNotContent || "",
              }}
            ></div>
            {
              !expertManagerAnswer?.pocOfficerRequirementAnswers?.length &&
              !expertManagerAnswer?.pocOfficerNoteTemplate &&
              !expertManagerAnswer?.pocOfficerNotContent && <div>لا يوجد ملاحظات</div>
            }
          </div> */}
        </div>
      </div>
      {!isAdminFunc() && !isInquiryManager() && (
      <NextBtn onClick={() => setMenuElement(7)} />
      )}
    </>
  );
};

export default NotesForAgency;
