import {Link} from "react-router-dom";
import AppActions from "../../../../../components/appActions";
import UpdateIndicatorModal from "./updateIndicatorModal";
import {useContext, useState} from "react";
import AppDeleteItemModal from "../../../../../components/Models/appDeleteItemModal";
import environment from "../../../../../services/environment";
import {IndicatorsContext} from "../../../../../contexts/indicatorsContext";
import AppMoreButton from "../../../../../components/buttons/appMoreButton";

const IndicatorItem = ({id, title, description, moreLink}) => {
  const {removeDeletedIndicator, getIndicatorList} = useContext(
    IndicatorsContext
  );
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div className="p-2">
      <div
        className="border-[1px] h-full transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.02] duration-300 shadow hover:shadow-lg border-gray-200 rounded-lg  p-4 pb-6">
        <AppActions
          handleDeleteClick={() => setOpenDeleteModal(true)}
          handleEditClick={() => setOpenUpdateModal(true)}
        />

        <UpdateIndicatorModal
          openModal={openUpdateModal}
          setOpenModal={setOpenUpdateModal}
          values={{id, title, description, isActive: true}}
        />
        <AppDeleteItemModal
          id={id}
          getIndicatorList={getIndicatorList}
          modalTitle="حذف مؤشر"
          itemTitle={title}
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          api={environment.deleteIndicator}
          params={{id}}
          removeItemFromList={removeDeletedIndicator}
        />

        <div className="flex flex-col gap-2 justify-between">
          <div className="max-h-[170px] h-[170px]">
            <h3 className="text-blue h-1/3 overflow-hidden"  title={title?.length > 50 ? title : ''}>
              {
                truncateText(title, 50)
              }
              &nbsp;&nbsp;
              {
                title.length > 50 ? <MoreButton onClick={() => setOpenUpdateModal(true)} /> : null
              }
            </h3>
            <p className="text-light h-2/3 overflow-hidden text-sm pt-3" title={description?.length > 50 ? description : ''}>
              {
                truncateText(description, 150) 

              }
              &nbsp;&nbsp;
              {
                description.length > 150 ? <MoreButton onClick={() => setOpenUpdateModal(true)} /> : null
              }
            </p>
          </div>

          <div className="pb-6">
            <Link to={moreLink}>
              <AppMoreButton fullwidth>التفاصيل</AppMoreButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorItem;


function truncateText(text, length) {
  return text.length > length ? text.substring(0, length) + "... " : text;
}

function MoreButton({onClick}) {
  return (
    <button
      onClick={onClick}
      className="text-blue text-sm font-semibold"
    >
      المزيد
    </button>
  );
}
