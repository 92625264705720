import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import NewTooltip from "../../../../../../../components/NewTooltip";
import AppSwitch from "../../../../../../../components/form/appSwitch";

const MainRequirement = ({
  onChange,
  checked,
  hasSubRequirements = true,
  children,
  title,
  requirementDonotApplyChecked,
  handleDonotApplyRequirementChange,
  readOnly = false,
  bgColor = "",
  disableDonotApply = false,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  return (
    <div className="w-full mb-3">
      <div
        className="flex items-center w-full rounded-md px-2 bg-slate-50 "
        style={{ backgroundColor: bgColor }}
      >
        {
          "specialTextDonTShowIt" !== title && 
          <h3 className="text-lg py-2">
            {title}
          </h3>
        }
        {/* <input
          type="checkbox"
          className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-full w-[24px]  h-[24px]"
          onChange={onChange}
          checked={checked}
          disabled={readOnly}
          value={12}
          id={12}
          name={12}
        />
        <div className="flex items-center w-full select-none">
          <label
            className="text-[16px] grow w-[85%] mr-3 mb-2"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {title}
          </label>
          {hasSubRequirements && (
            <div
              className="bg-slate-300 rounded-lg cursor-pointer w-7 h-7 flex items-center justify-center"
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            >
              <FontAwesomeIcon className="text-[#292069]" icon={faAngleDown} />
            </div>
          )}
          <div className="mr-3">
            <NewTooltip content="لا ينطبق" placement="right">
              <AppSwitch
                checked={requirementDonotApplyChecked}
                handleChange={handleDonotApplyRequirementChange}
                disabled={disableDonotApply}
              />
            </NewTooltip>
          </div>
        </div> */}
      </div>

      {children && isAccordionOpen && (
        <div className="px-6 flex flex-col gap-3">
          <div className="mt-1 mb-2 py-1 rounded-md flex flex-col gap-4">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainRequirement;
