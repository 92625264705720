import { toast } from "react-toastify";

export function showRequirement(requirement) {
  // support for requirements without subStandardRequirements

  if(requirement.title === "specialTextDonTShowIt" || requirement.standardRequirementTitle === "specialTextDonTShowIt"){
    return false
  }

  if(requirement.expertSubRequirementAnswers) {
    requirement.subStandardRequirements = requirement.expertSubRequirementAnswers;
  }

  if (
    !requirement.subStandardRequirements ||
    requirement.subStandardRequirements.length === 0
  ) {
    return (
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false
    );
  } else {
    return !!(
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false &&
      requirement.subStandardRequirements.find(
        (subReq) =>
          subReq.donotApply == false &&
          (subReq.decision == 0 || !subReq.decision)
      )
    );
  }
}

export function getCommitmentLevelsBasedOnDegree( degree, commitmentLevels ){
  const commitmentLevel = commitmentLevels.find((level) => level.rangeFrom <= degree && level.rangeTo >= degree);
  return commitmentLevel?.title;
}


let toastId = {moreThan: null, lessThan: null};
export function showRequirementDegreeErrorMessage (isUnAnswered, value) {
  if (isUnAnswered) {
    if (value > 20) {
      if (!toast.isActive(toastId.moreThan)) {
        const id = toast.error("الدرجة لا يمكن أن تكون أكبر من 20");
        toastId = { ...toastId, moreThan: id };
      }
      return true;
    }
    if (value < 1) {
      if (!toast.isActive(toastId.lessThan)) {
        const id = toast.error("الدرجة لا يمكن أن تكون أٌقل من 1");
        toastId = { ...toastId, lessThan: id };
      }
      return true;
    }
  } else {
    if (value > 100) {
      if (!toast.isActive(toastId.moreThan)) {
        const id = toast.error("الدرجة لا يمكن أن تكون أكبر من 100");
        toastId = { ...toastId, moreThan: id };
      }
      return true;
    }
    if (value < 0) {
      if (!toast.isActive(toastId.lessThan)) {
        const id = toast.error("الدرجة لا يمكن أن تكون أٌقل من 0");
        toastId = { ...toastId, lessThan: id };
      }
      return true;
    }
  }

  // Dismiss toasts when value is valid
  // if (toastId.moreThan) {
  //   toast.dismiss(toastId.moreThan);
  // toastId = {...toastId, moreThan: null};
  // }

  // if (toastId.lessThan) {
  //   toast.dismiss(toastId.lessThan);
  // toastId = {...toastId, lessThan: null};
  // }

  return false;
}

export function checkTheDummyDimensionName(name){
  return name === "specialTextDonTShowIt"
}

export function checkForNaN(value){
  return isNaN(value) || value === null || value === undefined || value === "NaN"
}
