import { Badge, Progress } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
// import Card from "../Card";
import NewCard from "../../../components/dashboard/NewCard";
import environment from "../../../services/environment";
import NewTable from "../../../components/NewTable";
import { StageContext } from "../../../contexts/stageContext";
import { formateDate } from "../../../helpers/dateFormat";
import { axiosGetRequest } from "../../../services/Request";
import { surveyId } from "../../../helpers/isPermission";

const EndoscopesCard = ({ title }) => {
  // /api/services/app/AgencyStatistics/GetAgencyPerspectiveStatus
  const { currentStage , stageForDashboard } = useContext(StageContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [agencyPerspectiveStatus, setAgencyPerspectiveStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const agencyId = localStorage.getItem("agencyId");

  const getAgencyPerspectiveStatus = async (page) => {
    setLoading(true);

    const res = await axiosGetRequest(
      environment.getAgencyPerspectiveStatus,
      {
        surveyStageId: stageForDashboard || localStorage?.getItem("stageForDashboard") || 0,
        agencyId: agencyId,
        surveyId: surveyId(),
        MaxResultCount: 5,
        SkipCount: page * 5,
      }
    );

    if (res?.success) {
      setAgencyPerspectiveStatus(res?.result?.items);
      setCount(res?.result?.totalCount);
    }

    setLoading(false);
  };

  useEffect(() => {
    getAgencyPerspectiveStatus(0);
  }, [stageForDashboard]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAgencyPerspectiveStatus(newOffset);
  };

  const statusContent = (status) => {
    switch (status) {
      case 0:
        return (
          <Badge
            className="text-white font-medium bg-[#575757] pb-3 px-5 rounded-xl"
            size="sm"
          >
            لم يبدأ
          </Badge>
        );
      case 1:
        return (
          <Badge
            className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
            size="sm"
          >
            غير مكتمل
          </Badge>
        );
      case 2:
        return (
          <Badge
            className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
            size="sm"
          >
            مكتمل
          </Badge>
        );

      default:
        return (
          <div>
            <span>—</span>
          </div>
        );
    }
  };

  const columns = [
    {
      name: "الاسم",
      selector: (row) => row.perspectiveTitle,
      header: "الاسم",
      accessorKey: "perspectiveTitle",
      footer: (props) => props.column.perspectiveTitle,
      cell: (row) => (
        <div class="whitespace-normal break-words min-w-[350px] max-h-[120px] overflow-auto">
          {row.perspectiveTitle}
        </div>),
    },
    {
      name: "الوصف",
      selector: (row) => row.perspectiveDescription,
      header: "الوصف",
      accessorKey: "perspectiveDescription",
      footer: (props) => props.column.perspectiveDescription,
      cell: (row) => (
        <div class="whitespace-normal break-words min-w-[350px] max-h-[120px] overflow-auto">
          {row.perspectiveDescription}
        </div>),
    },
    {
      name: "آخر تحديث",
      selector: (row) => row.lastAnswerDateOnARelatedStandard,
      header: "آخر تحديث",
      accessorKey: "lastAnswerDateOnARelatedStandard",
      footer: (props) => props.column.lastAnswerDateOnARelatedStandard,
      sortable: true,
      width: '105px',
      cell: (info) =>
        info.lastAnswerDateOnARelatedStandard
          ? formateDate(info.lastAnswerDateOnARelatedStandard)
          : "-",
    },
    {
      name: "الحالة",
      selector: (row) => row.status,
      header: "الحالة",
      accessorKey: "status",
      width: '115px',
      footer: (props) => props.column.status,
      cell: (info) => statusContent(info.status),
      center: true,
    },
    {
      name: "معدل الاكتمال",
      selector: (row) => row.completionPercentage,
      header: "معدل الاكتمال",
      accessorKey: "completionPercentage",
      footer: (props) => props.column.completionPercentage,
      width: '110px',
      cell: (info) => (
        <div class="px-3 py-4 w-[110px] max-w-[110px]">
          <span className="text-sm text-[#A2A2A2]">{`${Math.round(
            info.completionPercentage
          ) || 0}%`}</span>

          <Progress
            theme={{
              color: {
                dark:
                  info.status == "0"
                    ? "bg-red-500"
                    : info.status == "1"
                    ? "bg-[#FFC000]"
                    : "bg-green",
              },
            }}
            color={"dark"}
            progress={info.completionPercentage}
          />
        </div>
      ),
    },
  ];

  return (
    <NewCard title={title || "نسبة الانجاز"}>
      <NewTable
        loading={loading}
        columns={columns}
        data={agencyPerspectiveStatus || []}
        pagination={true}
        totalPages={Math.ceil(count / 5)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default EndoscopesCard;
