import React from "react";
import { axiosPostRequest } from "../../services/Request";
import environment from "../../services/environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import NewTooltip from "../NewTooltip";

const FilePreview = ({ id, fileName, children, withOutToolTip }) => {
  
  const downloadFile = async () => {
    const queryParams = new URLSearchParams();

    queryParams.append("id", id);

    const resp = await axiosPostRequest(
      environment.downloadFile,
      null,
      queryParams,
      true,
      true,
      true
    );

    if (resp) {
      const blob = new Blob([resp], { type: "application/octet-stream" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    }
  };

  if (withOutToolTip) {
    return (
      <div
        role="button"
        tabIndex={0}
        className="cursor-pointer w-full"
        onClick={() => downloadFile()}
      >
        {children}
      </div>
    );
  }


  return (
    <NewTooltip content={"تحميل"}>
      {children ? (
        <div onClick={() => downloadFile()}>{children}</div>
      ) : (
        <FontAwesomeIcon
          icon={faFileDownload}
          className="bg-blue-50   rounded-full text-indigo-500 text-2xl p-2 cursor-pointer"
          onClick={() => downloadFile()}
        />
      )}
    </NewTooltip>
  );
};

export default FilePreview;
