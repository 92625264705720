
import { ifNotDefinedReturnZero } from "../../helpers/utils";

export const PageData = (surveyReports, surveyCommitmentLevelTitle, agencyTitle) => {
  const data = [];
  let startPageNumber = 22;


  surveyReports?.perspectives?.map((perspect, perspectIdx) => {
    perspect?.interlocutors?.map((interloc, interlocIdx) => {
      const perspectiveNum = perspectIdx + 1;
      const interlocutorNum = interlocIdx + 1;


      data.push({
        pageNumber: startPageNumber,
        sectionNumber: "5.1",
        pageTitle: `القدرة : ${perspectiveNum}. ${perspect.perspectiveTitle}`,
        agencyTitle: agencyTitle,
        level: perspect.commitmentLevelTitle,
        percentage: ifNotDefinedReturnZero(perspect.userPercentagesAvg)?.toFixed(2),
        property: perspect.perspectiveTitle,
        summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${surveyCommitmentLevelTitle}  في قدرة البحث ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "البحث" تمركزت حول عنصرين فرعيين هما: "الاتجاهات والتقنيات" و"القيمة والمخاطر المحتملة", ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
        elementsCode: `${perspectiveNum}.${interlocutorNum} `,
        elementsTitle: interloc.interlocutorTitle,
        elementsSubject:
          "يُعنى هذا العنصر بتكثيف الاستكشاف والتحليل لفرص التقنيات الناشئة من خلال توثيق مجموعة واسعة من الفوائد والمخاطر المحتملة، ويشتمل على معيارين فرعيين.​​",
        standardData: interloc.standards,
        perspectiveExpertNotes: perspect.perspectiveExpertNotes,
        interlocutorExpertNotes: interloc.interlocutorExpertNotes,
      });

      startPageNumber++;
    })
  })

  return data;
}




// return [
//   //page 22
//   {
//     pageNumber: 22,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الاولي :  ",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level}  في قدرة البحث ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "البحث" تمركزت حول عنصرين فرعيين هما: "الاتجاهات والتقنيات" و"القيمة والمخاطر المحتملة", ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
//     elementsSubject:
//       "يُعنى هذا العنصر بمتابعة عدة أنواع من الاتجاهات (العميل، أو المنافس، أو السوق، أو التقنيات) والتقنيات الناشئة، ويشتمل على (3) معايير فرعية.​",
//     standardData: [
//       {
//         title: "تركيز الموارد",
//         precentage: "10%",
//         color: "#03ABAF",
//       },
//       {
//         title: "التعاون بين مختلف الاقسام",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//       {
//         title: "تحديد الفرص",
//         precentage: "90%",
//         color: "#03ABAF",
//       },
//     ],
//   },
//   //page 23
//   {
//     pageNumber: 23,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الاولي : البحث ",
//     agencyTitle: "الخارجيه",
//     agencyLevel: "جيد",
//     agencyPercentage: "70%",
//     property: "البحث",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level}  في قدرة البحث ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "البحث" تمركزت حول عنصرين فرعيين هما: "الاتجاهات والتقنيات" و"القيمة والمخاطر المحتملة", ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
//     elements: "القيمة والمخاطر المحتملة​",
//     elementsSubject:
//       "يُعنى هذا العنصر بتكثيف الاستكشاف والتحليل لفرص التقنيات الناشئة من خلال توثيق مجموعة واسعة من الفوائد والمخاطر المحتملة، ويشتمل على معيارين فرعيين.​​",
//     standardData: [
//       {
//         title: "تحليل القيمة",
//         precentage: "10%",
//         color: "#2A206A",
//       },
//       {
//         title: "تحليل المخاطر",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//     ],
//   },
//   //page 24
//   {
//     pageNumber: 24,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثانية: التواصل ",
//     agencyTitle: "الخارجيه",
//     agencyLevel: "جيد",
//     agencyPercentage: "70%",
//     property: "التواصل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level}  في قدرة البحث ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "البحث" تمركزت حول عنصرين فرعيين هما: "الاتجاهات والتقنيات" و"القيمة والمخاطر المحتملة", ويدعم ذلك تقييم المختصين لهذه القدرات وتحليل البيانات المستلمة بخصوصها. ​`,
//     elements: "التثقيف",
//     elementsSubject:
//       "عنى هذا العنصر بالتوسع في الفئة المستهدفة، وإيصال نتائج الأبحاث التي تسعى إلى نقل المعرفة وتحفيز  المشاركة، ويشتمل على معيارين فرعيين ​​​",
//     standardData: [
//       {
//         title: "تنوّع المجتمع​",
//         precentage: "10%",
//         color: "#2A206A",
//       },
//       {
//         title: "رصد الفرص المتحمورة حول المجتمع",
//         precentage: "10%",
//         color: "#03ABAF",
//       },
//     ],
//   },
//   //page 25
//   {
//     pageNumber: 25,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثانية: التواصل ",
//     agencyTitle: "الخارجيه",
//     agencyLevel: "جيد",
//     agencyPercentage: "70%",
//     property: "التواصل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة التواصل ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "التواصل" تمركزت حول عنصرين فرعيين هما: "التثقيف" و"أصحاب المصلحة"، ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها. ​`,
//     elements: "أصحاب المصلحة​",
//     elementsSubject:
//       "يُعنى هذا العنصر بتكثيف الاستكشاف والتحليل لفرص التقنيات الناشئة من خلال توثيق مجموعة واسعة من الفوائد والمخاطر المحتملة، ويشتمل على (3) معايير فرعية.​ ​​​",
//     standardData: [
//       {
//         title: "تحديد اصحاب المصلحة / الشركاء",
//         precentage: "40%",
//         color: "#03ABAF",
//       },
//       {
//         title: "تحديد اصحاب المصلحة / الشركاء",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//       {
//         title: "مشاركة التحديثات ع اصحاب المصلحة /الشركاء",
//         precentage: "10%",
//         color: "#03ABAF",
//       },
//     ],
//   },
//   //page 26
//   {
//     pageNumber: 26,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثالثة: الإثبات ",
//     agencyTitle: "الداخليه",
//     agencyLevel: "سئ",
//     agencyPercentage: "10%",
//     property: "الإثبات",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "إثبات التقنية​​",
//     elementsSubject:
//       "يُعنى هذا العنصر بتكثيف الاستكشاف والتحليل لفرص التقنيات الناشئة من خلال توثيق مجموعة واسعة من الفوائد والمخاطر المحتملة، ويشتمل على (3) معايير فرعية.​ ​​​",
//     standardData: [
//       {
//         title: "تجربة التقنيات​",
//         precentage: "40%",
//         color: "#1AC082",
//       },
//       {
//         title: "الاثبات التدريجي للتقنيات",
//         precentage: "60%",
//         color: "#1AC082",
//       },
//     ],
//   },
//   //page 27
//   {
//     pageNumber: 27,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثالثة: الإثبات ",
//     agencyTitle: "الداخليه",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "60%",
//     property: "الإثبات",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "توافق المواهب البشرية والثقافة​",
//     elementsSubject:
//       "إلقاء نظرة شاملة على مدى استعداد ثقافة الجهة ومواردها البشرية لاستيعاب قدرات التقنيات الحديثة.، ويشتمل على معيار فرعي واحد​​",
//     standardData: [
//       {
//         title: "تقيم الثاقفة التقنية",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//     ],
//   },
//   //page 28
//   {
//     pageNumber: 28,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثالثة: الإثبات ",
//     agencyTitle: "التموين",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "100%",
//     property: "الإثبات",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "توافق العمليات والبنية ​​",
//     elementsSubject:
//       "تقييم التغيرات في الإجراءات والبنية والتكاليف التي قد تكون ضرورية للاستفادة من فرصة التقنيات الناشئة. ويشتمل على معيارين فرعيين​​",
//     standardData: [
//       {
//         title: "تقيم التقنيات من حيث طبيعة الاعمال ",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//       {
//         title: "تقييم التقنيات من حيث البينة",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//     ],
//   },
//   //page 29
//   {
//     pageNumber: 29,
//     sectionNumber: "5.1",
//     pageTitle: " القدرة الثالثة: الإثبات ",
//     agencyTitle: "القضاء",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "100%",
//     property: "الإثبات",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ​ ​​",
//     elementsSubject:
//       "تقييم مدى جاهزية الجهة لتبني تطبيقات الذكاء الاصطناعي. يتضمن ذلك تقييم البنية التحتية الحالية، مهارات القوى العاملة، والموارد المتاحة لضمان تكامل التقنيات، وتقليل التحديات، وتمكين الجهة من الابتكار والنمو، ويشتمل على (4) معايير فرعية.​​",
//     standardData: [
//       {
//         title: "الامتثال التنظيمي و الأخلاقي",
//         precentage: "40%",
//         color: "#002060",
//       },
//       {
//         title: "التوفر والجاهزية",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//       {
//         title: "البنية التحيتة و الموارد",
//         precentage: "10%",
//         color: "#002060",
//       },
//       {
//         title: "التوعية و التثقيف",
//         precentage: "40%",
//         color: "#002060",
//       },
//     ],
//   },
//   //page 30
//   {
//     pageNumber: 30,
//     sectionNumber: "5.1",
//     pageTitle: "القدرة الرابع: التكامل",
//     agencyTitle: "القضاء",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "90%",
//     property: "التكامل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "الابتكار المخصّص​​​​",
//     elementsSubject:
//       "دمج مخرجات البحث والتقييم في مجال التقنيات الناشئة في أعمال الابتكار المخصصة التي تجري في كامل الجهة، ويشتمل على معيار فرعي واحد.​​​",
//     standardData: [
//       {
//         title: "تحقق المنفعة علي المدي القريب ",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//     ],
//   },
//   //page 31
//   {
//     pageNumber: 31,
//     sectionNumber: "5.1",
//     pageTitle: "القدرة الرابع: التكامل",
//     agencyTitle: "القضاء",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "90%",
//     property: "التكامل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "الابتكار الاستراتيجي​​​​​",
//     elementsSubject:
//       "دمج أبحاث التقنيات الناشئة وتقييمها في جهود الابتكار الاستراتيجي، ويشتمل على معيارين فرعيين. ​​​​",
//     standardData: [
//       {
//         title: "مستهدفات للتقنيات الناشئة",
//         precentage: "40%",
//         color: "#2A206A",
//       },
//       {
//         title: "استراتيجية التقنيات الناشئة ",
//         precentage: "40%",
//         color: "#2A206A",
//       },
//     ],
//   },
//   //page 32
//   {
//     pageNumber: 32,
//     sectionNumber: "5.1",
//     pageTitle: "القدرة الرابع: التكامل",
//     agencyTitle: "السياحة",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "90%",
//     property: "التكامل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "الابتكار بدافع تقني​​​​​​",
//     elementsSubject:
//       "دعم تطور فرص التقنيات الناشئة إلى أعمال ابتكارية تعتمد على التقنية، وتستهدف الفرص التي أصبحت ممكنة بفضل التقنيات الناشئة المبنية على احتياج العملاء، ويشتمل على معيار فرعي واحد.​​​​​",
//     standardData: [
//       {
//         title: "قابلية الابتكار",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//     ],
//   },
//   //page 33
//   {
//     pageNumber: 33,
//     sectionNumber: "5.1",
//     pageTitle: "القدرة الرابع: التكامل",
//     agencyTitle: "السياحة",
//     agencyLevel: "ممتاز",
//     agencyPercentage: "90%",
//     property: "التكامل",
//     summary: `تمكنت ${agencyTitle} من تحقيق مستوى ${level} في قدرة الإثبات ضمن إطار قدرات مؤشر جاهزية تبني التقنيات الناشئة. وأبرز مرئيات مرحلة تقييم قدرة "الإثبات" تمركزت حول (4) عناصر فرعية هي: "إثبات التقنية" و"توافق المواهب البشرية والثقافة" و"توافق العمليات والبنية "و" الجاهزية للتقنيات السائدة (الذكاء الاصطناعي) ", ويدعم ذلك تقييم المختصين لهذه القدرة، وتحليل البيانات المستلمة بخصوصها.. ​​`,
//     elements: "التبني والاستخدام للتقنيات السائدة (الذكاء الاصطناعي) ​​​​​​​",
//     elementsSubject:
//       "​قياس مستوى تبني واستخدام الذكاء الاصطناعي. تتبع عملية الدمج في العمليات، وسرعة ومدى التبني، وتأثيراتها على الكفاءة والتنافسية. كما يجب تقييم تفاعل المستخدمين لضمان التوافق مع الأهداف طويلة المدى.، ويشتمل على (3) معايير فرعية.​​​​​",
//     standardData: [
//       {
//         // title: "قابلية الابتكار",
//         title: "حوكمة البيانات، إدارة البيانات، والاستخدام الأخلاقي​",
//         precentage: "40%",
//         color: "#A6A6A6",
//       },
//       {
//         title: "الاستثمار واستراتيجية التقنية​",
//         precentage: "40%",
//         color: "#7C32C9",
//       },
//       {
//         title: "تبني التقنيات ودمجها​",
//         precentage: "40%",
//         color: "#A6A6A6",
//       },
//     ],
//   },
//   ]

