import {t} from "i18next";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";

import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import {serveySettingTabs} from "../data";
import {Title} from "../../../../../components";
import {programsIcon} from "../../../../../assets/icons/Index";
import {get, post} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import NewTable from "../../../../../components/NewTable";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {formatDate, formatTime} from "../../../../../helpers/utils";
import { current } from "immer";

const PolicesAndConditions = () => {
  const {surveyId} = useParams();
  const history = useHistory();
  const [content, setContent] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [users, setUsers] = useState([
    {id: 1, userName: "احمد", agency: "جهة ١"},
    {id: 2, userName: "محمد", agency: "جهة ٢"},
  ]);

  const [agenciesTerms, setAgenciesTerms] = useState([]);

  useEffect(() => {
    getAllAgencyTerms(0);
  }, []);

  const getAllAgencyTerms = (page) => {
    console.log(page)
    // setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgenciesTerms + "?SkipCount=" +
    page * 6 +
    "&MaxResultCount=" + 6, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        console.log(res);
        //  let newStandards = []
        //  res.data.result.items.forEach((standard,index)=>{
        //    let obj = {label:standard.title,id:standard.id,value:standard.id}
        //    newStandards.push(obj)
        //  })
        setAgenciesTerms(res.data.result.items);
        setTotalPages(Math.round(res.data.result.totalCount / 6))
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const res = post(
      environment.SetSurveyTerms,
      JSON.stringify(content),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          contentType: "application/json",
        },
        params: {
          surveyId,
        },
      },
      (res) => {
        console.log(res, "post ");
      }
    );
    console.log(res, "resss");
    if (res?.success == true) {
      console.log("success");
    }
  };

  const handlePageChange = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllAgencyTerms(newOffset);
  };

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"اعدادات الاستبیان"}
            thired={true}
            subTitleSeconed={"السياسات والأحكام"}
            toPageSeconed={() => history.push(`/indicators/${localStorage.getItem("NavIndicatorId") || null}`)}
            // toPageSeconed={() => history.push("/surveys")}
            title={t("المؤشرات")}
            withoutButton={true}
            toPage={() => history.push(`/indicators`)}
          />
        </div>
      }
    >
      {/* <div className="flex justify-end mb-5">
         <Button title={"إضافة شروط و احكام"} style={{width:'200px'}} onClick={()=>history.push(`/surveys/${surveyId}/settings/polices/add`)}/>
         </div> */}

      <div className="bg-gray_light p-6 flex justify-between items-center rounded-lg">
        <h3 className="text-blue_text text-lg">{t("السياسات والأحكام")}</h3>
        <div className="flex justify-end flex-grow">
          <button
            className="text-white py-2 px-3 min-w-[140px] pt-1 mt-0 rounded-full bg-blue hover:bg-blue_text"
            onClick={() =>
              history.push(`/surveys/${surveyId}/settings/polices/add`)
            }
          >
            {t("تعديل الشروط و احكام")}
          </button>
        </div>
      </div>
      <NewTable
        data={agenciesTerms}
        columns={[
          {
            name: "اسم المستخدم",
            accessorKey: "creatorUser",
            selector: (row) => row.creatorUser,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#108AE0]">
                {info.creatorUser?.fullName}
              </div>
            ),
          },
          {
            name: "أسم الجهة",
            accessorKey: "agency",
            selector: (row) => row.agency,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {info.agency?.nameInArabic}
              </div>
            ),
          },
          {
            name: "أسم الشركة",
            accessorKey: "companyName",
            selector: (row) => row.companyName,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {info.companyName == null ? "لا يوجد" : info.companyName}
              </div>
            ),
          },
          {
            name: "الحالة",
            accessorKey: "status",
            selector: (row) => row.user,
            cell: (info) => (
              <div className="text-sm text-[#1CC081]">
                {info.creatorUser == null ? "غير موافق" : "وافق"}
              </div>
            ),
            sortable: true,
          },
          {
            name: "التاريخ",
            accessorKey: "creationTime",
            selector: (row) => row.creationTime,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {formatDate(new Date(info.creationTime)) +
                  "  " +
                  formatTime(new Date(info.creationTime))}
              </div>
            ),
            sortable: true,
          },
        ]}
        // selectableRows={true}
        // onSelectedRowsChange={handleChangeEntitiesFilter}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        
      />
      {/* <TestTimeline /> */}
      {/* <div className="mb-3">
        <p className="mb-3">السياسات والاحكام</p>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onReady={(editor) => {
            setContent(editor.getData());
            editor.plugins.get("FileRepository").createUploadAdapter = function(
              loader
            ) {
              console.log(loader);
              return new UploadAdapter(loader);
            };
          }}
          config={{
            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,

              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
            editor.plugins.get("FileRepository").createUploadAdapter = function(
              loader
            ) {
              console.log(loader);
              return new UploadAdapter(loader);
            };
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />

        <div className="mt-3 text-end">
          <AppButton onClick={handleSubmit}>حفظ</AppButton>
        </div>
      </div> */}
    </SecondaryTapsLayout>
  );
};

export default PolicesAndConditions;
