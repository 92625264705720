export function processAnswerHistory(answerHistory) {
    if (answerHistory?.surveyExpertAnswerHistory?.length) {
      return answerHistory.surveyExpertAnswerHistory.map(surveyAnswer => {
        const surveyStandardRequirementAnswer = surveyAnswer.expertStandardRequirementAnswerHistory.map(surveyRequire => {
          const surveySubRequirementAnswers = surveyRequire.expertSubStandardRequirementAnswersHistory.map(surveySubReq => {
            return {
              ...surveySubReq,
              expertSubRequirementAnswerSides: surveySubReq.expertSubRequirementAnswerSideHistory,
            };
          });
          return {
            ...surveyRequire,
            expertSubRequirementAnswers: surveySubRequirementAnswers,
          };
        });
        return {
          ...surveyAnswer,
          expertRequirementAnswers: surveyStandardRequirementAnswer,
        };
      });
    }
    return [];
  }
  
