import { Input } from "../../../../../../../../components";
import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import { getCommitmentLevelsBasedOnDegree, showRequirementDegreeErrorMessage } from "../../utils";
import { TextInput } from "flowbite-react";
import UploadFile from "../../UploadFile";

export function EditStandardRequirementsBasedOnStandard({
    requirements,
    commitmentLevels,
    label,
    from,
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
    hasAnswer,
    isReadOnlyView
}) {
    
    // check if expert / reviewer has answer...
    const isReadOnly = !(from === 'expert' ? newExpertManagerAnswer?.expertLastAnsweredId : newExpertManagerAnswer?.reviewerLastAnsweredId);

    const handleSubRequirementChange = (
        requirement,
        subReqId,
        property = "note",
        sideId,
        value,
        isUnAnswered,
        from
    ) => {

        const isNotValid = property == "evaluationDegree" && showRequirementDegreeErrorMessage(isUnAnswered, value);
        if (isNotValid) return;

        const standardRequirements = from === 'expert' ? 'expertRequirementsAnswer' : 'reviewerRequirementsAnswer';
        const sides = from === 'expert' ? 'expertSubRequirementAnswerSides' : 'reviewerSubRequirementAnswerSides';

        const updatedSubRequirements =
            newExpertManagerAnswer?.[standardRequirements]?.map((item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                if (property === "isReturnable" || property === "reason") {
                                    return {
                                        ...subRequire,
                                        [property]: value,
                                    }
                                }

                                return {
                                    ...subRequire,
                                    [sides]: subRequire?.[sides]?.map((side) => {
                                        if (sideId === side?.id) {
                                            return {
                                                ...side,
                                                [property]: value,
                                            };
                                        }

                                        return side;
                                    }),
                                };
                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            });

        setNewExpertManagerAnswer({
            ...newExpertManagerAnswer,
            [standardRequirements]: updatedSubRequirements,
        });
    };


    return (
        !hasAnswer ? (<h2 className="text-2xl pb-2">لا يوجد إجابة حتى الآن</h2>)
            :
            requirements?.length > 0 &&
            requirements?.map((requirement) => {
                const hasSubRequirements =
                    requirement?.subStandardRequirements?.length > 0;
                // TODO : remove this side and fix it
                const side = {}
                return (
                    <>
                        <MainRequirement
                            key={requirement?.id}
                            title={requirement?.title}
                            hasSubRequirements={hasSubRequirements}
                            checked={requirement?.decision}
                            requirementDonotApplyChecked={requirement?.donotApply}
                            onChange={() => { }}
                            handleDonotApplyRequirementChange={() => { }}
                        >
                            {hasSubRequirements &&
                                requirement?.subStandardRequirements?.map((subRequirement) => {
                                    const isUnAnswered = (subRequirement?.isUnAnswered || subRequirement?.isUnAnswered === null);

                                    return (
                                        <>
                                            <div className="px-5 flex flex-col mb-3 py-2">
                                                <div className="flex flex-row gap-2 mb-3 py-2">
                                                    <h2 className="text-xl pb-2">{subRequirement.title}</h2>
                                                </div>
                                                <div className="flex flex-row gap-2 mb-3 py-2">
                                                    <Input
                                                        type="checkbox"
                                                        className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                                                        id={subRequirement.value}
                                                        name={"ارجاع الى الجهة"}
                                                        label={""}
                                                        value={subRequirement.isReturnable}
                                                        checked={subRequirement.isReturnable}
                                                        disabled={isReadOnly || isReadOnlyView}
                                                        onChange={(e) => {
                                                            handleSubRequirementChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "isReturnable",
                                                                side.id,
                                                                e.target.checked,
                                                                isUnAnswered,
                                                                from
                                                            );
                                                        }}
                                                    />
                                                    <span>معاد للجهة</span>
                                                </div>

                                                {subRequirement?.isReturnable && (
                                                    <div className="flex gap-2 w-full items-center mb-4">
                                                        <span className="w-32">ملاحظة الى الجهة</span>
                                                        <TextInput
                                                            value={subRequirement?.reason}
                                                            className="grow"
                                                            placeholder="ملاحظة الى الجهة"
                                                            disabled={isReadOnly || isReadOnlyView}
                                                            onChange={(e) => {
                                                                handleSubRequirementChange(
                                                                    requirement,
                                                                    subRequirement?.id,
                                                                    "reason",
                                                                    side.id,
                                                                    e.target.value,
                                                                    isUnAnswered,
                                                                    from
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                <div className="flex flex-col gap-4">
                                                    <>
                                                        {from === "reviewer" &&
                                                            subRequirement?.reviewerSubRequirementAnswerSides?.map(
                                                                (side, index) => {
                                                                    return (
                                                                        <div className="border-b pb-2">
                                                                            <h3 className="text-xl pb-3">
                                                                                {side.title}
                                                                            </h3>
                                                                            <div className=" flex items-center gap-2 mb-4">
                                                                                <span className="w-24">
                                                                                    {label || "تقييم المدقق"}
                                                                                </span>
                                                                                <TextInput
                                                                                    value={side?.evaluationDegree}
                                                                                    type="number"
                                                                                    placeholder=""
                                                                                    min={isUnAnswered ? 1 : 0}
                                                                                    max={isUnAnswered ? 20 : 100}
                                                                                    disabled={isReadOnly || isReadOnlyView}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "evaluationDegree",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <label>
                                                                                    {getCommitmentLevelsBasedOnDegree(
                                                                                        Number(side?.evaluationDegree),
                                                                                        commitmentLevels
                                                                                    )}
                                                                                </label>
                                                                            </div>
                                                                            <div className="flex gap-2 w-full items-center mb-4">
                                                                                <span className="w-24"> الملاحظة</span>
                                                                                <TextInput
                                                                                    value={side?.evaluationNote}
                                                                                    className="grow"
                                                                                    placeholder="ملاحظة المدقق للجهة"
                                                                                    disabled={isReadOnly || isReadOnlyView}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "evaluationNote",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="flex gap-2 w-full items-center">
                                                                                <span className="w-24">التوصية</span>
                                                                                <TextInput
                                                                                    value={side?.recommendation}
                                                                                    className="grow"
                                                                                    placeholder="توصية المدقق للجهة"
                                                                                    disabled={isReadOnly || isReadOnlyView}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "recommendation",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <UploadFile
                                                                                value={side.attachmentId}
                                                                                readOnly={isReadOnly || isReadOnlyView}
                                                                                withOutToolTip
                                                                                label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                                                                onChange={(value) => {
                                                                                    handleSubRequirementChange(
                                                                                        requirement,
                                                                                        subRequirement?.id,
                                                                                        "attachmentId",
                                                                                        side.id,
                                                                                        value,
                                                                                        isUnAnswered,
                                                                                        from
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        {from === "expert" &&
                                                            subRequirement?.expertSubRequirementAnswerSides?.map(
                                                                (side, index) => {
                                                                    return (
                                                                        <div className="border-b pb-2">
                                                                            <h3 className="text-xl pb-3">
                                                                                {side.title}
                                                                            </h3>
                                                                            <div className=" flex items-center gap-2 mb-4">
                                                                                <span className="w-24">
                                                                                    {label || "تقييم الخبير"}
                                                                                </span>
                                                                                <TextInput
                                                                                    value={side?.evaluationDegree}
                                                                                    type="number"
                                                                                    placeholder=""
                                                                                    min={isUnAnswered ? 1 : 0}
                                                                                    max={isUnAnswered ? 20 : 100}
                                                                                    disabled={isReadOnly}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "evaluationDegree",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <label>
                                                                                    {getCommitmentLevelsBasedOnDegree(
                                                                                        Number(side?.evaluationDegree),
                                                                                        commitmentLevels
                                                                                    )}
                                                                                </label>
                                                                            </div>
                                                                            <div className="flex gap-2 w-full items-center mb-4">
                                                                                <span className="w-24"> الملاحظة</span>
                                                                                <TextInput
                                                                                    value={side?.evaluationNote}
                                                                                    className="grow"
                                                                                    placeholder="ملاحظة الخبير للجهة"
                                                                                    disabled={isReadOnly}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "evaluationNote",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="flex gap-2 w-full items-center">
                                                                                <span className="w-24">التوصية</span>
                                                                                <TextInput
                                                                                    value={side?.recommendation}
                                                                                    className="grow"
                                                                                    placeholder="توصية الخبير للجهة"
                                                                                    disabled={isReadOnly}
                                                                                    onChange={(e) => {
                                                                                        handleSubRequirementChange(
                                                                                            requirement,
                                                                                            subRequirement?.id,
                                                                                            "recommendation",
                                                                                            side.id,
                                                                                            e.target.value,
                                                                                            isUnAnswered,
                                                                                            from
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <UploadFile
                                                                                value={side.attachmentId}
                                                                                withOutToolTip
                                                                                label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                                                                onChange={(value) => {
                                                                                    handleSubRequirementChange(
                                                                                        requirement,
                                                                                        subRequirement?.id,
                                                                                        "attachmentId",
                                                                                        side.id,
                                                                                        value,
                                                                                        isUnAnswered,
                                                                                        from
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                        </MainRequirement>
                    </>
                );
            })
    );
}
