import { useQuery } from "@tanstack/react-query";
import { axiosPostRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";
import { surveyId } from "../../../../helpers/isPermission";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

export const useGetVisitors = () => {
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingVisitors } = useQuery({
    queryKey: ["Visitors", phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.getDashboardVisitors,
        {
          maxCount: 5,
          surveyStageId: phaseOneStageId,
          surveyId: +ifNotDefinedReturnZero(surveyId()),
        },
        null,
        true,
        true,
        false,
        signal
      ),
  });

  const visitorsData = data?.result;

  return { data, visitorsData, isGettingVisitors };
};
