
const LoginLogo = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 642.22 218.97"
    {...props}
  >
    <g>
      <path
        d="m330.09,58.91c-3.7,0-6.52-.83-8.47-2.48-1.94-1.65-2.91-4-2.91-7.05s.92-6.28,2.76-9.93l4.81,1.89c-.95,2.6-1.42,4.65-1.42,6.14,0,3.18,2.18,4.77,6.54,4.77,3.52,0,5.89-.75,7.13-2.24-2.1-.42-4.11-.97-6.03-1.66l1.22-6.1c3.67.89,7.21,1.34,10.59,1.34h.63v7.01h-1.38c-.52,2.49-1.95,4.5-4.27,6.03-2.33,1.52-5.39,2.28-9.2,2.28Zm3.86,8.31l-3.27-2.68-2.48,2.8-3.82-3.15,3.11-3.7,3.27,2.8,2.48-2.91,3.86,3.31-3.15,3.54Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m344.94,50.59c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h9.06v7.01h-9.06Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m354,50.59c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h.59c1.76,0,2.93-.19,3.5-.57.58-.38.87-.91.87-1.6v-5.75l5.83-.4v5.44c0,.97-.05,1.79-.16,2.44,1.29.29,2.78.43,4.49.43h.24v7.01h-1.34c-2.65,0-4.75-.55-6.3-1.65-1.5,1.1-3.65,1.65-6.46,1.65h-1.26Zm3.47-24.42l4.25,3.66-3.46,3.94-4.25-3.5,3.47-4.1Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m369.36,50.59c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h9.06v7.01h-9.06Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m378.42,50.59c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h.59c1.76,0,2.93-.19,3.5-.57.58-.38.87-.91.87-1.6v-5.75l5.83-.4v5.44c0,.97-.05,1.79-.16,2.44,1.29.29,2.78.43,4.49.43h.24v7.01h-1.34c-2.65,0-4.75-.55-6.3-1.65-1.5,1.1-3.65,1.65-6.46,1.65h-1.26Zm2.36,2.36l4.25,3.66-3.47,3.94-4.25-3.51,3.47-4.1Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m393.78,50.59c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h9.06v7.01h-9.06Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m409.61,32.24l-3.27-2.68-2.48,2.8-3.82-3.15,3.11-3.7,3.27,2.8,2.48-2.91,3.86,3.31-3.15,3.54Zm-6.77,18.35c-.39,0-.73-.34-1.01-1.02-.27-.68-.41-1.51-.41-2.48s.14-1.8.41-2.48c.28-.68.61-1.03,1.01-1.03h.31c1.71,0,3.14-.13,4.29-.39-.1-2.97-.48-5.84-1.14-8.63l5.71-1.22c.52,2.42.79,5.03.79,7.84,0,3.07-.32,5.55-.95,7.44-.97.52-2.28.98-3.94,1.38-1.66.39-3.28.59-4.88.59h-.2Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
    </g>
    <g>
      <path
        d="m23.82,188.77v3.54H7.28v-27.57h16.54v3.54h-12.44v8.15h10.67v3.55h-10.67v8.78h12.44Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m32.88,176.24v16.07h-3.98v-18.59c2.62-1.24,5.43-1.85,8.43-1.85,2.39,0,4.27.6,5.63,1.81,2.18-1.21,4.42-1.81,6.74-1.81,2.65,0,4.67.67,6.05,2.01,1.38,1.34,2.07,3.21,2.07,5.59v12.84h-3.98v-12.56c0-3.02-1.43-4.53-4.29-4.53-1.76,0-3.33.46-4.73,1.38.34,1,.51,2.19.51,3.58v12.13h-3.9v-12.56c0-3.02-1.42-4.53-4.25-4.53-1.55,0-2.98.34-4.29,1.02Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m79.75,191.41c-1.97.92-4.23,1.38-6.77,1.38-3.18,0-5.7-.93-7.58-2.78-1.88-1.85-2.82-4.42-2.82-7.7,0-3.1.85-5.61,2.56-7.54,1.71-1.93,3.99-2.89,6.85-2.89,2.55,0,4.56.81,6.05,2.44s2.22,3.73,2.22,6.3c0,.76-.05,1.64-.16,2.64h-13.43c.4,4.1,2.61,6.14,6.66,6.14,1.92,0,3.83-.41,5.75-1.22l.67,3.23Zm-7.76-16.27c-1.52,0-2.74.49-3.64,1.48s-1.45,2.34-1.63,4.08h9.65c.02-.16.04-.37.04-.63,0-1.42-.37-2.59-1.12-3.52-.75-.93-1.84-1.4-3.29-1.4Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m85.3,173.76c2.26-1.26,5.02-1.89,8.27-1.89,1.02,0,1.95.09,2.8.27l-.55,3.35c-.55-.18-1.21-.27-1.97-.27-1.76,0-3.28.34-4.57,1.02v16.07h-3.98v-18.55Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m106.85,199.83c-2.44,0-4.63-.38-6.58-1.14l.83-3.23c1.71.66,3.54.98,5.51.98,1.73,0,3.06-.38,3.98-1.14.92-.76,1.38-1.82,1.38-3.19v-1.66c-1.31.68-2.76,1.03-4.33,1.03-2.83,0-5.07-.83-6.71-2.48-1.64-1.66-2.46-3.96-2.46-6.93,0-3.12.92-5.61,2.76-7.44,1.84-1.84,4.29-2.76,7.37-2.76,2.7,0,5.16.52,7.36,1.57v18.31c0,2.57-.81,4.56-2.42,5.97s-3.84,2.11-6.68,2.11Zm-4.33-18.08c0,1.97.5,3.52,1.5,4.65,1,1.13,2.39,1.69,4.17,1.69,1.45,0,2.7-.34,3.78-1.02v-11.23c-1.15-.42-2.28-.63-3.39-.63-1.86,0-3.34.59-4.43,1.75-1.09,1.17-1.63,2.76-1.63,4.78Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m124.18,168.88c-.79,0-1.4-.22-1.83-.67s-.65-1.04-.65-1.77.22-1.28.67-1.71,1.05-.65,1.81-.65,1.36.22,1.79.65.65,1.01.65,1.71-.21,1.33-.65,1.77-1.03.67-1.79.67Zm2.01,3.46v19.97h-3.98v-19.97h3.98Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m136.39,192.31h-3.98v-18.59c2.62-1.24,5.49-1.85,8.59-1.85,2.73,0,4.82.66,6.26,1.99,1.44,1.33,2.17,3.2,2.17,5.61v12.84h-3.98v-12.56c0-3.02-1.49-4.53-4.45-4.53-1.71,0-3.24.34-4.61,1.02v16.07Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m162.58,199.83c-2.44,0-4.63-.38-6.58-1.14l.83-3.23c1.71.66,3.54.98,5.51.98,1.73,0,3.06-.38,3.98-1.14.92-.76,1.38-1.82,1.38-3.19v-1.66c-1.31.68-2.76,1.03-4.33,1.03-2.83,0-5.07-.83-6.71-2.48-1.64-1.66-2.46-3.96-2.46-6.93,0-3.12.92-5.61,2.76-7.44,1.84-1.84,4.29-2.76,7.37-2.76,2.7,0,5.16.52,7.36,1.57v18.31c0,2.57-.81,4.56-2.42,5.97s-3.84,2.11-6.68,2.11Zm-4.33-18.08c0,1.97.5,3.52,1.5,4.65,1,1.13,2.39,1.69,4.17,1.69,1.45,0,2.7-.34,3.78-1.02v-11.23c-1.15-.42-2.28-.63-3.39-.63-1.86,0-3.34.59-4.43,1.75-1.09,1.17-1.63,2.76-1.63,4.78Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m202.48,168.29h-7.92v24.03h-4.1v-24.03h-7.92v-3.54h19.93v3.54Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m219.57,191.41c-1.97.92-4.23,1.38-6.77,1.38-3.18,0-5.7-.93-7.58-2.78-1.88-1.85-2.82-4.42-2.82-7.7,0-3.1.85-5.61,2.56-7.54,1.71-1.93,3.99-2.89,6.85-2.89,2.55,0,4.56.81,6.05,2.44s2.22,3.73,2.22,6.3c0,.76-.05,1.64-.16,2.64h-13.43c.4,4.1,2.61,6.14,6.66,6.14,1.92,0,3.83-.41,5.75-1.22l.67,3.23Zm-7.76-16.27c-1.52,0-2.74.49-3.64,1.48s-1.45,2.34-1.63,4.08h9.65c.02-.16.04-.37.04-.63,0-1.42-.37-2.59-1.12-3.52-.75-.93-1.84-1.4-3.29-1.4Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m233.56,171.87c2.15,0,4.08.39,5.79,1.18l-.79,3.19c-1.63-.68-3.21-1.02-4.73-1.02-1.89,0-3.35.64-4.39,1.91-1.04,1.27-1.55,3.01-1.55,5.22s.54,4.07,1.61,5.28,2.65,1.81,4.73,1.81c1.5,0,3.01-.37,4.53-1.1l.71,3.23c-1.57.81-3.41,1.22-5.51,1.22-3.21,0-5.69-.91-7.46-2.74-1.77-1.82-2.66-4.36-2.66-7.62s.86-5.76,2.58-7.68c1.72-1.92,4.1-2.87,7.15-2.87Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m248.01,192.31h-3.98v-28.32l3.98-.2v9.22c1.47-.76,3.14-1.14,5-1.14,2.49,0,4.46.67,5.89,2.01,1.43,1.34,2.15,3.21,2.15,5.59v12.84h-4.02v-12.53c0-3.02-1.52-4.53-4.57-4.53-1.6,0-3.08.38-4.45,1.14v15.91Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m270.98,192.31h-3.98v-18.59c2.62-1.24,5.49-1.85,8.59-1.85,2.73,0,4.82.66,6.26,1.99,1.44,1.33,2.17,3.2,2.17,5.61v12.84h-3.98v-12.56c0-3.02-1.49-4.53-4.45-4.53-1.71,0-3.24.34-4.61,1.02v16.07Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m307.49,182.31c0,3.18-.83,5.72-2.5,7.62-1.67,1.91-3.96,2.86-6.87,2.86s-5.17-.95-6.83-2.86c-1.67-1.9-2.5-4.44-2.5-7.62s.84-5.68,2.5-7.58c1.67-1.9,3.94-2.85,6.83-2.85s5.2.95,6.87,2.85c1.67,1.91,2.5,4.43,2.5,7.58Zm-14.65,0c0,2.21.45,3.95,1.36,5.22.91,1.27,2.21,1.91,3.92,1.91s3.08-.63,3.96-1.89c.88-1.26,1.32-3.01,1.32-5.24s-.45-3.94-1.34-5.2c-.89-1.26-2.2-1.89-3.94-1.89s-3.01.64-3.92,1.91c-.9,1.27-1.36,3-1.36,5.18Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m316.35,163.8v22.13c0,1.21.18,2.1.55,2.66s.98.85,1.85.85c.45,0,.83-.04,1.14-.12l.32,3.19c-.74.19-1.49.28-2.25.28-3.73,0-5.59-2.27-5.59-6.81v-21.94l3.98-.24Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m341.52,182.31c0,3.18-.83,5.72-2.5,7.62-1.67,1.91-3.96,2.86-6.87,2.86s-5.17-.95-6.83-2.86c-1.67-1.9-2.5-4.44-2.5-7.62s.84-5.68,2.5-7.58c1.67-1.9,3.94-2.85,6.83-2.85s5.2.95,6.87,2.85c1.67,1.91,2.5,4.43,2.5,7.58Zm-14.65,0c0,2.21.45,3.95,1.36,5.22.91,1.27,2.21,1.91,3.92,1.91s3.08-.63,3.96-1.89c.88-1.26,1.32-3.01,1.32-5.24s-.45-3.94-1.34-5.2c-.89-1.26-2.2-1.89-3.94-1.89s-3.01.64-3.92,1.91c-.9,1.27-1.36,3-1.36,5.18Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m353.69,199.83c-2.44,0-4.63-.38-6.58-1.14l.83-3.23c1.71.66,3.54.98,5.51.98,1.73,0,3.06-.38,3.98-1.14.92-.76,1.38-1.82,1.38-3.19v-1.66c-1.31.68-2.76,1.03-4.33,1.03-2.83,0-5.07-.83-6.71-2.48-1.64-1.66-2.46-3.96-2.46-6.93,0-3.12.92-5.61,2.76-7.44,1.84-1.84,4.29-2.76,7.37-2.76,2.7,0,5.16.52,7.36,1.57v18.31c0,2.57-.81,4.56-2.42,5.97s-3.84,2.11-6.68,2.11Zm-4.33-18.08c0,1.97.5,3.52,1.5,4.65,1,1.13,2.39,1.69,4.17,1.69,1.45,0,2.7-.34,3.78-1.02v-11.23c-1.15-.42-2.28-.63-3.39-.63-1.86,0-3.34.59-4.43,1.75-1.09,1.17-1.63,2.76-1.63,4.78Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m371.02,168.88c-.79,0-1.4-.22-1.83-.67s-.65-1.04-.65-1.77.22-1.28.67-1.71,1.05-.65,1.81-.65,1.36.22,1.79.65.65,1.01.65,1.71-.21,1.33-.65,1.77-1.03.67-1.79.67Zm2.01,3.46v19.97h-3.98v-19.97h3.98Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m395.24,191.41c-1.97.92-4.23,1.38-6.77,1.38-3.18,0-5.7-.93-7.58-2.78-1.88-1.85-2.82-4.42-2.82-7.7,0-3.1.85-5.61,2.56-7.54,1.71-1.93,3.99-2.89,6.85-2.89,2.55,0,4.56.81,6.05,2.44s2.22,3.73,2.22,6.3c0,.76-.05,1.64-.16,2.64h-13.43c.4,4.1,2.61,6.14,6.66,6.14,1.92,0,3.83-.41,5.75-1.22l.67,3.23Zm-7.76-16.27c-1.52,0-2.74.49-3.64,1.48s-1.45,2.34-1.63,4.08h9.65c.02-.16.04-.37.04-.63,0-1.42-.37-2.59-1.12-3.52-.75-.93-1.84-1.4-3.29-1.4Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
      <path
        d="m412.45,172.93l-.47,3.27c-2.18-.66-3.94-.98-5.28-.98-2.36,0-3.55.81-3.55,2.44,0,.82.3,1.42.89,1.81.59.4,1.58.8,2.97,1.22,2.34.66,4.03,1.43,5.08,2.32,1.05.89,1.57,2.17,1.57,3.82,0,1.94-.71,3.42-2.13,4.43s-3.3,1.52-5.63,1.52c-2.6,0-4.87-.41-6.81-1.22l.59-3.35c2.1.82,4.16,1.22,6.18,1.22,1.18,0,2.12-.22,2.82-.67.7-.45,1.04-1.06,1.04-1.85s-.33-1.42-1-1.83c-.67-.41-1.93-.87-3.76-1.4-1.86-.55-3.29-1.27-4.27-2.17-.98-.89-1.48-2.15-1.48-3.78,0-1.89.63-3.34,1.89-4.35,1.26-1.01,3.03-1.52,5.32-1.52s4.35.36,6.03,1.06Z"
        style={{
          fill: "#2a206a",
          strokeWidth: 0,
        }}
      />
    </g>
    <g>
      <path
        d="m75.94,124.84h-3.43c-4.11,0-6.81-1.42-8.12-4.28-1.63.22-3.12.34-4.47.34-3.85,0-6.89-.78-9.13-2.34-2.23-1.56-3.35-3.89-3.35-7.01,0-3.56,1.09-6.66,3.27-9.31,2.18-2.65,4.99-4.61,8.42-5.89s7.22-1.92,11.36-1.92v16.46c0,1.67.34,2.76,1.01,3.28.67.52,1.93.78,3.79.78h.65v9.9Zm-10.3-32.97l-4.52-3.78-3.43,3.95-5.29-4.45,4.31-5.23,4.52,3.95,3.43-4.11,5.34,4.67-4.36,5.01Zm-4.09,20.41c.62,0,1.09-.02,1.42-.06v-8.45c-2.58.41-4.65,1.17-6.21,2.28-1.56,1.11-2.34,2.19-2.34,3.23,0,.85.63,1.57,1.88,2.14,1.25.57,3,.86,5.26.86Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m75.94,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.82c2.43,0,4.05-.27,4.85-.81s1.2-1.29,1.2-2.25v-8.12l8.07-.56v7.67c0,1.37-.07,2.52-.22,3.45,1.78.41,3.85.61,6.21.61h.33v9.9h-1.85c-3.67,0-6.58-.78-8.72-2.33-2.07,1.56-5.05,2.33-8.94,2.33h-1.74Zm15.91-26.36c-4.29.78-8.43,1.96-12.42,3.56l-1.25-3.84c1.05-.56,2.18-1.04,3.38-1.45-1.24-1.07-1.85-2.58-1.85-4.5,0-1.74.58-3.14,1.74-4.2s2.62-1.59,4.36-1.59,3.22.37,4.63,1.11l-1.31,3.61c-1.09-.29-2.05-.44-2.89-.44-.76,0-1.39.18-1.88.53-.49.35-.74.8-.74,1.36,0,.63.22,1.2.65,1.7s1.09.75,1.96.75,2.49-.24,4.85-.72l.76,4.12Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m129.18,124.9c-3.23,0-5.72-.8-7.47-2.39-1.74,1.74-4.52,2.61-8.34,2.61-3.27,0-6.1-.8-8.5-2.39-1.93,1.41-4.29,2.11-7.08,2.11h-.6c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h1.42c1.31,0,2.3-.34,2.97-1,.67-.67,1.08-1.91,1.23-3.73l.27-3.67,7.52-.56-.22,4.23c-.04,1.19-.18,2.39-.44,3.61,1.13.56,2.36.83,3.71.83,3.02,0,4.52-1.35,4.52-4.06v-4.62l7.58-.5v6.29c0,2.11,1.2,3.17,3.6,3.17,1.16,0,2.45-.2,3.87-.61-.07-1.56-.6-4.86-1.58-9.9l7.9-1.72c.73,3.33,1.09,6.28,1.09,8.84,0,4.34-.44,7.84-1.31,10.51-3.38,1.89-6.76,2.84-10.14,2.84Zm-3.05-22.69l-4.41-3.73-3.6,4.11-5.18-4.39,4.25-5.11,4.41,3.89,3.6-4.28,5.17,4.56-4.25,4.95Zm-5.56-16.01l4.69,4.11-3.81,4.45-4.69-3.95,3.81-4.61Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m160.02,114.95v9.9h-2.62c-4,0-6.84-1.14-8.53-3.42-1.69-2.28-2.53-5.55-2.53-9.81v-26.36l8.5-.72v25.19c0,3.48,1.53,5.23,4.58,5.23h.6Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m160.02,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.82c2.43,0,4.05-.27,4.85-.81s1.2-1.29,1.2-2.25v-8.12l8.07-.56v7.67c0,1.37-.07,2.52-.22,3.45,1.78.41,3.85.61,6.21.61h.33v9.9h-1.85c-3.67,0-6.58-.78-8.72-2.33-2.07,1.56-5.05,2.33-8.94,2.33h-1.74Zm4.8-34.47l5.89,5.17-4.8,5.56-5.89-4.95,4.8-5.78Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m181.27,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.44c2,0,3.65-.15,4.96-.44.07-1.63.11-4.15.11-7.56,0-1.26-.05-8.49-.16-21.69l8.56-.72v21.35c0,7.97-.47,13.36-1.42,16.18-1.34.74-3.16,1.39-5.45,1.95-2.29.56-4.54.83-6.76.83h-.27Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m209.66,124.84h-8.5v-39.59l8.5-.72v40.31Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m247.37,125.4c-3.85,0-7.08-.32-9.7-.97-2.62-.65-4.6-1.6-5.94-2.87-1.34-1.26-2.29-2.63-2.83-4.11s-.82-3.23-.82-5.23c0-3,.69-6.62,2.07-10.84l7.25,1.45c-.47,2.49-.71,4.45-.71,5.89,0,1.67.31,2.96.93,3.87.62.91,1.84,1.6,3.68,2.08,1.84.48,4.46.72,7.88.72,5.23,0,9.46-.48,12.7-1.45-.07-3.26-.58-7.08-1.53-11.46l8.77-1.72c.84,3.75,1.25,7.43,1.25,11.07s-.51,6.84-1.53,9.28c-4.32,2.85-11.48,4.28-21.47,4.28Zm7.25-23.02l-4.52-3.78-3.43,3.95-5.29-4.45,4.3-5.23,4.53,3.95,3.43-4.12,5.34,4.67-4.36,5Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m289.77,114.95v9.9h-2.62c-4,0-6.84-1.14-8.53-3.42-1.69-2.28-2.53-5.55-2.53-9.81v-26.36l8.5-.72v25.19c0,3.48,1.53,5.23,4.58,5.23h.6Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m299.96,138.02l-4.52-3.78-3.43,3.95-5.28-4.45,4.3-5.23,4.52,3.95,3.43-4.11,5.34,4.67-4.36,5.01Zm-10.19-13.18c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.82c2.43,0,4.05-.27,4.85-.81s1.2-1.29,1.2-2.25v-8.12l8.07-.56v7.67c0,1.37-.07,2.52-.22,3.45,1.78.41,3.85.61,6.21.61h.33v9.9h-1.85c-3.67,0-6.58-.78-8.72-2.33-2.07,1.56-5.05,2.33-8.94,2.33h-1.74Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m311.02,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.82c2.43,0,4.05-.27,4.85-.81s1.2-1.29,1.2-2.25v-8.12l8.07-.56v7.67c0,1.37-.07,2.52-.22,3.45,1.78.41,3.85.61,6.21.61h.33v9.9h-1.85c-3.67,0-6.58-.78-8.72-2.33-2.07,1.56-5.05,2.33-8.94,2.33h-1.74Zm4.8-34.47l5.89,5.17-4.8,5.56-5.89-4.95,4.8-5.78Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m332.27,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h1.53c.76,0,2.09-.09,3.98-.28-1.34-2.11-2.02-4.56-2.02-7.34,0-3.82,1.1-6.73,3.3-8.73,2.2-2,4.93-3,8.2-3s5.81.97,7.96,2.92c2.14,1.95,3.21,4.61,3.21,7.98,0,3.11-.91,5.86-2.72,8.23,1.63.15,3.18.22,4.63.22h1.8v9.9h-3.71c-5.27,0-9.41-.76-12.42-2.28-3.71,1.52-7.86,2.28-12.48,2.28h-1.25Zm19.07-31.36l-4.52-3.78-3.43,3.95-5.28-4.45,4.3-5.23,4.52,3.95,3.43-4.11,5.34,4.67-4.36,5.01Zm-8.83,14.01c0,2.11,1.42,4.08,4.25,5.89,3.05-1.52,4.58-3.19,4.58-5.01,0-1.11-.45-2.05-1.36-2.81-.91-.76-2.05-1.14-3.43-1.14-1.13,0-2.08.32-2.86.95s-1.17,1.33-1.17,2.11Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m372.98,100.26l-4.52-3.78-3.43,3.95-5.29-4.45,4.31-5.23,4.52,3.95,3.43-4.12,5.34,4.67-4.36,5Zm-10.84,24.58c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.82c2.43,0,4.05-.27,4.85-.81s1.2-1.29,1.2-2.25v-8.12l8.07-.56v7.67c0,1.37-.07,2.52-.22,3.45,1.78.41,3.85.61,6.21.61h.33v9.9h-1.85c-3.67,0-6.58-.78-8.72-2.33-2.07,1.56-5.05,2.33-8.94,2.33h-1.74Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m383.39,124.84c-.54,0-1.01-.48-1.39-1.45-.38-.96-.57-2.13-.57-3.5s.19-2.54.57-3.5c.38-.96.84-1.45,1.39-1.45h.44c2,0,3.65-.15,4.96-.44.07-1.63.11-4.15.11-7.56,0-1.26-.05-8.49-.16-21.69l8.56-.72v21.35c0,7.97-.47,13.36-1.42,16.18-1.34.74-3.16,1.39-5.45,1.95-2.29.56-4.54.83-6.76.83h-.27Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <path
        d="m411.78,124.84h-8.5v-39.59l8.5-.72v40.31Z"
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
    </g>
    <g>
      <g>
        <g>
          <path
            d="m552.13,170.96c-35.31,0-64.04-29.7-64.04-66.21s28.73-66.21,64.04-66.21c1.92,0,3.47,1.61,3.47,3.59s-1.55,3.59-3.47,3.59c-31.49,0-57.1,26.48-57.1,59.04s25.62,59.04,57.1,59.04c25.55,0,48.17-17.78,55.02-43.23,1.38-5.12,2.08-10.44,2.08-15.8,0-1.98,1.55-3.59,3.47-3.59s3.47,1.61,3.47,3.59c0,6.02-.78,11.98-2.33,17.73-7.68,28.55-33.06,48.49-61.71,48.49Z"
            style={{
              fill: "#00abaf",
              strokeWidth: 0,
            }}
          />
          <path
            d="m552.13,151.56c-24.97,0-45.28-21-45.28-46.81s20.31-46.81,45.28-46.81c1.92,0,3.47,1.61,3.47,3.59s-1.55,3.59-3.47,3.59c-21.14,0-38.34,17.78-38.34,39.64s17.2,39.63,38.34,39.63c9.44,0,18.51-3.6,25.56-10.13,1.43-1.32,3.62-1.2,4.9.28,1.28,1.48,1.16,3.75-.27,5.07-8.32,7.71-19.04,11.96-30.19,11.96Z"
            style={{
              fill: "#00abaf",
              strokeWidth: 0,
            }}
          />
          <path
            d="m552.13,132.16c-14.62,0-26.51-12.3-26.51-27.41s11.89-27.41,26.51-27.41c1.92,0,3.47,1.61,3.47,3.59s-1.55,3.59-3.47,3.59c-10.79,0-19.57,9.08-19.57,20.23s8.78,20.23,19.57,20.23c1.92,0,3.47,1.61,3.47,3.59s-1.55,3.59-3.47,3.59Z"
            style={{
              fill: "#00abaf",
              strokeWidth: 0,
            }}
          />
        </g>
        <path
          d="m552.13,190.36c-45.66,0-82.81-38.41-82.81-85.62s37.15-85.61,82.81-85.61c1.92,0,3.47,1.61,3.47,3.59s-1.55,3.59-3.47,3.59c-41.83,0-75.87,35.19-75.87,78.44s34.03,78.44,75.87,78.44,75.87-35.19,75.87-78.44c0-20.92-7.9-40.6-22.25-55.44-1.35-1.4-1.35-3.68,0-5.08,1.36-1.4,3.55-1.4,4.91,0,15.66,16.19,24.28,37.68,24.28,60.51,0,47.21-37.15,85.62-82.81,85.62Z"
          style={{
            fill: "#00abaf",
            strokeWidth: 0,
          }}
        />
      </g>
      <ellipse
        cx={552.13}
        cy={128.57}
        rx={7.51}
        ry={7.77}
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <ellipse
        cx={580.01}
        cy={136.93}
        rx={7.51}
        ry={7.77}
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <ellipse
        cx={612.7}
        cy={104.75}
        rx={7.51}
        ry={7.77}
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
      <ellipse
        cx={608.2}
        cy={46.77}
        rx={7.51}
        ry={7.77}
        style={{
          fill: "#00abaf",
          strokeWidth: 0,
        }}
      />
    </g>
  </svg>
);
export default LoginLogo;
