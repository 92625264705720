import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import { useContext } from "react";
import { StageContext } from "../../../contexts/stageContext";
import { permissionsCheck } from "../../../helpers/utils";

export const useGetAuditorExpertUnfinishedStandards = ({ currentPage }) => {
  const { currentStage, userId } =
    useContext(StageContext);
  const surveyStageId =
    currentStage?.id || localStorage.getItem("surveyStageId");
  const surveyId = currentStage?.surveyId || localStorage.getItem("surveyId");
  const expertId = permissionsCheck("Pages.UserType.Expert")
    ? userId || localStorage.getItem("userId")
    : null;
  const reviewerId = permissionsCheck("Pages.UserType.Reviewer")
    ? userId || localStorage.getItem("userId")
    : null;

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAuditorExpertUnfinishedStandards } =
    useQuery({
      queryKey: [
        "AuditorExpertUnfinishedStandards",
        currentPage,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          ExpertId: expertId,
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });

  const unfinishedStandards = data?.result?.unReviewedAgencies;
  const allStandardsCount = data?.result?.totalAssignedAgenciesCount;
  const unfinishedStandardsCount = data?.result?.totalUnReviewedAgenciesCount;
  const totalPages = Math.ceil(unfinishedStandardsCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertUnfinishedStandards",
        currentPage + 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          ExpertId: expertId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertUnfinishedStandards",
        currentPage - 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          ExpertId: expertId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    unfinishedStandards,
    unfinishedStandardsCount,
    allStandardsCount,
    totalPages,
    data,
    isGettingAuditorExpertUnfinishedStandards,
  };
};
