import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";
import { checkForNaN } from "../../../../modules/main/features/standerds/standardAnswer/answers/utils";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

export const useGetNotEnteredAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingNotEnteredAgencies } = useQuery({
    queryKey: ["NotEnteredAgencies", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getNotStartedAgencies,
        {
          KeyWord: filterValue,
          surveyId: ifNotDefinedReturnZero(surveyId),
          SkipCount: currentPage * 10,
          MaxResultCount: 10,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
  });

  const notStartedAgencies = data?.result?.items;
  const notStartedAgenciesCount = checkForNaN(data?.result?.notLoggedCount) ? 0 : (data?.result?.notLoggedCount);
  const totalCount = checkForNaN(data?.result?.totalCount) ? 0 : (data?.result?.totalCount);
  const totalPages = Math.ceil(totalCount / 10);

  return {
    queryClient,
    data,
    notStartedAgencies,
    notStartedAgenciesCount,
    totalPages,
    isGettingNotEnteredAgencies,
  };
};
