import { programsIcon } from "../../../../assets/icons/Index";
import { isAgency, isExpert, isExpertManager, isPOCOfficer, isReviewer } from "../../../../helpers/isPermission";
import { permissionsCheck } from "../../../../helpers/utils";

export const standerdsBreadcumbs = [
  {
    icon: programsIcon,
    title: "استبيان التقنيات الناشئة",
  },
  {
    title: "المعايير",
  },
];
export const addStanderdsBreadcumbs = [
  {
    icon: programsIcon,
    title: "استبيان التقنيات الناشئة",
  },
  {
    title: "المعايير",
  },
  {
    title: "إضافة",
  },
];
export const editStanderdsBreadcumbs = [
  {
    icon: programsIcon,
    title: "استبيان التقنيات الناشئة",
  },
  {
    title: "المعايير",
  },
  {
    title: "تعديل",
  },
];
export const standerdEntitesBreadcumbs = (
  indicatorId,
  surveyId,
  perspectiveId,
  interlocutorId,
  standardTitle
) => {
  return [
    {
      icon: programsIcon,
      title:  "قياس التقنيات الناشئة",
      url: permissionsCheck("Pages.UserType.Reviewer") || permissionsCheck("Pages.UserType.Expert") || permissionsCheck("Pages.UserType.Agency") || permissionsCheck("Pages.UserType.ExpertManager")
        ? null
        : "/indicators/" + indicatorId,
    },

    {
      title: standardTitle,
      url: isReviewer() || isExpert() || isAgency() || isExpertManager() || isPOCOfficer()
        ? `/programs/${surveyId}/standards`
        : `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: "الجهات الحكومية",
    },
  ];
};
