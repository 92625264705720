import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import { StageContext } from "../../../contexts/stageContext";
import { useContext } from "react";
import { ifNotDefinedReturnZero, isDefined, permissionsCheck } from "../../../helpers/utils";
import { expertId, expertManagerId, isExpert, isReviewer, reviewerId, surveyId } from "../../../helpers/isPermission";

export const useGetSubStandardRequirementForExpert = () => {
  
  const { data: totalSubStandardRequirement } = useQuery({
    queryKey: ["GetTotalSubStandardRequirementForExpert", surveyId(), expertId()],
    queryFn: () =>
      axiosGetRequest(environment.GetTotalSubStandardRequirementForExpert, {
        surveyId: surveyId(),
        reviewerId: reviewerId(),
        expertId: expertId(),
      }),
    enabled: surveyId() != null,
    select: data => {
      if (data?.result) {
        const {
          totalAssignedSubStandardRequirementForReviewer,
          totalAssignedSubStandardRequirementForExpert,
          totalAssignedSubStandardRequirementForExpertManager
        } = data.result;

        if (isReviewer()) {
          return ifNotDefinedReturnZero(totalAssignedSubStandardRequirementForReviewer);
        }
        else if (isExpert()) {
          return ifNotDefinedReturnZero(totalAssignedSubStandardRequirementForExpert);
        }
        else {
          return ifNotDefinedReturnZero(totalAssignedSubStandardRequirementForExpertManager);
        }
      }
      return 0
    }
  });

  const { data: answeredSubStandardRequirement } = useQuery({
    queryKey: ["GetAnsweredSubStandardRequirementForExpert", surveyId(), expertId()],
    queryFn: () =>
      axiosGetRequest(environment.GetAnsweredSubStandardRequirementForExpert, {
        surveyId: surveyId(),
        reviewerId: reviewerId(),
        expertId: expertId(),
        expertManagerId: expertManagerId(),
      }),
    enabled: surveyId() != null,
    select: data => data?.result
  });

  return {
    totalSubStandardRequirement,
    answeredSubStandardRequirement,
  }
}
