import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import { StageContext } from "../../../contexts/stageContext";
import { useContext } from "react";
import { permissionsCheck } from "../../../helpers/utils";

export const useGetAuditorExpertStandards = ({ currentPage }) => {
  const { currentStage, userId } =
    useContext(StageContext);
  const surveyStageId =
    currentStage?.id || localStorage.getItem("surveyStageId");
  const surveyId = currentStage?.surveyId || localStorage.getItem("surveyId");
  const expertId = permissionsCheck("Pages.UserType.Expert")
    ? userId || localStorage.getItem("userId")
    : null;
  const reviewerId = permissionsCheck("Pages.UserType.Reviewer")
    ? userId || localStorage.getItem("userId")
    : null;

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAuditorExpertStandards } = useQuery({
    queryKey: ["AuditorExpertStandards", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId,
        ReviewerId: reviewerId,
        ExpertId: expertId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const standards = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 5);

  return {
    queryClient,
    standards,
    totalCount,
    totalPages,
    data,
    isGettingAuditorExpertStandards,
  };
};
