import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { StageContext } from "../../../../contexts/stageContext";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { checkForNaN } from "../../../../modules/main/features/standerds/standardAnswer/answers/utils";
import { expertId, isDgaUsers, reviewerId } from "../../../../helpers/isPermission";
import { useGetIncludedAgencies } from "./useGetIncludedAgencies";
import { useDashboardContext } from "../../../../contexts/DashboardContext";

export const useGetAgenciesStartedAnswering = ({
  currentPage,
  filterValue,
}) => {
  const queryClient = useQueryClient();
  const { stageForDashboard } = useContext(StageContext);
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingAgenciesStartedAnswering } = useQuery({
    queryKey: [
      "AgenciesStartedAnswering",
      currentPage,
      filterValue,
      phaseOneStageId
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesStartedAnswering,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          StageId: stageForDashboard || 0,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
          expertId: expertId(),
          reviewerId: reviewerId()
        },
        true,
        signal
      ),
    enabled: !!stageForDashboard,
  });

  const agenciesStartedAnswering = data?.result?.items;
  const agenciesStartedAnsweringCount = checkForNaN(data?.result?.totalCount) ? 0 : (data?.result?.totalCount);
  const totalPages = Math.ceil(agenciesStartedAnsweringCount / 4);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesStartedAnswering",
        currentPage + 1,
        filterValue,
        stageForDashboard,
        phaseOneStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesStartedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage + 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
            expertId: expertId(),
            reviewerId: reviewerId()  
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseOneStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesStartedAnswering",
        currentPage - 1,
        filterValue,
        stageForDashboard,
        phaseOneStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesStartedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage - 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
            expertId: expertId(),
            reviewerId: reviewerId()  
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseOneStageId,
    });
  }

  const {
    includedAgenciesCount,
  } = useGetIncludedAgencies(0, null);
  const { setIncludedAgenciesCount } = useDashboardContext();

  useEffect(() => {
    if(isDgaUsers()) {
      setIncludedAgenciesCount(checkForNaN(includedAgenciesCount) ? 0 : includedAgenciesCount);
    }
  }, [includedAgenciesCount]);

  return {
    queryClient,
    data,
    agenciesStartedAnswering,
    agenciesStartedAnsweringCount,
    totalPages,
    isGettingAgenciesStartedAnswering,
  };
};
