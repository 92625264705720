
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";
import { surveyId } from "../../../../helpers/isPermission";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { checkForNaN } from "../../../../modules/main/features/standerds/standardAnswer/answers/utils";

export const useGetFinishedAnswerAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const { stageForDashboard } = useContext(StageContext);
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingFinishedAnswerAgencies } = useQuery({
    queryKey: [
      "FinishedAnswerAgencies",
      currentPage,
      filterValue,
      stageForDashboard,
      phaseOneStageId
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesFinishedAnswering,
        {
          KeyWord: filterValue,
          surveyId: surveyId(),
          StageId: stageForDashboard || 0,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
    enabled: !!stageForDashboard,
  });

  const agenciesFinishedAnswering = data?.result?.items;
  const agenciesFinishedAnsweringCount = checkForNaN(data?.result?.totalCount) ? 0 : (data?.result?.totalCount);
  const totalPages = Math.ceil(agenciesFinishedAnsweringCount / 4);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "FinishedAnswerAgencies",
        currentPage + 1,
        filterValue,
        stageForDashboard,
        phaseOneStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesFinishedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId(),
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage + 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseOneStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "FinishedAnswerAgencies",
        currentPage - 1,
        filterValue,
        stageForDashboard,
        phaseOneStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesFinishedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId(),
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage - 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseOneStageId,
    });
  }

  return {
    queryClient,
    data,
    agenciesFinishedAnswering,
    agenciesFinishedAnsweringCount,
    totalPages,
    isGettingFinishedAnswerAgencies,
  };
};
