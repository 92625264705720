import React, {useContext} from "react";
import AppTextarea from "../../../../../components/form/appTextarea";
import {AddStandardContext} from "./addStandardContext";

const StandardOrders = ({ disabled }) => {
  const {addFinalValue, setAddFinalValue} = useContext(AddStandardContext);

  const handleChange = (e) => {
    setAddFinalValue({
      ...addFinalValue,
      royalOrdersAndCabinetDecisions: e.target.value,
    });
  };

  return (
    <div className="mt-8">
      <h2 className="font-semibold mb-4">
        الأوامر والقرارات والتعاميم المرتبطة
      </h2>
      <div className="bg-white p-5 pb-7 rounded-lg">
        <AppTextarea
          label="الأوامر والقرارات والتعاميم المرتبطة"
          placeholder="اكتب هنا"
          onChange={handleChange}
          readOnly={disabled}
          value={addFinalValue.royalOrdersAndCabinetDecisions}
        />
      </div>
    </div>
  );
};

export default StandardOrders;
