import { useState } from "react";
import { useAnswerContext } from "../../../answerContext";
import StandardAnswerRequirement from "../../../standardAnswerRequirements";
import AgencyRequirementAnswer from "../../Agency/agencyRequirementAnswer";
import { ViewStandardRequirementsBasedOnStandard } from "./../StandardRequirements/ViewStandardRequirementsBasedOnStandard";
import { AddStandardRequirementsBasedOnStandard } from "./../StandardRequirements/AddStandardRequirementsBasedOnStandard";


export const ReviewerRequirementAnswerBasedOnStandard = ({ setMenuElement }) => {
    const { newReviewerAnswer } = useAnswerContext();

    const [selectedRequirementsTab, setSelectedRequirementsTab] =
        useState("reviewer");

    return (
        <div>
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
                <div className="flex  py-5 w-full">
                    <p
                        className={
                            selectedRequirementsTab == "agency"
                                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
                        }
                        onClick={() => setSelectedRequirementsTab("agency")}
                    >
                        الجهة
                    </p>
                    <p
                        className={
                            selectedRequirementsTab == "reviewer"
                                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
                        }
                        onClick={() => setSelectedRequirementsTab("reviewer")}
                    >
                        المدقق
                    </p>

                    <p
                        className={
                            selectedRequirementsTab == "expert"
                                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
                        }
                        onClick={() => setSelectedRequirementsTab("expert")}
                    >
                        الخبير
                    </p>
                </div>

                {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
                {selectedRequirementsTab === "reviewer" && (
                    <>
                        {newReviewerAnswer.isUpdatedByExpertManager && (
                            <div className="flex justify-center items-center bg-red-100 p-2 rounded-md mb-3">
                                <p className="text-red-600 text-lg">
                                    تم تعديل الإجابة بواسطة رئيس الخبراء
                                </p>
                            </div>
                        )}
                        <AddStandardRequirementsBasedOnStandard />
                    </>
                )}
                {selectedRequirementsTab === "expert" && (
                    <ViewStandardRequirementsBasedOnStandard
                        requirements={newReviewerAnswer?.expertRequirementsAnswer}
                        hasAnswer={newReviewerAnswer?.expertRequirementAnswers?.length}
                        label={"تقييم الخبير"}
                    />
                )}
            </StandardAnswerRequirement>
        </div>
    );
};