import { TextInput } from "flowbite-react";
import UploadFile from "../../modules/main/features/standerds/standardAnswer/answers/UploadFile";
import { getCommitmentLevelsBasedOnDegree } from "../../modules/main/features/standerds/standardAnswer/answers/utils";
import MainRequirement from "../../modules/main/features/standerds/standardAnswer/standardAnswerRequirements/ui/mainRequirement";
import { useParams } from "react-router";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";
import { useQuery } from "../../helpers/utils";
import { Input } from "../../components";

export function ReadOnlyRequirementAnswers({ requirements, inputLabel, structureSubStandardRequirementDirectly }) {

    const { standardId } = useParams();
    const { data: commitmentLevels = [] } = useQuery({
      queryKey: ["commitmentLevels", { standardId }],
      queryFn: async ({ queryKey }) => {
        const [, { standardId }] = queryKey;
  
        const res = await axiosGetRequest(environment.getCommitmentLevels, {
          standardId,
        });
  
        return res?.result;
      },
    });

  return (
    requirements?.length > 0 &&
    requirements?.map((requirement) => {
      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title || requirement?.standardRequirementTitle}
            hasSubRequirements={
              requirement?.expertSubRequirementAnswers?.length > 0
            }
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {}}
            handleDonotApplyRequirementChange={() => {}}
          >
            {requirement?.expertSubRequirementAnswers?.map((subRequirement) => {
              return structureSubStandardRequirementDirectly ? (
                <>
                  {/* على مستوى المعيار */}
                  <div className="px-5 flex flex-col mb-3 py-2">
                    <div className="flex flex-row gap-2 mb-1 py-1">
                      <h2 className="text-xl pb-2">
                        {subRequirement.subStandardRequirementTitle}
                      </h2>
                    </div>
                    <div className="flex flex-row gap-2 mb-3 py-2">
                      <Input
                        type="checkbox"
                        className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                        id={subRequirement.value}
                        name={"ارجاع الى الجهة"}
                        label={""}
                        value={subRequirement.isReturnable}
                        checked={subRequirement.isReturnable}
                        disabled={true}
                      />
                      <span>معاد للجهة</span>
                    </div>

                    {subRequirement?.isReturnable && (
                      <div className="flex gap-2 w-full items-center mb-4">
                        <span className="w-32">ملاحظة الى الجهة</span>
                        <TextInput
                          value={subRequirement?.reason}
                          className="grow"
                          placeholder="ملاحظة الى الجهة"
                          disabled={true}
                        />
                      </div>
                    )}

                    <div className="flex flex-col gap-4">
                      <>
                        {subRequirement?.expertSubRequirementAnswerSides?.map(
                          (side, index) => {
                            return (
                              <div className="border-b pb-2">
                                <h3 className="text-xl pb-3">
                                  {side.subStandardRequirementSideTitle}
                                </h3>
                                <div className=" flex items-center gap-2 mb-4">
                                  <span className="w-24">{`تقييم ${inputLabel}`}</span>
                                  <TextInput
                                    value={side?.evaluationDegree}
                                    type="number"
                                    placeholder=""
                                    min={0}
                                    max={100}
                                    disabled
                                  />
                                  <label>
                                    {getCommitmentLevelsBasedOnDegree(
                                      Number(
                                        subRequirement
                                          ?.expertSubRequirementAnswerSides?.[
                                          index
                                        ]?.evaluationDegree
                                      ),
                                      commitmentLevels
                                    )}
                                  </label>
                                </div>
                                <div className="flex gap-2 w-full items-center mb-4">
                                  <span className="w-24"> الملاحظة</span>
                                  <TextInput
                                    value={side?.evaluationNote}
                                    className="grow"
                                    placeholder={`ملاحظة ${inputLabel} للجهة`}
                                    disabled
                                  />
                                </div>
                                <div className="flex gap-2 w-full items-center">
                                  <span className="w-24">التوصية</span>
                                  <TextInput
                                    value={side?.recommendation}
                                    className="grow"
                                    placeholder={`توصية ${inputLabel} للجهة`}
                                    disabled
                                  />
                                </div>
                                {side.attachmentId && (
                                  <div className="flex gap-2 w-full items-center">
                                    <span className="w-24">المستند</span>
                                    <UploadFile
                                      value={side.attachmentId}
                                      withOutToolTip
                                      readOnly
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* على مستوى البعد */}
                  <div className="px-5 flex flex-col mb-3 py-2">
                    <div className="flex flex-row gap-2 mb-1 py-1">
                      <h2 className="text-xl pb-2">
                        {subRequirement.subStandardRequirementTitle}
                      </h2>
                    </div>
                    <div className="flex flex-row gap-2 mb-3 py-2">
                      <Input
                        type="checkbox"
                        className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                        id={subRequirement.value}
                        name={"ارجاع الى الجهة"}
                        label={""}
                        value={subRequirement.isReturnable}
                        checked={subRequirement.isReturnable}
                        disabled={true}
                      />
                      <span>معاد للجهة</span>
                    </div>

                    {subRequirement?.isReturnable && (
                      <div className="flex gap-2 w-full items-center mb-4">
                        <span className="w-32">ملاحظة الى الجهة</span>
                        <TextInput
                          value={subRequirement?.reason}
                          className="grow"
                          placeholder="ملاحظة الى الجهة"
                          disabled={true}
                        />
                      </div>
                    )}

                    <div className="flex flex-col gap-4">
                      <>
                        <div className="border-b pb-2">
                          {/* <h3 className="text-xl pb-3">
                                {subRequirement.subStandardRequirementSideTitle}
                              </h3> */}
                          <div className=" flex items-center gap-2 mb-4">
                            <span className="w-24">{`تقييم ${inputLabel}`}</span>
                            <TextInput
                              value={subRequirement.percentage}
                              type="number"
                              placeholder=""
                              min={0}
                              max={100}
                              disabled
                            />
                            <label>
                              {getCommitmentLevelsBasedOnDegree(
                                Number(subRequirement.percentage),
                                commitmentLevels
                              )}
                            </label>
                          </div>
                          <div className="flex gap-2 w-full items-center mb-4">
                            <span className="w-24"> الملاحظة</span>
                            <TextInput
                              value={subRequirement.note}
                              className="grow"
                              placeholder={`ملاحظة ${inputLabel} للجهة`}
                              disabled
                            />
                          </div>
                          <div className="flex gap-2 w-full items-center">
                            <span className="w-24">التوصية</span>
                            <TextInput
                              value={subRequirement.recommendation}
                              className="grow"
                              placeholder={`توصية ${inputLabel} للجهة`}
                              disabled
                            />
                          </div>
                          {subRequirement.attachmentId && (
                            <div className="flex gap-2 w-full items-center">
                              <span className="w-24">المستند</span>
                              <UploadFile
                                value={subRequirement.attachmentId}
                                withOutToolTip
                                readOnly
                              />
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </>
              );
            })}
          </MainRequirement>
        </>
      );
    })
  );
}
