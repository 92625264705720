import { useEffect } from "react";
import FlipCard from "../../../../modules/main/home/FlipCard";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { useDashboardContext } from "../../../../contexts/DashboardContext";
import { checkForNaN } from "../../../../modules/main/features/standerds/standardAnswer/answers/utils";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

function StandardsCount() {
  const surveyId = localStorage.getItem("surveyId");

  const { totalStandardsCount, setTotalStandardsCount } = useDashboardContext();

  const getTotalStandardsCount = async (page) => {
    const res = await axiosGetRequest(environment.getTotalStandardsCount, {
      surveyId: ifNotDefinedReturnZero(surveyId),
    });

    if (res.success) {
      setTotalStandardsCount(checkForNaN(res?.result) ? 0 : (res?.result));
    }
  };

  useEffect(() => {
    getTotalStandardsCount();
  }, []);

  return (
    <FlipCard
      isFilp={false}
      title="العدد الكلي للمعايير"
      frontContent={
        <div className="w-full h-[400px] flex flex-col gap-5 justify-center items-center">
          <p className="text-blue font-semibold text-3xl">
            {totalStandardsCount}
          </p>
          <p className="text-blue font-semibold text-3xl">
            العدد الكلي للمعايير
          </p>
        </div>
      }
    />
  );
}

export default StandardsCount;
