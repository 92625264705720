import { useHistory } from "react-router";
import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import usePrevPocOfficerAnswer from "../useGetPrevAnswer/usePrevPocOfficerAnswer";

const useCreatePocOfficerAnswer = () => {
  const history = useHistory();
  const { getPrevPocOfficerAnswer } = usePrevPocOfficerAnswer();

  const { newPocOfficerAnswer, pocOfficerDegree, setCreateAnswerLoading } =
    useAnswerContext();

  const createPocOfficerAnswer = async (standardId, entityId, currentStage) => {
    console.log(
      newPocOfficerAnswer?.standardRequirements,
      "newPocOfficerAnswer?.standardRequirements -  newPocOfficerAnswer?.standardRequirements - newPocOfficerAnswer?.standardRequirements"
    );
    const pocOfficerRequirementAnswers =
      newPocOfficerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || "",
            recommendation: req?.subReq || "",
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          recommendation: req?.note || "",
          donotApply: req?.donotApply,
          pocOfficerSubRequirementAnswers: subStandardRequirements,
        };
      });

    console.log(
      pocOfficerRequirementAnswers,
      "pocOfficerRequirementAnswers - pocOfficerRequirementAnswers - "
    );

    // * Proof Files
    // const agencyProofAnswers = newPocOfficerAnswer?.standardProofs?.map(
    //   (file) => {
    //     return {
    //       standardProofId: file?.standardProofId,
    //       note: file?.note,
    //     };
    //   }
    // );

    // const data = {
    //   surveyStageId: Number(currentStage),
    //   standardId: Number(standardId),
    //   agencyId: Number(entityId),
    //   reviewerId: Number(localStorage.getItem("userId")),
    //   pocOfficerRequirementAnswers,
    //   // agencyProofAnswers,
    //   noteForAgency: {
    //     content: "",
    //   },
    //   selfEvaluationCommitmentLevelId:
    //     reviewerDegree?.selfEvaluationCommitmentLevel?.id,
    //   finalCommitmentLevelId: newPocOfficerAnswer?.checkedreviewerDegree?.id,
    //   selfEvaluationDegree: reviewerDegree?.selfEvaluationDegree,
    //   finalDegree: newPocOfficerAnswer?.checkedreviewerDegree?.fixedDegree,
    //   donotApply: false,
    //   visitId: null,
    // };

    const surveyPOCOfficerAnswerStandardProofs =
      newPocOfficerAnswer?.standardProofs
        ?.filter((proof) => proof?.pocOfficerProofNotes)
        .map((proof) => ({
          standardProofId: proof?.standardProofId || null,
          pocOfficerProofNotes: proof?.pocOfficerProofNotes || "",
        })) || [];

    console.log(
      newPocOfficerAnswer,
      "newPocOfficerAnswer - newPocOfficerAnswer"
    );
    console.log(
      newPocOfficerAnswer,
      "newPocOfficerAnswer - newPocOfficerAnswer"
    );

    // validation
    // if (newPocOfficerAnswer?.checkedPocOfficerDegree?.value == null) {
    //   return errorToast("لابد من وجود اجابة للتدقيق");
    // }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number((currentStage || 0)),
      pocOfficerRequirementAnswers,
      surveyPOCOfficerAnswerStandardProofs,
      noteForAgency: {
        unCheckRequirement:
          newPocOfficerAnswer?.newRequirementsForPOCOfficer == undefined
            ? ""
            : `<ul style="padding : 6px 0 ">` +
              newPocOfficerAnswer?.newRequirementsForPOCOfficer +
              "</ul>",
        template: "",
        content: newPocOfficerAnswer?.pocOfficerSelectedTemplate,
        noteTemplateId: newPocOfficerAnswer?.selectedTemplatesForAgency,

        // content:
        //   newPocOfficerAnswer?.selectedTemplatesForAgency == undefined
        //     ? "<p>لا يوجد</p>" +
        //       "<ul>" +
        //       newPocOfficerAnswer?.newRequirementsForPOCOfficer +
        //       "</ul>"
        //     : newPocOfficerAnswer?.selectedTemplatesForAgency +
        //       "<ul>" +
        //       newPocOfficerAnswer?.newRequirementsForPOCOfficer +
        //       "</ul>",
        // template: newPocOfficerAnswer?.pocOfficerSelectedTemplate,
      },
      noteForReviewer: {
        content: "",
      },
      pocOfficerId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree: newPocOfficerAnswer?.checkedPocOfficerDegree?.fixedDegree,
      finalCommitmentLevelId:
        newPocOfficerAnswer?.checkedPocOfficerDegree?.value,
      selfEvaluationCommitmentLevelId:
        pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree:
        pocOfficerDegree?.pocOfficerSelfEvaluationDegree || 0,

      pocOfficerAnswerNote:
        pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedPocOfficerDegree?.title
          ? newPocOfficerAnswer?.pocOfficerAnswerNote
          : null,

      needVisit: newPocOfficerAnswer?.needVisit || false,
      visitId: newPocOfficerAnswer?.visitId || null,
      visitNote: newPocOfficerAnswer?.visitNote || "",
      visitRecommendation: newPocOfficerAnswer?.visitRecommendation || "",
      donotApply: newPocOfficerAnswer?.donotApply || false,
    };

    console.log(data, "newPocOfficerAnswer - data");

    // if (
    //   pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
    //     newPocOfficerAnswer?.checkedPocOfficerDegree?.title &&
    //   !newPocOfficerAnswer?.pocOfficerAnswerNote
    // ) {
    //   errorToast(
    //     "الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة ضابط الاتصال"
    //   );
    //   return;
    // }

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addPocOfficerAnswer, data);
    if (res?.success) {
      successToast("تم إضافة الإجابة بنجاح");

      getPrevPocOfficerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createPocOfficerAnswer };
};

export default useCreatePocOfficerAnswer;
