import AppButton from "../../../../../../components/buttons/appButton";
import AppActions from "../../../../../../components/appActions";
import UpdateSurveyModal from "./updateSurveyModal";
import {useContext, useState} from "react";
import AppDeleteItemModal from "../../../../../../components/Models/appDeleteItemModal";
import environment from "../../../../../../services/environment";
import {IndicatorsContext} from "../../../../../../contexts/indicatorsContext";
import {useHistory} from "react-router-dom";
import AppModal from "../../../../../../components/Models/appModal";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";

const IndicatorSurveyItem = ({
                               id,
                               indicatorId,
                               title,
                               description,
                               status,
                               interlocutorsCount,
                               standardsCount,
                               perspectivesCount,
                               goPageSetting,
                               subRequirementsCount,
                             }) => {
  const {removeDeletedIndicatorSurveys} = useContext(IndicatorsContext);
  const [openEditModal, setopenEditModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [selectedPOCOfficers, setSelectedPOCOfficers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [pocOfficers, setPocOfficers] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);
  const [loading, setLoading] = useState(false);
  const useType = localStorage.getItem("userType");
  const history = useHistory();


  const handleChangeExperts = (value) => {
    setSelectedExperts(value || []);
  };
  const handleChangeReviewers = (value) => {
    setSelectedReviewers(value || []);
  };

  const handleChangePOCOfficers = (value) => {
    setSelectedPOCOfficers(value || []);
  };


  return (
    <div className="w-full">
      <div className="border-[1px] bg-white  shadow hover:shadow-lg border-gray-200 rounded-2xl  p-3 px-5">
        <h3 className="text-blue">{title}</h3>

        <div className="flex justify-between items-end mt-3 mb-1">
          <div className="flex justify-between flex-wrap  gap-x-8 gap-2 mb-1">
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد القدرات</p>
              <p className=" text-green">{perspectivesCount}</p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد العناصر</p>
              <p className=" text-green">{interlocutorsCount}</p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد المعايير</p>
              <p className=" text-green">{standardsCount}</p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد الأسئلة</p>
              <p className=" text-green">{subRequirementsCount ?? 0}</p>
            </div>
          </div>

          <div className="flex items-end gap-3">
            <div className="mt-1 flex flex-row">
              <AppActions
                handleEditClick={() => {
                  setopenEditModal(true);
                }}
                handleDeleteClick={() => {
                  setopenDeleteModal(true);
                }}
                // handleAssignAgenciesToExpertAndReviewerClick={() => {}}
                handleSettingClick={()=>{
                  localStorage.setItem("NavIndicatorId" , indicatorId)
                  goPageSetting()
                }}
                // handleAssignExperts={() => {
                //   setShowAssignModal(true);
                // }} 
                hideDelete={status == 2}
                hideEdit={status == 2}
                hideSettings={status == 2}
              />
              <AppDeleteItemModal
                id={id}
                modalTitle="حذف استبيان"
                itemTitle={title}
                openModal={openDeleteModal}
                setOpenModal={setopenDeleteModal}
                api={environment.deleteSurvey}
                params={{ id }}
                removeItemFromList={removeDeletedIndicatorSurveys}
              />
              <UpdateSurveyModal
                openModal={openEditModal}
                setOpenModal={setopenEditModal}
                values={{ id, title, indicatorId, description }}
              />
              <AppModal
                handleSubmit={() => {}}
                open={showAssignModal}
                setOpen={setShowAssignModal}
                loading={loading}
                submitLabel="إضافة"
              >
                <NewDropdownMultiSelect
                  label={"الخبراء"}
                  placeholder={"اختر خبراء"}
                  options={experts}
                  handleChange={handleChangeExperts}
                  value={selectedExperts}
                  isMultiple={true}
                />
                <div className="mt-3">
                  <NewDropdownMultiSelect
                    label={"المدققين"}
                    placeholder={"اختر مدققين"}
                    options={reviewers}
                    handleChange={handleChangeReviewers}
                    value={selectedReviewers}
                    isMultiple={true}
                  />
                </div>

                {/* <div className="mt-3">
                  <NewDropdownMultiSelect
                    label={"ضباط الاتصال"}
                    placeholder={"اختر ضباط اتصال"}
                    options={pocOfficers}
                    handleChange={handleChangePOCOfficers}
                    value={selectedPOCOfficers}
                    isMultiple={true}
                  />
                </div> */}
              </AppModal>
            </div>
            {/* <Link to={`/programs/${id}/perspectives`}> */}
            <div>
              <AppButton
                more
                fullwidth
                onClick={() =>{
                  const search = new URLSearchParams();
                  search.append("indicatorId", indicatorId);
                  localStorage.setItem("NavIndicatorId", indicatorId);
                  console.log("search.toString()", search.toString());

                  history.push({
                    pathname: `/programs/${(id ?? 0)}/perspectives`,
                    indicatorId: indicatorId,
                    search:  "?" + search.toString(),
                  })
                }
                }
              >
                المزيد
              </AppButton>
            </div>

            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSurveyItem;
