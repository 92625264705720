import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, Form, Formik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { AppInput } from "../../../../../components";
import NewDropdownMultiSelect from "../../../../../components/NewDropDownMultiSelect";
import AppButton from "../../../../../components/buttons/appButton";
import { axiosGetRequest, axiosPostRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { convertHtmlToString } from "../../../../../helpers/utils";

const defaultSidesValue = {
  title: "",
  description: "",
  relatedSubRequirementIds: [],
};

const initialValues = {
  title: "",
  description: "",
  relatedSubRequirementIds: [],
};


const AddSurveySide = ({degreeList, setDegreeList}) => {
  const {surveyId} = useParams();
  const [side, setSide] = useState(defaultSidesValue);
  const [loading, setLoading] = useState(false);

  const {
    data: allQuestions = [],
    isLoading: isLoadingGetSubStandardRequirementsBySurveyId,
  } = useQuery({
    queryKey: ["getSubStandardRequirementsBySurveyId", surveyId],
    queryFn: () =>
      axiosGetRequest(environment.getSubStandardRequirementsBySurveyId, {
        surveyId: surveyId,
      }),
    enabled: !!surveyId,
    select: (data) => data.result,
  });

  console.log({ allQuestions, isLoadingGetSubStandardRequirementsBySurveyId });

  const handleInputsChange = (e) => {
    setSide({...side, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPostRequest(environment.addSide, {
      ...side,
      surveyId,
      relatedSubRequirementIds: side.relatedSubRequirementIds.map((item) => item.value),
    }, 
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (error) => {
      setLoading(false);
    })


    if (res.success == true) {
      toast.success("تم إضافة بعد ");
      const newDegrees = [...degreeList, res.result];
      setDegreeList(newDegrees);
      setSide(defaultSidesValue);
    }

    setLoading(false);
  };

  const handleChangeStandardProofRequirements = (value) => {
    setSide({ ...side, relatedSubRequirementIds: value });
  };

  const validate = (values) => {
    console.log({values});
    const errors = {};

    if (!values.title) {
      errors.title = t("لا يمكن ترك هذا الحقل فارغ");
    }

    if (!values.description) {
      errors.description = t("لا يمكن ترك هذا الحقل فارغ");
    }

    if (!values.relatedSubRequirementIds || !values.relatedSubRequirementIds.length) {
      errors.relatedSubRequirementIds = t("يجب اختيار سؤال واحد على الأقل");
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => validate(side)}
      onSubmit={handleSubmit}
    >
      {({handleChange, values}) => (
        <Form className="">
          <div className="row">
            <div className="col-md-4 mb-3">
              <AppInput
                type={"text"}
                label={"اسم البعد"}
                name="title"
                value={side.title}
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>

            <div className="col-md-4">
              <AppInput
                type={"text"}
                label={"وصف البعد"}
                name="description"
                value={side.description}
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="description"
                component="div"
              />
            </div>
            <div className="col-md-4">
              <NewDropdownMultiSelect
                label="الأسئلة المرتبطة"
                value={side.relatedSubRequirementIds}
                handleChange={handleChangeStandardProofRequirements}
                options={allQuestions.map((item) => ({
                  label: "(" + item.code + ") "  + (item.title || convertHtmlToString(item.recommendation)),
                  value: item.id,
                }))}
                isMultiple={true}
                isRequired={true}
                isClearable={true}
                isSearchable={true}
                withoutLabel={false}
                // errorMessage={errors.standardProofSubRequirements}
              />
              <ErrorMessage
                className="text-red-700"
                name="relatedSubRequirementIds"
                component="div"
              />
          </div>


            <div className="col-md-4 self-end">
              <div className="text-end ">
                <AppButton type="submit" loading={loading}>
                  إضافة جديد
                </AppButton>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSurveySide;
