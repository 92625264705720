import React, { useContext } from "react";
import AppButton from "../../../../../../../components/buttons/appButton";
import { StageContext } from "../../../../../../../contexts/stageContext";

const SaveBtn = ({ onClick, pocAnswer = false }) => {
  const { stageRoles } = useContext(StageContext);

  return (
    <div className=" sticky bottom-0 left-0 w-full h-16 ">
      <div className="bg-white_gred h-full flex justify-between  items-start pl-8">
        {!stageRoles.includes(localStorage.getItem("userType")) && (
          <p className="text-danger text-lg mt-3 pr-5">
            لا يمكنك الإجابة فى هذه المرحلة
          </p>
        )}
        {pocAnswer && (
          <p className="text-danger text-lg mt-3 pr-5">
            لقد تم إرسال الإجابات ، لا يمكنك الإجابة الآن
          </p>
        )}
        <div className="mr-auto">
          <AppButton
            disabled={
              !stageRoles.includes(localStorage.getItem("userType")) ||
              pocAnswer
            }
            className="text-xl font-semibold px-12"
            onClick={onClick}
          >
            حــفــظ
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default SaveBtn;
