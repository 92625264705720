import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";
import { checkForNaN } from "../../../../modules/main/features/standerds/standardAnswer/answers/utils";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

export const useGetAnsweredStandards = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingAnsweredStandards } = useQuery({
    queryKey: ["AnsweredStandards", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.PrepareAnsweredStandards,
        {
          KeyWord: filterValue,
          surveyId: +surveyId,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: ifNotDefinedReturnZero(phaseOneStageId),
        },
        null,
        true,
        true,
        false,
        signal
      ),
  });

  const answeredStandards = data?.result?.items;
  const answeredStandardsCount = data?.result?.totalCount;
  const totalAnsweredStandardsCount = data?.result?.totalAnsweredStandardsCount;
  const answeredStandardsPercentage = data?.result?.answeredStandardsPercentage;
  const totalAnsweredNum =
    checkForNaN(data?.result?.totalAnsweredAgenciesInAllAnsweredStandardsCount) ? 0 : (data?.result?.totalAnsweredAgenciesInAllAnsweredStandardsCount);
  const totalPages = Math.ceil(answeredStandardsCount / 4);

  return {
    queryClient,
    data,
    answeredStandards,
    answeredStandardsCount,
    answeredStandardsPercentage,
    totalAnsweredStandardsCount,
    totalAnsweredNum,
    totalPages,
    isGettingAnsweredStandards,
  };
};
