import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {pen, plusLight, removeIcon, settingDark, personIcon} from "../assets/icons/Index";
import { plus, plusWhite } from "../assets/icons/questions";
import {permissionsCheck} from "../helpers/utils";
import NewTooltip from "./NewTooltip";

const AppActions = ({
  handleSettingClick,
  handleEditClick,
  handleDeleteClick,
  hideSettings = false,
  hideEdit = false,
  hideDelete = false,
  handleAssignExperts,
  handleAssignAgenciesToExpertAndReviewerClick,
  page,
}) => {
  return (
    <div className="flex items-center justify-end gap-1">
      {handleAssignAgenciesToExpertAndReviewerClick && (
        <NewTooltip content={"إضافة الجهات لمستخدمين"}>
          <div
            role="button"
            onClick={handleAssignAgenciesToExpertAndReviewerClick}
            className="rounded-full p-1 bg-[#228B221A] cursor-pointer flex items-center"
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: 20, color: "#228B22" }}
            />
          </div>
        </NewTooltip>
      )}
      {handleSettingClick && !hideSettings && (
        <NewTooltip content={"الإعدادات"}>
          <div
            role="button"
            onClick={handleSettingClick}
            className="rounded-full p-1 bg-[#EE5D501A] cursor-pointer"
          >
            <img src={settingDark} alt="" />
          </div>
        </NewTooltip>
      )}
      {/* {handleAssignExperts && (
        <NewTooltip content={"إضافة خبراء"}>
          <div
            role="button"
            onClick={handleAssignExperts}
            className="rounded-full p-1 bg-[#EE5D501A] cursor-pointer"
          >
            <img src={personIcon} alt="إضافة خبراء" />
          </div>
        </NewTooltip>
      )} */}
      {permissionsCheck("Pages.Indicators.Edit") ? (
        <div>
          {handleEditClick && !hideEdit && (
            <NewTooltip content={"تعديل"}>
              <div
                role="button"
                onClick={handleEditClick}
                className="rounded-full p-1 bg-[#FFF6DC] cursor-pointer w-[40px]"
              >
                <img src={pen} alt="edit icon" />
              </div>
            </NewTooltip>
          )}
        </div>
      ) : null}
      {permissionsCheck("Pages.Indicators.Delete") ? (
        <div>
          {handleDeleteClick && !hideDelete && (
            <NewTooltip content={"حذف"}>
              <div
                role="button"
                onClick={handleDeleteClick}
                className="rounded-full p-1 bg-[#FEEDEF] cursor-pointer"
              >
                <img src={removeIcon} alt="delete icon" />
              </div>
            </NewTooltip>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AppActions;
