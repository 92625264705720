import { useQuery } from "@tanstack/react-query";
import { ErrorMessage } from "formik";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AppInput } from "../../../../../components";
import AppModal from "../../../../../components/Models/appModal";
import NewDropdownMultiSelect from "../../../../../components/NewDropDownMultiSelect";
import { NewForm } from "../../../../../components/form/NewForm";
import {
  axiosGetRequest,
  axiosPutRequest,
} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { convertHtmlToString } from "../../../../../helpers/utils";

const initialValues = {
  title: "",
  description: "",
  relatedSubRequirementIds: [],
};

const UpdateSideModal = ({
  id,
  openModal,
  setOpenModal,
  values,
  sidesList,
  setSidesList,
  subStandardRequirementSides = [],
}) => {
  console.log("UpdateSideModal", {
    id,
    openModal,
    setOpenModal,
    values,
    sidesList,
    setSidesList,
  });

  console.log({ subStandardRequirementSides });
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    {
      id: values.id || "",
      surveyId: values.surveyId || "",
      title: values.title || "",
      description: values.description || "",
      relatedSubRequirementIds:
        subStandardRequirementSides.map((item) => ({
          label: item.title,
          value: item.subStandardRequirementId,
        })) || [],
    }
  );

  useEffect(() => {
    setInputs({
      id: values.id || "",
      surveyId: values.surveyId || "",
      title: values.title || "",
      description: values.description || "",
      relatedSubRequirementIds:
        subStandardRequirementSides.map((item) => ({
          label: item.title,
          value: item.subStandardRequirementId,
        })) || [],
    });
  }, [subStandardRequirementSides, values]);


  const {
    data: allQuestions = [],
    isLoading: isLoadingGetSubStandardRequirementsBySurveyId,
  } = useQuery({
    queryKey: ["getSubStandardRequirementsBySurveyId", values.surveyId],
    queryFn: () =>
      axiosGetRequest(environment.getSubStandardRequirementsBySurveyId, {
        surveyId: values.surveyId,
      }),
    enabled: !!values.surveyId,
    select: (data) => data.result,
  });

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  function handleChangeStandardProofRequirements(value) {
    setInputs({ ...inputs, relatedSubRequirementIds: value });
  }

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPutRequest(
      environment.updateSide,
      {
        ...inputs,
        relatedSubRequirementIds: inputs.relatedSubRequirementIds.map(
          (item) => item.value
        ),
      }
    );
    setLoading(false);
    if (res?.success) {
      toast.info("تم تحديث البعد بنجاح");
      const editValue = sidesList?.map((item) => {
        if (item.id == id) return { ...item, ...res.result };
        return item;
      });
      setSidesList(editValue);
      console.log(res);
      setOpenModal(false);
    }
  };

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = t("لا يمكن ترك هذا الحقل فارغ");
    }

    if (!values.description) {
      errors.description = t("لا يمكن ترك هذا الحقل فارغ");
    }

    if (
      !values.relatedSubRequirementIds ||
      !values.relatedSubRequirementIds.length
    ) {
      errors.relatedSubRequirementIds = t("يجب اختيار سؤال واحد على الأقل");
    }

    return errors;
  };


  // this is some kind of not good
  inputs.relatedSubRequirementIds = inputs.relatedSubRequirementIds.map(
    (item) => ({
      label: allQuestions.find((q) => q.id == item.value)?.title || convertHtmlToString(allQuestions.find((q) => q.id == item.value)?.recommendation),
      value: item.value,
    })
  );

  return (
    <AppModal
      headerTitle="تحديث البعد"
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleFormik}
      type={"submit"}
      loading={loading}
      submitLabel="تحديث"
      stopScroll
    >
      <NewForm
        initialValues={initialValues}
        validate={() => validate(inputs)}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t("اسم البعد")}
              variant="gray"
              value={inputs.title}
              name="title"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="title"
              component="div"
            />
          </div>
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.description}`}
              label={t("وصف البعد")}
              variant="gray"
              value={inputs.description}
              name="description"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="description"
              component="div"
            />
          </div>
          <div className="mb-1">
            <NewDropdownMultiSelect
              label="الأسئلة المرتبطة"
              value={inputs.relatedSubRequirementIds}
              handleChange={handleChangeStandardProofRequirements}
              options={allQuestions.map((item) => ({
                label: "(" + item.code + ") " + (item.title || convertHtmlToString(item.recommendation)),
                value: item.id,
              }))}
              isMultiple={true}
              isRequired={true}
              isClearable={true}
              isSearchable={true}
              withoutLabel={false}
              // errorMessage={errors.standardProofSubRequirements}
            />
            <ErrorMessage
              className="text-red-700"
              name="relatedSubRequirementIds"
              component="div"
            />
          </div>
        </div>
      </NewForm>
    </AppModal>
  );
};

export default UpdateSideModal;
