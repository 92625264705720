import environment from "../services/environment";
import { axiosPostRequest } from "../services/Request";

export const useGetAttachmentBase64URL = async (attachmentId) => {
    if (attachmentId) {
        const queryParams = new URLSearchParams();
        queryParams.append("id", attachmentId);

        const resp = await axiosPostRequest(
            environment.downloadFile,
            null,
            queryParams,
            true,
            true,
            true
        );

        if (resp) {
            const blob = new Blob([resp], { type: "application/octet-stream" });
            function blobToBase64(blob) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result); // Base64 string
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            }

            const base64URL = await blobToBase64(blob);

            return base64URL; // Return the Base64 string
        }
    }
    return null;
}
