import React, { useContext, useEffect, useState } from "react";
import {
  AppInput,
  DropdownMultiSelect,
  InputLabel,
} from "../../../../../../../components";
import AppButton from "../../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../../addStandardContext";
import { produce } from "immer";
import { errorToast, successToast } from "../../../../../../../helpers/toast";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { convertHtmlToString, generateRandomNumber, uniqueBy } from "../../../../../../../helpers/utils";
import { useParams } from "react-router";
import AppConfirmModal from "../../../../../../../components/Models/appConfirmModal";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import AppEditor from "../../../../../../../components/form/appEditor";

const AddQuestion = ({ requirementIndx }) => {
  const { interlocutorId } = useParams();
  const { setSubRequirementCount, addFinalValue, setAddFinalValue, questionsBank, setQuestionsBank } =
    useContext(AddStandardContext);
  const [ selectedBankQuestion, setSelectedBankQuestion ] = useState(null);
  const [ confirmModal, setConfirmModal ] = useState(false);

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    title: "",
    recommendation: "",
    subStandardRequirementAnswerMandatory: "",
    subStandardRequirementType: "",
    subStandardRequirementHaveAttachments: "",
    numOfTextFields: 1,
  });

  const isDuplicatedQuestion = (newQuestionTitle) => {
    const allSubStandardRequirements = addFinalValue?.standardRequirements?.map(item => item.subStandardRequirements).flat()

    return allSubStandardRequirements.find(item => item.title?.trim() === newQuestionTitle);
  }
  
  const handleChangeSubStandardRequirementAnswerMandatory = (value) => {
    setValues(values => ({
      ...values,
      subStandardRequirementAnswerMandatory: value,
    }));
  };

  const handleChangeSubStandardRequirementType = (value) => {
    setValues(values => ({
      ...values,
      subStandardRequirementType: value,
    }));
  };

  const handleChangeSubStandardRequirementHaveAttachments = (value) => {
    setValues(values => ({
      ...values,
      subStandardRequirementHaveAttachments: value,
    }));
  };

  const handleChange = (e) => {
    setValues(values => ({ ...values, [e.target.name]: e.target.value?.trimStart() }));
  };

  const handleRecommendationChange = (value) => {
    setValues(values => ({ ...values, recommendation: value }));
  };

  const handleSubmit = async () => {
    if (!values?.recommendation || !values.subStandardRequirementType || !values.subStandardRequirementHaveAttachments) {
      errorToast("يجب إدخال البيانات المطلوبة للسؤال ");
      return;
    }

    if (
      values.numOfTextFields < 1 &&
      values.subStandardRequirementType?.value === 2
    ) {
      errorToast("يجب إدخال عدد النصوص");
      return;
    }


    try {
      const isDuplicated = isDuplicatedQuestion(values.title);
      if (
        !isDuplicated ||
        (selectedBankQuestion?.title &&
          (!values.questionBankId || (values.title !== selectedBankQuestion.title))
        )) {
        setLoading(true);
        let res;
        res = await axiosPostRequest(environment.questionBankCreate, {
          title: values.title,
          recommendation: values.recommendation,
          answerMandatory:
            values.subStandardRequirementAnswerMandatory?.value ?? 1,
          requirementType: values.subStandardRequirementType?.value ?? 1,
          haveAttachments:
            values.subStandardRequirementHaveAttachments?.value ?? 1,
          numOfTextFields: values.numOfTextFields,
          interlocutorId,
        });
        setLoading(false);
        if (res?.success || values.questionBankId) {
          handleAddingQuestion(res?.result?.id);
          getQuestionBank()
        }
      } else {
        setConfirmModal(true); 
      }
    } catch (err) {
      errorToast("حدث خطأ ");
    }

  };

  function handleAddingQuestion(questionsBankId) {
    successToast("تم إضافة السؤال بنجاح");
    setSubRequirementCount((prev) => prev + 1);
    const randomNumber = generateRandomNumber(0, 2147483647)
    setAddFinalValue(
      produce((draft) => {
        const newSubRequirement = {
          title: values.title,
          recommendation: values.recommendation,
          numOfTextFields: values.numOfTextFields,
          answerMandatory:
            values.subStandardRequirementAnswerMandatory?.value ?? 1,
          requirementType: values.subStandardRequirementType?.value ?? 1,
          haveAttachments:
            values.subStandardRequirementHaveAttachments?.value ?? 1,
          id: randomNumber,
          questionBankId: questionsBankId ?? values.questionBankId,
          numberOfStandardRequirement: randomNumber,
          isExisted: false,
        };

        if (
          !draft.standardRequirements[requirementIndx].subStandardRequirements
        ) {
          draft.standardRequirements[
            requirementIndx
          ].subStandardRequirements = [];
        }
        draft.standardRequirements[
          requirementIndx
        ].subStandardRequirements.push(newSubRequirement);
        draft.questionBankIds?.push(questionsBankId ?? values.questionBankId);
      })
    );

    setConfirmModal(false);
    setValues({
      title: "",
      recommendation: "",
      subStandardRequirementAnswerMandatory: "",
      subStandardRequirementType: "",
      subStandardRequirementHaveAttachments: "",
      numOfTextFields: 1,
    });
  }

  function handleNumberOfTextBoxes(e) {
    const numOfTextFields = e.target.value;
    console.log("numOfTextFields", numOfTextFields);
    setValues({
      ...values,
      numOfTextFields: numOfTextFields,
    });
  }

  const getQuestionBank = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("MaxResultCount", "10000");
    searchParams.append("interlocutorId", interlocutorId);
    
    try {
      const res = await axiosGetRequest(environment.questionBankGetAll + '?' + searchParams.toString());
      setQuestionsBank(uniqueBy(res?.result?.items ?? [], "title"));
    } catch (err) {
      errorToast("حدث خطأ في بنك الأسئلة");
    }
  };

  useEffect(() => {
    getQuestionBank();
  }, []);

  const handleQuestionBankChange = (selection) => {
    const question = questionsBank.find((item) => item.id === selection.value);
    setSelectedBankQuestion(question);
    if (question) {
      setValues({
        title: question.title,
        recommendation: question.recommendation,
        subStandardRequirementAnswerMandatory: question.answerMandatory === 1 ? { label: "اجباري", value: 1 } : { label: "اختياري", value: 2 },
        subStandardRequirementType: question.requirementType === 1 ? { label: "نعم او لا ", value: 1 } : { label: "نص ", value: 2 },
        subStandardRequirementHaveAttachments: question.haveAttachments === 1 ? { label: "نعم", value: 1 } : { label: "لا", value: 2 },
        numOfTextFields: question.numOfTextFields,
        questionBankId: selection.value,
      });
    }
  };

  console.log({ questionsBank })

  return (
    <div className="bg-white p-5 pb-7 rounded-lg mt-7">
      <div className="flex items-center justify-between">
        <h2 className="font-semibold mb-4">إضافة سؤال </h2>
        <div className="test w-[460px]">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={true}
            isSearchable={true}
            handleChange={handleQuestionBankChange}
            placeholder="اختر من بنك الأسئلة"
            options={questionsBank?.map((item, index) => ({
              label: (index + 1).toString() + " - "+ ( item.title || convertHtmlToString(item.recommendation) ) ,
              value: item.id,
            }))}
            isMultiple={false}
            isRequired={false}
          />
        </div>
      </div>

      <div className="row gap-y-3">
        {/* <div className="col-md-6 ">
          <AppInput
            variant="gray"
            label="عنوان"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-xl-5">
          <AppInput
            variant="gray"
            label="التوصية"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اكتب هنا"
            options={classificationOptions}
            isMultiple={false}
          />
        </div> */}
        <div className="col-md-10 ">
          <AppTextarea
            rows={3}
            variant="gray"
            label="عنوان السؤال"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleChange}
            isRequired={false}
            // errorMessage={errors.title}
          />
        </div>
        <div className="col-md-10 ">
          <AppEditor
            label="وصف السؤال"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleRecommendationChange}
            isRequired={true}
          />
        </div>
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={values.subStandardRequirementAnswerMandatory}
            handleChange={handleChangeSubStandardRequirementAnswerMandatory}
            label="هل الإجابة على السؤال اختياري، أم إجباري"
            placeholder="اجباري"
            options={[
              { label: "اجباري", value: 1 },
              { label: "اختياري", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors.standardRequirementClassificationId}
          />
        </div>
        {/* {from === "add" ? null : (
          <div className="col-xl-5">
            <AppInput
              variant="gray"
              label="رقم السؤال"
              placeholder="اكتب هنا"
              name="َrecommendation"
              onChange={handleChange}
              errorMessage={errors.recommendation}
            />
          </div>
        )} */}
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={values.subStandardRequirementType}
            handleChange={handleChangeSubStandardRequirementType}
            label="نوع إجابة السؤال"
            placeholder="اختار نوع إجابة السؤال"
            //  openEneded Questions and close ended questions
            options={[
              { label: "نعم او لا ", value: 1 },
              { label: "نص ", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors.standardRequirementClassificationId}
          />
        </div>
        {/* Number of text fields */}
        {values.subStandardRequirementType?.value === 2 && (
          <div className="col-md-5">
            <InputLabel
              min={1}
              value={values.numOfTextFields}
              type={"number"}
              onChange={handleNumberOfTextBoxes}
              name={"numOfTextFields"}
              label={"عدد النصوص"}
            />
          </div>
        )}

        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            value={values.subStandardRequirementHaveAttachments}
            handleChange={handleChangeSubStandardRequirementHaveAttachments}
            label="هل له مرفقات"
            placeholder="اختر"
            options={[
              { label: "نعم", value: 1 },
              { label: "لا", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors?.agencyStandardRequirements}
          />
        </div>
      </div>

      <div className="row gap-y-4 mt-8">
        <div className="col-xl-3 align-self-end">
          <div className="text-end">
            <AppButton loading={loading} onClick={handleSubmit}>
              إضافة سؤال
            </AppButton>
          </div>
        </div>
      </div>
      <AppConfirmModal
        headerTitle={"السؤال موجود"}
        open={confirmModal}
        setOpen={setConfirmModal}
        handleSubmit={() => handleAddingQuestion(values?.questionBankId ?? null)}
        loading={loading}
        submitLabel="تخطي "
        cancelLabel="تغير"
      >
        يوجد سؤال بنفس العنوان "{values.title}"
      </AppConfirmModal>

    </div>
  );
};

export default AddQuestion;


