import { Spinner } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hayaa } from "../../../assets/imgs/Index";
import { StageContext } from "../../../contexts/stageContext";
import { errorToast } from "../../../helpers/toast";
import { logoutUser, useQuery } from "../../../helpers/utils";
import { axiosPostRequest, get } from "../../../services/Request";
import environment from "../../../services/environment";
import LoginLogo from "../../auth/LoginLogo";

const RaqmiPage = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const stageContext = useContext(StageContext);
  const history = useHistory();
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const raqamiAuth = async () => {
    const res = await axiosPostRequest(environment.RaqamiAuthenticate, {
      nationalId: query
        ?.get("ud")
        ?.split(" ")
        ?.join("+"),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    () => {
    },
  );


    if (res?.success) {
      console.log(res, "RESPONSE");
      localStorage.setItem("accessToken", res?.result?.accessToken);
      localStorage.setItem("userId", res?.result?.userId);
      getUserData(res?.result?.userId);
    } else {
      errorToast("ليس لديك صلاحيات");
      logoutUser();
      history.push("/login");
      // history.push("/login");
    }
  };
  const getUserData = (userId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getUser + "?Id=" + userId, config, (res) => {
      if (res.status == 403) {
        errorToast("ليس لديك صلاحيات");
        logoutUser();
        history.push("/login");
      } else if (res.status == 500) {
        errorToast("حدث خطأ ، يرجى التحدث للدعم الفنى");
        logoutUser();
        history.push("/login");
      } else if (res.status == 200) {
        localStorage.setItem("userId", res.data.result?.id);
        localStorage.setItem("userName", res.data.result?.name);
        localStorage.setItem("fullName", res.data.result?.fullName);
        localStorage.setItem("agencyId", res.data.result?.agencyId);
        localStorage.setItem("userType", res.data.result?.staticRole);
        localStorage.setItem("IndicatorId", 1);
        localStorage.setItem("userImage", res.data.result?.profilePicture);

        if(res?.data?.result?.showRole?.id == 0){
          errorToast("ليس لديك صلاحيات");
          logoutUser();
          return history.push("/login");
        }

        getUserPermissions(res?.data?.result?.showRole?.id);
        setLoading(false);
        // window.location.href = "/ChooseCurruntIndicator";
      }
    });
    setLoading(false);
  };

  const getUserPermissions = (roleId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getRoleForEdit + "?Id=" + roleId, config, (res) => {
      if (res.status == 200) {
        console.log(res);
        stageContext.setPermissions(res.data.result.permissions);
        stageContext.setRole(res.data.result.role);
        setLoading(false);

        localStorage.setItem(
          "perm",
          JSON.stringify(res.data.result.permissions)
        );
        history.push("/ChooseCurruntIndicator");
      }
    });
  };

  // console.log(stageContext);

  useEffect(() => {
    raqamiAuth();
  }, []);

  return (
    <div className="bg-white h-screen overflow-y-scroll  relative flex items-center">
      <div className="flex flex-col md:flex-row items-center container mx-auto p-5 h-full">
        <div className="flex-1 flex justify-center items-center py-20">
          <div className="flex bg-[#F9F9FF] rounded-[20px] h-[70vh] w-full lg:w-[670px]   flex-col items-center justify-center gap-5 ">
            <div className="mb-10">
              {/* <img src={qiyasColored} alt="qiyasLogo" /> */}
              <div  className="w-[240px]" >
                <LoginLogo />
              </div>
            </div>

            {/* <h2 className=" text-qiyas_violet whitespace-pre-line"></h2> */}

            <p className="text-lg my-3 text-green_hover font-semibold">
              جارى تسجيل الدخول
            </p>

            <button className="!bg-[#319795] rounded-2xl w-[290px] px-10 py-3 pb-4 text-white">
              <div className="flex flex-row  justify-center items-center">
                <Spinner
                  aria-label="Info spinner example"
                  color="info"
                  className="text-lg"
                />
                <span className="pl-3 pr-3 mb-2 text-white">
                  الرجاء الانتظار
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute right-10 top-10">
        <img src={hayaa} alt="hayaa" width={203.5443037974684} height={60} />
      </div>
    </div>
  );
};

export default RaqmiPage;
