import { v4 } from 'uuid';

export const serveySettingTabs = (dynamicUrl = '') => {
  return [
    // {
    //   id: v4(),
    //   title: 'التصنيف والاوزان للمتطلبات',
    //   url: dynamicUrl + '/settings'
    // },
    {
      id: v4(),
      title: 'مستوى جاهزية التبني',
      url: `${dynamicUrl}/settings/degrees`
    },
    // {
    //   id: v4(),
    //   title: 'آلية الإحتساب للإستبيان',
    //   url: `${dynamicUrl}/settings/strategy`
    // },
    // {
    //   id: v4(),
    //   title: 'قوالب الملاحظة',
    //   url: `${dynamicUrl}/settings/templates`
    // },
    {
      id: v4(),
      title: 'المراحل',
      url: `${dynamicUrl}/settings/phases`
    },
    {
      id: v4(),
      title: 'أبعاد التقييم',
      url: `${dynamicUrl}/settings/sides`
    },
    {
      id: v4(),
      title: 'إضافة الاستبیان للجهات الحكومية',
      url: `${dynamicUrl}/settings/survey-to-agency`
    },
    {
      id: v4(),
      title: 'السياسات والاحكام',
      url: `${dynamicUrl}/settings/polices`
    },
    // {
    //   id: v4(),
    //   title: 'اعدادات النموذج الابداعي',
    //   url: `${dynamicUrl}/settings/creative`
    // },
    {
      id: v4(),
      title: 'إسناد الخبراء',
      url: `${dynamicUrl}/settings/experts`
    },
    {
      id: v4(),
      title: 'إسناد الجهات الحكومية',
      url: `${dynamicUrl}/settings/government`
    },
    {
      id: v4(),
      title: 'مستوى إنشاء الأسئلة',
      url: `${dynamicUrl}/settings/questions-level`
    },
  ];
};
