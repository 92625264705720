import { Page, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import Slide19 from "../assets/images/Slide19.PNG";
export default function Page19({ styles, agencyTitle }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <View
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Image src={Slide19} style={styles.imagePage} />
        <Text
          style={{
            position: "absolute",
            top: 330,
            right: 50,
            color: "#666",
            fontSize: 22,
          }}
        >
          نتائج تقييم {agencyTitle}
        </Text>
      </View>
    </Page>
  );
}
