import NoAnswerComponent from "../../components/NoAnswerComponent";
import AuditTimeLine from "./AuditTimeLine";

function AnswersHistory({ answersHistory, showNote = true, userType, structureSubStandardRequirementDirectly }) {
  return (
    <div>
      {!answersHistory?.length ? (
        <NoAnswerComponent />
      ) : (
        <>
          {userType === "expert" ||
          userType === "reviewer" ||
          userType === "expertManager" ? (
            <AuditTimeLine
              events={answersHistory}
              showNote={showNote}
              userType={userType}
              isAnswersHistory={true}
              structureSubStandardRequirementDirectly={structureSubStandardRequirementDirectly}
            />
          ) : (
            <AuditTimeLine
              events={answersHistory}
              showNote={showNote}
              userType={userType}
            />
          )}
        </>
      )}
    </div>
  );
}

export default AnswersHistory;
