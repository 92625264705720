import { useState } from "react";
import { useGetAnswerHistory } from "./useGetAnswerHistory";
import { isAdminFunc, isAgency, isExpertManager } from "../../helpers/isPermission";
import AnswersHistory from "./AnswersHistory";
import AppBlueTabs from "../../components/AppBlueTabs";

function AuditsTabs({ standardId, agencyId }) {
  const [activeTab, setActiveTab] = useState(0);

  const { data, isGettingAnswerHistory } = useGetAnswerHistory({
    standardId,
    agencyId,
  });

  const tabs = [
    {
      title: "اٍجابات الجهة",
      content: (
        <AnswersHistory
          answersHistory={data?.agencyAnswersHistory}
          structureSubStandardRequirementDirectly={data?.structureSubStandardRequirementDirectly}
          showNote={false}
        />
      ),
    },
    !isAgency() && {
      title: "اٍجابات المدققين",
      content: (
        <AnswersHistory
          userType="reviewer"
          answersHistory={data?.reviewerAnswersHistory}
          structureSubStandardRequirementDirectly={data?.structureSubStandardRequirementDirectly}
        />
      ),
    },
    !isAgency() &&
    {
      title: "اٍجابات الخبراء",
      content: (
        <AnswersHistory
          userType="expert"
          answersHistory={data?.expertAnswersHistory}
          structureSubStandardRequirementDirectly={data?.structureSubStandardRequirementDirectly}
        />
      ),
    },
    (isAdminFunc() || isExpertManager()) && {
      title: "اٍجابات رؤساء الخبراء",
      content: (
        <AnswersHistory
          userType="expertManager"
          answersHistory={data?.expertManagerAnswersHistory}
          structureSubStandardRequirementDirectly={data?.structureSubStandardRequirementDirectly}
        />
      ),
    },
    // !isAgency() && {
    //   title: "اٍجابات ضباط الاتصال الداخليين",
    //   content: (
    //     <AnswersHistory
    //       userType="pocOfficer"
    //       answersHistory={data?.internalPOCOfficerAnswersHistory}
    //     />
    //   ),
    // },
  ].filter(Boolean);

  return (
    <AppBlueTabs
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default AuditsTabs;
