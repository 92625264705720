import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewTable from "../../../../components/NewTable";
import NewTooltip from "../../../../components/NewTooltip";

import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import {
  isAdminFunc,
  isExpert,
  isExpertManager,
  isPocOfficer,
  isReviewer
} from "../../../../helpers/isPermission";
import { convertHtmlToString } from "../../../../helpers/utils";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { checkGreenCircle, drawLeftGreen } from "../../../../assets/icons/Index";
import { removeCircleDarkRed } from "../../../../assets/icons/dashboard";
import IconBasedOnRevieweStatus from "../../features/standerds/standardEntities/IconBasedOnRevieweStatus";

const conditionalRowStyles = [
  {
    when: (row) => !row.isReviewed,
    style: {
      backgroundColor: "#faebe8",
      borderBottom: "2px solid #fcd3cb !important",
      minHeight: "60px",
      // fontWeight: 600,
      // color: "#b61409",

      "&:hover": {
        backgroundColor: "#fcd3cb",
        outlineColor: "#fcd3cb !important",
        cursor: "pointer",
      },
    },
  },

  {
    when: (row) =>
      (isPocOfficer() && row.isReviewedByPoc) ||
      ((isExpert() || isAdminFunc() || isExpertManager() || isReviewer()) &&
        row.isReviewedByPoc),
    style: {
      backgroundColor: "#bba8f040",
      // color: "#966718",
      // fontWeight: 600,
      borderBottom: "2px solid #bba8f0 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#ad95f06a",
        outlineColor: "#bba8f06f !important",
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) =>
      (isReviewer() && row.isReviewed) ||
      ((isExpert() || isAdminFunc() || isExpertManager() || isPocOfficer()) &&
        row.isReviewedByReviewer),
    style: {
      backgroundColor: "#ffff0026",
      // color: "#966718",
      // fontWeight: 600,
      borderBottom: "2px solid #f0e9a8 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#f0e9a8",
        outlineColor: "#f0e9a8 !important",
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) =>
      (isExpert() && row.isReviewed) ||
      ((isReviewer() || isAdminFunc() || isExpertManager() || isPocOfficer()) &&
        row.isReviewedByExpert),
    style: {
      backgroundColor: "#edfcec",
      // color: "#359027",
      // fontWeight: 600,
      borderBottom: "2px solid #c8ecc4 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#c8ecc4",
        outlineColor: "#c8ecc4 !important",
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => isPocOfficer() && row.isReviewedByPoc,
    style: {
      backgroundColor: "#bba8f040",
      // color: "#966718",
      // fontWeight: 600,
      borderBottom: "2px solid #bba8f0 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#bba8f06f",
        outlineColor: "#bba8f06f !important",
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => isReviewer() && row.isReviewed,
    style: {
      backgroundColor: "#ffff0026",
      borderBottom: "2px solid #f0e9a8 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#f0e9a8",
        outlineColor: "#f0e9a8 !important",
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => isExpert() && row.isReviewed,
    style: {
      backgroundColor: "#edfcec",
      // color: "#359027",
      // fontWeight: 600,
      borderBottom: "2px solid #c8ecc4 !important",
      minHeight: "60px",
      "&:hover": {
        backgroundColor: "#c8ecc4e8",
        outlineColor: "#c8ecc4 !important",
        cursor: "pointer",
      },
    },
  },
];

function StandardEntitiesTable({
  entities,
  loading,
  maxResult,
  setMaxResult,
  reviewedStatus,
  filterValue,
  customColumns,
  handleCustomRowClick
}) {
  const history = useHistory();
  const [excelLoading, setExcelLoading] = useState(false);

  const { surveyId, standardId } = useParams();

  const downloadExcel = useExcelExport();

  const columns = [
    {
      name: "كود المعيار",
      center: true,
      sortable: true,
      cell: (row) => row.standardCode || "-",
    },
    {
      name: "اسم الجهة",
      center: true,
      sortable: true,
      width: "300px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) => (
        <div className="text-center text-sm font-normal">
          <span>{row.nameInArabic || row.name || "-"}</span>
        </div>
      ),
    },
    {
      name: "اجابة الجهة",
      sortable: true,
      center: true,
      width: "140px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.agencyAnswer == null ? (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="flex items-center">
              <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            </div>
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="mb-1">{row.agencyAnswer}</p>

            &nbsp;
            {row.agencyAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}
          </div>
        ),
    },

    {
      name: "اجابة المدقق",
      sortable: true,
      center: true,
      width: "135px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.reviewerAnswer == null ? (
          <div className="flex items-center">
            <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
            <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              // color: "white",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: row?.reviewerAnswerColor,
              }}
              className={`rounded-full h-[10px] w-[10px]  ml-2 `}
            ></div>
            <span className="mb-1">{row.reviewerAnswer}</span>

            &nbsp;
            {row.reviewerAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}

          </div>
          // <div
          //   className="text-sm"
          //   style={{
          //     backgroundColor: row?.reviewerAnswerColor,
          //     color: "white",
          //     padding: 5,
          //     borderRadius: 4,
          //   }}
          // >
          //   {row.reviewerAnswer}
          // </div>
        ),
    },
    // {
    //   name: "اجابة ضابط الاتصال",
    //   sortable: true,
    //   center: true,
    //   width: "135px",
    //   style: {
    //     borderLeft: "2px solid #dfdfdf !important",
    //   },
    //   cell: (row) =>
    //     row.pocAnswer == null ? (
    //       <span className="text-xs">لا يوجد اجابة</span>
    //     ) : (
    //       <div
    //         className="text-xs bg-opacity-15"
    //         style={{
    //           backgroundColor: "rgba(0,0,0, 0.025)",
    //           // color: "white",
    //           padding: "2px 12px 6px",
    //           borderRadius: 4,
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div
    //           style={{
    //             backgroundColor: row?.pocAnswerColor,
    //           }}
    //           className={`rounded-full h-[10px] w-[10px]  ml-2 `}
    //         ></div>
    //         <span className="mb-1">{row.pocAnswer}</span>
    //       </div>
    //       // <div
    //       //   className="text-sm"
    //       //   style={{
    //       //     backgroundColor: row?.pocAnswerColor,
    //       //     color: "white",
    //       //     padding: 5,
    //       //     borderRadius: 4,
    //       //   }}
    //       // >
    //       //   {row.pocAnswer}
    //       // </div>
    //     ),
    // },
    {
      name: "اجابة الخبير",
      sortable: true,
      center: true,
      width: "135px",
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) =>
        row.expertAnswer == null ? (
          <div className="flex items-center">
            <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
            <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
          </div>
        ) : (
          <div
            className="text-xs bg-opacity-15"
            style={{
              backgroundColor: "rgba(0,0,0, 0.025)",
              // color: "white",
              padding: "2px 12px 6px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: row?.expertAnswerColor,
              }}
              className={`rounded-full h-[10px] w-[10px]  ml-2 `}
            ></div>
            <span className="mb-1">{row.expertAnswer}</span>

            &nbsp;
            {row.expertAnswer === "توجد اجابة" ? (
              <img src={checkGreenCircle} alt="done mark" width={"16px"} />
            ) : (
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            )}

          </div>
          // <div
          //   className="text-sm"
          //   style={{
          //     backgroundColor: row?.expertAnswerColor,
          //     color: "white",
          //     padding: 5,
          //     borderRadius: 4,
          //   }}
          // >
          //   {row.expertAnswer}
          // </div>
        ),
    },
    ...(isExpertManager() ? [
      {
        name: "اجابة رئيس الخبراء",
        sortable: true,
        center: true,
        width: "135px",
        style: {
          borderLeft: "2px solid #dfdfdf !important",
        },
        cell: (row) =>
          row.expertManagerAnswer == null ? (
            <div className="flex items-center">
              <span className="text-xs mb-1 pl-1">لا يوجد اجابة</span>
              <img
                src={removeCircleDarkRed}
                width={"16px"}
                alt="not answered standard"
              />
            </div>
          ) : (
            <div
              className="text-xs bg-opacity-15"
              style={{
                backgroundColor: "rgba(0,0,0, 0.025)",
                // color: "white",
                padding: "2px 12px 6px",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: row?.expertManagerAnswerColor,
                }}
                className={`rounded-full h-[10px] w-[10px]  ml-2 `}
              ></div>
              <span className="mb-1">{row.expertManagerAnswer}</span>

              &nbsp;
              {row.expertManagerAnswer === "توجد اجابة" ? (
                <img src={checkGreenCircle} alt="done mark" width={"16px"} />
              ) : (
                <img
                  src={removeCircleDarkRed}
                  width={"16px"}
                  alt="not answered standard"
                />
              )}
            </div>
          ),
      },
    ] : []
    ),
    {
      name: "حالة التدقيق",
      sortable: true,
      center: true,
      style: {
        borderLeft: "2px solid #dfdfdf !important",
      },
      cell: (row) => (
        <div className="text-center rounded-full ">
          <IconBasedOnRevieweStatus row={row} />
        </div>
      ),
    },
    {
      name: "كود الجهة",
      sortable: true,
      center: true,
      cell: (row) => <span className="text-xs"> {row.agencyCode || "-"} </span>,
    },
    // {
    //   name: "نوع الجهة",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (
    //     <span className="text-xs">
    //       {(row.isConfidentialAgency ? "جهة سرية" : "جهة غير سرية") || "-"}
    //     </span>
    //   ),
    // },

    // (isExpert() || isReviewer() || isExpertManager() || isAdminFunc()) && {
    //   name: "زيارة ميدانية",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (
    //     <>
    //       {row.hasVisit ? (
    //         <FontAwesomeIcon
    //           icon={faCheck}
    //           className="text-emerald-600 text-[20px]"
    //         />
    //       ) : (
    //         <FontAwesomeIcon
    //           icon={faXmark}
    //           className="text-red-700 text-[20px]"
    //         />
    //       )}
    //     </>
    //   )
    // },
    // (isExpert() || isReviewer() || isExpertManager() || isPOCOfficer()) && {
    //   name: "حالة الإجابة",
    //   selector: (row) => row.hasVisit,
    //   header: "hasAnswered",
    //   accessorKey: "hasAnswered",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (
    //     <div>
    //     {row.hasAnswered == true ? (
    //      <div className="w-[20px] h-[20px] rounded-full bg-[#22bb33]"></div>
    //     ):(
    //      <div className="w-[20px] h-[20px] rounded-full bg-[#bb2124]"></div>
    //     )}
    //   </div>
    //   ),
    // },
    {
      name: "الاجراءات",
      sortable: true,
      center: true,
      width: "150px",
      cell: (row) => (
        <div className="flex gap-4 items-center">
          <NewTooltip content="اجابة الجهة">
            <Link
              to={`/programs/${surveyId}/program/${row.id}/entity/${standardId}/standard`}
              className="block w-8 h-8"
            >
              <img
                src={drawLeftGreen}
                alt="drawLeft Green icon"
                // className="w-6 h-6"
              />
            </Link>
          </NewTooltip>

          <NewTooltip content="بيانات الجهة">
            <Link
              to={`/entities/${row.id}/details`}
              className="block w-6 h-6 mb-1"
            >
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-500 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer "
              />
            </Link>
          </NewTooltip>
        </div>
      ),
    },
  ].filter(Boolean);

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getAgenciesByStandardId, {
      standardId: standardId,
      IsReviewed: reviewedStatus,
      KeyWord: filterValue || null,
      SkipCount: 0,
      MaxResultCount: 500,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.result?.map((ele) => {
      let isReviewedStatus = 'غير مدقق'

      if (ele.isReviewed) {
        isReviewedStatus = 'مدقق';
      } else if (
        (isExpert() || isExpertManager() || isExpert()) && 
        ele.isReviewedByReviewer
      ) {
        isReviewedStatus = 'غير مدقق'
      } else if (isReviewer() && ele.isReviewed) {
        isReviewedStatus = 'مدقق';
      }
    
      
      return {
        standardCode: ele?.standardCode,
        agencyName: ele.nameInArabic,
        agencyCode: ele.agencyCode,
        agencySector: ele.sector,
        isReviewed: isReviewedStatus,
        // agencyAnswer: ele.agencyAnswer || "لا يوجد",
        answer: isReviewer()
          ? ele.reviewerAnswer || "-----"
          : isExpert()
          ? ele.reviewerAnswer || "-----"
          : isPocOfficer()
          ? ele.pocAnswer || "-----"
          : "",
        note: isReviewer()
          ? convertHtmlToString(ele.reviewerAgencyNote?.template || "") || "--"
          : isExpert()
          ? convertHtmlToString(ele.expertAgencyNote?.template || "") || "--"
          : isExpertManager()
          ? convertHtmlToString(ele.expertManagerAgencyNote?.template || "") || "--"
          : isPocOfficer()
          ? convertHtmlToString(ele.pocAgencyNote?.template || "") || "--"
          : "",
        agencyType: ele.isConfidentialAgency ? "جهة سرية" : "جهة غير سرية",
      };
    });

    downloadExcel("الجهات الحكومية", dataForExcel);
  };

  const handleRowClick = (row) => {
    console.log(row);

    history.push(
      `/programs/${surveyId}/program/${row?.id}/entity/${standardId}/standard`
    );
    // surveyId, standardId
    //  "/programs/${surveyId}/program/${row?.id}/entity/${standardId}/standard"
  };

  return (
    <NewTable
      withMaxResultChange
      maxResultValue={maxResult}
      setMaxResultValue={setMaxResult}
      columns={customColumns ?? columns}
      data={entities}
      pagination={false}
      loading={loading}
      // withExcel
      excelLoading={excelLoading}
      conditionalRowStyles={conditionalRowStyles}
      onExportExcel={handleExcelExport}
      handleRowClick={handleCustomRowClick ?? handleRowClick}

    />
  );
}

export default StandardEntitiesTable;
