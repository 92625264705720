import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import {
  isAdminFunc,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateExpertManagerAnswer from "../useCreateAnswer/useCreateExpertManagerAnswer";
import usePrevExpertManagerAnswer from "../useGetPrevAnswer/usePrevExpertManagerAnswer";
import ExpertManagerNoteTemplate from "./expertManagerNoteTemplates";
import ExpertManagerProofFiles from "./expertManagerProofFiles";
import ExpertManagerRequirementAnswerBasedOnSides from "./StandardRequirements/ExpertManagerRequirementAnswerBasedOnSides";
import NotesForAgency from "./notesForAgency";
import { ExpertManagerRequirementAnswerBasedOnStandard } from "./StandardRequirements/ExpertManagerRequirementAnswerBasedOnStandard";

const ExpertManagerAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();
  const { loadPreExpertManagerAnswer, newExpertManagerAnswer } =
    useAnswerContext();
  const { createExpertManagerAnswer } = useCreateExpertManagerAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { entityId, standardId } = useParams();

  useEffect(() => {
    getPrevExpertManagerAnswer(standardId, entityId, currentStage?.id);
  }, [currentStage]);

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertManagerAnswer}
        />
      )}

      {loadPreExpertManagerAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 &&
            (newAgencyAnswer.surveyStructureSubStandardRequirementDirectly ? (
              <ExpertManagerRequirementAnswerBasedOnStandard
                setMenuElement={setMenuElement}
              />
            ) : (
              <ExpertManagerRequirementAnswerBasedOnSides setMenuElement={setMenuElement} />
            ))}
          {menuElement === 3 && (
            <>
              <ExpertManagerProofFiles setMenuElement={setMenuElement} />
              <NextBtn onClick={() => setMenuElement(6)} />
            </>
          )}
          {menuElement === 6 && (
            <NotesForAgency setMenuElement={setMenuElement} />
          )}
          {!isAdminFunc() && !isInquiryManager() && menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertManagerAnswer(
                  standardId,
                  entityId,
                  currentStage?.id
                )
              }
            >
              <ExpertManagerNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertManagerAnswer;

