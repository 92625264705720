import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Badge } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { programsIcon } from "../../../assets/icons/Index";
import {
  SModal,
  Title,
} from "../../../components";
import DeleteIcon from "../../../components/DeleteIcon";
import EditIcon from "../../../components/EditIcon";
import AppModal from "../../../components/Models/appModal";
import NewTable from "../../../components/NewTable";
import NewTooltip from "../../../components/NewTooltip";
import ViewIcon from "../../../components/ViewIcon";
import { StageContext } from "../../../contexts/stageContext";
import { agencyId, isAgency, isDgaUsers, isExpert, isExpertManager, isReviewer } from "../../../helpers/isPermission";
import { errorToast } from "../../../helpers/toast";
import { isDefined, truncateText } from "../../../helpers/utils";
import NewHeader from "../../../layouts/header/NewHeader";
import {
  axiosDeleteRequest,
  axiosPostRequest,
  axiosPutRequest,
  get
} from "../../../services/Request";
import environment from "../../../services/environment";
import AddEditEvaluationNote from "./AddEditEvaluationNote";

const EvaluationNote = () => {
  const { currentStage, userId } = useContext(StageContext);
  const surveyId = currentStage?.surveyId || localStorage.getItem("surveyId");
  const expertId = userId || localStorage.getItem("userId");

  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState({});

  const formRef = useRef(null);
  const initialInputsState = {
    note: "",
    recommendation: "",
    agency: null,
    prespectives: null,
    interlocutors: null,
    attachmentId: "",
    expertEvaluationNoteType: "1"
  };
  const [inputs, setInputs] = useState(initialInputsState);

  const [descriptionFile, setDescriptionFile] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");

  useEffect(() => {
    getEvaluationNote(0);
  }, []);

  const getEvaluationNote = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const queryParams = new URLSearchParams();
    queryParams.append("SurveyId", surveyId);

    queryParams.append("SkipCount", (page || 0) * 10);
    queryParams.append("MaxResultCount", 10);

    (isExpert() || isExpertManager() || isReviewer()) && queryParams.append("ExpertId", expertId);

    if (agencyId()) {
      queryParams.append("agencyId", agencyId());
    }

    get(
      `${environment.getSurveyExpertEvaluationNote}?` + queryParams.toString(),
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: "لا يوجد لديك صلاحيات" },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: "error",
              message: "هناك مشكلة في الخدمة يجب العودة للدعم الفني",
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(Math.ceil(res.data.result.totalCount / 8));
          setLogs(res.data.result.items, setLoading(false));
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getEvaluationNote(newOffset);
  };

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const handleSubmitEdit = async (row) => {
    const errors = validate()
    if (!Object.keys(errors).length) {
      setAddLoading(true);
      const interlocutorId = inputs?.interlocutors?.[0]?.value ?? null;
      const perspectiveId = inputs?.prespectives?.[0]?.value ?? null;
        
      const res = await axiosPutRequest(
        environment.updateSurveyExpertEvaluationNote,
        {
          recommendation: inputs?.recommendation || "",
          note: inputs?.note || "",
          surveyId: surveyId || 0,
          agencyId: inputs?.agency?.value,
          expertId: expertId,
          expertEvaluationNoteType: +(inputs?.expertEvaluationNoteType),
          interlocutorId: interlocutorId,
          perspectiveId: perspectiveId,
          attachmentId: isDefined(descriptionFileId) ? descriptionFileId : 0,
          id: row.id,
        }
      );
      setAddLoading(false);
      if (res?.success) {
        toast.success("تم إضافة الملاحظة بنجاح");
        // setOpen(false);
        resetInputsValues();
        getEvaluationNote(currentPage);
      } else {
        errorToast(res?.response?.data?.error?.message);
      }
    }
    else {
      formRef.current?.setErrors(errors);
      formRef.current?.submitForm();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.note.trim()) {
      errors.note = "هذا الحقل مطلوب";
    }
    if (!inputs.agency) {
      errors.agency = "هذا الحقل مطلوب";
    }
    if (
      ((inputs.expertEvaluationNoteType === "2") && !inputs.interlocutors) ||
      ((inputs.expertEvaluationNoteType === "3") && !inputs.prespectives)
    ) {
      errors.interlocutors = "هذا الحقل مطلوب";
      errors.prespectives = "هذا الحقل مطلوب";
    }

    console.log('✌️errorshhhh --->', errors);
    return errors;
  };

  const handleSubmit = async () => {
    setAddLoading(true);
    const res = await axiosPostRequest(
      environment.addSurveyExpertEvaluationNote,
      {
        ...inputs,
        surveyId: surveyId || 0,
        isApproved: isExpertManager(),
        agencyId: inputs?.agency?.value,
        expertId: expertId,
        attachmentId: descriptionFileId,
        expertEvaluationNoteType: +(inputs?.expertEvaluationNoteType),
        interlocutorIds: inputs?.interlocutors?.map(i => i?.value) ?? null,
        perspectiveIds: inputs?.prespectives?.map(i => i?.value) ?? null,
      }
    );
    setAddLoading(false);
    if (res?.success) {
      toast.success("تم إضافة الملاحظة بنجاح");
      setOpen(false);
      resetInputsValues();
      getEvaluationNote(0);
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
  };

  const resetInputsValues = () => {
    setDescriptionFile("");
    setDescriptionFileId("");
    setInputs({ ...initialInputsState });
  };

  const getIputsDetails = (row) => {
    const {
      perspectiveCode,
      perspectiveId,
      perspectiveTitle,
      interlocutorCode,
      interlocutorId,
      interlocutorTitle,
      agencyId,
      agencyTitle,
      attachmentTitle,
      attachmentId
    } = row;

    const selectedAttachment = isDefined(attachmentId)
      ? { name: attachmentTitle, attachmentId }
      : null;

    const selectedAgency = isDefined(agencyId)
      ? { label: agencyTitle, value: agencyId }
      : null;

    const selectedPrespective = isDefined(perspectiveId)
      ? [{ label: `(${perspectiveCode}) ${perspectiveTitle}`, value: perspectiveId }]
      : null;

    const selectedInterlocutors = isDefined(interlocutorId)
      ? [{ label: `(${interlocutorCode}) ${interlocutorTitle}`, value: interlocutorId }]
      : null;

    setInputs({
      note: row.note,
      recommendation: row.recommendation,
      expertEvaluationNoteType: row.expertEvaluationNoteType?.toString(),
      prespectives: selectedPrespective,
      interlocutors: selectedInterlocutors,
      agency: selectedAgency,
    });

    setDescriptionFile(selectedAttachment);
    setDescriptionFileId(attachmentId || "");
  };

  const downloadFile = async (file) => {
    const queryParams = new URLSearchParams();

    queryParams.append("id", file.id);

    const resp = await axiosPostRequest(
      environment.downloadFile,
      null,
      queryParams,
      true,
      true,
      true
    );

    if (resp) {
      const blob = new Blob([resp], { type: "application/octet-stream" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleDeleteEvaluationNote = async (id) => {
    setDeleteLoading(true);

    const res = await axiosDeleteRequest(environment.DeleteSurveyExpertEvaluationNote, {
      Id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getEvaluationNote(currentPage);
    }
  };

  async function approveSurveyExpertEvaluationNote(id){
    const res = await axiosPostRequest(
      environment.approveSurveyExpertEvaluationNote,
      null,
      {
        id,
      }
    );

    console.log({ res })

    if (res?.success) {
      toast.success("تم الاعتماد بنجاح");
      getEvaluationNote(currentPage);
    }
  }

  

  const EvaluationNoteList = [
    {
      name: "نوع الملاحظات",
      selector: (row) => row.surveyTitle,
      accessorKey: "surveyId",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          {info.expertEvaluationNoteType == "1"
            ? "الاستبيان"
            : info.expertEvaluationNoteType == "2"
            ? "العنصر"
            : "القدرة"}
        </div>
      ),
    },
    {
      name: "الجهة",
      selector: (row) => row.agencyTitle,
      accessorKey: "nameInArabic",
      footer: (props) => props.column.id,
      cell: (info) => <div>{info.agencyTitle}</div>,
    },
    ...(!isAgency()
      ? [
          {
            name: "الخبير",
            selector: (row) => row.expertName,
            accessorKey: "expertId",
            footer: (props) => props.column.id,
            cell: (info) => <div>{info.expertName}</div>,
          },
        ]
      : []),
    {
      name: "الملاحظة",
      selector: (row) => row.note,
      accessorKey: "note",
      footer: (props) => props.column.id,
      cell: (info) => <div>{truncateText(info.note, 120)}</div>,
    },
    {
      name: "التوصية",
      selector: (row) => row.recommendation,
      accessorKey: "recommendation",
      footer: (props) => props.column.id,
      cell: (info) => <div>{truncateText(info.recommendation, 120)}</div>,
    },
    {
      name: "المستند",
      selector: (row) => row.attachmentTitlee,
      accessorKey: "attachmentId",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className="underline cursor-pointer"
            onClick={() =>
              downloadFile({
                id: info.attachmentId,
                name: info.attachmentTitle,
              })
            }
          >
            {info.attachmentTitle}
          </span>
        </div>
      ),
    },
    {
      name: "معتمدة",
      selector: (row) => row.isApproved,
      accessorKey: "isApproved",
      cell: (row) => (
        <Badge
          className={classNames(
            "text-white font-medium pb-3 px-5 rounded-xl",
            { "bg-[#1cc081]": row?.isApproved },
            { "bg-red-500": !row.isApproved }
          )}
          size="sm"
        >
          {row?.isApproved ? "نعم" : "لا"}
        </Badge>
      ),
    },
    ...(!isAgency()
      ? [
          {
            name: "الاجراءات",
            selector: (row) => row.actions,
            header: "actions",
            accessorKey: "actions",
            width: "200px",
            footer: (props) => props.column.id,
            cell: (row) => (
              // permissionsCheck("Pages.UserType.Admin") && (
              <div className="flex gap-2">
                {((row.expertId == expertId && !row.isApproved) ||
                  isExpertManager()) && (
                  <>
                    <EditIcon
                      initialValuesFunction={() => getIputsDetails(row)}
                      id={row.id}
                      editFunction={() => handleSubmitEdit(row)}
                      headerTitle={"تعديل ملاحظة"}
                      editModalContent={
                        <AddEditEvaluationNote
                          formRef={formRef}
                          isAddMode={false}
                          surveyId={surveyId}
                          initialInputsState={initialInputsState}
                          inputs={inputs}
                          setInputs={setInputs}
                          setDescriptionFileId={setDescriptionFileId}
                          setDescriptionFile={setDescriptionFile}
                          descriptionFile={descriptionFile}
                          handleSubmit={handleSubmit}
                          validate={validate}
                        />
                      }
                    />

                    <DeleteIcon
                      loading={deleteLoading}
                      id={row.id}
                      deleteFunction={() => handleDeleteEvaluationNote(row.id)}
                      deletedName={"هذه الملاحظة"}
                    />
                  </>
                )}

                {isExpertManager() && !row.isApproved && (
                  <button
                    className="rounded-full focus:outline-none"
                    onClick={() => approveSurveyExpertEvaluationNote(row.id)}
                  >
                    <NewTooltip content={"أعتماد"}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="bg-[#e8f8f3] mr-1 rounded-full p-2 cursor-pointer"
                      />
                    </NewTooltip>
                  </button>
                )}

                <ViewIcon
                  initialValuesFunction={() => getIputsDetails(row)}
                  id={row.id}
                  viewFunction={() => handleSubmitEdit(row)}
                  headerTitle={"تفاصيل الملاحظة"}
                  viewModalContent={
                    <AddEditEvaluationNote
                      formRef={formRef}
                      isAddMode={false}
                      surveyId={surveyId}
                      initialInputsState={initialInputsState}
                      inputs={inputs}
                      setInputs={setInputs}
                      setDescriptionFileId={setDescriptionFileId}
                      setDescriptionFile={setDescriptionFile}
                      descriptionFile={descriptionFile}
                      handleSubmit={handleSubmit}
                      validate={validate}
                      disabled
                    />
                  }
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  // ----------------------------------------------------- //
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        onClick={() => {
          resetInputsValues();
          setOpen(true);
        }}
        title={!isDgaUsers() ? "توصيات المؤشر" : "ملاحظات وتوصيات المؤشر إلى الجهة"}
        single={isExpert()}
        withoutButton={!isDgaUsers()}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton="إضافة ملاحظة"
        style={{ backgroundColor: "#2B2969" }}
      />

      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />

      <AppModal
        loading={addLoading}
        handleSubmit={handleSubmitAdd}
        type={"submit"}
        headerTitle={"إضافة ملاحظات وتوصيات المؤشر إلى الجهة"}
        open={open}
        setOpen={(value) => {
          resetInputsValues();
          setOpen(value);
        }}
        onClose={resetInputsValues}
        submitLabel={"إضافة"}
      >
        <AddEditEvaluationNote
          formRef={formRef}
          isAddMode={true}
          surveyId={surveyId}
          initialInputsState={initialInputsState}
          inputs={inputs}
          setInputs={setInputs}
          setDescriptionFileId={setDescriptionFileId}
          setDescriptionFile={setDescriptionFile}
          descriptionFile={descriptionFile}

          handleSubmit={handleSubmit}
          validate={validate}
        />

      </AppModal>

      <div className="px-5">
        {/* <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => search()}
            handleClear={() => clear()}
          >
            <AppInput
              type={"text"}
              label={"اسم الخدمة"}
              name="serviceName"
              variant="gray"
              value={serviceName}
              isRequired={false}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <AppInput
              type={"text"}
              label={"اسم العملية"}
              name="methodName"
              variant="gray"
              value={methodName}
              isRequired={false}
              onChange={(e) => setMethodName(e.target.value)}
            />

            <AppInput
              type={"text"}
              label={"اسم المستخدم"}
              name="userName"
              variant="gray"
              value={userName}
              isRequired={false}
              onChange={(e) => setUserName(e.target.value)}
            />
          </DrawerFilter>
        </div> */}

        <NewTable
          loading={loading}
          columns={EvaluationNoteList}
          data={logs}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default EvaluationNote;
