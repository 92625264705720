import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import FModal from "./FModal";
import NewTooltip from "./NewTooltip";
import { viewNew } from "../assets/icons/Index";

const ViewIcon = ({
  loading,
  viewFunction,
  viewName,
  viewModalContent,
  initialValuesFunction,
  onClose,
  type,
  headerTitle = "",
  stopScroll,
}) => {
  const [openView, setOpenView] = useState(false);

  return (
    <>
      <NewTooltip content={" تفاصيل "}>
        <button
          onClick={() => {
            initialValuesFunction?.();
            setOpenView(true);
          }}
        >
          <img src={viewNew} className="w-[40px] cursor-pointer" alt="" />
        </button>
      </NewTooltip>

      <FModal
        stopScroll={stopScroll}
        onClose={onClose}
        submitLabel={"تفاصيل"}
        headerTitle={
          headerTitle !== "" ? headerTitle : `تحديث بيانات ${viewName}`
        }
        content={viewModalContent}
        open={openView}
        loading={loading}
        setOpen={() => setOpenView(false)}
        action={viewFunction}
        type={type}
        actions={false}
      />
    </>
  );
};

export default ViewIcon;
