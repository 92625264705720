import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../../services/environment";
import { axiosGetRequest } from "../../../../../services/Request";
import {
  expertId,
  expertManagerId,
  pocOfficerId,
  reviewerId,
} from "../../../../../helpers/isPermission";
import { useParams } from "react-router";
import { ifNotDefinedReturnZero } from "../../../../../helpers/utils";

export const useGetAgenciesByStandardId = ({
  currentPage,
  filterValue,
  reviewedStatus,
  standardId,
  maxResult,
}) => {
  const { surveyId } = useParams();
  const { data: currentStage = {} } = useQuery({
    queryKey: [
      environment.getCurrentSurveyStage
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getCurrentSurveyStage,
        {
          surveyId: ifNotDefinedReturnZero(surveyId),
        },
        undefined,
        signal
      ),
      select: (data) => data.result
  });

  const surveyStageId = (currentStage?.id || 0);
  const queryClient = useQueryClient();

  const stageId = surveyStageId || 0;

  console.log("stageId", stageId);  

  const { data, isLoading: isGettingAllAgenciesByStandardId } = useQuery({
    queryKey: [
      "AgenciesByStandardId",
      currentPage,
      filterValue,
      standardId,
      reviewedStatus,
      maxResult,
      stageId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(environment.getAgenciesByStandardId, {
        StageId: stageId,
        standardId: standardId,
        SkipCount: currentPage * maxResult,
        MaxResultCount: maxResult,
        IsReviewed: reviewedStatus,
        KeyWord: filterValue || null,
        AssignedReviewerId: reviewerId(),
        AssignedExpertId: expertId(),
        AssignedPOCOfficerId: pocOfficerId(),
      },
      true,
      signal
    ),
    // enabled: !!stageId,
  });

  const allAgenciesByStandardId = data?.result?.result;
  const totalCount = data?.result?.totalItemCountFilterd;
  const totalPages = Math.ceil(totalCount / maxResult);
  const allItemsCount = data?.result?.totalItemCountNotFilterd;
  const totalReviewedCount = data?.result?.totalReviewed;
  const totalNotReviewedCount = data?.result?.totalNotReviewed;
  const totalNotReviewedByExpert = data?.result?.totalNotReviewedByExpert;
  const totalNotReviewedByPoc = data?.result?.totalNotReviewedByPoc;
  const totalNotReviewedByReviewer = data?.result?.totalNotReviewedByReviewer;
  const totalReviewedByExpert = data?.result?.totalReviewedByExpert;
  const totalReviewedByPoc = data?.result?.totalReviewedByPoc;
  const totalReviewedByReviewer = data?.result?.totalReviewedByReviewer;
  const totalReviewedByExpertManager = data?.result?.totalReviewedByExpertManager;
  const totalNotReviewedByExpertManager = data?.result?.totalNotReviewedByExpertManager;

  return {
    queryClient,
    allAgenciesByStandardId,
    totalCount,
    totalPages,
    data,
    isGettingAllAgenciesByStandardId,
    totalReviewedCount,
    totalNotReviewedCount,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
    totalReviewedByExpertManager,
    totalNotReviewedByExpertManager
  };
};

