import { useEffect, useState } from "react";
import NewTable from "../../../../components/NewTable";
import NewCard from "../../../../components/dashboard/NewCard";
import { getStandardRatioBadge } from "../../../../helpers/utils";
import { useGetExperts } from "../api/useGetExperts";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useDashboardContext } from "../../../../contexts/DashboardContext";

function ExpertsCard() {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const { setLoading, setAuditorsDashboardStats } = useDashboardContext();

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const {
    allExperts,
    totalPages,
    isGettingAllExperts,
    totalCount,
  } = useGetExperts({
    currentPage,
    filterValue,
  });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      expertCount: totalCount,
    }));
    setLoading(isGettingAllExperts);
  }, [totalCount, isGettingAllExperts]);

  const columns = [
    {
      name: "اسم الخبير",
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.name.localeCompare(rowB.name),
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.name}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المسندة الى الخبير",
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.assignedAgenciesCount - rowB.assignedAgenciesCount,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.assignedAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير المجابة بواسطة الجهات",
      center: true,
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.answeredAgenciesCount - rowB.answeredAgenciesCount,
      cell: (row) => (
        <div className="text-center">{row.answeredAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المدققة",
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.reviewedAgenciesCount - rowB.reviewedAgenciesCount,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.reviewedAgenciesCount}</div>
      ),
    },
    {
      name: "نسبة اٍنجاز الخبير",
      center: true,
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.reviewRatio - rowB.reviewRatio,
      cell: (row) => getStandardRatioBadge(row.reviewRatio),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.GetAllExpertsWithAssignedStandards,
      {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId(),
        ExpertId: expertId(),
        KeyWord: filterValue,
        SkipCount: 0,
        MaxResultCount: 10000,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "اسم الخبير": ele?.name,
        "عدد المعايير الكلية المسندة الى الخبير": ele?.assignedAgenciesCount,
        "عدد المعايير المجابة بواسطة الجهات": ele?.answeredAgenciesCount,
        "عدد المعايير الكلية المدققة": ele?.reviewedAgenciesCount,
        "نسبة اٍنجاز الخبير": ele?.reviewRatio,
      };
    });

    downloadExcel("الخبراء", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={`الخبراء (${totalCount})`}>
      <NewTable
        withSearch
        onSearch={handleFilter}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAllExperts}
        columns={columns}
        data={allExperts || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
}

export default ExpertsCard;
