import React from "react";
import UploadFileItem from "./uploadFileItem";
import {v4} from "uuid";
import {useUploadContext} from "./uploadFilesContext";

const UploadItemsList = ({ proofId, standardId, document, relatedRequirementId, standardProofId }) => {
  const {
    files,
    uploadedErrorsCount,
    attachmentFilesCount,
  } = useUploadContext();

  let filesTodDisplay = files;
  if(relatedRequirementId){
    filesTodDisplay = files?.filter((item) => item?.relatedRequirementId == relatedRequirementId);
  }

  const renderedFiles = filesTodDisplay
    ?.filter(
      (item) => item?.proofId == proofId && (item.standardId == standardId && item.standardProofId == standardProofId)
    )
    .map((item) => <UploadFileItem key={v4()} {...item} document={document} />);
  
    
  return (
    <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-slate-800 dark:border-gray-700">
      {!!renderedFiles.length && <div className="bg-gray-50 border-b border-gray-200 rounded-t-xl py-2 pb-4 px-4 md:px-5 dark:bg-white/[.05] dark:border-gray-700">
        <div className="flex flex-wrap justify-between items-center gap-x-3">
          <div>
            <span className="text-sm font-semibold text-gray-800 dark:text-white">
              تم إرفاق ( {renderedFiles.length} ) ملف
            </span>
          </div>
        </div>
      </div>}

      <div className="p-4 pb-6 space-y-7">{renderedFiles}</div>

      {uploadedErrorsCount > 0 && (
        <div className="bg-gray-50 border-t border-gray-200 rounded-b-xl py-2 pb-4 px-4 md:px-5 dark:bg-white/[.05] dark:border-gray-700">
          <div className="flex flex-col gap-x-3">
            <div className="mb-1">
              <span className="text-sm text-red-700">
                هناك مشكلة فى إرفاق {uploadedErrorsCount} ملف
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadItemsList;
