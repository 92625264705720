import axios from "axios";
import React, {useEffect, useState} from "react";
import SecondaryTapsLayout from "../../../../layouts/secondaryTapsLayout";
import {Title} from "../../../../components";
import {plusLight, programsIcon, removeIcon} from "../../../../assets/icons/Index";
import {t} from "i18next";
import environment from "../../../../services/environment";
import {tokenConfig} from "../../../../constants/contents";
import {useParams} from "react-router";
import PermissionsDetail from "./PermissionsDetail";
import {useHistory} from 'react-router-dom'
import {permissionsCheck} from '../../../../helpers/utils'
import NewTooltip from "../../../../components/NewTooltip";
import { remove } from "../../../../services/Request";
import { toast } from "react-toastify";

const UserPermissions = () => {
  const {id} = useParams();
  const history = useHistory()
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllRoles = async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/${environment.getAllRoles}?MaxResultCount=1000`,
      tokenConfig()
    );

    setRoles(res.data?.result?.items);
    setLoading(false);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  function handleDeleteClick(input) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    remove(environment.deleteRole + "?id=" + input.id, config, (res) => {

      if(res.response?.status === 400){
        toast.error("لا يمكن حذف هذا الدور لوجود مستخدمين مرتبطين به")
      }

      if(res.response?.status === 500){
        toast.error("لا يمكنك الحذف حيث ان الدور مستخدم  بالفعل")
      }

      if(res?.status === 200){
        toast.success("تم حذف الدور بنجاح",
          {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }      

      getAllRoles();
    });
  }

  const sidebarLinks = roles?.map((role) => ({
    id: role?.id,
    title: role?.displayName,
    url: `/permissions/${role?.id}`,
  }));

  const SideBarActions = ({item})=> 
    <NewTooltip content={`حذف دور ${item.title}`}>
          <div
            role="button"
            onClick={() => handleDeleteClick(item)}
            className="rounded-full p-1 bg-[#FEEDEF] cursor-pointer"
          >
            <img src={removeIcon} alt="delete icon" />
          </div>
    </NewTooltip>;

  return (
    <SecondaryTapsLayout
      linksArr={sidebarLinks}
      loading={loading}
      SideBarActions={SideBarActions}
      breadcrumbs={
        <Title
          iconTitle={programsIcon}
          title={t("modules.main.users.permissions.title")}
          seconed={true}
          subTitle={t("modules.main.users.permissions.subTitle")}
          withoutButton={true}
          textStyle={{color: "white", fontSize: 18}}
          titleButton={t("modules.main.users.permissions.titleAddButton")}
          withIcon={true}
          icon={plusLight}
          onClick={() => history.push("/permissions/add")}
          style={{backgroundColor: "#2B2969"}}
        />
      }
    >
      <PermissionsDetail/>
    </SecondaryTapsLayout>
  );
};

export default UserPermissions;
