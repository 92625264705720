import React, { useEffect, useState } from "react";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";
import { useAnswerContext } from "../../answerContext";
import { useParams } from "react-router-dom";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import AgencyRequirementAnswer from "../Agency/agencyRequirementAnswer";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";

const PocOfficerRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newPocOfficerAnswer, setNewPocOfficerAnswer } =
    useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("pocOfficer");

  const handleRequirementChange = (requirement, property = "decision") => {
    const updatedRequirements = newPocOfficerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, "pocOfficer");

    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleRequirementNoteChange = (e, requirement) => {
    const standardRequirements = newPocOfficerAnswer?.standardRequirements?.map(
      (req) => {
        if (req?.standardRequirementId === requirement?.standardRequirementId) {
          return {
            ...req,
            note: e.target.value,
            recommendation: e.target.value,
          };
        }
        return req;
      }
    );

    setNewPocOfficerAnswer({ ...newPocOfficerAnswer, standardRequirements });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    const updatedSubRequirements =
      newPocOfficerAnswer?.standardRequirements?.map((item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          console.log(isAllSubReqChecked);

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      });
    getSelfEvaluation(
      standardId,
      updatedSubRequirements,
      entityId,
      "pocOfficer"
    );

    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSubRequirementNoteChange = (e, requirement, subRequirementId) => {
    console.log(e.target.value);
    console.log(newPocOfficerAnswer);
    console.log(requirement);

    const standardRequirements = newPocOfficerAnswer?.standardRequirements?.map(
      (req) => {
        if (req?.subStandardRequirements?.length > 0) {
          const subStandardRequirements = req?.subStandardRequirements?.map(
            (subReq) => {
              if (subReq?.subStandardRequirementId == subRequirementId) {
                return {
                  ...subReq,
                  note: e.target.value,
                  recommendation: e.target.value,
                };
              }
              return subReq;
            }
          );

          return {
            ...req,
            subStandardRequirements,
          };
        }
        return req;
      }
    );

    setNewPocOfficerAnswer({ ...newPocOfficerAnswer, standardRequirements });
  };

  const handleSelectAllReqs = (action = "selectAll") => {
    const updatedRequirements = newPocOfficerAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            ...subReq,
            donotApply: 0,
            decision: action === "selectAll" ? 1 : 0,
          })
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === "selectAll" ? 1 : 0,
        };
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, "pocOfficer");

    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const renderedPocOfficerRequirements =
    newPocOfficerAnswer?.standardRequirements?.length > 0 &&
    newPocOfficerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          "donotApply"
                        )
                      }
                    />
                    {/* <div className="px-6">
                      <AppTextarea
                        placeholder="اكتب توصية"
                        style={{
                          marginBottom: "24px",
                          backgroundColor: "#fafafa",
                        }}
                        value={subRequirement?.note || ""}
                        onChange={(e) =>
                          handleSubRequirementNoteChange(
                            e,
                            requirement,
                            subRequirement?.subStandardRequirementId
                          )
                        }
                      />
                    </div> */}
                  </>
                );
              })}
          </MainRequirement>

          {/* <div className="px-5 w-full">
            <AppTextarea
              placeholder="اكتب توصية"
              style={{ marginBottom: "24px" }}
              value={requirement?.note || ""}
              onChange={(e) => handleRequirementNoteChange(e, requirement)}
            />
          </div> */}
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == "agency"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("agency")}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
          {/* <p
            className={
              selectedRequirementsTab == "pocOfficer"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("pocOfficer")}
          >
            ضابط الاتصال
          </p> */}
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          <ReadOnlyRequirements
            requirements={newPocOfficerAnswer?.reviewerRequirementsAnswer}
          />
        )}
        {selectedRequirementsTab === "expert" && (
          <ReadOnlyRequirements
            requirements={newPocOfficerAnswer?.expertRequirementsAnswer}
          />
        )}
        {/* {selectedRequirementsTab === "pocOfficer" && (
          <>
            <div className="flex justify-end w-full gap-4">
              <p
                onClick={() => handleSelectAllReqs()}
                className="mb-6 cursor-pointer text-end text-emerald-500"
              >
                تحديد الكل
              </p>
              <p
                onClick={() => handleSelectAllReqs("cancel")}
                className="mb-6 cursor-pointer text-end text-red-800"
              >
                الغاء تحديد الكل
              </p>
            </div>
            {renderedPocOfficerRequirements}
          </>
        )} */}
      </StandardAnswerRequirement>
    </div>
  );
};

export default PocOfficerRequirementAnswer;
