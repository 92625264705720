import { Page, Image, StyleSheet } from "@react-pdf/renderer";
import Slide3 from "../assets/images/Slide3.PNG";

export default function Page3({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <Image src={Slide3} style={styles.imagePage} />
    </Page>
  );
}
