import { Page, Image, StyleSheet } from "@react-pdf/renderer";
import Slide6 from "../assets/images/Slide6.PNG";

export default function Page6({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <Image src={Slide6} style={styles.imagePage} />
    </Page>
  );
}
