import { Page, Image, View, Text } from "@react-pdf/renderer";
import Slide1 from "../assets/images/Slide1.PNG";

export default function Page1({ styles, agencyTitle, date = "22 يناير " }) {
  return (
    <Page style={styles.pageImage} size="A4">
      <View
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Image src={Slide1} style={styles.imagePage} />
        <Text
          style={{
            position: "absolute",
            top: 508,
            right: 110,
            color: "#1DC183",
            fontSize: 12,
          }}
        >
          التقرير التفصيلي {agencyTitle}
        </Text>
        <Text
          style={{
            position: "absolute",
            top: 705,
            right: 470,
            color: "black",
            fontSize: 11,
          }}
        >
          {date}
        </Text>
      </View>
    </Page>
  );
}
