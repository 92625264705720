import { useHistory } from "react-router";
import { useUploadContext } from "../../../../../../../components/upload/uploadFilesContext";
import { successToast } from "../../../../../../../helpers/toast";
import { axiosGetRequest, axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { StageContext } from "../../../../../../../contexts/stageContext";

const useCreateAgencyAnswer = ({ entityId, standardId, programId }) => {
  const history = useHistory();

  const { currentStage } = useContext(StageContext);
  const { newAgencyAnswer, agencyDegree } = useAnswerContext();
  const { files } = useUploadContext();
  const { setCreateAnswerLoading, setCreateDradtAnswerLoading } = useAnswerContext();
  const isThirdStage = currentStage?.displayOrder === 3;

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
      isThirdStage
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: (currentStage?.id || 0),
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });

  const createAgencyAnswer = async (standardId, entityId, currentStage, isDraft = false) => {
    const agencyRequirementAnswers = newAgencyAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            answerMandatory: subReq?.answerMandatory,
            donotApply: subReq?.donotApply,
            numOfTextFields: subReq?.numOfTextFields,
            textFields: JSON.stringify(subReq?.textFields),
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          donotApply: req?.donotApply,
          answerMandatory: req?.answerMandatory,
          agencySubRequirementAnswers: subStandardRequirements,
        };
      }
    );

    const allSubRequirements = agencyRequirementAnswers
      .map((req) => req?.agencySubRequirementAnswers)
      .flat();

    // check if there are any sub requirements with text fields containing URLs
    const subRequirementsWithUrls =
      allSubRequirements?.filter((subReq) => {
        return matchUrls(subReq?.textFields).length > 0;
      }) ?? [];

    if (subRequirementsWithUrls.length > 0) {
      toast.error("يجب ازالة الروابط من حقول النص");
      return;
    }

    console.log("")

    const mandatorySubRequirements = allSubRequirements.filter((subReq) => {
      if (subReq?.answerMandatory === 1) {

        // check if all fields have value for mandatory questions
        const allFieldshasValue = (JSON.parse(subReq?.textFields))?.every(item => item?.trim()?.length)
        // Check if decision is null and textFields is not "null"
        return subReq?.decision === null && 
        (
          subReq?.textFields === "null" || 
          subReq?.textFields === `[""]` || 
          (JSON.parse(subReq?.textFields)?.length && !allFieldshasValue) ||
          (JSON.parse(subReq?.textFields)?.length !== subReq?.numOfTextFields)
        );
      }
      return false; // Skip if answerMandatory is not 1
    });

    if (mandatorySubRequirements.length > 0 && !isDraft) {
      toast.error("يجب الإجابة على جميع الحقول المطلوبة");
      return;
    }

    // * Proof Files

    const agencyProofAnswers = newAgencyAnswer?.standardProofs?.map((file) => {
      const prevUploadedFiles =
        file?.standardProofAttachments?.length > 0
          ? file?.standardProofAttachments?.map((prevFile) => ({
              attachmentId: prevFile?.attachment?.id,
              fromPastYear: prevFile?.fromPastYear,
            }))
          : [];
      const pastYearUploadedFiles = file?.pastSelectedFiles?.filter(item => item.standardProofId === file.standardProofId ).map(
        (prevFile) => ({
          attachmentId: prevFile?.value,
          fromPastYear: true,
        })
      );
      // * New files
      const newUploadedFiles = files
        ?.filter((newFile) => newFile?.proofId == file?.id && newFile?.standardProofId == file.standardProofId)
        .map((newFile) => ({
          attachmentId: newFile?.attachmentId,
          fromPastYear: false,
          relatedRequirementId: newFile?.relatedRequirementId ?? null,
        }));

      const standardProofAttachments = [
        ...prevUploadedFiles,
        ...newUploadedFiles,
        ...pastYearUploadedFiles,
      ];

      
      return {
        standardProofId: file?.standardProofId || file?.id,
        standardProofAttachments: [
          ...prevUploadedFiles,
          ...newUploadedFiles,
          ...pastYearUploadedFiles,
        ],
        note: file?.note || "",
      };
    });


    const standerRequirements = standardRequirementsPhaseThree?.result?.standardRequirements
    const standerRequirementsIds = standerRequirements?.map((req) => req.id);
    const standardProofs =
      !isThirdStage || !standerRequirements
        ? newAgencyAnswer?.standardProofs
        : newAgencyAnswer?.standardProofs.filter((proof) => includeProof(proof, standerRequirementsIds) );

    if (!checkIfValidStandardProofs(agencyProofAnswers, standardProofs) && !isDraft) {
      toast.error("يجب رفع جميع الملفات المطلوبة");
      return;
    }

    if (!checkIfValidStandardProofs(agencyProofAnswers, standardProofs) && !isDraft) {
      toast.error("يجب رفع جميع الملفات المطلوبة");
      return;
    }

        // check if there are any sub requirements with text fields containing URLs
        const agencyProofAnswersWithUrls =
        agencyProofAnswers?.filter((file) => {
          return matchUrls(file?.note).length > 0;
        }) ?? [];
  
      if (agencyProofAnswersWithUrls.length > 0) {
        toast.error("يجب ازالة الروابط من حقول النص");
        return;
      }
  
  
    const data = {
      surveyStageId: Number((currentStage || 0)),
      standardId: Number(standardId),
      agencyId: Number(entityId),
      agencyRequirementAnswers,
      agencyProofAnswers,
      selfEvaluationCommitmentLevelId:
        agencyDegree?.agencySelfEvaluationCommitmentLevel?.id || null,
      finalCommitmentLevelId: newAgencyAnswer?.checkedAgencyDegree?.id,
      selfEvaluationDegree: agencyDegree?.agencySelfEvaluationDegree || 0,
      finalDegree: newAgencyAnswer?.checkedAgencyDegree?.fixedDegree,
      donotApply: false,
      pocAnswer: false,
      isDraft,
    };

    if(isDraft){
      setCreateDradtAnswerLoading(true);
    } else {
      setCreateAnswerLoading(true);
    }

    const res = await axiosPostRequest(environment.addAgencyAnswer, data);
    if (res?.success) {
      successToast("تم إضافة الإجابة بنجاح");
    }
    setCreateDradtAnswerLoading(false);
    setCreateAnswerLoading(false);


    if (res?.success) {
      history.goBack();
    }
  };
  return { createAgencyAnswer };
};

export default useCreateAgencyAnswer;


function checkIfValidStandardProofs(agencyProofAnswers, standardProofs) {
  const requiredProofs = standardProofs.filter(
    (proof) => proof?.standardProofTypeId === 1
  );
  const requiredProofsIds = requiredProofs.map((proof) => proof?.standardProofId || proof?.id);

  const uploadedProofs = agencyProofAnswers
    .filter((proof) => requiredProofsIds.includes(proof?.standardProofId))
    .filter((proof) => proof?.standardProofAttachments.length > 0);

  return requiredProofs.length <= uploadedProofs.length;
}


/**
 * Checks if a proof includes any of the specified standard requirement IDs.
 *
 * @param {Object} proof - The proof object to check.
 * @param {Array} standerRequirementsIds - The array of standard requirement IDs to check against.
 * @returns {boolean} - Returns true if the proof includes any of the specified standard requirement IDs, otherwise returns false.
 */
function includeProof(proof, standerRequirementsIds) {
  return proof.standardProofRequirements.some((req) =>
    standerRequirementsIds.includes(req.standardRequirementId)
  );
}

/**
 * Extracts URLs from a given text string.
 *
 * @param {string} text - The text to search for URLs.
 * @returns {Array} - Array of URLs found in the text, or an empty array if none are found.
 */
function matchUrls(text) {
  const urlRegex = /((https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?)/gi;
  // Extract all matches of the URL pattern from the text
  const urls = text.match(urlRegex);
  return urls || [];
}

/** Note
const urlRegex = /(
  (https?:\/\/)?       // Matches "http://" or "https://" at the start of the URL (optional)
  (www\.)?             // Matches "www." at the start (optional)
  [a-zA-Z0-9.-]+       // Matches the main part of the domain (letters, numbers, dots, hyphens)
  \.[a-zA-Z]{2,}       // Matches the top-level domain (e.g., ".com", ".org"), requires at least 2 characters
  (:\d+)?              // Matches a port number after the domain (e.g., ":8080") (optional)
  (\/[^\s]*)?          // Matches any additional path after the domain (optional), allowing any characters except whitespace
)/gi; // Flags: g - global (matches all URLs in the text); i - case-insensitive
*/


