import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import { getCommitmentLevelsBasedOnDegree, showRequirementDegreeErrorMessage } from "../../utils";
import { Input } from "../../../../../../../../components";
import { TextInput } from "flowbite-react";
import UploadFile from "../../UploadFile";

export function EditStandardRequirementsBasedOnSides({
    requirements,
    commitmentLevels,
    label,
    from,
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
    hasAnswer,
    isReadOnlyView,
}) {

    // check if expert / reviewer has answer...
    const isReadOnly = !(from === 'expert' ? newExpertManagerAnswer?.expertLastAnsweredId : newExpertManagerAnswer?.reviewerLastAnsweredId);

    const handleSubRequirementChange = (
        requirement,
        subReqId,
        property = "note",
        value,
        isUnAnswered,
        from
    ) => {
        const isNotValid = property == "percentage" && showRequirementDegreeErrorMessage(isUnAnswered, value);
        if (isNotValid) return;

        const standardRequirements = from === "expert" ? "expertRequirementsAnswer" : "reviewerRequirementsAnswer";

        const updatedSubRequirements = newExpertManagerAnswer?.[standardRequirements]?.map(
            (item) => {
                if (item?.id === requirement?.id) {
                    const newSubReqs = requirement?.subStandardRequirements?.map(
                        (subRequire) => {
                            if (subReqId === subRequire?.id) {
                                return {
                                    ...subRequire,
                                    [property]: value,
                                }

                            }

                            return subRequire;
                        }
                    );

                    return { ...requirement, subStandardRequirements: newSubReqs };
                }

                return item;
            }
        );

        setNewExpertManagerAnswer({
            ...newExpertManagerAnswer,
            [standardRequirements]: updatedSubRequirements,
        });
    };


    return !hasAnswer ? (<h2 className="text-2xl pb-2">لا يوجد إجابة حتى الآن</h2>)
        :
        requirements?.length > 0 &&
        requirements?.map((requirement) => {
            const hasSubRequirements =
                requirement?.subStandardRequirements?.length > 0;

            return (
                <>
                    <MainRequirement
                        key={requirement?.id}
                        title={requirement?.title}
                        hasSubRequirements={hasSubRequirements}
                        checked={requirement?.decision}
                        requirementDonotApplyChecked={requirement?.donotApply}
                        onChange={() => {
                        }}
                        handleDonotApplyRequirementChange={() => { }}
                    >
                        {hasSubRequirements &&
                            requirement?.subStandardRequirements?.map((subRequirement) => {
                                const isUnAnswered = (subRequirement?.isUnAnswered || subRequirement?.isUnAnswered === null);

                                return (
                                    <>
                                        <div className="px-5 flex flex-col mb-3 py-2">
                                            <div className="border-b pb-2">
                                                <div className="flex flex-row gap-2 mb-3 py-2">
                                                    <h3 className="text-xl pb-2">{subRequirement.title}</h3>
                                                </div>
                                                <div className="flex flex-row gap-2 mb-3 py-2">
                                                    <Input
                                                        type="checkbox"
                                                        className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                                                        id={subRequirement.value}
                                                        name={"ارجاع الى الجهة"}
                                                        label={""}
                                                        value={subRequirement.isReturnable}
                                                        checked={subRequirement.isReturnable}
                                                        disabled={isReadOnly || isReadOnlyView}
                                                        onChange={(e) => {
                                                            handleSubRequirementChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "isReturnable",
                                                                e.target.checked,
                                                                isUnAnswered,
                                                                from
                                                            );
                                                        }}
                                                    />
                                                    <span>معاد للجهة</span>
                                                </div>

                                                {subRequirement?.isReturnable && (
                                                    <div className="flex gap-2 w-full items-center mb-4">
                                                        <span className="w-32">ملاحظة الى الجهة</span>
                                                        <TextInput
                                                            value={subRequirement?.reason}
                                                            className="grow"
                                                            placeholder="ملاحظة الى الجهة"
                                                            disabled={isReadOnly || isReadOnlyView}
                                                            onChange={(e) => {
                                                                handleSubRequirementChange(
                                                                    requirement,
                                                                    subRequirement?.id,
                                                                    "reason",
                                                                    e.target.value,
                                                                    isUnAnswered,
                                                                    from
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                <div className=" flex items-center gap-2 mb-4">
                                                    <span className="w-24">{label || "تقييم المدقق"}</span>
                                                    <TextInput
                                                        value={subRequirement?.percentage}
                                                        type="number"
                                                        placeholder=""
                                                        min={isUnAnswered ? 1 : 0}
                                                        max={isUnAnswered ? 20 : 100}
                                                        disabled={isReadOnly || isReadOnlyView}
                                                        onChange={(e) => {
                                                            handleSubRequirementChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "percentage",
                                                                e.target.value,
                                                                isUnAnswered,
                                                                from
                                                            );
                                                        }}
                                                    />
                                                    <label>
                                                        {getCommitmentLevelsBasedOnDegree(
                                                            Number(subRequirement?.percentage),
                                                            commitmentLevels
                                                        )}
                                                    </label>
                                                </div>
                                                <div className="flex gap-2 w-full items-center mb-4">
                                                    <span className="w-24"> الملاحظة</span>
                                                    <TextInput
                                                        value={subRequirement?.note}
                                                        className="grow"
                                                        placeholder="الملاحظة"
                                                        disabled={isReadOnly || isReadOnlyView}
                                                        onChange={(e) => {
                                                            handleSubRequirementChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "note",
                                                                e.target.value,
                                                                isUnAnswered,
                                                                from
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex gap-2 w-full items-center">
                                                    <span className="w-24">التوصية</span>
                                                    <TextInput
                                                        value={subRequirement?.recommendation}
                                                        className="grow"
                                                        placeholder="التوصية"
                                                        disabled={isReadOnly || isReadOnlyView}
                                                        onChange={(e) => {
                                                            handleSubRequirementChange(
                                                                requirement,
                                                                subRequirement?.id,
                                                                "recommendation",
                                                                e.target.value,
                                                                isUnAnswered,
                                                                from
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <UploadFile
                                                    value={subRequirement.attachmentId}
                                                    withOutToolTip
                                                    readOnly={isReadOnly || isReadOnlyView}
                                                    onChange={(value) => {
                                                        handleSubRequirementChange(
                                                            requirement,
                                                            subRequirement?.id,
                                                            "attachmentId",
                                                            value,
                                                            isUnAnswered,
                                                            from
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </MainRequirement>
                </>
            );
        });

}