import { programsIcon } from "../../../../../assets/icons/Index";
import { isExpert, isReviewer } from "../../../../../helpers/isPermission";

export const previousSurveysBreadcumbs = [
  {
    icon: programsIcon,
    title:  (isExpert() || isReviewer()) ? "تقييم الاستبيان" : "التقرير النهائي",
    url: "/previousSurveys",
  },
];

export function getPercentageStyle(percentage) {
  const numericValue = parseInt(percentage);

  if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
    return "";
  }

  if (numericValue < 50) {
    return "bg-[#e4473c] text-white";
  } else if (numericValue >= 50 && numericValue < 70) {
    return "bg-[#ed6c02] text-white";
  } else if (numericValue >= 70 && numericValue < 80) {
    return "bg-[#ffc107] text-black";
  } else if (numericValue >= 80 && numericValue < 90) {
    return "bg-[#0288d1] text-white";
  } else {
    return "bg-green text-white";
  }
}

export const previousSurveysTableHeaders = [
  {
    name: "الرقم",
    selector: (row) => row.id,
    header: "الرقم",
    accessorKey: "number",
    footer: (props) => props.column.id,

    sortable: true,
  },
  {
    name: "اسم الدورة",
    selector: (row) => row.title,
    header: "الاسم",
    accessorKey: "title",
    footer: (props) => props.column.title,
  },
  {
    name: "تاريخ البداية",
    selector: (row) => row.startDate,
    header: "الاسم",
    accessorKey: "startDate",
    footer: (props) => props.column.startDate,
    center: true,
    sortable: true,
  },
  {
    name: "تاريخ النهاية",
    selector: (row) => row.endDate,
    header: "الاسم",
    accessorKey: "endDate",
    footer: (props) => props.column.endDate,
    center: true,
    sortable: true,
  },
  {
    name: "المؤشر",
    selector: (row) => row.indicator,
    header: "الاسم",
    accessorKey: "indicator",
    footer: (props) => props.column.indicator,
    center: true,
  },
  {
    name: "الحالة",
    selector: (row) => row.status,
    header: "الحالة",
    accessorKey: "status",
    footer: (props) => props.column.status,
    center: true,
    cell: (info) => (
      <div>{info.status == 2 ? "استبيان سابق" : "استبيان حالي"}</div>
    ),
  },
  {
    name: "النتيجة",
    selector: (row) => row.result,
    header: "النتيجة",
    accessorKey: "result",
    footer: (props) => props.column.result,
    center: true,
    cell: (info) => (
      <div className="py-3">
        <span
          className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-medium text-xs leading-none rounded-lg ${getPercentageStyle(
            info.result
          )}`}
        >
          {info.result}
        </span>
      </div>
    ),
  },
  {
    name: "الاطلاع",
    selector: (row) => row.more,
    header: "الحالة",
    accessorKey: "more",
    footer: (props) => props.column.more,
    center: true,
    sortable: true,
    cell: (info) => (
      <div className="py-3">
        <span
          className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-medium text-xs leading-none rounded-lg bg-green text-white`}
        >
          {info.more}
        </span>
      </div>
    ),
  },
];
