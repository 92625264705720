import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

export const useGetStandardsByAgencyId = ({
  currentPage,
  filterValue,
  reviewedStatus,
  agencyId,
  maxResult,
}) => {

  const { data: currentStage = {} } = useQuery({
    queryKey: [
      'getCurrentSurveyStage'
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getCurrentSurveyStage,
        {
          surveyId: ifNotDefinedReturnZero(surveyId()),
        },
        undefined,
        signal
      ),
    select: (data) => data.result
  });
  const surveyStageId = (currentStage?.id || 0);
  const queryClient = useQueryClient();

  const stageId = surveyStageId || 0;

  const { data, isLoading: isGettingAllAgenciesByStandardId, refetch: refetchGetStandardsByAgencyId } = useQuery({
    queryKey: [
      "GetStandardsByAgencyId",
      currentPage,
      filterValue,
      agencyId,
      reviewedStatus,
      maxResult,
      stageId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(environment.GetStandardsByAgencyId, {
        surveyId: ifNotDefinedReturnZero(surveyId()),
        StageId: stageId,
        agencyId: agencyId,
        SkipCount: currentPage * maxResult,
        MaxResultCount: maxResult,
        IsReviewed: reviewedStatus,
        KeyWord: filterValue || null,
        AssignedReviewerId: reviewerId(),
        AssignedExpertId: expertId(),
        AssignedPOCOfficerId: pocOfficerId(),
      },
        true,
        signal
      ),
    enabled: !!agencyId,
  });

  const allAgenciesByStandardId = data?.result?.result;
  const totalCount = data?.result?.totalItemCountFilterd;
  const totalPages = Math.ceil(totalCount / maxResult);
  const allItemsCount = data?.result?.totalItemCountNotFilterd;
  const totalReviewedCount = data?.result?.totalReviewed;
  const totalNotReviewedCount = data?.result?.totalNotReviewed;
  const totalNotReviewedByExpert = data?.result?.totalNotReviewedByExpert;
  const totalNotReviewedByPoc = data?.result?.totalNotReviewedByPoc;
  const totalNotReviewedByReviewer = data?.result?.totalNotReviewedByReviewer;
  const totalReviewedByExpert = data?.result?.totalReviewedByExpert;
  const totalReviewedByPoc = data?.result?.totalReviewedByPoc;
  const totalReviewedByReviewer = data?.result?.totalReviewedByReviewer;
  const totalReviewedByExpertManager = data?.result?.totalReviewedByExpertManager;
  const totalNotReviewedByExpertManager = data?.result?.totalNotReviewedByExpertManager;

  return {
    queryClient,
    allAgenciesByStandardId,
    totalCount,
    totalPages,
    data,
    isGettingAllAgenciesByStandardId,
    totalReviewedCount,
    totalNotReviewedCount,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
    totalReviewedByExpertManager,
    totalNotReviewedByExpertManager,
    refetchGetStandardsByAgencyId,
  };
};

