import { Badge } from "flowbite-react";
import { removeCircleDarkRed } from "../assets/icons/dashboard";

function NoAnswerBadge() {
  return (
    <Badge
      className="text-white text-center bg-black font-medium pb-3 px-5 rounded-xl"
      size="sm"
    >
      لم يتم الاٍجابة

      <img
        src={removeCircleDarkRed}
        width={"16px"}
        alt="not answered standard"
      />
    </Badge>
  );
}

export default NoAnswerBadge;
