import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";

export const useGetFinalStandardAnswersDetailsForAgency = ({
  currentPage,
  standardId,
  agencyId,
}) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAgencyDetails } = useQuery({
    queryKey: [
      "FinalStandardAnswersDetailsForAgency",
      currentPage,
      standardId,
      agencyId,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetFinalStandardAnswersDetailsForAgency, {
        StandardId: standardId,
        AgencyId: agencyId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
  });

  const { data: allAttachments, isLoading: isAllAttachmentsLoading } = useQuery({
    queryKey: [
      "FinalStandardAnswersDetailsForAgency",
      standardId,
      agencyId,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetFinalStandardAnswersDetailsForAgency, {
        StandardId: standardId,
        AgencyId: agencyId,
        MaxResultCount: 1000
      }),
  });

  const agencyDetails = data?.result?.items;
  const totalCount = data?.result?.filesTotalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "FinalStandardAnswersDetailsForAgency",
        currentPage + 1,
        standardId,
        agencyId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.GetFinalStandardAnswersDetailsForAgency, {
          StandardId: standardId,
          AgencyId: agencyId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "FinalStandardAnswersDetailsForAgency",
        currentPage - 1,
        standardId,
        agencyId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.GetFinalStandardAnswersDetailsForAgency, {
          StandardId: standardId,
          AgencyId: agencyId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
    });
  }

  return {
    queryClient,
    agencyDetails,
    totalCount,
    totalPages,
    data,
    isGettingAgencyDetails,
    allAttachments,
  };
};
