import React, { useContext, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../addStandardContext";
import { produce } from "immer";
import {
  convertHtmlToString,
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import { addStandardProofsValidations } from "../addStandardValidation";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";

const defaultValues = {
  title: "",
  standardProofTypeId: "",
  fileCount: "",
  standardProofFileTypeAssociations: [],
  fileSize: "",
  isRequired: true,
  standardProofRequirements: [],
  standardProofSubRequirements: [],
};

const AddStandardProofFiles = () => {
  const {
    chooseDocType,
    fileTypes,
    addFinalValue,
    setAddFinalValue,
  } = useContext(AddStandardContext);

  const allQuestions = addFinalValue.standardRequirements.map(item => item.subStandardRequirements).flat();
  
  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [standardProofSubRequirements, setStandardProofSubRequirements] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({
    title: "",
    standardProofTypeId: "",
    agencyStandardProofs: [],
    standardProofFileTypeAssociations: "",
    standardProofSubRequirements: [],
  });

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardProofsValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
    setValues({ ...values, [name]: value });
  };

  const handleChangeDocType = (value) => {
    setSelectedDocType(value);
    validateInput("standardProofTypeId", value?.label);
    setValues({
      ...values,
      standardProofTypeId: value?.id ? value?.id?.toString() : "",
    });
  };

  const handleChangeStandardProofRequirements = (value) => {
    const standardProofSubRequirements = value || [];
    setStandardProofSubRequirements(standardProofSubRequirements);
    validateInput("standardProofSubRequirements", standardProofSubRequirements);
    setValues({
      ...values,
      standardProofSubRequirements,
    });
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      addStandardProofsValidations,
      values
    );
    if (formErrors || !validForm) {
      setErrors({ ...errors, ...formErrors });
      return;
    }

    // const agencyStandardProofs = values?.agencyStandardProofs?.map((item) => ({
    //   agencyId: item?.value,
    //   agencyNameInArabic: item?.label,
    // }));

    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs.push({ ...values, subStandardProofRequirements: standardProofSubRequirements });
      })
    );

    setValues(defaultValues);
    setSelectedDocType("");
    setSelectedFileTypes([]);
    setStandardProofSubRequirements([]);
  };

  
  const handleChangeFileTypes = async (value) => {
    setSelectedFileTypes(value);
    await validateInput("standardProofFileTypeAssociations", value || []);

    const types =
      (value?.length > 0 &&
        value.map((item) => ({
          standardProofFileTypeId: item.id,
        }))) ||
      [];

    setValues({
      ...values,
      standardProofFileTypeAssociations: [
        // ...values.standardProofFileTypeAssociations,
        ...types,
      ],
    });
  };

  return (
    <div className="bg-white p-5 pb-7 rounded-lg">
      <div className="row gap-y-3">
        <div className="col-md-6 col-xl-3">
          <AppInput
            variant="gray"
            label="اسم المستند"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleInputChange}
            errorMessage={errors.title}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedDocType}
            handleChange={handleChangeDocType}
            label="القيود"
            placeholder="اختر القيود"
            options={chooseDocType || []}
            isMultiple={false}
            isRequired={true}
            errorMessage={errors.standardProofTypeId}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <NewDropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedFileTypes}
            handleChange={handleChangeFileTypes}
            label="نوع الملف"
            placeholder="اختر نوع الملف"
            options={fileTypes || []}
            isMultiple={true}
            errorMessage={errors.standardProofFileTypeAssociations}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <NewDropdownMultiSelect
            label="الأسئلة المرتبطة"
            value={standardProofSubRequirements}
            handleChange={handleChangeStandardProofRequirements}
            options={
              allQuestions
                ?.filter((item) => item.haveAttachments === 1)
                ?.map((item) => ({
                  label: (item.title || convertHtmlToString(item.recommendation)),
                  value: item.id,
                })) ?? []
            }
            isMultiple={true}
            isRequired={true}
            isClearable={true}
            isSearchable={true}
            withoutLabel={false}
            errorMessage={errors.standardProofSubRequirements}
          />
        </div>

        <div className="col-md-6 col-xl-3 self-end">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة مستند</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStandardProofFiles;
