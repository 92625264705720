import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import { showRequirement } from "../utils";
import answer from "./../../../../../../../assets/icons/answer.svg";

const NotesForAgency = ({ setMenuElement }) => {
  const { newPocOfficerAnswer,  pocOfficerAnswer } = useAnswerContext();

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newPocOfficerAnswer?.reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {pocOfficerAnswer?.reviewerRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement?.reviewerSubRequirementAnswers?.map(
                        (subRequirement) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newPocOfficerAnswer?.reviewerNotContent || "",
              }}
            ></div>
            {!pocOfficerAnswer?.reviewerNoteTemplate &&
              !pocOfficerAnswer?.reviewerNotContent &&
              !pocOfficerAnswer?.reviewerRequirementAnswers?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newPocOfficerAnswer?.expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {pocOfficerAnswer?.expertRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement) ? requirement.title : ""}
                    <ul>
                      {requirement?.expertSubRequirementAnswers?.map(
                        (subRequirement) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">{subRequirement.title}</li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newPocOfficerAnswer?.expertNotContent || "",
              }}
            ></div>
            {!pocOfficerAnswer?.standardRequirements?.length &&
              !pocOfficerAnswer?.expertNoteTemplate &&
              !pocOfficerAnswer?.expertNotContent && <div>لا يوجد ملاحظات</div>}
          </div>
          {/* <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newPocOfficerAnswer?.pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {pocOfficerAnswer?.standardRequirements?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement?.subStandardRequirements?.map(
                        (subRequirement) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newPocOfficerAnswer?.pocOfficerNotContent || "",
              }}
            ></div>
            {!pocOfficerAnswer?.pocOfficerNoteTemplate &&
              !newPocOfficerAnswer?.pocOfficerNotContent &&
              !pocOfficerAnswer?.standardRequirements?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div> */}
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(7)} />
    </>
  );
};

export default NotesForAgency;
