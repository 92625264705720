import react, { useRef, useState } from "react";
import {
    DropdownMultiSelect,
    InputLabel,
} from "../../../components";
import { ErrorMessage } from "formik";
import { NewForm } from "../../../components/form/NewForm";
import cloud from "../../../assets/icons/cloud.svg";
import file from "../../../assets/icons/file.svg";
import { Spinner } from "flowbite-react";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import { ifNotDefinedReturnZero } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import environment from "../../../services/environment";
import { useQuery } from "@tanstack/react-query";
import { axiosGetRequest, post } from "../../../services/Request";
import { errorToast } from "../../../helpers/toast";
import AppRadio from "../../../components/form/appRadio";
import { expertId, isExpertManager } from "../../../helpers/isPermission";
import NewTooltip from "../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const AddEditEvaluationNote = ({
    formRef,
    isAddMode,
    surveyId,
    initialInputsState,
    inputs,
    setInputs,
    descriptionFile,
    setDescriptionFile,
    setDescriptionFileId,
    handleSubmit,
    validate,
    disabled,
}) => {

    const { t } = useTranslation();
    const fileDescriptionInputRef = useRef(null);
    const [loadingImageDescription, setLoadingImageDescription] = useState(false);
    const [value, setValue] = useState(1);

    // Get all agency data
    const { data: allAgencies, isLoading: isLoadingAgencies } = useQuery({
        queryKey: ["getAllAgencyWithoutPagination", surveyId],
        queryFn: () =>
            axiosGetRequest(environment.getAllAgencyWithoutPagination, {
                surveyId: ifNotDefinedReturnZero(surveyId),
                expertId: isExpertManager()? null: localStorage.getItem("userId"),
            }),
        select: res => res?.result
            ?.filter((agency) => agency.id && agency.nameInArabic)
            .map((agency) => {
                return { label: agency.nameInArabic, value: agency.id };
            })
    });

    // Get all Perspectives data
    const { data: allPrespectives, isLoading: isLoadingPrespectives } = useQuery({
        queryKey: ["getPerspectiveList", surveyId],
        queryFn: () =>
            axiosGetRequest(environment.getPerspectiveList, {
                surveyId: ifNotDefinedReturnZero(surveyId),
                SkipCount: 0,
                MaxResultCount: 1000,
            }),
        select: res => res?.result?.items?.map((item) => {
            return { label: `(${item.code}) ${item.title}`, value: item.id };
        })
    });

    // Get all Interlocators data
    const { data: allInterlocators, isLoading: isLoadingInterlocators } = useQuery({
        queryKey: ["getInterviewers", surveyId],
        queryFn: () =>
            axiosGetRequest(environment.getInterviewers, {
                surveyId: ifNotDefinedReturnZero(surveyId),
                SkipCount: 0,
                MaxResultCount: 1000,
            }),
        select: res => res?.result?.items?.map((item) => {
            return { label: `(${item.code}) ${item.title}`, value: item.id };
        })
    });


    function handleLevelChange(e) {
        setInputs({ ...inputs, 'expertEvaluationNoteType': e.target.value });
    }

    const handleChangeAgency = async (value) => {
        setInputs({ ...inputs, agency: value });
    };

    const handleChangePrespective = async (value) => {
        setInputs({ ...inputs, prespectives: value.length ? value : null });
    };

    const handleChangeInterlocators = async (value) => {
        setInputs({ ...inputs, interlocutors: value.length ? value : null });
    };

    const handleInputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    const handleFileSelect = (e) => {
        fileDescriptionInputRef.current.click();
    };

    const generateUpload = (file, e, values) => {
        setLoadingImageDescription(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        };
        const formData = new FormData();

        formData.append("Name", file.name);
        formData.append("FileType", "image/*");
        formData.append("EntityType", "");
        formData.append("File", file);

        post(environment.attachmentFile, formData, config, (res) => {
            setDescriptionFileId(
                res.data?.result?.id,
                setLoadingImageDescription(false)
            );
        });
    };

    const onChangeFile = (event, e) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0]; // Get the uploaded file
            if (file) {
                const fileSizeInBytes = file.size; // Get file size in bytes
                const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB
                if (fileSizeInMB > 30) {
                    errorToast("حجم الملف يجب أن لا يتجاوز 30 ميجا");
                    return;
                }
            }

            setDescriptionFile(
                event.target.files[0],
                generateUpload(event.target.files[0], 1)
            );
        }

        event.target.value = ""; // Reset the value

    };

    const resetUploadedFiles = () => {
        setDescriptionFile(null);
        setDescriptionFileId(null);
    }
    
    const MultiSelectComponent = isAddMode ? NewDropdownMultiSelect : DropdownMultiSelect;

    // ----------------------------------------------------- //
    return (
        <div className="bg-white rounded-[30px] m-5">
            <NewForm
                initialValues={initialInputsState}
                validate={validate}
                innerRef={formRef}
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col gap-3">
                    {/* Radio Group [survey, perspective, interlocator] */}
                    <div>
                        <AppRadio
                            label="على مستوى الاستبيان "
                            name={"expertEvaluationNoteType"}
                            id={1}
                            value={"1"}
                            checked={inputs.expertEvaluationNoteType === "1"}
                            onChange={handleInputChange}
                            disabled={!isAddMode || disabled}
                        />
                        <AppRadio
                            label="على مستوى العنصر"
                            name={"expertEvaluationNoteType"}
                            id={2}
                            value={"2"}
                            checked={inputs.expertEvaluationNoteType === "2"}
                            onChange={handleInputChange}
                            disabled={!isAddMode || disabled}
                        />
                        <AppRadio
                            label="على مستوى القدرة"
                            name={"expertEvaluationNoteType"}
                            id={3}
                            value={"3"}
                            checked={inputs.expertEvaluationNoteType === "3"}
                            onChange={handleInputChange}
                            disabled={!isAddMode || disabled}
                        />
                    </div>
                    {/* العنصر */}
                    {
                        inputs.expertEvaluationNoteType === "2" &&
                        <div>
                            <MultiSelectComponent
                                isClearable={true}
                                withoutLabel={false}
                                isSearchable={false}
                                value={isAddMode ? (inputs.interlocutors || []) : (inputs.interlocutors?.[0])}
                                handleChange={handleChangeInterlocators}
                                placeholder="العنصر"
                                label="اختر العنصر"
                                options={allInterlocators || []}
                                isMultiple={isAddMode}
                                isRequired={inputs.expertEvaluationNoteType === "2"}
                                loading={isLoadingInterlocators}
                                disabled={!isAddMode || disabled}
                            />
                            <ErrorMessage
                                className="text-red-700"
                                name="interlocutors"
                                component="div"
                            />
                        </div>
                    }
                    {/* القدرة */}
                    {
                        inputs.expertEvaluationNoteType === "3" &&
                        <div>
                            <MultiSelectComponent
                                isClearable={true}
                                withoutLabel={false}
                                isSearchable={false}
                                value={isAddMode ? (inputs.prespectives || []) : (inputs.prespectives?.[0])}
                                handleChange={handleChangePrespective}
                                placeholder="القدرة"
                                label="اختر القدرة"
                                options={allPrespectives || []}
                                isMultiple={isAddMode}
                                isRequired={true}
                                loading={isLoadingPrespectives}
                                disabled={!isAddMode || disabled}
                            />
                            <ErrorMessage
                                className="text-red-700"
                                name="prespectives"
                                component="div"
                            />
                        </div>
                    }

                    {/* الجهة */}
                    <div>
                        <DropdownMultiSelect
                            isClearable={true}
                            withoutLabel={false}
                            isSearchable={true}
                            value={inputs.agency}
                            handleChange={handleChangeAgency}
                            label="اختر الجهة"
                            placeholder="اختر الجهة"
                            options={allAgencies}
                            isMultiple={false}
                            isRequired={true}
                            loading={isLoadingAgencies}
                            disabled={disabled}
                        // errorMessage={"هذا الحقل مطلوب"}
                        />
                        <ErrorMessage
                            className="text-red-700"
                            name="agency"
                            component="div"
                        />
                    </div>
                    {/* الملاحظة */}
                    <div className="space-y-1">
                        <InputLabel
                            label={"الملاحظات الى الجهة"}
                            name="note"
                            value={inputs.note}
                            onChange={handleInputChange}
                            isRequired={true}
                            disabled={disabled}
                        />
                        <ErrorMessage
                            className="text-red-700"
                            name="note"
                            component="div"
                        />
                    </div>
                    {/* التوصية */}
                    <div className="space-y-1">
                        <InputLabel
                            rows={3}
                            type={"textarea"}
                            value={inputs.recommendation}
                            onChange={handleInputChange}
                            name={"recommendation"}
                            label={"التوصية"}
                            isRequired={false}
                            disabled={disabled}
                        />
                        <ErrorMessage
                            className="text-red-700"
                            name="recommendation"
                            component="div"
                        />
                    </div>
                    {/* ارفاق ملف */}
                    <>
                        <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9 mt-3">
                            <img src={cloud} alt="cloud" />
                            <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                                يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا
                            </p>

                            <input
                                style={{ display: "none" }}
                                isImage={true}
                                ref={fileDescriptionInputRef}
                                type={"file"}
                                id={"fileUpload"}
                                label={t("modules.main.users.users.form.image")}
                                onChange={(event) => onChangeFile(event, 1)}
                                image={descriptionFile?.name || ""}
                            />

                            <button
                                disabled={disabled}
                                type="button"
                                onClick={() => handleFileSelect(1)}
                                className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                            >
                                إرفاق ملف
                            </button>
                        </div>
                        <ErrorMessage
                            className="text-red-700"
                            name="attachmentId"
                            component="div"
                        />
                        {descriptionFile && (
                            <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                                <div className="flex flex-row items-center">
                                    <img
                                        src={file}
                                        alt="file"
                                        style={{ width: "15px", height: "15px" }}
                                    />
                                    {loadingImageDescription ? (
                                        <Spinner aria-label="Info Spinner example" color="info" />
                                    ) : (
                                        <p className="text-sm text-[#161318] mr-3">
                                            {descriptionFile?.name || ""}
                                        </p>
                                    )}
                                </div>
                                <div className="flex flex-row items-center">
                                    <p className="text-sm text-[#7D8BAB] ml-3">
                                        {descriptionFile?.size ? descriptionFile?.size + "KB" : ""}
                                    </p>
                                </div>

                                <NewTooltip content={"حذف"}>
                                    <FontAwesomeIcon
                                        icon={faX}
                                        onClick={() => {
                                            resetUploadedFiles();
                                        }}
                                        className="text-red-700 bg-rose-50 mr-1 rounded-full p-2 cursor-pointer"
                                    />
                                </NewTooltip>
                            </div>
                        )}
                    </>
                </div>
            </NewForm>
        </div>
    );
};

export default AddEditEvaluationNote;
