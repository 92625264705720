import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router";
import {StageContext} from "../contexts/stageContext";
import NotFound from "../services/NotFound";
import { isAgency } from "../helpers/isPermission";

function arrayContainsValuesFromArray(arr1, arr2) {
  for (let i = 0; i < arr2.length; i++) {
    if (arr1.includes(arr2[i])) {
      return true;
    }
  }
  return false;
}



const CheckRoles = ({ children, permission }) => {
  const stageContext = useContext(StageContext);
  const history = useHistory();
  const gotPemission = JSON.parse(localStorage.getItem("perm"))?.some((obj) => {
    return Object.values(obj).some((value) => permission?.includes(value));
  });


  // Check if acceptedTerms is false
  const acceptedTerms = JSON.parse(localStorage.getItem("acceptedTerms"));
  const surveyId = JSON.parse(localStorage.getItem("surveyId"));

  // If terms are not accepted, redirect to the current page
  useEffect(() => {
    if (isAgency() && !acceptedTerms && window.location.href !== `/survey/${surveyId}/polices/accept`) {
      history.push(`/survey/${surveyId}/polices/accept`);
    }
  }, [acceptedTerms]);

  stageContext.setHasPermission(gotPemission);
  if (!stageContext.hasPermission && !permission?.includes("all")) {
    return <NotFound />;
  }


  return <>{children}</>;
};

export default CheckRoles;
