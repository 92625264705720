import { useQuery } from "@tanstack/react-query";
import { Spinner } from "flowbite-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cloud from "../../../../../../assets/icons/cloud.svg";
import file from "../../../../../../assets/icons/file.svg";
import FilePreview from "../../../../../../components/upload/filePreview";
import environment from "../../../../../../services/environment";
import {
  axiosGetRequest,
  post
} from "../../../../../../services/Request";
import { toast } from "react-toastify";
import { errorToast } from "../../../../../../helpers/toast";

export default function UploadFile({ onChange, value, readOnly, label = "يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"}) {
  const fileDescriptionInputRef = useRef(null);
  const [descriptionFile, setDescriptionFile] = useState("");
  const [loadingImageDescription, setLoadingImageDescription] = useState(false);
  const [descriptionFileId, setDescriptionFileId] = useState(value ?? null);
  const { t } = useTranslation();

  const { data: attachment } = useQuery({
    queryKey: [environment.getAttachment, value],
    queryFn: () => {
      return axiosGetRequest("services/app/Attachment/Get", {
        id: value,
      });
    },
    enabled: !!value,
    select: (data) => {
      return data?.result;
    },
  });

  const generateUpload = (file, e, values) => {
    setLoadingImageDescription(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", file.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", file);

    post(environment.attachmentFile, formData, config, (res) => {
      setDescriptionFileId(
        res?.data?.result?.id,
        setLoadingImageDescription(false)
      );
      onChange(res?.data?.result?.id);
    });
  };

  const onChangeFile = (event, e) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]; // Get the uploaded file
      if (file) {
        const fileSizeInBytes = file.size; // Get file size in bytes
        const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2); // Convert to KB
        const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB
        if (fileSizeInMB > 30) {
          errorToast("حجم الملف يجب أن لا يتجاوز 30 ميجا");
          return;
        }
      }

      setDescriptionFile(
        event.target.files[0],
        generateUpload(event.target.files[0], 1)
      );
    }
  };

  const handleFileSelect = (e) => {
    fileDescriptionInputRef.current.click();
  };


  return (
    <div className="flex flex-col gap-2 w-full  mb-4">
      {!readOnly && <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9 mt-10">
        <img src={cloud} alt="cloud" />
        <p className="text-[14px] text-[#E42829] w-[40%] text-center">{label}</p>

        <input
          style={{ display: "none" }}
          isImage={true}
          ref={fileDescriptionInputRef}
          type={"file"}
          id={"fileUpload"}
          label={t("modules.main.users.users.form.image")}
          onChange={(event) => onChangeFile(event, 1)}
          image={descriptionFile.name}
        />

        <button
          type="button"
          onClick={() => handleFileSelect(1)}
          className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
        >
          إرفاق ملف
        </button>
      </div>
      }

      {(attachment || loadingImageDescription) && (
        <div className="flex flex-row items-center">
          <FilePreview id={attachment?.id} fileName={attachment?.name ?? descriptionFile.name} withOutToolTip>
            <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
              <div className="flex flex-row items-center">
                <img style={{width: '15px', height: '15px'}} src={file} alt="file" />
                {loadingImageDescription ? (
                  <Spinner aria-label="Info Spinner example" color="info" />
                ) : (
                  <p className="text-sm text-[#161318] mr-3">
                    {attachment?.name || descriptionFile.name}
                  </p>
                )}
              </div>
            </div>
          </FilePreview>
        </div>
      )}
    </div>
  );
}
