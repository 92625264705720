import React, { useEffect, useState } from "react";
import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import StandardEntitiesList from "./standardEntitiesList";
import { standerdEntitesBreadcumbs } from "../content";
import { useParams } from "react-router-dom";
import TableCardSwitch from "../../../../../components/TableCardSwitch";
import StandardEntitiesTable from "./standardEntitiesList/StandardEntitiesTable";
import AppPagination from "../../../../../components/Pagination";
import ReviewedStatusCards from "./ReviewedStatusCards";
import { useGetAgenciesByStandardId } from "./useGetAgenciesByStandardId";
import TableSearch from "../../../../../components/TableSearch";
import { useLocation } from "react-router";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { useHistory } from "react-router-dom";
import { AppInput } from "../../../../../components";
import AppButton from "../../../../../components/buttons/appButton";
import { getDegrees } from "../standardAnswer/answers/useGetPrevAnswer/usePrevExpertManagerAnswer";
import { errorToast } from "../../../../../helpers/toast";

const StandardEntities = () => {
  const { surveyId, indicatorId, interlocutorId, perspectiveId, standardId } =
    useParams();
  const history = useHistory();

  const query = useQueryParams();

  console.log(query);
  console.log(query.get("page"));

  const [inputPageNum, setInputPageNum] = useState("");
  const [reviewedStatus, setReviewedStatus] = useState(1);
  const [showTable, setShowTable] = useState(true);
  const [filterValue, setFilterValue] = useState(null);
  const [donotApply, setDonotApply] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(query.get("page")) || 0
  );
  const [maxResult, setMaxResult] = useState({ label: "10", value: 10 });

  const {
    allAgenciesByStandardId,
    isGettingAllAgenciesByStandardId,
    totalPages,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
    totalReviewedByExpertManager,
    totalNotReviewedByExpertManager,
  } = useGetAgenciesByStandardId({
    currentPage,
    standardId,
    reviewedStatus,
    filterValue,
    maxResult: maxResult.value,
  });

  const location = useLocation();
  const standardTitle = location?.state?.standardTitle;

  const goToPage = (pageNumber) => {
    if (pageNumber < 0) {
      errorToast("يجب إدخال رقم الصفحة");
      return;
    }
    setCurrentPage(pageNumber);
    history.push(`?page=${pageNumber}`);
  };

  const getDonotApplyDegree = async () => {
    const surveyDegrees = await getDegrees(standardId);

    const donotApplyDegree =
      surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";

    setDonotApply(donotApplyDegree);
  };

  useEffect(() => {
    getDonotApplyDegree();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page")) || 0; // Get the 'page' parameter from the URL
    setCurrentPage(page); // Set the current page based on the URL parameter
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page")) || 0; // Get the 'page' parameter from the URL
    if (currentPage !== page) {
      setCurrentPage(page); // Update the current page if it doesn't match the URL parameter
    }
  }, [currentPage, location]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    goToPage(newOffset);
  };

  const onSearch = (value) => {
    setFilterValue(value);
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={standerdEntitesBreadcumbs(
        indicatorId || localStorage.getItem("NavIndicatorId") || 1,
        surveyId,
        perspectiveId,
        interlocutorId,
        standardTitle
      )}
    >
      <div>
        <ReviewedStatusCards
          loading={isGettingAllAgenciesByStandardId}
          cardsData={{
            all: allItemsCount,
            totalNotReviewedByExpert,
            totalNotReviewedByPoc,
            totalNotReviewedByReviewer,
            totalReviewedByExpert,
            totalReviewedByPoc,
            totalReviewedByReviewer,
            totalReviewedByExpertManager,
            totalNotReviewedByExpertManager
          }}
          setReviewedStatus={setReviewedStatus}
        />
      </div>

      <div className={`m-5 mt-14 flex justify-between ${!showTable && "py-5"}`}>
        <TableSearch onSearch={onSearch} />
        <TableCardSwitch showTable={showTable} setShowTable={setShowTable} />
      </div>

      {showTable ? (
        <StandardEntitiesTable
          maxResult={maxResult}
          setMaxResult={setMaxResult}
          entities={allAgenciesByStandardId}
          loading={isGettingAllAgenciesByStandardId}
          donotApplyDegree={donotApply}
          reviewedStatus={reviewedStatus}
          filterValue={filterValue}
        />
      ) : (
        <StandardEntitiesList
          entities={allAgenciesByStandardId}
          loading={isGettingAllAgenciesByStandardId}
        />
      )}

      <div className="pb-5 mx-auto">
        <AppPagination
          loading={isGettingAllAgenciesByStandardId}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
        />

        <div className="py-2 max-w-[290px] mx-auto gap-2 flex items-center">
          <AppInput
            style={{width: '100px'}}
            type="number"
            min={1}
            value={inputPageNum}
            placeholder="الصفحة"
            onChange={(e) => {
              setInputPageNum(e.target.value);
            }}
          />
          <AppButton
            className="mx-auto text-sm"
            disabled={inputPageNum < 1 || inputPageNum > totalPages}
            onClick={() => {
              goToPage(inputPageNum - 1);
            }}
          >
            الذهاب للصفحة
          </AppButton>
        </div>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default StandardEntities;


