import { useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";
import { ifNotDefinedReturnZero } from "../../../../helpers/utils";

export const useGetAdminDashboardStats = () => {
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = (currentStage?.stageIds?.[0] ?? 0);

  const { data, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ["AdminDashboardStats", phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAdminDashboardStats,
        {
          surveyId: ifNotDefinedReturnZero(surveyId),
          surveyStageId: phaseOneStageId
        },
        true,
        signal
      ),
  });

  const adminStats = data?.result;

  return { data, adminStats, isGettingDashboardStats };
};
