import React from "react";
import AppActions from "./appActions";
import classNames from "classnames";
import { truncateText } from "../helpers/utils";

const AppAccordion = ({
  number,
  title,
  children,
  variant = "primary",
  handleEditClick,
  handleDeleteClick,
  setIsOpen,
  isOpen,
}) => {
  const accordionClasses = classNames(
    {
      "border-[2px] ": isOpen,
      "border-blue_hover": isOpen && variant == "primary",
      "border-green": isOpen && variant == "green",
      "border-purple_light": isOpen && variant == "purple",
      "bg-purple_light": variant == "purple" && !isOpen,
      "bg-green_dark": variant == "green" && !isOpen,
      "bg-blue": !isOpen,
    },
    "py-5 pr-4 pl-6  rounded-lg"
  );
  return (
    <div className={accordionClasses}>
      <div className="flex justify-between items-center">
        <div
          className="w-11 h-11 flex gap-2 items-center cursor-pointer flex-grow"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isDefined(number) && (
            <div className="w-11 h-11 bg-[#D2F2E6] rounded-full flex justify-center items-center">
              <p className="mb-1">{number}</p>
            </div>
          )}
          <p
            className={`mb-1 select-none ${
              isOpen ? "text-black" : "text-white"
            }`}
          >
            {truncateText(title, 120)}
          </p>
        </div>
        <div className="flex gap-4 items-center">
          <AppActions
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />

          <div
            className="cursor-pointer"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
            >
              <path
                d="M6.62455 7.60193C6.50056 7.6027 6.37763 7.57885 6.26282 7.53176C6.14802 7.48466 6.04359 7.41525 5.95553 7.3275L0.301298 1.64687C0.20831 1.55983 0.13372 1.45484 0.0819796 1.33818C0.0302393 1.22152 0.00240907 1.09558 0.000149588 0.967869C-0.00210989 0.840161 0.0212477 0.713304 0.0688285 0.594869C0.116409 0.476434 0.187238 0.368847 0.277088 0.27853C0.366938 0.188212 0.473968 0.117015 0.591791 0.0691865C0.709613 0.0213582 0.835813 -0.00212087 0.962861 0.000150366C1.08991 0.0024216 1.2152 0.0303966 1.33125 0.082406C1.44731 0.134415 1.55175 0.209393 1.63835 0.302865L6.62455 5.32003L11.6028 0.311912C11.783 0.157386 12.0146 0.0768154 12.2514 0.0862588C12.4881 0.0957021 12.7126 0.194465 12.8801 0.362866C13.0476 0.531266 13.1459 0.756937 13.1553 0.994904C13.1647 1.23287 13.0845 1.46566 12.9308 1.64687L7.28058 7.32649C7.10519 7.50127 6.86852 7.59982 6.62155 7.60092L6.62455 7.60193Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

export default AppAccordion;


function isDefined(value) {
  return value !== undefined && value !== null;
}
