import { AuditorExpertContextProvider } from "../../contexts/AuditorExpertDashboardContext";
import { isExpertManager } from "../../helpers/isPermission";
import AdminDashboardStatsPhase2 from "../admin/phase2/AdminDashboardStatsPhase2";
import AuditorExpertDashboardCards from "./AuditorExpertDashboardCards";
import AuditorExpertDashboardStats from "./AuditorExpertDashboardStats";

function AuditorExpertDashboard() {
  return (
    <AuditorExpertContextProvider>
      {isExpertManager() ? (
        <AdminDashboardStatsPhase2 />
      ) : (
        <AuditorExpertDashboardStats />
      )}

      <AuditorExpertDashboardCards />
    </AuditorExpertContextProvider>
  );
}

export default AuditorExpertDashboard;
