import React, { useState, useEffect, useRef } from "react";
import { FModal, Title } from "../../../components";

import NewHeader from "../../../layouts/header/NewHeader";
import { programsIcon, settingsIcon } from "../../../assets/icons/Index";
import environment from "../../../services/environment";
import { axiosPostRequest } from "../../../services/Request";

import AppButton from "../../../components/buttons/appButton";
import { toast } from "react-toastify";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import AddFQAClassificationModal from "./fqa/AddFQAClassificationModal";
import FQAClassificationsTable from "./fqa/FQAClassificationsTable";
import { permissionsCheck } from "../../../helpers/utils";

const FQASettings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addClassificationLoading, setAddClassificationLoading] = useState(
    false
  );

  const [addClassificationInputs, setAddClassificationInputs] = useState({
    title: "",
  });

  // LATER
  // getAllFQAClassifications
  const [
    getAllFQAClassifications,
    allFQAClassifications,
    getAllFQAClassificationsLoading,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllFQAClassifications(environment.getAllClassificationsFaq);
  }, []);

  // handleAddFQAClassification
  const handleAddFQAClassification = async () => {
    const body = {
      title: addClassificationInputs.title,
    };

    setAddClassificationLoading(true);
    const res = await axiosPostRequest(
      environment.createFQAClassification,
      body
    );
    setAddClassificationLoading(false);

    if (res?.success) {
      toast.success(`تم إضافة تصنيف جديد بنجاح`);
      getAllFQAClassifications(environment.getAllClassificationsFaq);
      setOpenAddModal(false);
      setAddClassificationInputs({
        title: "",
      });
    } else {
      toast.error("حدث خطأ");
    }
  };

  const handleAddClassificationInputs = (e) => {
    setAddClassificationInputs({
      ...addClassificationInputs,
      [e.target.name]: e.target.value,
    });
  };

  const tabs = [
    {
      // icon: priorityIcon,
      title: "التصنيفات",
      table: "classifications",
      content: (
        <FQAClassificationsTable
          loading={getAllFQAClassificationsLoading}
          allFQAClassifications={allFQAClassifications}
          getAllFQAClassifications={getAllFQAClassifications}
        />
      ),
    },
  ];

  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={settingsIcon}
        style={{ backgroundColor: "#2B2969" }}
        single={true}
        title={"اٍعدادات الأسئلة الشائعة"}
        withIcon={true}
        withoutButton={true}
      />
      <div className="w-full bg-white pb-5 my-5 flex flex-col">
        <div className="w-full flex justify-end h-[50px] px-5">
          {permissionsCheck("Pages.FaqSettings.Create") ? 
          <AppButton onClick={() => setOpenAddModal(true)}>
            {activeTab === 0 ? "إضافة تصنيف" : ""}
          </AppButton>
          :null}
        </div>

        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  "mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] " +
                  (activeTab === index
                    ? "bg-emerald-500 text-white"
                    : "text-slate-400 bg-slate-50")
                }
                onClick={() => setActiveTab(index)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={"24px"}
                  height={"24px"}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>{activeTab === index && tab.content}</div>
            ))}
          </div>
        </div>

        <FModal
          type={"submit"}
          loading={activeTab === 0 ? addClassificationLoading : ""}
          submitLabel={"إضافة"}
          headerTitle={activeTab === 0 ? "إضافة تصنيف" : ""}
          content={
            activeTab === 0 ? (
              <AddFQAClassificationModal
                formRef={formRef}
                inputs={addClassificationInputs}
                onChange={handleAddClassificationInputs}
                handleAddFQAClassification={handleAddFQAClassification}
              />
            ) : (
              ""
            )
          }
          open={openAddModal}
          setOpen={() => setOpenAddModal(false)}
          action={activeTab === 0 ? handleSubmitAdd : ""}
        />
      </div>
    </div>
  );
};

export default FQASettings;
