import { createContext, useState } from "react";
import environment from "../services/environment";
import { get } from "../services/Request";
import { ifNotDefinedReturnZero } from "../helpers/utils";

const StageContext = createContext();

function StageContextProvider(props) { 
  const [currentStage, setCurrentStage] = useState("");
  const [userId, setUserId] = useState("");
  const [stageForDashboard, setStageForDashboard] = useState("");

  const [endDate, setEndDate] = useState("");
  const [stageRoles, setStageRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState({});
  const [hasPermission, setHasPermission] = useState(true);

  const getCurrentSurveyStage = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    // Note: This api return "ExpertsManager" role instead of "ExpertManager"
    get(
      environment.getCurrentSurveyStage +
        "?surveyId=" +
        ifNotDefinedReturnZero(localStorage.getItem("surveyId")),
      config,
      (res) => {
        if (res?.status == 200) {
          const roles = res?.data?.result?.surveyStageRoles
            ? res?.data?.result?.surveyStageRoles?.map(
                (item) => item?.role?.name
              )
            : [];
          setCurrentStage(res?.data?.result);
          
          // TODO: check the logic behind this
          const stageIdsFirstStage = res?.data?.result?.stageIds?.slice(0 , 2);
          const stageIdsSecondStage = res?.data?.result?.stageIds?.slice(2 , 4);

          if(stageIdsFirstStage?.includes(res?.data?.result?.id)){
            setStageForDashboard(stageIdsFirstStage?.[0]);
            localStorage.setItem("stageForDashboard" , (stageIdsFirstStage?.[0] ?? 0) )
          }
          if(stageIdsSecondStage?.includes(res?.data?.result?.id)){
            setStageForDashboard(stageIdsSecondStage?.[0]);
            localStorage.setItem("stageForDashboard" , (stageIdsSecondStage?.[0] ?? 0) )

          }

          setEndDate(res?.data?.result?.endDate);
          setStageRoles(roles);
          localStorage.setItem("surveyStageId", (res?.data?.result?.id ?? 0));
        }
      }
    );
  };

  return (
    <StageContext.Provider
      value={{
        currentStage,
        stageForDashboard,
        getCurrentSurveyStage,
        endDate,
        stageRoles,
        permissions,
        setPermissions,
        role,
        setRole,
        hasPermission,
        setHasPermission,
        setUserId,
        userId,
        refresh: getCurrentSurveyStage,
      }}
    >
      {props.children}
    </StageContext.Provider>
  );
}

export { StageContext, StageContextProvider };


// currentStage, stageForDashboard



function localStorageGet(key) {
  if(isDefined(localStorage.getItem(key))){
    return localStorage.getItem(key);
  }
  return null;
}

function isDefined(value) {
  return value !== null && value !== undefined && value !== "" && value !== "null";
}
