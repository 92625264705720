import React, { useContext, useState } from "react";
import StandardAnswerRequirement from "../../../standardAnswerRequirements";
import { useAnswerContext } from "../../../answerContext";
import { useParams } from "react-router-dom";
import AgencyRequirementAnswer from "../../Agency/agencyRequirementAnswer";
import {
  isAdminFunc,
  isExpertManager,
  isInquiryManager,
} from "../../../../../../../../helpers/isPermission";
import { useQuery } from "@tanstack/react-query";
import environment from "../../../../../../../../services/environment";
import { axiosGetRequest, axiosPutRequest } from "../../../../../../../../services/Request";
import AppButton from "../../../../../../../../components/buttons/appButton";
import usePrevExpertManagerAnswer from "../../useGetPrevAnswer/usePrevExpertManagerAnswer";
import { StageContext } from "../../../../../../../../contexts/stageContext";
import { errorToast, successToast } from "../../../../../../../../helpers/toast";
import { EditStandardRequirementsBasedOnSides } from "./EditStandardRequirementsBasedOnSides";
import { AddStandardRequirementsBasedOnSides } from "./AddStandardRequirementsBasedOnSides";

const ExpertManagerRequirementAnswerBasedOnSides = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const {
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
  } = useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("expertManager");

  const { currentStage } = useContext(StageContext);
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();
  // check if expert / reviewer has answer...
  const isReadOnly = !(selectedRequirementsTab === 'expert' ? newExpertManagerAnswer?.expertLastAnsweredId : newExpertManagerAnswer?.reviewerLastAnsweredId);

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ["commitmentLevels", { standardId }],
    queryFn: async ({ queryKey }) => {
      const [, { standardId }] = queryKey;

      const res = await axiosGetRequest(environment.getCommitmentLevels, {
        standardId,
      });

      return res?.result;
    },
  })

  const updateRequirementsAnswer = async (from) => {

    const answeredId = from === 'expert' ? newExpertManagerAnswer?.expertLastAnsweredId : newExpertManagerAnswer?.reviewerLastAnsweredId;

    let reqModel = {
      "id": answeredId ?? 0,
      "agencyId": Number(entityId),
      "standardId": Number(standardId),
      "surveyStageId": Number((currentStage?.id || 0)),

      // "selfEvaluationCommitmentLevelId": null,
      // "finalCommitmentLevelId": null,
      // "selfEvaluationDegree": 0,
      // "finalDegree": 0,
      // "donotApply": false,
      // "visitId": null,
    }

    if (from === "expert") {
      reqModel = {
        ...reqModel,
        "expertId": newExpertManagerAnswer?.lastAnsweredExpertId,
        "expertRequirementAnswers": [...newExpertManagerAnswer?.expertRequirementsAnswer?.map(req => {
          return {
            ...req,
            expertSubRequirementAnswers: req?.subStandardRequirements
          }
        })],
      }
    } else {
      reqModel = {
        ...reqModel,
        "reviewerId": newExpertManagerAnswer?.lastAnsweredReviewerId,
        "reviewerRequirementAnswers": [...newExpertManagerAnswer?.reviewerRequirementsAnswer?.map(req => {
          return {
            ...req,
            reviewerSubRequirementAnswers: req?.subStandardRequirements
          }
        })],
      }
    }
    const apiName = from === 'expert' ? environment.updateExpertAnswer : environment.updateReviewerAnswer;
    const res = await axiosPutRequest(
      apiName,
      reqModel
    );
    if (res?.success) {
      successToast("تم إضافة الإجابة بنجاح");
      getPrevExpertManagerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage?.id)
      );
    } else {
      errorToast(res?.response?.data?.error?.message ?? 'حدث خطأ');
    }
  }

  return (
    <div>
      {/* اسئلة على مستوى البعد */}
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="w-full flex justify-between">
          <div className="flex  pb-5 w-full">
            <p
              className={
                selectedRequirementsTab == "agency"
                  ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("agency")}
            >
              الجهة
            </p>
            <p
              className={
                selectedRequirementsTab == "reviewer"
                  ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("reviewer")}
            >
              المدقق
            </p>

            <p
              className={
                selectedRequirementsTab == "expert"
                  ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("expert")}
            >
              الخبير
            </p>
            <p
              className={
                selectedRequirementsTab == "expertManager"
                  ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                  : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
              }
              onClick={() => setSelectedRequirementsTab("expertManager")}
            >
              رئيس الخبراء
            </p>
          </div>

          {(!isReadOnly && !isAdminFunc()) &&
            (selectedRequirementsTab === "reviewer" ||
              selectedRequirementsTab === "expert") && (
              <AppButton
                variant="secondary"
                onClick={() =>
                  updateRequirementsAnswer(selectedRequirementsTab)
                }
              >
                تعديل الإجابة
              </AppButton>
            )}
        </div>

        {selectedRequirementsTab === "agency" && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === "reviewer" && (
          <>
            <div className="pr-10">
              {newExpertManagerAnswer.isReviewerUpdatedByExpertManager && (
                <div className="flex justify-center items-center bg-red-100 p-2 rounded-md mb-3">
                  <p className="text-red-600 text-lg">
                    تم تعديل الإجابة بواسطة رئيس الخبراء
                  </p>
                </div>
              )}
            </div>
            <EditStandardRequirementsBasedOnSides
              from={selectedRequirementsTab}
              newExpertManagerAnswer={newExpertManagerAnswer}
              setNewExpertManagerAnswer={setNewExpertManagerAnswer}
              requirements={newExpertManagerAnswer?.reviewerRequirementsAnswer}
              hasAnswer={newExpertManagerAnswer?.reviewerRequirementAnswers}
              commitmentLevels={commitmentLevels}
              label={"تقييم المدقق"}
              isReadOnlyView={isAdminFunc() || isInquiryManager()}
            />
          </>
        )}
        {selectedRequirementsTab === "expert" && (
          <>
            <div className="pr-10">
              {newExpertManagerAnswer.isExpertUpdatedByExpertManager && (
                <div className="flex justify-center items-center bg-red-100 p-2 rounded-md mb-3">
                  <p className="text-red-600 text-lg">
                    تم تعديل الإجابة بواسطة رئيس الخبراء
                  </p>
                </div>
              )}
            </div>
            <EditStandardRequirementsBasedOnSides
              from={selectedRequirementsTab}
              newExpertManagerAnswer={newExpertManagerAnswer}
              setNewExpertManagerAnswer={setNewExpertManagerAnswer}
              requirements={newExpertManagerAnswer?.expertRequirementsAnswer}
              hasAnswer={newExpertManagerAnswer?.expertRequirementAnswers}
              commitmentLevels={commitmentLevels}
              label={"تقييم الخبير"}
              isReadOnlyView={isAdminFunc() || isInquiryManager()}
            />
          </>
        )}

        {(
          isExpertManager() ||
          isAdminFunc() ||
          isInquiryManager()
        ) &&
          selectedRequirementsTab === "expertManager" && (
            <>
              <AddStandardRequirementsBasedOnSides />
            </>
          )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertManagerRequirementAnswerBasedOnSides;