import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { TextInput } from "flowbite-react";
import { Input } from "../../../../../../../../components";
import { axiosGetRequest } from "../../../../../../../../services/Request";
import environment from "../../../../../../../../services/environment";
import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import UploadFile from "../../UploadFile";
import { getCommitmentLevelsBasedOnDegree } from "../../utils";


export function ViewStandardRequirementsBasedOnSides({
    requirements,
    hasAnswer
}) {

    const { standardId } = useParams();

    const { data: commitmentLevels = [] } = useQuery({
        queryKey: ["commitmentLevels", { standardId }],
        queryFn: async ({ queryKey }) => {
            const [, { standardId }] = queryKey;

            const res = await axiosGetRequest(environment.getCommitmentLevels, {
                standardId,
            });

            return res?.result;
        },
    });


    return !hasAnswer ? (
        <h2 className="text-2xl pb-2">لا يوجد إجابة حتى الآن</h2>
    ) : (
        requirements?.length > 0 &&
        requirements?.map((requirement) => {
            const hasSubRequirements =
                requirement?.subStandardRequirements?.length > 0;

            return (
                <>
                    <MainRequirement
                        key={requirement?.id}
                        title={
                            requirement?.title || requirement?.standardRequirementTitle
                        }
                        hasSubRequirements={hasSubRequirements}
                        checked={requirement?.decision}
                        requirementDonotApplyChecked={requirement?.donotApply}
                        onChange={() => { }}
                        handleDonotApplyRequirementChange={() => { }}
                    >
                        {hasSubRequirements &&
                            hasAnswer &&
                            requirement?.subStandardRequirements?.map((subRequirement) => {
                                return (
                                    <>
                                        <div className="px-5 flex flex-col mb-3 py-2">
                                            <div className="flex flex-col gap-4">
                                                <div className="border-b pb-2">
                                                    <div className="flex flex-row gap-2 mb-3 py-2">
                                                        <Input
                                                            type="checkbox"
                                                            className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] h-[24px] my-1"
                                                            id={subRequirement.value}
                                                            name={"ارجاع الى الجهة"}
                                                            label={""}
                                                            value={subRequirement.isReturnable}
                                                            checked={subRequirement.isReturnable}
                                                            disabled={true}
                                                        />

                                                        <h3 className="text-xl pb-2">
                                                            {subRequirement.title}
                                                        </h3>
                                                    </div>

                                                    {subRequirement?.isReturnable && (
                                                        <div className="flex gap-2 w-full items-center mb-4">
                                                            <span className="w-32">ملاحظة الى الجهة</span>
                                                            <TextInput
                                                                value={subRequirement?.reason}
                                                                className="grow"
                                                                placeholder="ملاحظة الى الجهة"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className=" flex items-center gap-2 mb-4">
                                                        <span className="w-24">تقييم المدقق</span>
                                                        <TextInput
                                                            value={subRequirement?.percentage}
                                                            type="number"
                                                            placeholder=""
                                                            min={0}
                                                            max={100}
                                                            disabled
                                                        />
                                                        <label>
                                                            {getCommitmentLevelsBasedOnDegree(
                                                                Number(subRequirement?.percentage),
                                                                commitmentLevels
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div className="flex gap-2 w-full items-center mb-4">
                                                        <span className="w-24"> الملاحظة</span>
                                                        <TextInput
                                                            value={subRequirement?.note}
                                                            className="grow"
                                                            placeholder="ملاحظة المدقق للجهة"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="flex gap-2 w-full items-center">
                                                        <span className="w-24">التوصية</span>
                                                        <TextInput
                                                            value={subRequirement?.recommendation}
                                                            className="grow"
                                                            placeholder="توصية المدقق للجهة"
                                                            disabled
                                                        />
                                                    </div>
                                                    <UploadFile
                                                        value={subRequirement.attachmentId}
                                                        withOutToolTip
                                                        readOnly
                                                        label="يمكنك إرفاق ملف ولا يزيد حجم الملف عن 30 ميجا"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </MainRequirement>
                </>
            );
        })
    );
}
