import { Page, Image, StyleSheet } from "@react-pdf/renderer";
import Slide5 from "../assets/images/Slide5.PNG";

export default function Page5({ styles }) {
  return (
    <Page size="A4" style={styles.pageImage}>
      <Image src={Slide5} style={styles.imagePage} />
    </Page>
  );
}
