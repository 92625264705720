import { Page, Image, StyleSheet, Link, Text, View } from "@react-pdf/renderer";
import Slide37 from "../assets/images/Slide37.PNG";
export default function Page19({ styles }) {

  const pageStyles = StyleSheet.create({
    clickableButton: {
      position: "absolute",
      top: "55%",
      right: "35%",
      width: 170,
      height: 50,
    },
    buttonShadow: {
      width: '100%',
      height: '100%',
      borderRadius: 8,
      backgroundColor: '#1B191E',
      position: 'absolute',
      top: 3,
      zIndex: -1,

    }
  })

  return (
    <Page size="A4" style={styles.pageImage}>
      <View style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}>
        <Image src={Slide37} style={styles.imagePage} />

        <View style={pageStyles.clickableButton}>
          <View style={{ position: 'relative', width: '100%', height: '100%' }}>
            <View
              style={styles.button}
            >
              <Link
                src="https://www.google.com"
                style={{
                  textDecoration: 'none', color: '#fff', width: '100%', height: '100%',
                }}
              >
                <Text> اطلاع​ </Text>
              </Link>
            </View>
            <View style={pageStyles.buttonShadow}></View>
          </View>
        </View>
      </View>

    </Page>
  );
}
