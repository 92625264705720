import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { calendarGreen } from "../../../assets/icons/Index";
import { Button } from "../../../components";
import SCalendar from "../../../components/SCalendar";
import UploadAdapter from "../../../components/UploadAdapter";
import { formatDate, permissionsCheck } from "../../../helpers/utils";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import { toast } from "react-toastify";
import Skeleton from "react-skeleton-loader";
import { errorToast } from "../../../helpers/toast";

const Visit = ({
  setActiveTab,
  getAllStandardsVisitAgency,
  standardsVisitAgency,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [day, setDay] = useState("");

  const [visitSlots, setVisitSlots] = useState([]);
  const [standardsVisit, setStandardsVisit] = useState([]);
  // const [standardsVisitAgency, setStandardsVisitAgency] = useState([]);

  const [noneReservedvisitSlots, setNoneReservedVisitSlots] = useState([]);

  const [reservedDates, setReservedDates] = useState([]);

  const [noneReservedId, setNoneReservedId] = useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    getAllVisitSlots();
  }, []);

  const [availableDates, setAvailableDates] = useState([]);

  const getAllVisitSlots = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    let agencyId = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      agencyId = "?AgencyId=" + localStorage.getItem("agencyId");
    }
    get(environment.getVisitSlot + agencyId, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        let arrDates = [];
        res.data.result.items.forEach((element) => {
          if (element.visitSlotStatus == 1) {
            arrDates.push(new Date(element.visitDate.slice(0, 10)));
          }
        });
        setReservedDates(arrDates);
        let availableArr = [];
        res.data.result.items.forEach((element) => {
          if (element.visitSlotStatus == 2) {
            availableArr.push(element.visitDate.slice(0, 10));
            // availableArr.push(element.visitDate);
          }
        });
        setAvailableDates(availableArr);

        setVisitSlots(res.data.result.items);
        getAllStandardsVisit();
      }
    });
  };

  const getAllStandardsVisit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllStandardVisitSlot, config, (res) => {
      if (res?.status == 403) {
        //   toast.error(t("general.authError"), {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
        // } else if (res.status == 500) {
        //   toast.error(t("general.serverError"), {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
      } else if (res?.status == 200) {
        setStandardsVisit(res?.data?.result?.items);
        getAllStandardsVisitAgency();
      }
    });
  };

  // const getAllStandardsVisitAgency = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //   };
  //   get(
  //     environment.getAllVisitsAnswerAgency +
  //       "?AgencyId=" +
  //       localStorage.getItem("agencyId"),
  //     config,
  //     (res) => {
  //       if (res.status == 403) {
  //         toast.error(t("general.authError"), {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       } else if (res.status == 500) {
  //         toast.error(t("general.serverError"), {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       } else if (res.status == 200) {
  //         setStandardsVisitAgency(res.data.result.items);
  //       }
  //     }
  //   );
  // };

  const [nonReservedLoading, setNonReservedLoading] = useState(false);

  const getAllNonReservedVisitSlots = (date) => {
    setNonReservedLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let agencyId = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      // agencyId = "?agenyId=" + localStorage.getItem("agencyId");
      agencyId = "&agenyId=" + localStorage.getItem("agencyId");
    }
    get(
      environment.getAllNonReservedVisitSlotsBySpecificDate +
        "?date=" +
        date +
        agencyId,
      config,
      (res) => {
        setNonReservedLoading(false);
        if (res.status == 200) {
          setNoneReservedVisitSlots(res.data.result.items);
        } else {
          setNoneReservedVisitSlots([]);
        }
      }
    );
  };

  // ---------------------------- //

  // ---------------------------- //

  const getDate = (date) => {
    setSelectedDate(date);
    setDate(
      formatDate(new Date(date)),
      getAllNonReservedVisitSlots(formatDate(new Date(date)))
    );
    setDay(date.toString().split(" ")[0]);
  };

  const [reserveSlotLoading, setReserveSlotLoading] = useState(false);

  const reserveSlot = () => {
    if (!noneReservedId) {
      errorToast("لابد من اختيار موعد من الأوقات المتاحة");
      return;
    }
    setReserveSlotLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      agencyId: localStorage.getItem("agencyId"),
      visitSlotId: noneReservedId,
      description: description,
    };
    post(environment.reserveSlot, data, config, (res) => {
      setReserveSlotLoading(false);
      if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAllVisitSlots();
      } else {
        toast.success("تم حجز  موعد بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setActiveTab(1);
        getAllVisitSlots();
      }
    });
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <div className="flex justify-end p-3"></div>
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:grid-cols-2 px-5">
        <div className="p-3">
          <SCalendar
            available={availableDates}
            dateCalendar={selectedDate}
            getDate={getDate}
            disabledDates={reservedDates}
          />
          <div className="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#A1A9C4] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">
                التواريخ غير المتاحة
              </div>
            </div>
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#E8F9F2] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">التواريخ المتاحة</div>
            </div>
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#554AA7] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">التاريخ المختار</div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-full flex flex-col mt-5 p-3">
            <label className="text-[16px] text-[#292069] mb-3">
              التاريخ المختار
            </label>
            <div className="w-full bg-[#292069] rounded-3xl py-3 px-6 flex flex-row justify-between items-center">
              <div className="flex flex-row items-center">
                <div className="text-white text-[13px]">{day}</div>
                <div className="text-white text-[13px] mr-5 ml-5">{date}</div>
              </div>
              <img src={calendarGreen} />
            </div>
          </div>
          <div className="w-full flex flex-col mt-5 p-3">
            <label className="text-[16px] text-[#292069] mb-3">
              الاوقات المتاحة
            </label>
            {nonReservedLoading ? (
              <div className="w-full flex flex-col gap-5  mt-2">
                <Skeleton width={"500px"} height={"40px"} color={"#F9F9FF"} />
                <Skeleton width={"500px"} height={"40px"} color={"#F9F9FF"} />
                <Skeleton width={"500px"} height={"40px"} color={"#F9F9FF"} />
              </div>
            ) : noneReservedvisitSlots.length == 0 ? (
              <div className="flex flex-row items-center">لا يوجد اوقات </div>
            ) : (
              <>
                {noneReservedvisitSlots.map((item, index) => (
                  <div
                    className={`w-full bg-[${
                      noneReservedId == item.id ? "#292069" : "#F9F9FF"
                    }] rounded-3xl py-3 px-6 flex flex-row justify-between items-center cursor-pointer mt-2`}
                    onClick={() => setNoneReservedId(item.id)}
                  >
                    <div className="flex flex-row items-center">
                      <div
                        className={`${
                          noneReservedId == item.id
                            ? "text-white"
                            : "text-[#7D8BAB]"
                        } text-[13px]`}
                      >
                        من {`${item.startTime}`}{" "}
                      </div>
                      <div
                        className={`${
                          noneReservedId == item.id
                            ? "text-white"
                            : "text-[#7D8BAB]"
                        } text-[13px] mr-2 ml-2`}
                      >
                        الى {`${item.endTime}`}{" "}
                      </div>
                    </div>
                    <img src={calendarGreen} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div></div>
      </div>

      {permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate") ? (
        <div className="px-5 mt-5 w-full">
          <label className="text-[16px] text-[#292069]">أجندة الإجتماع</label>
          <div className="mt-5">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onReady={(editor) => {
                setDescription(editor.getData());
                editor.plugins.get(
                  "FileRepository"
                ).createUploadAdapter = function(loader) {
                  return new UploadAdapter(loader);
                };
              }}
              config={{
                language: "ar",

                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1' }, // Corrected to 'h1'
                    { model: 'heading2', view: 'h2', title: 'Heading 2' }, // Corrected to 'h2'
                    { model: 'heading3', view: 'h3', title: 'Heading 3' }  // Corrected to 'h3'
                  ]
                },   

                toolbar: [ 
                  'undo', 'redo', '|', 
                  'heading', '|', 
                  'bold', 'italic', 'Underline', '|',
                  'link', 'insertTable', 'blockQuote', '|',
                  'bulletedList', 'numberedList', '|',
                  // 'outdent', 'indent', 
                ],
    
                simpleUpload: {
                  uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                  withCredentials: true,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
                editor.plugins.get(
                  "FileRepository"
                ).createUploadAdapter = function(loader) {
                  return new UploadAdapter(loader);
                };
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </div>
        </div>
      ) : null}

      {permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate") ? (
        <div className="px-5 flex flex-row justify-end">
          <Button
            onClick={() => reserveSlot()}
            loading={reserveSlotLoading}
            title="حجز الموعد"
            style={{ marginBottom: "50px", marginTop: "50px", width: "12rem" }}
          />
        </div>
      ) : null}

      {/* {permissionsCheck("Pages.UserType.Expert") ||
      permissionsCheck("Pages.UserType.ExpertManager") ? (
        <div className="bg-white m-5">
          <p className="text-[16px] text-[#292069] mb-5">
            نموذج تدقيق الزيارات
          </p>
          <NewTable
            data={standardsVisit}
            columns={[
              {
                name: "كود المعيار",
                accessorKey: "standardCode",
                selector: (row) => row.standardCode,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#108AE0]">
                    {info.standardCode}
                  </div>
                ),
              },
              {
                name: "أسم المعيار",
                accessorKey: "standardTitle",
                selector: (row) => row.standardTitle,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">
                    {info.standardTitle}
                  </div>
                ),
              },
              {
                name: "تاريخ الزيارة",
                accessorKey: "visitDate",
                selector: (row) => row.visitDate,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">
                    {formatDate(new Date(info.visitDate))}
                  </div>
                ),
              },
              {
                name: "من",
                accessorKey: "startTime",
                selector: (row) => row.startTime,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">{info.startTime}</div>
                ),
              },
              {
                name: "الي",
                accessorKey: "endTime",
                selector: (row) => row.endTime,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">{info.endTime}</div>
                ),
              },
              {
                name: "إجراءات",
                selector: (row) => row.status,
                sortable: true,
                cell: (info) => (
                  <div className="flex flex-row w-[100%]">
                    <div
                      onClick={() =>
                        history.push(
                          `/visit/${info.visitId}/standard/${info.standardId}/rate`
                        )
                      }
                    >
                      <img src={viewNew} className="w-[40px] cursor-pointer" />
                    </div>
                  </div>
                ),
              },
            ]}
            pagination={false}
          />
        </div>
      ) : null} */}

      {/* {permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate") ? (
        <div className="bg-white m-5">
          <p className="text-[16px] text-[#292069] mb-5">نتائج التدقيق</p>

          <NewTable
            data={standardsVisitAgency}
            columns={[
              {
                name: "كود المعيار",
                accessorKey: "standardCode",
                selector: (row) => row.standardCode,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#108AE0]">
                    {info.standardCode}
                  </div>
                ),
              },
              {
                name: "أسم المعيار",
                accessorKey: "standardTitle",
                selector: (row) => row.standardTitle,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">
                    {info.standardTitle}
                  </div>
                ),
              },
              {
                name: "مستوي الالتزام",
                accessorKey: "commitmentLevelTitle",
                selector: (row) => row.commitmentLevelTitle,
                cell: (info) => (
                  <div className="text-sm text-[#1CC081]">
                    {info.commitmentLevelTitle}
                  </div>
                ),
                sortable: true,
              },
              {
                name: "الملاحظة",
                accessorKey: "notes",
                selector: (row) => row.notes,
                cell: (info) => (
                  <div
                    className="text-sm text-[#1CC081]"
                    dangerouslySetInnerHTML={{ __html: info.notes }}
                  ></div>
                ),
                sortable: true,
              },
            ]}
            pagination={false}
          />
        </div>
      ) : null} */}
    </div>
  );
};

export default Visit;
