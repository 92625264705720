import { useTranslation } from "react-i18next";
import { FModal, InputLabel } from "../../../components";
import { useEffect, useRef, useState } from "react";
import environment from "../../../services/environment";
import { get, post, put, remove } from "../../../services/Request";
import { useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import DeleteIcon from "../../../components/DeleteIcon";
import { toast } from "react-toastify";
import AppColorInput from "../../../components/form/appColorInput";
import EditIcon from "../../../components/EditIcon";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import { NewForm } from "../../../components/form/NewForm";
import { ErrorMessage } from "formik";
import { permissionsCheck } from "../../../helpers/utils";

const CategoriesList = ({ open, setOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#000000");
  const [isSupported, setIsSupported] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [deletedId, setDeletedId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // --------------------------------------------- //
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);
  const formRefEdit = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };
  const handleSubmitEdit = () => {
    if (formRefEdit.current) {
      formRefEdit.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
    users: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!title) {
      errors.title = "هذا الحقل مطلوب";
    }

    if (!selectedUsers.length) {
      errors.users = "هذا الحقل مطلوب";
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const columns = [
    {
      name: t("modules.main.tickets.category"),
      selector: (row) => row.name,
      header: "name",
      accessorKey: "name",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">{row.name}</div>
      ),
    },
    {
      name: t("modules.main.tickets.color"),
      selector: (row) => row.color,
      header: "color",
      accessorKey: "color",
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div
          className="text-white w-28 h-12 rounded-xl text-center text-base"
          style={{ padding: "10px", backgroundColor: row.color }}
        >
          {row.color}
        </div>
      ),
    },
    {
      name: t("modules.main.mailbox.actions"),
      selector: (row) => row.id,
      header: "id",
      accessorKey: "id",
      footer: (props) => props.column.id,
      center: true,
      cell: (row) =>
        permissionsCheck("Pages.TicketsCategory.Edit") && (
          <div className="flex">
            <EditIcon
              onClose={() => clear()}
              initialValuesFunction={() => editCategory(row)}
              loading={editLoading}
              editFunction={handleSubmitEdit}
              type={"submit"}
              editedName={row.name}
              editModalContent={
                <NewForm
                  initialValues={initialValues}
                  validate={validate}
                  innerRef={formRefEdit}
                  onSubmit={updateCategory}
                >
                  <div className="flex flex-col gap-3">
                    <div className="space-y-1">
                      <InputLabel
                        label={"الفئة"}
                        type={"text"}
                        value={title}
                        name={"title"}
                        onChange={handleChangeTitle}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="title"
                        component="div"
                      />
                    </div>

                    <AppColorInput
                      label={"اللون"}
                      type={"color"}
                      name={"color"}
                      value={color}
                      onChange={handleChangeColor}
                    />

                    <div className="flex flex-col space-y-1">
                      <NewDropdownMultiSelect
                        withoutLabel={false}
                        isSearchable={true}
                        value={selectedUsers}
                        handleChange={handleChangeSelectedUsers}
                        placeholder={t("sideMenu.users")}
                        label={t("sideMenu.users")}
                        options={users}
                        isMultiple={true}
                        name="users"
                        isRequired={true}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="users"
                        component="div"
                      />
                    </div>

                    <div className="flex flex-row items-center mt-3 gap-2">
                      <input
                        type="checkbox"
                        className="ml-2 bg-slate-200 border-none"
                        checked={isSupported}
                        value={isSupported}
                        onChange={handleChangeIsSupported}
                      />
                      <label className="text-lg text-[#292069] -mt-2">
                        {t("modules.main.tickets.supportStandard")}
                      </label>
                    </div>
                  </div>
                </NewForm>
              }
            />
            {permissionsCheck("Pages.TicketsCategory.Delete") ? (
              <DeleteIcon
                loading={deleteLoading}
                deleteFunction={() => deleteCategory(row.id)}
                deletedName={`الفئة ${row.name}`}
              />
            ) : null}
          </div>
        ),
    },
  ];

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeColor = (e) => {
    setColor(e.target.value);
  };

  const handleChangeIsSupported = (e) => {
    setIsSupported(e.target.checked);
  };

  const handleChangeSelectedUsers = (value) => {
    //setSelectedUsers(e.target.value);
    setSelectedUsers(value);
  };

  useEffect(() => {
    getAllUsers();
    getAllList(0);
  }, []);

  const getAllList = (page) => {
    setCategories([]);
    setCurrentPage(page);
    getAllCategories(page);
  };

  const getAllCategories = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get("services/app/Category/GetAll"+"?MaxResultCount=" + 100, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        setCategories(res.data.result.items);
        setTotalPages(Math.ceil(res.data.result.totalCount / 10));
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const getAllUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    post(environment.getAllUsersWithoutPagination, null, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result && res.data.result.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.length; i++) {
            array.push({
              value: res.data.result[i].id,
              userId: res.data.result[i].id,
              label: res.data.result[i].fullName,
              isSelected: false,
            });
          }
          setUsers(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const addCategory = () => {
    setOpen(true);
    setId(0);
    setTitle("");
    setColor("");
    setIsSupported(false);
    setSelectedUsers([]);
  };

  const clear = () => {
    setId(0);
    setTitle("");
    setColor("#000");
    setIsSupported(false);
    setSelectedUsers([]);
  };

  const editCategory = (row) => {
    setId(row.id);
    setTitle(row.name);
    setColor(row.color);
    setIsSupported(row.isSupported);
    setSelectedUsers([]);
    if (row.categoryUsers && row.categoryUsers.length > 0) {
      var array = [];
      for (var i = 0; i < row.categoryUsers.length; i++) {
        array.push({
          value: row.categoryUsers[i].userId,
          userId: row.categoryUsers[i].userId,
          label: row.categoryUsers[i].userFullName,
          isSelected: true,
        });
      }
      setSelectedUsers(array);
    }
  };

  const saveCategory = () => {
    if (id == 0) {
      createCategory();
    } else {
      updateCategory();
    }
  };

  const createCategory = () => {
    setAddLoading(true);
    if (!title) {
      toast.error("برجاء اٍدخال اسم الفئة");
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      name: title,
      color: color,
      isSupportStandard: isSupported,
      categoryUsers: selectedUsers,
    };
    post(environment.createCategory, data, config, (res) => {
      setAddLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.success("تم إضافة فئة جديدة بنجاح");
        getAllList(0);
        clear();
        setOpen(false);
        setOpenModal(true);
      }
    });
  };

  const updateCategory = () => {
    setEditLoading(true);
    if (!title) {
      toast.error(t("modules.main.calendar.nameRequired"));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: id,
      name: title,
      color: color,
      isSupportStandard: isSupported,
      categoryUsers: selectedUsers,
    };
    put("services/app/Category/Update", data, config, (res) => {
      setEditLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.info("تم تحديث الفئة بنجاح");
        getAllList(0);
        clear();
        setOpen(false);
        setOpenModal(true);
      }
    });
  };

  const deleteCategory = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove("services/app/Category/Delete?Id=" + id, config, (res) => {
      setDeleteLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.success("تم حذف الفئة بنجاح");
        getAllList(0);
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset);
  };

  return (
    <div className="">
      {permissionsCheck("Pages.TicketsCategory.GetAll") ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={categories}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}

      <FModal
        loading={addLoading}
        submitLabel={"إضافة"}
        headerTitle={t("modules.main.tickets.addCategory")}
        type={"submit"}
        action={handleSubmitAdd}
        content={
          <NewForm
            initialValues={initialValues}
            validate={validate}
            innerRef={formRef}
            onSubmit={createCategory}
          >
            <div className="flex flex-col gap-3">
              <div className="space-y-1">
                <InputLabel
                  label={"الفئة"}
                  type={"text"}
                  value={title}
                  name={"title"}
                  onChange={handleChangeTitle}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>

              <AppColorInput
                label={"اللون"}
                type={"color"}
                name={"color"}
                value={color}
                onChange={handleChangeColor}
              />

              <div className="flex flex-col space-y-1">
                <NewDropdownMultiSelect
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedUsers}
                  handleChange={handleChangeSelectedUsers}
                  placeholder={t("sideMenu.users")}
                  label={t("sideMenu.users")}
                  options={users}
                  isMultiple={true}
                  name="users"
                  isRequired={true}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="users"
                  component="div"
                />
              </div>

              <div className="flex flex-row items-center mt-3 gap-2">
                <input
                  type="checkbox"
                  className="ml-2 bg-slate-200 border-none"
                  checked={isSupported}
                  value={isSupported}
                  onChange={handleChangeIsSupported}
                />
                <label className="text-lg text-[#292069] -mt-2">
                  {t("modules.main.tickets.supportStandard")}
                </label>
              </div>
            </div>
          </NewForm>
        }
        open={open}
        setOpen={() => setOpen(false)}
      />
    </div>
  );
};

export default CategoriesList;

{
  /* <div>
<InputLabel
label={"الفئة"}
type={"text"}
value={title}
onChange={handleChangeTitle}
/>
<div className="flex">
<InputLabel
  label={"اللون"}
  type={"color"}
  value={color}
  onChange={handleChangeColor}
  containerStyle={{ height: "50px", width: "100px" }}
/>
<div className="flex flex-row items-center mx-20 w-6/12">
  <input
    type="checkbox"
    className="ml-2 bg-slate-200 border-none"
    checked={isSupported}
    value={isSupported}
    onChange={handleChangeIsSupported}
  />
  <h2 className="text-[15px] text-[#7D8BAB]">
    {t("modules.main.tickets.supportStandard")}
  </h2>
</div>
</div>
<label className="label_input text-lg text-[#0D062D] mb-3 w-11/12">
{t("sideMenu.users")}
</label>
<DropdownMultiSelect
withoutLabel={true}
isSearchable={true}
value={selectedUsers}
handleChange={handleChangeSelectedUsers}
placeholder={t("sideMenu.users")}
label={t("sideMenu.users")}
options={users}
isMultiple={true}
name="users"
/>
</div> */
}
