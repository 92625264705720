import { useState } from "react";
import AppActions from "../../../../../../components/appActions";
import UpdateSideModal from "../UpdateSideModal";
import AppDeleteItemModal from "../../../../../../components/Models/appDeleteItemModal";
import environment from "../../../../../../services/environment";

const SideItem = ({
  id,
  title,
  description,
  surveyId,
  setSidesList,
  sidesList,
  subStandardRequirementSides
}) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const removeDeletedDegree = () => {
    const deleteItem = sidesList.filter((item) => item.id != id);
    setSidesList(deleteItem);
  };

  return (
    <div className="bg-white rounded-lg row gap-y-6 mb-4 mx-1 p-6">
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">اسم البعد</p>
          <p className="px-3 text-sm text-light">{title}</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            وصف البعد
          </p>
          <p className="px-3 text-sm text-light">{description}</p>
        </div>
      </div>
      <div className="col-md-4">
        <AppActions
          handleEditClick={() => {
            setOpenUpdateModal(true);
          }}
          handleDeleteClick={() => {
            setOpenDeleteModal(true);
          }}
        />
      </div>

      <UpdateSideModal
        id={id}
        openModal={openUpdateModal}
        setOpenModal={setOpenUpdateModal}
        values={{ id, title, surveyId, description }}
        setSidesList={setSidesList}
        sidesList={sidesList}
        subStandardRequirementSides={subStandardRequirementSides}
      />

      <AppDeleteItemModal
        id={id}
        modalTitle="حذف البعد"
        itemTitle={title}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        api={environment.deleteSide}
        params={{ Id: id }}
        setDegreeList={setSidesList}
        degreeList={sidesList}
        removeItemFromList={removeDeletedDegree}
      />
    </div>
  );
};

export default SideItem;
