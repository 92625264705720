import AppButton from "../../../../../components/buttons/appButton";
import { axiosPostRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { useContext, useEffect, useState } from "react";
import { AddStandardContext } from "./addStandardContext";
import { useParams } from "react-router";
import { produce } from "immer";
import { errorToast, successToast } from "../../../../../helpers/toast";
import { useHistory } from "react-router-dom";
import { generateRandomNumber } from "../../../../../helpers/utils";

const SubmitAddStandard = () => {
  const { surveyId, perspectiveId, interlocutorId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { addFinalValue, setAddFinalValue } = useContext(
    AddStandardContext
  );

  useEffect(() => {
    setAddFinalValue(
      produce((draft) => {
        draft.interlocutorId = Number(interlocutorId);
      })
    );
  }, []);

  console.log(...addFinalValue?.standardRequirements);

  const handleSubmit = async () => {
    const agencyStandards = [
      ...addFinalValue?.agencyStandards?.agencies,
      ...addFinalValue?.agencyStandards?.commonAgencies,
    ];

    const allQuestions = addFinalValue.standardRequirements.map(item => item.subStandardRequirements ?? []).flat()

    if(!allQuestions || !allQuestions.length){
      errorToast("يجب إدخال سؤال على الأقل");
      return;
    }
  
    if (!addFinalValue?.title?.trim()) {
      errorToast("يجب إدخال البيانات المطلوبة فى اسم المعيار");
      return;
    }

    if (!addFinalValue?.target?.trim()) {
      errorToast("يجب إدخال البيانات المطلوبة فى وصف المعيار");
      return;
    }

    if (addFinalValue?.standardRequirements?.length == 0) {
      errorToast("يجب إدخال سؤال على الأقل من بيانات السؤال");
      return;
    }

    const atLeastOneQuestionHasAttachments = allQuestions.some(item => item.haveAttachments === 1)

    if (atLeastOneQuestionHasAttachments && addFinalValue?.standardProofs?.length == 0) {
      errorToast("يجب إدخال مستند على الأقل فى طريقة الإثبات");
      return;
    }

    const standardRequirements = [
      ...addFinalValue?.standardRequirements,
    ];

    let standardProofs = [...addFinalValue?.standardProofs]


    // check if all standard requirements have subStandard requirements and ignore first item
    const allStandardRequirementsHaveSubStandardRequirements =
      standardRequirements
        .slice(1)
        .every((item) => item?.subStandardRequirements?.length > 0);

    // show error if not all standard requirements have subStandard requirements
    if (!allStandardRequirementsHaveSubStandardRequirements) {
      errorToast("يجب إضافة سؤال للبعد");
      return;
    }

    standardProofs = standardProofs?.map(
      ({
        subStandardProofRequirements,
        standardProofSubRequirements,
        ...item
      }) => ({
        ...item,
        numberOfStandardProof: generateRandomNumber(0, 2147483647),
        numberOfStandardRequirements: subStandardProofRequirements?.map(
          (item) => {
            return item?.value;
          }
        ),
        // agencyStandardProofs: item?.agencyStandardProofs
        //   ?.map((ele) => ({
        //     agencyId: ele.value,
        //   }))
        //   .filter((item) => item?.agencyId),
      })
    );

    const standardRequirementList = standardRequirements?.map((item) => ({
      ...item,
    }));
  
    const pastSurveyStandardAssociations = addFinalValue?.pastSurveyStandardAssociations?.map(
      (item) => ({
        pastSurveyStandardId: item?.id,
      })
    );
    setLoading(true);
    let res;

    try {
      res = await axiosPostRequest(environment.addStandard, {
        ...addFinalValue,
        agencyStandards,
        standardRequirements: standardRequirementList,
        standardProofs,
        pastSurveyStandardAssociations,
      });
    } catch (err) {
      errorToast("حدث خطأ ");
    }

    setLoading(false);
    if (res.success) {
      successToast("تم إضافة المعيار بنجاح");
      history.push(
        `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`
      );
    }
  };

  return (
    <div className="my-8 mt-12 flex justify-between rounded-lg items-center flex-wrap gap-x-6 px-6 py-3 bg-white fixed bottom-6 left-20  shadow-xl">
      <div className="flex gap-2 mb-1">
        <p>
          {addFinalValue.standardRequirements?.length - 1 || "0"}{" "}
          <span className="text-light">بعد مضاف ،</span>
        </p>
        <p>
          {addFinalValue.standardProofs?.length || "0"}{" "}
          <span className="text-light">مستند مضاف ،</span>
        </p>
        <p>
        {(addFinalValue.standardRequirements?.map(item => item?.subStandardRequirements)?.flat() ?? []).length || "0"}{" "}
          <span className="text-light">سؤال مضاف</span>
        </p>
      </div>
      <div className="text-end">
        <AppButton loading={loading} onClick={handleSubmit}>
          حفظ
        </AppButton>
      </div>
    </div>
  );
};

export default SubmitAddStandard;
