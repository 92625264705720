import React, { useContext } from "react";
import {
  isAdminFunc,
  isExpert,
  isExpertManager,
} from "../../../../../../helpers/isPermission";
import { StageContext } from "../../../../../../contexts/stageContext";

export const NotesAndRecomendation = ({
  selectedRequirementsTab,
  expertValue,
  experManagertValue,
  reviewerValue,
}) => {


  const { currentStage } = useContext(StageContext);
  const isSecondStage = currentStage?.displayOrder === 2;
  const isAdminInSecondStage = (isAdminFunc() && isSecondStage);

  const isExpertManagerView = isExpertManager() || isAdminFunc();
  return (
    <>
      <div className="pt-3">
        <h2 className="text-[#292069] text-lg font-bold mb-1">
          {selectedRequirementsTab === "note" ? "ملاحظات" : "توصية"} المدقق:
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: reviewerValue || "",
          }}
        ></div>
        {!reviewerValue && <div>لا يوجد ملاحظات</div>}
      </div>

      {/* {(isExpert() || isExpertManagerView) && ( */}
        <>
          <div className="pt-3">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              {selectedRequirementsTab === "note" ? "ملاحظات" : "توصية"} الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: expertValue || "",
              }}
            ></div>
            {!expertValue && <div>لا يوجد ملاحظات</div>}
          </div>
        </>
      {/* )} */}

      {isExpertManagerView && (
        <>
          <div className="pt-3">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              {selectedRequirementsTab === "note" ? "ملاحظات" : "توصية"} رئيس الخبراء:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: experManagertValue || "",
              }}
            ></div>
            {!experManagertValue && <div>لا يوجد ملاحظات</div>}
          </div>
        </>
      )}
    </>
  );
};
