import React, { useState } from "react";
import AppDivider from "../../../../../../../components/appDivider";
import AppButton from "../../../../../../../components/buttons/appButton";
import SubRequirementList from "./subRequirementList";
import AddQuestion from "./addQuestion";

const StandardQuestions = ({ subReq, requirementIndx, disabled, structureSubStandardRequirementDirectly }) => {
  const [showAdd, setShowAdd] = useState(false);
  console.log("subReq", subReq);
  return (
    <div className="mt-7">
      <AppDivider />
      <div className="flex justify-between mt-8">
        <h3 className="font-semibold">الأسئلة</h3>
        {!showAdd && (
          <AppButton className="px-5" onClick={setShowAdd((prev) => !prev)}>
            إضافة سؤال 
          </AppButton>
        )}
      </div>
      {showAdd && !disabled && (
        <AddQuestion isSpecial={structureSubStandardRequirementDirectly} requirementIndx={requirementIndx} subReq={subReq} />
      )}
      <div className="mt-4">
        <SubRequirementList
          requirementIndx={requirementIndx}
          subReq={subReq}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default StandardQuestions;
