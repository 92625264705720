import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

const FlipCard = ({
  title,
  frontContent,
  backContent,
  fullWidth,
  isFilp = true,
  showBackContentOnly = false,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <div
      className="shadow-lg h-full flex flex-col"
      style={{
        borderRadius: "20px",
        border: "1px solid #F5F5F5",
        background: "white",
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <div
        style={{
          borderBottom: "2px solid #F5F5F5",
        }}
        className="flex flex-row justify-between w-full items-center py-[21px] px-[23px] "
      >
        <p className="text-base font-semibold text-[#2a2069]">{title}</p>

        {isFilp && (
          <div onClick={handleClick} className="cursor-pointer">
            <FontAwesomeIcon icon={faRepeat} />
          </div>
        )}
      </div>

      {isFilp && (
        <div className="py-[21px] px-[23px] flex-1">
          {isFlipped ? backContent : frontContent}
        </div>
      )}

      {!isFilp && !showBackContentOnly && (
        <div className="py-[21px] px-[23px] flex-1">{frontContent}</div>
      )}

      {showBackContentOnly && (
        <div className="py-[21px] px-[23px] flex-1">{backContent}</div>
      )}
    </div>
  );
};

export default FlipCard;
