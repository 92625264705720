import { useQuery } from "@tanstack/react-query";
import { agencyId, expertId, expertManagerId, isAgency, reviewerId, surveyId, userId } from "../../helpers/isPermission";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";
import { useGetAttachmentBase64URL } from "../../hooks/useGetAttachmentBase64URL";

export const useGetAgencyAnswerReportData = (_selectedAgencyId) => {

  const selectedAgencyId =  agencyId() ?? _selectedAgencyId;
  const {
    data: answersAverageStatisics,
    isLoading: isGettingAnswersAverageStatisics,
  } = useQuery({
    queryKey: [
      "GetAnswersOnPerspectiveInterlocutorStandardAverageStatisics",
      surveyId(),
      userId(),
      selectedAgencyId,
    ],
    queryFn: () =>
      axiosGetRequest(
        environment.GetAnswersOnPerspectiveInterlocutorStandardAverageStatisics,
        {
          agencyId: selectedAgencyId || 0,
          surveyId: surveyId(),
          reviewerId: reviewerId(),
          expertId: expertId(),
          expertManagerId: expertManagerId(),
        }
      ),
    select: (data) => {
      const surveyReports = data?.result?.surveyReports;
      const structureSubStandardRequirementDirectly = data?.result?.structureSubStandardRequirementDirectly;

      const getCommitmentLevelTitle = (item) => structureSubStandardRequirementDirectly
        ? item.evaluationDgreeCommitmentLevelTitle
        : item.percentageCommitmentLevelTitle;

      const getUserPercentagesAvg = (item, key) => structureSubStandardRequirementDirectly
        ? item?.[`${key}UserEvaluationDgreeAvg`]
        : item?.[`${key}UserPercentagesAvg`];

      return {
        ...data?.result,
        perspectiveLsts: data?.result?.perspectiveLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          perspectiveUserPercentagesAvg: getUserPercentagesAvg(item, 'perspective')
        })),
        interlocutorLsts: data?.result?.interlocutorLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          interlocutorUserPercentagesAvg: getUserPercentagesAvg(item, 'interlocutor')
        })),
        standardLsts: data?.result?.standardLsts.map((item) => ({
          ...item,
          percentageCommitmentLevelTitle: getCommitmentLevelTitle(item),
          standardUserPercentagesAvg: getUserPercentagesAvg(item, 'standard')
        })),
        surveyReports: {
          ...surveyReports,
          perspectives: surveyReports?.perspectives?.map(item => {
            return {
              ...item,
              commitmentLevelTitle: getCommitmentLevelTitle(item),
              userPercentagesAvg: getUserPercentagesAvg(item, 'perspective'),

              interlocutors: item.interlocutors?.map(interLoc => {
                return {
                  ...interLoc,
                  commitmentLevelTitle: getCommitmentLevelTitle(interLoc),
                  userPercentagesAvg: getUserPercentagesAvg(interLoc, 'interlocutor'),
                  standards: interLoc.standards?.map(stand => {
                    return {
                      ...stand,
                      commitmentLevelTitle: getCommitmentLevelTitle(stand),
                      userPercentagesAvg: getUserPercentagesAvg(stand, 'standard'),
                    }
                  })
                }
              })
            }
          })
        }
      };
    },
  });

  const reportType = answersAverageStatisics?.reportType ?? "1";
  const agencyTitle = answersAverageStatisics?.agencyName ?? "";
  const agencySector = answersAverageStatisics?.agencySector ?? "";
  const agencyLogoId = answersAverageStatisics?.attachmentId ?? null;
  let agencyLogoUrl = null;
  const surveyReports = answersAverageStatisics?.surveyReports ?? null;
  const surveyTitle = surveyReports?.surveyTitle ?? "";

  const surveyCommitmentLevelTitle = answersAverageStatisics?.structureSubStandardRequirementDirectly
    ? surveyReports?.evaluationDgreeCommitmentLevelTitle
    : surveyReports?.percentageCommitmentLevelTitle;

  const surveyPercentagesAvg = answersAverageStatisics?.structureSubStandardRequirementDirectly
    ? surveyReports?.surveyUserEvaluationDgreeAvg
    : surveyReports?.surveyUserPercentagesAvg;

  agencyLogoUrl = useGetAttachmentBase64URL(agencyLogoId);

  const returnedData = {
    answersAverageStatisics,
    isGettingAnswersAverageStatisics,
    agencyTitle,
    agencySector,
    agencyLogoUrl,
    surveyReports,
    surveyTitle,
    surveyCommitmentLevelTitle,
    surveyPercentagesAvg,
    reportType,
  }
  
  console.log('✌️answersAverageStatisics hhh--->', {answersAverageStatisics, returnedData, agencyLogoId});

  return returnedData;
};
