import { useEffect, useState } from "react"
import file from '../../../assets/icons/file.svg'
import download from '../../../assets/icons/download.svg'
import models from '../../../assets/icons/models.svg'
import NewTitle from "../../../components/NewTitle"
import { useHistory, useParams } from "react-router-dom"
import { LogoHeader } from "../../../assets/imgs/Index"
import { Button, CModal, DropdownMultiSelect, Ecard, FModal, InputLabel, NewECard, SModal, Title } from "../../../components"
import { useTranslation } from "react-i18next"
import environment from "../../../services/environment"
import { get } from "../../../services/Request"
import Skeleton from "react-skeleton-loader"
import ReactPaginate from "react-paginate"
import { programsIcon, search } from "../../../assets/icons/Index"
import NewHeader from "../../../layouts/header/NewHeader"
import { isAgency, isExpert, isExpertManager } from "../../../helpers/isPermission"

const CardDetails = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [innovativeFormAgencyAnswer, setInnovativeFormAgencyAnswer] = useState("")
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getInnovativeFormAgencyAnswer(0)
    }, [])
    const getInnovativeFormAgencyAnswer = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
        get(environment.getInnovativeFormAgencyAnswer + "?Id=" + id, config, (res) => {
            // debugger;
            if (res.status == 403) {
                setMessage({ type: 'error', message: res.data.error.message }, setOpenConfirmModal(true))
            } else if (res.status == 500) {
                setMessage({ type: 'error', message: res.data.error.message }, setOpenConfirmModal(true))
            } else if (res.status == 200) {
                console.log(res)
                setInnovativeFormAgencyAnswer(res.data.result)

            }
        })
    }

    const handleDownloadDes = () => {
        if (innovativeFormAgencyAnswer.descriptionAttachmentUrl) {
            // Simulating file download by creating an anchor element
            const url = innovativeFormAgencyAnswer.descriptionAttachmentUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', innovativeFormAgencyAnswer.descriptionAttachmentName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No file chosen');
        }
    };

    const handleDownloadFinancial = () => {
        if (innovativeFormAgencyAnswer.financialImpactAttachmentUrl) {
            // Simulating file download by creating an anchor element
            const url = innovativeFormAgencyAnswer.financialImpactAttachmentUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', innovativeFormAgencyAnswer.financialImpactAttachmentName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No file chosen');
        }
    };

    const handleDownloadAgency = () => {
        if (innovativeFormAgencyAnswer.impactOnAgencyAttachmentUrl) {
            // Simulating file download by creating an anchor element
            const url = innovativeFormAgencyAnswer.impactOnAgencyAttachmentUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', innovativeFormAgencyAnswer.impactOnAgencyAttachmentName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No file chosen');
        }
    };
    const handleDownloadBeneficiaries = () => {
        if (innovativeFormAgencyAnswer.impactOnBeneficiariesAttachmentUrl) {
            // Simulating file download by creating an anchor element
            const url = innovativeFormAgencyAnswer.impactOnBeneficiariesAttachmentUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', innovativeFormAgencyAnswer.impactOnBeneficiariesAttachmentName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No file chosen');
        }
    };
    const handleDownloadReliability = () => {
        if (innovativeFormAgencyAnswer.reliabilityAndCompetitivenessAttachmentUrl) {
            // Simulating file download by creating an anchor element
            const url = innovativeFormAgencyAnswer.reliabilityAndCompetitivenessAttachmentUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', innovativeFormAgencyAnswer.reliabilityAndCompetitivenessAttachmentName); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No file chosen');
        }
    };

  return (
    // <div className='bg-white m-5 p-5 rounded-[30px] flex flex-col items-center'>
    <div className="bg-white rounded-[30px] m-5">
    <NewHeader/>
    {/* <Title title={"المؤشرات"} withoutButton={true} iconTitle={programsIcon} single={true} /> */}
    <Title title={"النماذج الابداعية"} withoutButton={true} iconTitle={programsIcon} subTitle={innovativeFormAgencyAnswer.name} toPage={()=>history.push("/creativeCards")} subTitleSeconed={"تقييم النموذج"} seconed={true} />

          {/* <NewTitle icon={models} titlesArr={["النماذج الأبداعية", "النموذج", "تقييم النموذج"]} /> */}
          {loading ? <Skeleton animated={true} color={"#2B296969"} width={"100%"} height={"500px"} count={1} /> :
              (
      <div className='bg-[#F9F9FF] w-full h-full p-5 rounded-3xl flex flex-col justify-center'>
        <div className='flex flex-row items-center justify-center mt-2 mb-4'>
            <svg className='ml-2' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#251D5B"/>
    <path d="M23.2471 31.8719C23.2471 33.7603 25.1247 35.4588 26.9914 34.8836C27.5716 34.7043 28.0792 34.3444 28.4404 33.8563C28.8016 33.3683 28.9974 32.7775 28.9992 32.1703C29.6082 32.2302 30.2222 32.1268 30.778 31.8708C31.3338 31.6148 31.8114 31.2155 32.1617 30.7138C32.5121 30.212 32.7225 29.626 32.7713 29.0161C32.8202 28.4061 32.7057 27.7941 32.4396 27.243C32.9104 26.8339 33.2779 26.3195 33.5122 25.7415C33.7466 25.1636 33.8411 24.5385 33.7882 23.9171C33.7354 23.2957 33.5365 22.6956 33.2079 22.1655C32.8793 21.6355 32.4301 21.1905 31.897 20.8669C35.6955 18.6963 32.1086 12.8411 28.4349 15.5218C28.5492 15.1724 28.5907 14.8033 28.5566 14.4372C28.5225 14.0712 28.4137 13.7161 28.2369 13.3938C28.06 13.0715 27.8189 12.789 27.5284 12.5637C27.2379 12.3384 26.9043 12.1751 26.5482 12.0839C26.1921 11.9928 25.8211 11.9756 25.4581 12.0337C25.0951 12.0917 24.7478 12.2236 24.4379 12.4212C24.1279 12.6189 23.8619 12.8781 23.6561 13.1827C23.4504 13.4873 23.3093 13.8309 23.2417 14.1923L23.2471 31.8719Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.4401 15.5271C28.2427 15.9636 27.9203 16.3316 27.5136 16.5847C27.1069 16.8378 26.6343 16.9645 26.1555 16.9488" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.902 20.8722C31.902 20.8722 29.829 21.4909 28.7112 20.0908" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.4394 27.2489C32.4394 27.2489 30.3665 27.5202 29.5254 26.2341" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.9991 32.1762C28.9991 32.1762 26.9153 31.7584 26.9967 29.8374" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.8141 28.0731H18.4877C18.4877 25.1971 15.2752 24.9149 15.6279 20.6008C15.7567 19.1278 16.4058 17.7488 17.4587 16.7107C18.5117 15.6726 19.8999 15.0434 21.3746 14.9355H21.8141" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.45 28.9417V30.027C18.45 30.7624 18.742 31.4677 19.2621 31.9877C19.7821 32.5077 20.4875 32.7999 21.2229 32.7999H21.9772" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.2512 32.8811V33.4238C20.2512 33.8382 20.4159 34.2358 20.709 34.5289C21.002 34.822 21.3996 34.9866 21.8141 34.9866" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.814 13.4435V12.0164" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1932 13.9589L18.7048 12.624" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9086 15.3429L15.9915 14.2522" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.2318 17.4262L14 16.7153" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.814 34.9924C22.3315 35.0388 22.8464 34.8788 23.2466 34.5474" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.4917 18.1373C20.7864 17.4638 21.1198 16.8079 21.4902 16.1729C20.4795 16.4498 19.5775 17.0291 18.9054 17.8332C18.2332 18.6373 17.823 19.6276 17.7296 20.6715C17.5668 22.6576 18.2723 23.5204 19.07 24.5188C19.354 24.8643 19.6168 25.2268 19.8569 25.6042C19.5556 24.5888 19.42 23.5315 19.4553 22.473C19.5277 20.9758 19.8791 19.5053 20.4917 18.1373Z" stroke="#1CC182" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h3 className='mb-1 text-sm text-[#292069]'>النموذج</h3>
            <svg className='mr-2' width="117" height="48" viewBox="0 0 117 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="48" y1="23" x2="117" y2="23" stroke="#DBE0EF" stroke-width="2"/>
<rect width="48" height="48" rx="24" fill="#DBE0EF"/>
<g clip-path="url(#clip0_1016_26568)">
<path d="M26.3147 31.5274H20.2667C20.2668 32.2786 20.5044 33.0103 20.9449 33.6164C21.3854 34.2224 22.0059 34.6714 22.7167 34.8983V35.9457C22.7167 36.1427 22.7943 36.3315 22.9325 36.4708C23.0708 36.61 23.2582 36.6883 23.4537 36.6883H24.6376C24.833 36.6883 25.0205 36.61 25.1587 36.4708C25.2969 36.3315 25.3746 36.1427 25.3746 35.9457V34.8983C26.0853 34.6714 26.7059 34.2224 27.1464 33.6164C27.5869 33.0103 27.8244 32.2786 27.8245 31.5274V30.2565C27.843 29.156 28.2786 28.1045 29.0421 27.3174C30.0269 26.3213 30.6963 25.0532 30.9655 23.6736C31.2347 22.294 31.0917 20.8649 30.5546 19.5673C30.0174 18.2696 29.1103 17.1617 27.9479 16.3837C26.7856 15.6057 25.4203 15.1927 24.0249 15.1968C22.6295 15.201 21.2667 15.6221 20.1089 16.407C18.9511 17.1919 18.0505 18.3052 17.5209 19.606C16.9914 20.9069 16.8568 22.3368 17.1341 23.7147C17.4114 25.0927 18.0882 26.3568 19.0788 27.347C19.4575 27.7292 19.7574 28.1831 19.9613 28.6825C20.1652 29.1819 20.2691 29.717 20.2669 30.2571" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.3745 34.8982H24.0457" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.0457 13.5342V12.312" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.784 14.6847L19.1775 13.6262" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.6645 17.8281L15.614 17.217" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5226 22.1218H14.3096" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.6903 22.1218H32.4773" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.4268 17.8281L32.4773 17.217" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.3071 14.6847L28.9136 13.6262" stroke="#7D8BAB" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.4495 18.1783L25.157 19.6227C25.2727 19.859 25.4437 20.0634 25.6553 20.2183C25.8669 20.3732 26.1127 20.4739 26.3715 20.5118L27.9534 20.7434C28.046 20.757 28.1329 20.7964 28.2044 20.8572C28.2759 20.9179 28.3291 20.9977 28.358 21.0873C28.3869 21.1769 28.3904 21.2729 28.368 21.3644C28.3456 21.4559 28.2983 21.5393 28.2314 21.6052L27.0866 22.7295C26.8993 22.9134 26.7593 23.1405 26.6785 23.391C26.5977 23.6416 26.5785 23.9082 26.6227 24.1679L26.893 25.7555C26.9087 25.8484 26.8984 25.9439 26.8631 26.0312C26.8278 26.1185 26.769 26.1941 26.6934 26.2495C26.6177 26.3049 26.5281 26.3379 26.4348 26.3447C26.3415 26.3515 26.2482 26.3319 26.1654 26.2881L24.7504 25.5386C24.5189 25.4159 24.2614 25.3519 23.9999 25.3519C23.7384 25.3519 23.4808 25.4159 23.2494 25.5386L21.8344 26.2881C21.7516 26.3319 21.6582 26.3515 21.5649 26.3447C21.4716 26.3379 21.3821 26.3049 21.3064 26.2495C21.2307 26.1941 21.1719 26.1185 21.1366 26.0312C21.1014 25.9439 21.091 25.8484 21.1068 25.7555L21.377 24.1681C21.4212 23.9084 21.4021 23.6418 21.3213 23.3912C21.2405 23.1406 21.1004 22.9136 20.9131 22.7297L19.7684 21.6052C19.7015 21.5393 19.6541 21.4559 19.6318 21.3644C19.6094 21.2729 19.6128 21.1769 19.6417 21.0873C19.6707 20.9977 19.7239 20.9179 19.7954 20.8572C19.8669 20.7964 19.9538 20.757 20.0463 20.7434L21.6283 20.5118C21.8871 20.4739 22.1328 20.3732 22.3444 20.2183C22.556 20.0634 22.727 19.859 22.8427 19.6227L23.5502 18.1783C23.5917 18.0939 23.6558 18.0228 23.7352 17.973C23.8147 17.9233 23.9063 17.897 23.9999 17.897C24.0934 17.897 24.1851 17.9233 24.2645 17.973C24.344 18.0228 24.4081 18.0939 24.4495 18.1783Z" stroke="#DBE0EF" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.2595 24.5631C24.871 24.3573 24.4388 24.2497 23.9999 24.2497C23.5611 24.2497 23.1288 24.3573 22.7404 24.5631L22.3874 24.7503L22.4548 24.3544C22.5291 23.9186 22.497 23.4711 22.3614 23.0506C22.2259 22.63 21.9908 22.2489 21.6765 21.9403L21.3916 21.6601L21.7859 21.6023C22.2201 21.5387 22.6324 21.3697 22.9874 21.1098C23.3423 20.85 23.6292 20.507 23.8234 20.1106L23.9999 19.7502L24.1765 20.1104C24.3706 20.5069 24.6575 20.8499 25.0125 21.1098C25.3674 21.3697 25.7798 21.5387 26.214 21.6023L26.6082 21.6601L26.3234 21.9403C26.009 22.2489 25.7739 22.63 25.6383 23.0506C25.5027 23.4712 25.4707 23.9187 25.5451 24.3545L25.6123 24.7502L25.2595 24.5631Z" stroke="#1CC182" stroke-miterlimit="10"/>
</g>
<defs>
<clipPath id="clip0_1016_26568">
<rect width="20" height="25" fill="white" transform="translate(14 12)"/>
</clipPath>
</defs>
            </svg>
        </div>
        <form className='bg-white rounded-3xl p-5 flex flex-col justify-between items-center'>
          <section className='w-full flex flex-col'>
            <label className='text-[16px] text-[#292069] mb-3'>اسم الفكرة</label>
                      <div className='text-sm text-[#292069] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5'>{innovativeFormAgencyAnswer.name }</div>
          </section>
          <section className='flex flex-col items-start w-full mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>وصف للفكرة</label>
            <div className='w-full flex flex-col'>
                <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                              {innovativeFormAgencyAnswer.description}
                </div>
              <section className='flex flex-row'>
                <div className='bg-[#F9F9FF] w-1/2 ml-1 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>
                  <div className='flex flex-row items-center'>
                    <img style={{width: '15px', height: '15px'}} src={file} alt='file'/>
                    <p className='text-sm text-[#161318] mr-3'> {innovativeFormAgencyAnswer.descriptionAttachmentName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                                      <p className='text-sm text-[#7D8BAB] ml-3'>{innovativeFormAgencyAnswer.descriptionAttachmentZize}KB</p>
                    <img onClick={handleDownloadDes} src={download} alt='download'/>
                  </div>
                </div>
             
            </section>
            </div>
          </section>
          <section className='w-full flex flex-col mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>بداية إطلاق الفكرة</label>
            <div className='w-full bg-[#292069] rounded-3xl py-3 px-6 flex flex-row justify-between items-center'>
                          <div className="text-white">{innovativeFormAgencyAnswer.launchdate}</div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2V5" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 2V5" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.5 9.08984H20.5" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
          </section>
          <section className='flex flex-row bg-white justify-between w-full mt-5'>
            <div className='flex flex-col p-7 bg-[#F9F9FF] w-1/2 ml-1 rounded-2xl'>
                <h3 className='text-[#292069] text-[16px]'>نوعية الفكرة</h3>
                <div className='flex flex-row mt-5'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.75" width="24" height="22.8" rx="5" fill="#1CC081"/>
    <path d="M19.6515 6.7812C19.1874 6.33972 18.4337 6.34 17.969 6.7812L10.3968 13.9751L7.03129 10.7779C6.56656 10.3364 5.81326 10.3364 5.34854 10.7779C4.88382 11.2193 4.88382 11.935 5.34854 12.3765L9.55526 16.3728C9.78747 16.5935 10.092 16.704 10.3965 16.704C10.701 16.704 11.0058 16.5937 11.238 16.3728L19.6515 8.37978C20.1162 7.93861 20.1162 7.22266 19.6515 6.7812Z" fill="white"/>
                    </svg>
                              <p className='mr-2 text-[15px]'>{innovativeFormAgencyAnswer.innovativeFormIdeaTypeTitle}</p>
                </div>
            </div>
            <div className='flex flex-col p-7 bg-[#F9F9FF] w-1/2 mr-1 rounded-2xl'>
                <h3 className='text-[#292069] text-[16px]'>نضج الفكرة</h3>
                <div className='flex flex-row mt-5'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.75" width="24" height="22.8" rx="5" fill="#1CC081"/>
    <path d="M19.6515 6.7812C19.1874 6.33972 18.4337 6.34 17.969 6.7812L10.3968 13.9751L7.03129 10.7779C6.56656 10.3364 5.81326 10.3364 5.34854 10.7779C4.88382 11.2193 4.88382 11.935 5.34854 12.3765L9.55526 16.3728C9.78747 16.5935 10.092 16.704 10.3965 16.704C10.701 16.704 11.0058 16.5937 11.238 16.3728L19.6515 8.37978C20.1162 7.93861 20.1162 7.22266 19.6515 6.7812Z" fill="white"/>
                    </svg>
                    <p className='mr-2 text-[15px]'>{innovativeFormAgencyAnswer.innovativeFormIdeaMaturityTitle}</p>
                </div>
            </div>
          </section>
          <section className='w-full flex flex-col mt-5'>
            <label typeof='dropdown' className='text-[16px] text-[#292069] mb-3'>العنصر المرتبط به الفكرة</label>
            <div className='flex flex-row mt-5 bg-[#292069] rounded-3xl py-3 px-6 w-full text-white'>
            العنصر المرتبط به الفكرة
            </div>
          </section>
          <section className='w-full flex flex-col my-5'>
            <label className='text-[16px] text-[#292069] mb-3'>أثر الفكرة</label>
            <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                          {innovativeFormAgencyAnswer.ideaImpact}
                </div>
          </section>
        </form>
        <div className='bg-white w-full mt-7 p-5 rounded-3xl flex flex-col justify-center'>
        <section className='flex flex-col items-start w-full mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>الأثر المالى</label>
            <div className='w-full flex flex-col'>
                <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                              {innovativeFormAgencyAnswer.financialImpact}
                </div>
              <section className='flex flex-row'>
                <div className='bg-[#F9F9FF] w-1/2 ml-1 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>
                  <div className='flex flex-row items-center'>
                    <img style={{width: '15px', height: '15px'}} src={file} alt='file'/>
                    <p className='text-sm text-[#161318] mr-3'> {innovativeFormAgencyAnswer.financialImpactAttachmentName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className='text-sm text-[#7D8BAB] ml-3'> {innovativeFormAgencyAnswer.financialImpactAttachmentSize}KB</p>
                    <img onClick={handleDownloadFinancial}  src={download} alt='download'/>
                  </div>
                </div>ImpactOnAgency
            
            </section>
            </div>
          </section>
          <section className='flex flex-col items-start w-full mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>الأثر على الجهة </label>
            <div className='w-full flex flex-col'>
                <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                              {innovativeFormAgencyAnswer.impactOnAgency}
                </div>
              <section className='flex flex-row'>
                <div className='bg-[#F9F9FF] w-1/2 mr-1 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>
                  <div className='flex flex-row items-center'>
                    <img style={{width: '15px', height: '15px'}} src={file} alt='file'/>
                    <p className='text-sm text-[#161318] mr-3'>{innovativeFormAgencyAnswer.impactOnAgencyAttachmentName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className='text-sm text-[#7D8BAB] ml-3'>{innovativeFormAgencyAnswer.impactOnAgencyAttachmentSize}KB</p>
                    <img onClick={handleDownloadAgency} src={download} alt='download'/>
                  </div>
                </div>
            </section>
            </div>
          </section>
          <section className='flex flex-col items-start w-full mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>الأثر على المتنافسين</label>
            <div className='w-full flex flex-col'>
                <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                              {innovativeFormAgencyAnswer.impactOnBeneficiaries}
                </div>
              <section className='flex flex-row'>
                <div className='bg-[#F9F9FF] w-1/2 ml-1 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>
                  <div className='flex flex-row items-center'>
                    <img style={{width: '15px', height: '15px'}} src={file} alt='file'/>
                                      <p className='text-sm text-[#161318] mr-3'> {innovativeFormAgencyAnswer.impactOnBeneficiariesAttachmentName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                                      <p className='text-sm text-[#7D8BAB] ml-3'>{innovativeFormAgencyAnswer.impactOnBeneficiariesAttachmentSize}KB</p>
                    <img onClick={handleDownloadBeneficiaries} src={download} alt='download'/>
                  </div>
                </div>
            </section>
            </div>
          </section>
          <section className='flex flex-col items-start w-full mt-5'>
            <label className='text-[16px] text-[#292069] mb-3'>الاعتماد والتنافسية</label>
            <div className='w-full flex flex-col'>
                <div className='text-[14px] mb-2 w-full h-full bg-[#F9F9FF] border-none rounded-3xl p-5'>
                              {innovativeFormAgencyAnswer.reliabilityAndCompetitiveness}
                </div>
              <section className='flex flex-row'>
                <div className='bg-[#F9F9FF] w-1/2 mr-1 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>
                  <div className='flex flex-row items-center'>
                    <img style={{width: '15px', height: '15px'}} src={file} alt='file'/>
                                      <p className='text-sm text-[#161318] mr-3'>{innovativeFormAgencyAnswer.reliabilityAndCompetitivenessAttachmentName}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className='text-sm text-[#7D8BAB] ml-3'>{innovativeFormAgencyAnswer.reliabilityAndCompetitivenessAttachmentSize}KB</p>
                    <img onClick={handleDownloadReliability} src={download} alt='download'/>
                  </div>
                </div>
            </section>
            </div>
          </section>
      </div>
      {(isExpert() || isExpertManager()) && 
        <div className='w-full flex flex-row justify-end'>
            <Button onClick={()=>history.push(`/creativeCards/${id}/evaluation`)} title="تقييم" style={{marginBottom: "50px", marginTop: "50px", width: "12rem"}}/>
        </div>
      }
                  </div>
              )
          }
    </div>
  )
}

export default CardDetails