import { StyleSheet, Text, View } from "@react-pdf/renderer";

export function Footer({ page }) {
    const styles = StyleSheet.create({
        footerContainer: {
            position: "absolute",
            bottom: 15,
            left: 20,
            right: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#666",
            fontSize: 8,
            fontWeight: 300,
        },
        pageCount: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 8,
        },

    });

    return (
        <View style={styles.footerContainer}>
            <Text>تقرير جاهزية تبني التقنيات الناشئة 2025م</Text>
            <View style={styles.pageCount}>
                <Text>
                    {page}
                </Text>
            </View>
        </View>
    );
}