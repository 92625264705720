import { useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import { useContext } from "react";
import { StageContext } from "../../../contexts/stageContext";
import { permissionsCheck } from "../../../helpers/utils";

export const useGetAgencyStats = () => {
  const { stageForDashboard } = useContext(StageContext);

  const isAgency = () =>
    permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.Delegate");

  const surveyStageId = localStorage.getItem("stageForDashboard");
  const surveyId = localStorage.getItem("surveyId");
  const agencyId = localStorage.getItem("agencyId");

  const { data, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ["AgencyDashboardStats", stageForDashboard],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyStatstics, {
        agencyId: (isAgency() && agencyId) || null,
        surveyStageId: stageForDashboard || surveyStageId || 0,
        surveyId: surveyId
      }),
    // enabled: !!(stageForDashboard || surveyStageId || 0),
  });

  const {
    data: answeredStandardsResponse,
    isLoading: isGettingAnsweredStandards,
  } = useQuery({
    queryKey: ["getAnsweredStandards", stageForDashboard],
    queryFn: () =>
      axiosGetRequest(environment.getAnsweredStandards, {
        agencyId: (isAgency() && agencyId) || null,
        surveyStageId: stageForDashboard || surveyStageId || 0,
        surveyId: surveyId,
      }),
    // enabled: !!(stageForDashboard || surveyStageId || 0),
  });

  const {
    data: unAnsweredStandardsResponse,
    isLoading: isGettingUnAnsweredStandards,
  } = useQuery({
    queryKey: ["getNotAnsweredStandards", stageForDashboard],
    queryFn: () =>
      axiosGetRequest(environment.getNotAnsweredStandards, {
        agencyId: (isAgency() && agencyId) || null,
        surveyStageId: stageForDashboard || surveyStageId || 0,
        surveyId: surveyId,
      }),
    // enabled: !!(stageForDashboard || surveyStageId || 0),
  });

  const agencyStats = data?.result;
  const answeredStandards = answeredStandardsResponse?.result;
  const unAnsweredStandards = unAnsweredStandardsResponse?.result;

  return {
    data,
    agencyStats,
    isGettingDashboardStats,
    answeredStandards,
    isGettingAnsweredStandards,
    unAnsweredStandards,
    isGettingUnAnsweredStandards,
  };
};
