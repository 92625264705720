import {
  PDFViewer,
  // PDFDownloadLink,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
// import DownloadButton from "../../../components/DownloadButton";
import { DigitalTransformationReport } from "./DigitalTransformationReport";
import { useGetAgencyAnswerReportData } from "./useGetAgencyAnswerReportData";
import AppLoader from "../../components/loader";

// Register font
// Font.register({
//   family: "DiodrumArabic",
//   src: "/static/media/DiodrumArabic-Medium.513de230123ccaeaa9ad.ttf",
// });

const AgencyAnswerReport = ({ selectedAgencyId = 5303 }) => {

  const agencyAnswerReportData = useGetAgencyAnswerReportData(selectedAgencyId);

  return (
    <div className="bg-white rounded-lg m-5 p-5">
      {
        agencyAnswerReportData?.isGettingAnswersAverageStatisics ?
          <div className="h-[400px] w-full flex justify-center items-center">
            <AppLoader />
          </div>
          :
          <PDFViewer width="100%" height="1300px">
            <DigitalTransformationReport {...agencyAnswerReportData} />
          </PDFViewer>
      }
    </div>
  );
};

export default AgencyAnswerReport;
