import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";
import { processAnswerHistory } from "../utils";

export const useGetAnswerHistory = ({ standardId, agencyId }) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAnswerHistory } = useQuery({
    queryKey: ["AnswerHistory", standardId, agencyId],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswerHistory, {
        StandardId: standardId,
        AgencyId: agencyId,
      }),

    select: data => {
      const updatedReviewerAnswersHistory = [];
      data.result.reviewerAnswersHistory.forEach(answerHistory => {
        updatedReviewerAnswersHistory.push(answerHistory);
        updatedReviewerAnswersHistory.push(...processAnswerHistory(answerHistory));
      });

      const updatedExpertAnswersHistory = [];
      data.result.expertAnswersHistory.forEach(answerHistory => {
        updatedExpertAnswersHistory.push(answerHistory);
        updatedExpertAnswersHistory.push(...processAnswerHistory(answerHistory));
      });


      return {
        ...data?.result,
        reviewerAnswersHistory: updatedReviewerAnswersHistory,
        expertAnswersHistory: updatedExpertAnswersHistory
      }
    }
  });

  return {
    queryClient,
    data,
    isGettingAnswerHistory,
  };
};
