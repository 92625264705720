import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const AddNotesAndRecomendation = ({
  selectedRequirementsTab,
  newAnswer,
  setNewAnswer,
  value,
  propName,
}) => {

  return (
    <>
        <h2 className="text-[#292069] text-lg font-bold mb-1">
          {selectedRequirementsTab === "note" ? "ملاحظة" : "توصية"} الى الجهة:
        </h2>

      <div className="my-6">
        <CKEditor
          editor={ClassicEditor}
          data={
            value
          }
          onReady={(editor) => {
            setNewAnswer({
              ...newAnswer,
              [propName]: editor.getData(),
            });
          }}
          config={{
            language: "ar",

            heading: {
              options: [
                {
                  model: "paragraph",
                  title: "Paragraph",
                  class: "ck-heading_paragraph",
                },
                { model: "heading1", view: "h1", title: "Heading 1" }, // Corrected to 'h1'
                { model: "heading2", view: "h2", title: "Heading 2" }, // Corrected to 'h2'
                { model: "heading3", view: "h3", title: "Heading 3" }, // Corrected to 'h3'
              ],
            },

            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "Underline",
              "|",
              "link",
              "insertTable",
              "blockQuote",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              // 'outdent', 'indent',
            ],

            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,

              headers: {
                // 'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();

            setNewAnswer({
              ...newAnswer,
              [propName]: data,
            });
          }}
        />
      </div>
    </>
  );
};
